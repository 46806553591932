import { OrderSide } from "silverkoi"

export const SideValue = ({ side }: { side: OrderSide }) => {
  const colorCs = (() => {
    switch (side) {
      case "bid":
        return "text-green"
      case "ask":
        return "text-red"
    }
  })()

  const text = (() => {
    switch (side) {
      case "bid":
        return "Long"
      case "ask":
        return "Short"
    }
  })()

  const cs = colorCs + " w-max "
  return <div className={cs}>{text}</div>
}
