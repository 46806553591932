import { IconProps, IconSvg } from "./IconSvg"

export const Logout = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 24 25">
      <path
        d="M12 2.01001C13.6569 2.01001 15 3.35316 15 5.01001V11.01H10C9.44772 11.01 9 11.4577 9 12.01C9 12.5623 9.44772 13.01 10 13.01H15V19.01C15 20.6669 13.6569 22.01 12 22.01H6C4.34315 22.01 3 20.6669 3 19.01V5.01001C3 3.35316 4.34315 2.01001 6 2.01001H12Z"
        fill={fill}
      />
      <path
        d="M15 13.01L18.0858 13.01L16.2929 14.8029C15.9024 15.1934 15.9024 15.8266 16.2929 16.2171C16.6834 16.6076 17.3166 16.6076 17.7071 16.2171L20.5 13.4242C21.2811 12.6432 21.281 11.3768 20.5 10.5958L17.7071 7.8029C17.3166 7.41238 16.6834 7.41238 16.2929 7.8029C15.9024 8.19343 15.9024 8.82659 16.2929 9.21712L18.0858 11.01L15 11.01V13.01Z"
        fill={fill}
      />
    </IconSvg>
  )
}
