import { useEffect } from "react"
import { PlacesType } from "react-tooltip"

import { useTooltipActions } from "../hooks"
import { TooltipId } from "../types"
import { TooltipMessageContent } from "./TooltipMessageContent"

interface TableColumnLabelProps {
  tooltipId?: TooltipId
  tooltipPlace?: PlacesType
  labelText: string
  className?: string
}

export const TableColumnLabel = ({
  tooltipId,
  tooltipPlace,
  labelText,
  className,
}: TableColumnLabelProps) => {
  const { add, remove } = useTooltipActions()
  const id = tooltipId ? `tooltip-table-label-${tooltipId}` : undefined
  useEffect(() => {
    if (!id || !tooltipId) return

    const message = <TooltipMessageContent tooltipId={tooltipId} />
    add(id, { message, place: tooltipPlace ?? "bottom", width: "300px" })

    return () => {
      remove(id)
    }
  }, [add, remove, id, tooltipId, tooltipPlace])

  const labelClassName = id
    ? "underline decoration-dotted underline-offset-4 hover:cursor-help flex justify-start min-w-full " +
      (className ?? "")
    : (className ?? "")

  const attrs = {
    "data-tooltip-id": id,
    className: labelClassName,
    width: "max-content",
  }

  // NOTE: The nested divs with the outer flex exists so that label's container
  // is snug around the label text. This makes the tooltip pointer's location
  // look more natural.
  return (
    <div className="flex w-max">
      <div {...attrs}>{labelText}</div>
    </div>
  )
}
