import { BigDecimal } from "silverkoi/math"

import { FormatValueArgs, formatValue, valueToColor } from "../utils"

interface TableValueCellProps extends FormatValueArgs {
  value?: BigDecimal | number | string
  className?: string
  color?: boolean
  colorCsIfNotZero?: string
}

export const TableValueCell = (props: TableValueCellProps) => {
  const { value, color, className, colorCsIfNotZero } = props
  const text = typeof value === "string" ? value : formatValue(value, props)
  const style = (() => {
    if (typeof value === "string") return {}
    return value && color ? { color: valueToColor(value) } : {}
  })()

  const colorCs = (() => {
    if (value === undefined || typeof value === "string") return ""
    const v = typeof value === "number" ? BigDecimal.fromString(value.toString()) : value
    return colorCsIfNotZero && !v.isZero() ? colorCsIfNotZero : ""
  })()

  return (
    <div
      className={`flex flex-row items-center justify-start align-middle gap-1 w-max ${className} ${colorCs}`}
    >
      <div className="w-max min-w-max" style={style}>
        {text}
      </div>
    </div>
  )
}
