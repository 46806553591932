import { createContext } from "react"

import { PositionSnapshot } from "silverkoi"

import { TradeContextStore } from "../stores"

export interface TradeContextData {
  symbol: string
  position?: PositionSnapshot // TODO: move to store
  useTradeContextStore: TradeContextStore
}

// NOTE: The default value will never appear in production. See
// https://react.dev/reference/react/createContext#parameters for details.
export const TradeContext = createContext<TradeContextData>(
  undefined as unknown as TradeContextData,
)
