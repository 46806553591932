import { Hash } from "viem"

import { useBlockExplorerUrl } from "../hooks"

interface Props {
  txHash: Hash
  type: "submit" | "error" | "success"
}

export const TxInfoMessage = ({ txHash, type }: Props) => {
  const url = useBlockExplorerUrl(txHash)

  const onClick = () => {
    if (url) {
      window.open(url, "_blank")
    }
  }
  const txHashText = <span className="text-blue underline">{truncate(txHash)}</span>

  const content = (() => {
    switch (type) {
      case "submit":
        return (
          <>
            Tx {txHashText} submitted! {url ? "Click to view in explorer" : ""}
          </>
        )
      case "success":
        return (
          <>
            Tx {txHashText} succeeded! {url ? "Click to view in explorer" : ""}
          </>
        )
      case "error":
        return (
          <>
            Tx {txHashText} failed! {url ? "Click to view in explorer" : ""}
          </>
        )
    }
  })()

  return (
    <div className="cursor-pointer" onClick={onClick}>
      {content}
    </div>
  )
}

const truncate = (x: string): string => {
  if (x.length > 10) {
    return x.slice(0, 10) + "..."
  } else {
    return x
  }
}
