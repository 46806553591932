import { IconProps, IconSvg } from "./IconSvg"

export const SolidTrophy = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 13.5 13.5">
      <g
        id="material-symbols:trophy-sharp"
        transform="matrix(1, 0, 0, 1, -2.25, -2.25)"
        style={{ transformOrigin: "2.25px 2.25px" }}
      >
        <path
          id="Vector"
          d="M5.25 15.75V14.25H8.25V11.925C7.6375 11.7875 7.09075 11.5282 6.60975 11.1473C6.12875 10.7662 5.7755 10.288 5.55 9.7125C4.6125 9.6 3.82825 9.19075 3.19725 8.48475C2.56625 7.77875 2.2505 6.9505 2.25 6V3.75H5.25V2.25H12.75V3.75H15.75V6C15.75 6.95 15.4342 7.77825 14.8027 8.48475C14.1712 9.19125 13.387 9.6005 12.45 9.7125C12.225 10.2875 11.872 10.7657 11.391 11.1473C10.91 11.5288 10.363 11.788 9.75 11.925V14.25H12.75V15.75H5.25ZM5.25 8.1V5.25H3.75V6C3.75 6.475 3.8875 6.90325 4.1625 7.28475C4.4375 7.66625 4.8 7.938 5.25 8.1ZM12.75 8.1C13.2 7.9375 13.5625 7.6655 13.8375 7.284C14.1125 6.9025 14.25 6.4745 14.25 6V5.25H12.75V8.1Z"
          fill={fill}
        />
      </g>
    </IconSvg>
  )
}
