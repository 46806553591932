import { useEffect } from "react"

import { PositionSnapshot } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { useDefaultLeverage, useTradeContextActions } from "~/hooks"
import { TRADING_MODAL_WIDTH, tw2 } from "~/utils"
import { ActionModal } from "./ActionModal"
import { CurrentSizeAndCollateralCards } from "./CurrentSizeAndCollateralCards"
import { OrderTypeButtons } from "./OrderTypeButtons"
import { ScrollableArea } from "./ScrollableArea"
import { TradingPanelInputs } from "./TradingPanelInputs"
import { TransactionSummary } from "./TransactionSummary"

interface ReducePositionModalProps {
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  position: PositionSnapshot
}

export const ReducePositionModal = ({ isOpen, setIsOpen, position }: ReducePositionModalProps) => {
  return (
    <ActionModal position={position} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Body isOpen={isOpen} setIsOpen={setIsOpen} position={position} />
    </ActionModal>
  )
}

const Body = ({ isOpen, setIsOpen, position }: ReducePositionModalProps) => {
  const { reset, setInputMode, setLeverage, setOrderType, setSide } = useTradeContextActions()
  const defaultLeverage = useDefaultLeverage(position)
  const defaultSize = position.size.abs()
  const side = position.size.gt(BigDecimal.zero()) ? "ask" : "bid"
  const posDir = position.size.gt(BigDecimal.zero()) ? "Long" : "Short"
  const idleText = `Close ${posDir} Position`
  const pendingText = `Closing ${posDir} Position ...`

  const submitButtonProps = {
    simEnabled: isOpen,
    onSuccess: () => {
      setIsOpen(false)
    },
    txDescription: "reduce position",
    idleText,
    pendingText,
  }

  useEffect(() => {
    reset()
    setInputMode("ReducePosition")
    setLeverage(defaultLeverage)
    setOrderType("market")
    setSide(side)
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="flex flex-col h-full bg-black rounded-lg px-6 py-4"
      style={{ width: TRADING_MODAL_WIDTH }}
    >
      <div className="flex flex-row justify-center">
        <div className={`${tw2("font-modal-title")} self-center py-3`}>Close Position</div>

        <button
          className={
            "absolute right-4 " + "bg-black justify-center items-center text-white border-none"
          }
          onClick={() => setIsOpen(false)}
        >
          &#x2715;
        </button>
      </div>

      <div className="shrink-0 h-2" />

      <OrderTypeButtons excludeStopLimit={true} />

      <div className="shrink-0 h-2" />

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <TradingPanelInputs
          withLeverage={true}
          defaultSize={defaultSize}
          submitButtonProps={submitButtonProps}
        />

        <div className="shrink-0 h-2" />

        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}
