import { ReactNode } from "react"

import { MarketSnapshot } from "silverkoi"

import * as skoi from "../../api/silverkoi"
import {
  MarketStats,
  useChainDetail,
  useMarket,
  useMarketStats,
  useMobileMainPageContext,
} from "../../hooks"
import { TooltipId } from "../../types"
import { COLORS, formatValue } from "../../utils"

const labelFont =
  "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-semibold text-neutral-04"
const bigValueFont = "font-sans text-[1.5rem] leading-[1.125em] tracking-[-0.01em] font-bold"
const valueFont = "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-bold"

export const MobileProduct = () => {
  const { symbolMeta } = useMobileMainPageContext()
  const { symbol } = symbolMeta
  const { chain } = useChainDetail()
  const market = useMarket(symbol)
  const { data: marketStats } = useMarketStats(chain, symbol)

  const stats = getFormattedStats(symbol, marketStats, market)

  return (
    <div className="flex flex-col shrink-0 w-full items-center">
      <div className="flex flex-col w-full">
        <Label text={"Market Price"} />
        <BigStat stat={stats.marketPrice} />
      </div>

      <div className="h-2" />

      <div className="flex w-full justify-between">
        <div className="flex flex-col">
          <div className="flex w-full">
            <div className="flex-col">
              <Label text={"Oracle Price"} />
              <div className="h-2" />
              <Stat stat={stats.oraclePrice} />
            </div>

            <div className="w-3" />

            <div className="flex-col">
              <Label text={"Twap Mkt Price"} />
              <div className="h-2" />
              <Stat stat={stats.twapMarketPrice} />
            </div>
          </div>

          <div className="h-2" />

          <div className="flex-col">
            <Label text={"Funding Rate"} />
            <div className="h-2" />
            <Stat stat={stats.fundingRate} />
          </div>
        </div>

        <div className="flex-col w-[8rem]">
          <div className="flex justify-between">
            <Label text={"24H Change"} />
            <Stat stat={stats.dailyChange} />
          </div>

          <div className="h-2" />

          <div className="flex justify-between">
            <Label text={"24H High"} />
            <Stat stat={stats.dailyHigh} />
          </div>

          <div className="h-2" />

          <div className="flex justify-between">
            <Label text={"24H Low"} />
            <Stat stat={stats.dailyLow} />
          </div>

          <div className="h-2" />

          <div className="flex justify-between">
            <Label text={"Volume"} />
            <Stat stat={stats.totalVolume} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Label = ({ text }: { text: string }) => {
  return <div className={labelFont}>{text}</div>
}

const BigStat = ({ stat }: { stat: Stat }) => {
  return (
    <div className={bigValueFont} style={{ color: stat.color || COLORS.WHITE }}>
      {stat.text}
    </div>
  )
}

const Stat = ({ stat }: { stat: Stat }) => {
  return (
    <div className={valueFont} style={{ color: stat.color || COLORS.WHITE }}>
      {stat.text}
    </div>
  )
}

interface FormattedValue {
  text: string
  color: string
}

interface Stat {
  tooltipId?: TooltipId
  label: string
  text: ReactNode | string
  color?: string
}

const DEFAULT_STAT = {
  text: "-",
}

interface FormattedStats {
  marketPrice: Stat
  twapMarketPrice: Stat
  oraclePrice: Stat
  dailyChange: Stat
  dailyHigh: Stat
  dailyLow: Stat
  totalVolume: Stat
  fundingRate: Stat
}

function getPctFmtVal(value: number, signed: boolean = true, decimals: number = 2): FormattedValue {
  if (!value) {
    return { text: "0.00%", color: COLORS.WHITE }
  } else if (value < 0) {
    return {
      text: (signed ? "-" : "") + Math.abs(value).toFixed(decimals) + "%",
      color: COLORS.RED,
    }
  } else {
    return {
      text: (signed ? "+" : "") + Math.abs(value).toFixed(decimals) + "%",
      color: COLORS.GREEN,
    }
  }
}

function formatVolume(value: number): string {
  if (value < 1e3) {
    return value.toFixed(0)
  } else if (value < 1e6) {
    return (value / 1e3).toPrecision(4) + "K"
  } else if (value < 1e9) {
    return (value / 1e6).toPrecision(4) + "M"
  } else {
    return (value / 1e9).toPrecision(4) + "B"
  }
}

function getFormattedStats(
  symbol: string,
  marketStats?: MarketStats,
  market?: MarketSnapshot,
): FormattedStats {
  const stats: FormattedStats = {
    marketPrice: {
      tooltipId: TooltipId.MarketPrice,
      label: "Market Price",
      ...DEFAULT_STAT,
    },
    twapMarketPrice: {
      tooltipId: TooltipId.TwapMarketPrice,
      label: "Twap Market Price",
      ...DEFAULT_STAT,
    },
    oraclePrice: {
      tooltipId: TooltipId.OraclePrice,
      label: "Oracle Price",
      ...DEFAULT_STAT,
    },
    dailyChange: {
      label: "24H Change",
      ...DEFAULT_STAT,
    },
    totalVolume: {
      label: "Volume",
      ...DEFAULT_STAT,
    },
    dailyHigh: {
      label: "24H High",
      ...DEFAULT_STAT,
    },
    dailyLow: {
      label: "24H Low",
      ...DEFAULT_STAT,
    },
    fundingRate: {
      tooltipId: TooltipId.FundingRate,
      label: "Funding Rate",
      ...DEFAULT_STAT,
    },
  }

  if (marketStats) {
    stats.dailyChange = {
      label: "24H Change",
      ...getPctFmtVal(marketStats.daily_stats.change_pct, true),
    }
    stats.dailyHigh = {
      label: "24H High",
      text: formatValue(marketStats.daily_stats.high, {
        minDecimals: 2,
        decimals: 2,
        dollar: true,
      }),
    }
    stats.dailyLow = {
      label: "24H Low",
      text: formatValue(marketStats.daily_stats.low, {
        minDecimals: 2,
        decimals: 2,
        dollar: true,
      }),
    }
    stats.totalVolume = {
      label: "Volume",
      text: `$${formatVolume(marketStats.total_stats.volume)}`,
    }
  }

  if (market) {
    const { decimals } = skoi.getSymbolConfig(symbol)

    stats.fundingRate = {
      tooltipId: TooltipId.FundingRate,
      label: "Funding Rate",
      ...getPctFmtVal(market.longHourlyRate.real() * 100, true, 6),
    }

    stats.marketPrice = {
      tooltipId: TooltipId.MarketPrice,
      label: "Market Price",
      text: formatValue(market.marketPrice, {
        minDecimals: decimals,
        decimals,
        dollar: true,
      }),
    }

    stats.twapMarketPrice = {
      tooltipId: TooltipId.TwapMarketPrice,
      label: "Twap Market Price",
      text: formatValue(market.twapMarketPrice, {
        minDecimals: decimals,
        decimals,
        dollar: true,
      }),
    }

    stats.oraclePrice = {
      tooltipId: TooltipId.OraclePrice,
      label: "Oracle Price",
      text: formatValue(market.oraclePrice, {
        minDecimals: decimals,
        decimals,
        dollar: true,
      }),
    }
  }

  return stats
}
