import { useParams } from "react-router"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Slide, ToastContainer } from "react-toastify"

import * as sk from "./api/silverkoi"
import { MessageBanner } from "./components/MessageBanner"
import { NavbarDesktop } from "./components/NavbarDesktop"
import { useChainDetail } from "./hooks"
import { LeaderboardPage } from "./pages/LeaderboardPage"
import { NotificationPage } from "./pages/NotificationPage"
import { PortfolioPage } from "./pages/PortfolioPage"
import { TradingPage } from "./pages/TradingPage"
import { getIcon } from "./utils"

export const MainApp = () => {
  return (
    <>
      <div
        className={
          "flex flex-col h-full w-full min-w-0 min-h-0 " +
          "scrollbar-thumb-neutral-04 scrollbar-track-neutral-06"
        }
      >
        <BrowserRouter>
          <NavbarDesktop />

          <MessageBanner />

          <Routes>
            <Route path="/" element={<Navigate to="/trade" replace />} />
            <Route path="trade/:symbol" element={<TradingPageOrRedirect />} />
            <Route path="trade/" element={<NavigateToDefaultTradingPage />} />
            {<Route path="portfolio" element={<PortfolioPage />} />}
            {<Route path="leaderboard" element={<LeaderboardPage />} />}
            {<Route path="notification" element={<NotificationPage />} />}
            <Route path="*" element={<Navigate to="/trade" replace />} />
          </Routes>
        </BrowserRouter>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Slide}
          icon={({ type }) => {
            switch (type) {
              case "error":
                return <img src={getIcon("warning-red")} className="w-6 h-6" />
              case "warning":
                return <img src={getIcon("warning-yellow")} className="w-6 h-6" />
              case "success":
                return <img src={getIcon("success")} className="w-6 h-6" />
              default:
                return <img src={getIcon("info")} className="w-6 h-6" />
            }
          }}
          toastClassName={() => {
            return (
              "relative flex p-3 mt-1 min-h-10 bg-neutral-05 bg-opacity-1 rounded-md justify-between items-center overflow-hidden cursor-pointer " +
              "font-sans text-[12px]"
            )
          }}
          bodyClassName={() => {
            return "flex h-[40px] bg-neutral-05 items-center"
          }}
        />
      </div>
    </>
  )
}

const TradingPageOrRedirect = () => {
  const { chain } = useChainDetail()
  const { symbol } = useParams()
  if (!symbol) {
    return <NavigateToDefaultTradingPage />
  } else if (symbol === symbol.toUpperCase()) {
    const symbols = sk.getSupportedSymbols(chain)
    if (symbols.includes(symbol)) {
      return <TradingPage />
    } else {
      return <Navigate to={`/trade`} replace />
    }
  } else {
    return <Navigate to={`/trade/${symbol.toUpperCase()}`} replace />
  }
}

function NavigateToDefaultTradingPage() {
  const { chain } = useChainDetail()
  const symbol = sk.getDefaultSymbol(chain)
  return <Navigate to={`/trade/${symbol.toUpperCase()}`} replace />
}
