import { useParams } from "react-router"

import * as skoi from "~/api/silverkoi"
import { MessageBanner } from "~/components/MessageBanner"
import { TradingPageTitle } from "~/components/TradingPageTitle"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useMobileMainPageContext } from "~/hooks"
import { MobileCreateTriggerView } from "./MobileCreateTriggerView"
import { MobileEditPositionView } from "./MobileEditPositionView"
import { MobileEditTriggerView } from "./MobileEditTriggerView"
import { MobileMainPageContextProvider } from "./MobileMainPageContextProvider"
import { MobileNavbar } from "./MobileNavbar"
import { MobileOrderDetailView } from "./MobileOrderDetailView"
import { MobileOverviewView } from "./MobileOverviewView"
import { MobilePositionDetailView } from "./MobilePositionDetailView"
import { MobileReducePositionView } from "./MobileReducePositionView"
import { MobileTradeView } from "./MobileTradeView"
import { MobileTriggerDetailView } from "./MobileTriggerDetailView"

export const MobileMainPage = () => {
  const { symbol: maybeSymbol } = useParams()
  const symbol: string = maybeSymbol!.toUpperCase()!
  const symbolMeta = skoi.getSymbolMeta(symbol)
  return (
    <MobileMainPageContextProvider symbolMeta={symbolMeta}>
      <Inner symbol={symbol} />
    </MobileMainPageContextProvider>
  )
}

const Inner = ({ symbol }: { symbol: string }) => {
  const onBackClick = useOnBackClick()
  return (
    <>
      <MobileNavbar onBackClick={onBackClick} />
      <MessageBanner />

      <TradingPageTitle symbol={symbol} />

      <TradeContextProvider symbol={symbol}>
        <div className="flex flex-col grow overflow-hidden">
          <Body />
        </div>
      </TradeContextProvider>
    </>
  )
}

const Body = () => {
  const { viewData } = useMobileMainPageContext()

  return (
    <>
      {viewData.type === "overview" && <MobileOverviewView data={viewData} />}
      {viewData.type === "trade" && <MobileTradeView />}
      {viewData.type === "edit-position" && <MobileEditPositionView data={viewData} />}
      {viewData.type === "create-trigger" && <MobileCreateTriggerView data={viewData} />}
      {viewData.type === "edit-trigger" && <MobileEditTriggerView data={viewData} />}
      {viewData.type === "reduce-position" && <MobileReducePositionView data={viewData} />}
      {viewData.type === "position-detail" && <MobilePositionDetailView data={viewData} />}
      {viewData.type === "order-detail" && <MobileOrderDetailView data={viewData} />}
      {viewData.type === "trigger-detail" && <MobileTriggerDetailView data={viewData} />}
    </>
  )
}

const useOnBackClick = () => {
  const { viewData, setViewData } = useMobileMainPageContext()
  switch (viewData.type) {
    case "overview":
      return undefined
    case "order-detail":
      return () =>
        setViewData({ type: "overview", symbolMeta: viewData.symbolMeta, initialTab: "orders" })
    case "trigger-detail":
      return () =>
        setViewData({ type: "overview", symbolMeta: viewData.symbolMeta, initialTab: "triggers" })
    case "position-detail":
      return () =>
        setViewData({ type: "overview", symbolMeta: viewData.symbolMeta, initialTab: "positions" })
    case "reduce-position":
    case "edit-position":
    case "create-trigger":
      return () =>
        setViewData({
          type: "position-detail",
          symbolMeta: viewData.symbolMeta,
          position: viewData.position,
        })
    case "edit-trigger":
      return () =>
        setViewData({
          type: "trigger-detail",
          symbolMeta: viewData.symbolMeta,
          trigger: viewData.trigger,
        })
    default:
      return () =>
        setViewData({ type: "overview", symbolMeta: viewData.symbolMeta, initialTab: "orderbook" })
  }
}
