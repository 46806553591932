export * from "./assets"
export * from "./constants"
export * from "./errors"
export * from "./format"
export * from "./leaderboard"
export * from "./network"
export * from "./sleep"
export * from "./tailwind"
export * from "./token"

// TODO: reorganize

import { BigDecimal } from "silverkoi/math"

import { Duration, DurationUnit } from "../types"

export function castToDurationUnit(s: string): DurationUnit {
  switch (s) {
    case "second":
    case "minute":
    case "hour":
    case "day":
    case "week":
      return s
    default:
      throw new Error(`unknown duration unit: ${s}`)
  }
}

export function* chunks<T>(arr: T[], chunkSize: number): Generator<[number, T[]]> {
  for (let i = 0; i * chunkSize < arr.length; ++i) {
    yield [i, arr.slice(i * chunkSize, (i + 1) * chunkSize)]
  }
}

export function parseBigDecimal(
  text: string,
  maxDecimals: bigint | number,
  lowerBound?: BigDecimal,
  upperBound?: BigDecimal,
): { text: string; value: BigDecimal | undefined } {
  maxDecimals = BigInt(maxDecimals)

  if (text) {
    let value = BigDecimal.fromString(text)
    if (upperBound && value.gt(upperBound)) {
      value = upperBound
      text = value.toStringTrimmed()
    } else if (lowerBound && value.lt(lowerBound)) {
      value = lowerBound
      text = value.toStringTrimmed()
    } else {
      text = value.decimals() <= maxDecimals ? text : value.toString(maxDecimals)
      value = value.round(maxDecimals)
    }
    return { value, text }
  } else {
    return { value: undefined, text: "" }
  }
}

export function getDurationSeconds(duration: Duration): BigDecimal {
  const unit = (() => {
    switch (duration.unit) {
      case "second":
        return 1
      case "minute":
        return 60
      case "hour":
        return 60 * 60
      case "day":
        return 60 * 60 * 24
      case "week":
        return 60 * 60 * 24 * 7
    }
  })()
  return duration.length.mul(BigDecimal.fromReal(unit, 0))
}

export function formatDuration(seconds: number): string {
  return new Date(seconds * 1000).toISOString().slice(11, 19)
}

// TODO: Get these colors from a common settings file
export const COLORS = {
  GREEN: "#83BF6E",
  RED: "#FF6A55",
  WHITE: "#FFF",
}

export function valueToColor(value: BigDecimal | bigint | number) {
  if (value instanceof BigDecimal) {
    return valueToColor(value.raw())
  } else if (typeof value === "bigint") {
    if (value > 0n) {
      return COLORS.GREEN
    } else if (value < 0n) {
      return COLORS.RED
    } else {
      return COLORS.WHITE
    }
  } else if (typeof value === "number") {
    if (value > 0) {
      return COLORS.GREEN
    } else if (value < 0) {
      return COLORS.RED
    } else {
      return COLORS.WHITE
    }
  } else {
    throw new Error(`invalid numeric value: ${value}`)
  }
}

export function getTextColorCs(value: BigDecimal | bigint | number) {
  if (value instanceof BigDecimal) {
    return getTextColorCs(value.raw())
  } else if (typeof value === "bigint") {
    if (value > 0n) {
      return "text-green"
    } else if (value < 0n) {
      return "text-red"
    } else {
      return "text-white"
    }
  } else if (typeof value === "number") {
    if (value > 0) {
      return "text-green"
    } else if (value < 0) {
      return "text-red"
    } else {
      return "text-white"
    }
  } else {
    throw new Error(`invalid numeric value: ${value}`)
  }
}

export function repeat<T>(arr: T[], n: number): T[] {
  return [].concat(...Array(n).fill(arr))
}

// Simple hash for psuedo-randomness. Taken from https://stackoverflow.com/a/52171480.
export const simpleHash = (s: string): [number, number] => {
  let h1 = 0xdeadbeef
  let h2 = 0x41c6ce57
  for (const c of s) {
    const ch = c.charCodeAt(0)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return [h2 >>> 0, h1 >>> 0]
}
