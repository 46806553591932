import { ReactNode } from "react"
import { PlacesType } from "react-tooltip"
import { subscribeWithSelector } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

interface TooltipActions {
  add: (id: string, props: TooltipProps) => void
  remove: (id: string) => void
}

interface TooltipProps {
  message: ReactNode
  place?: PlacesType
  width: string
}

interface TooltipState {
  tooltips: Map<string, TooltipProps> // Map from id to tooltip message
  actions: TooltipActions
}

// TODO: consider passing in an id to prefix and differentiate between tooltips
// from different contexts
export const createTooltipStore = () => {
  return createWithEqualityFn<TooltipState>()(
    subscribeWithSelector(
      immer((set) => ({
        tooltips: new Map<string, TooltipProps>(),
        fastTimer: 0n,
        actions: {
          add: (id, props) =>
            set((s) => {
              s.tooltips = new Map<string, TooltipProps>(s.tooltips).set(id, props)
            }),
          remove: (id) =>
            set((s) => {
              s.tooltips = new Map<string, TooltipProps>(s.tooltips)
              s.tooltips.delete(id)
            }),
        },
      })),
    ),
    shallow,
  )
}

export type TooltipStore = ReturnType<typeof createTooltipStore>
