import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { PropsWithChildren, ReactNode } from "react"

import { tw2 } from "~/utils"

type ColumnHelper<T> = ReturnType<typeof createColumnHelper<T>>

type AccessorType<T> = Parameters<ColumnHelper<T>["accessor"]>[0]

type DivProps = {
  className?: string
}

// eslint-disable-next-line react-refresh/only-export-components
const HeaderCell = ({ className, children }: PropsWithChildren<DivProps>) => {
  return (
    <div className={`flex py-3 text-left ${tw2("font-table-header")} ${className}`}>{children}</div>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
const RowCell = ({ className, children }: PropsWithChildren<DivProps>) => {
  return (
    <div className={`flex py-2 text-left ${tw2("font-table-value")} ${className}`}>{children}</div>
  )
}

type MakeColumnDefArgs<T> = {
  accessor: AccessorType<T>
  id?: string
  header: string | (() => ReactNode)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: (props: CellContext<T, any>) => ReactNode
}

const shortCols = ["cancel", "close", "edit", "sltp", "details"]

// Returns a column def functor to be used for all of our tables. This method is
// convenient for ensuring all tables follow consistent styling.
export function getMakeColumnDef<T>({
  table,
  fullVersion,
  columnHelper,
}: {
  table: string
  fullVersion: boolean
  columnHelper: ColumnHelper<T>
}) {
  const makeColumnDef = ({
    accessor,
    id: _id,
    header,
    cell,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: MakeColumnDefArgs<T>): ColumnDef<T, any> => {
    const id: string = (() => {
      if (_id) return _id
      if (typeof accessor === "string") return accessor
      throw new Error("must provide 'id'")
    })()

    const cs = (() => {
      if (id === "position") {
        return `px-5 w-[10rem]`
      }
      if (shortCols.includes(id)) {
        const baseCs = `justify-center text-center`
        const plCs = (() => {
          if (
            (id === "close" && table === "positions") ||
            (id === "cancel" && table === "orders") ||
            (id === "cancel" && table === "triggers")
          ) {
            return "pl-2"
          } else {
            return "pl-1"
          }
        })()
        const prCs = (() => {
          if (
            (id === "sltp" && table === "positions" && !fullVersion) ||
            (id === "details" && table === "positions" && fullVersion) ||
            (id === "cancel" && table === "orders") ||
            (id === "edit" && table === "triggers")
          ) {
            return "pr-5"
          } else {
            return "pr-1"
          }
        })()
        return `${baseCs} ${plCs} ${prCs}`
      }
      return "px-3"
    })()
    return columnHelper.accessor(accessor, {
      id,
      header: () => (
        <HeaderCell className={cs}>{typeof header === "string" ? header : header()}</HeaderCell>
      ),
      cell: (props) => <RowCell className={cs}>{cell(props)}</RowCell>,
    })
  }
  return makeColumnDef
}
