import { Helmet } from "react-helmet"
import { useDocumentTitle } from "usehooks-ts"

import * as skoi from "~/api/silverkoi"
import { useMarket } from "~/hooks"

type Props = {
  symbol: string
}

export const TradingPageTitle = ({ symbol }: Props) => {
  const title = useTradingPageTitle(symbol)
  useDocumentTitle(title)
  return (
    <Helmet>
      <title>Silver Koi</title>
    </Helmet>
  )
}

const useTradingPageTitle = (symbol: string): string => {
  const { displayName } = skoi.getSymbolMeta(symbol)
  const market = useMarket(symbol)
  if (market) {
    const { marketPrice } = market
    const marketPriceStr = marketPrice.format({ locale: "en-US", decimals: 2 })
    return `$${marketPriceStr} ${displayName} - Silver Koi`
  } else {
    return "Silver Koi"
  }
}
