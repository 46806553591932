import { BigDecimal } from "silverkoi/math"

// TODO: Move to ~/utils/constants.ts

// WARNING: If we ever change these values, we need to ensure the smart
// contracts support it.
export const MIN_LEVERAGE = BigDecimal.fromString("1")
export const MAX_LEVERAGE = BigDecimal.fromString("20")
export const MIN_SLIPPAGE = BigDecimal.fromString("0")
export const MAX_SLIPPAGE = BigDecimal.fromString("30")
export const DEFAULT_SLIPPAGE = BigDecimal.fromString("1.0")

// TODO: get from contract instead of hardcoding
export const SIZE_DECIMALS = 5n
export const PRICE_INPUT_DECIMALS = 4n
export const NOTIONAL_INPUT_DECIMALS = 4n
