interface Props {
  src: string
  size: string
}

export const CircleImage = ({ src, size }: Props) => {
  const style = {
    width: size,
    height: size,
    borderRadius: size,
  }
  return <img src={src} style={style} />
}
