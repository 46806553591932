import React from "react"

import { useIsMobile } from "~/hooks"
import { getIcon, tw2 } from "~/utils"
import { NumberInput } from "./NumberInput"

type TradeNumberInputProps = {
  label?: string
  tooltipId?: string
  disabled?: boolean
  placeholder: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  unit?: string | React.ReactNode
}

export const TradeNumberInput = ({
  label,
  tooltipId,
  placeholder,
  disabled,
  value,
  onChange,
  unit,
}: TradeNumberInputProps) => {
  const isMobile = useIsMobile()
  const heightCs = isMobile ? "h-[2rem]" : "h-[1.75rem]"
  const borderColorCs = disabled ? "border-neutral-shade-dark" : "border-blue"
  const unitColorCs = disabled ? "text-neutral-shade-dark" : tw2("font-gradient")

  return (
    <div className="flex flex-col w-full">
      {makeLabel({ label, tooltipId })}

      <div className="relative w-full">
        <NumberInput
          className={
            `inline-block items-center justify-center rounded-md border ${borderColorCs} bg-black ` +
            `px-3 text-white ${tw2("font-input-value")} box-border w-full ${heightCs} ` +
            "focus:outline-none"
          }
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />

        {unit && (
          <div className="flex absolute text-center right-[0.75rem] top-1/2 -translate-y-1/2">
            <div className={`flex ${tw2("font-input-unit")} ${unitColorCs}`}>{unit}</div>
          </div>
        )}
      </div>
    </div>
  )
}

const makeLabel = ({ label, tooltipId }: { label?: string; tooltipId?: string }) => {
  if (!label) {
    return <></>
  } else if (!tooltipId) {
    return (
      <>
        <div className={`flex ${tw2("font-input-label")} text-white pr-1`}>{label}</div>
        <div className="shrink-0 h-1" />
      </>
    )
  } else {
    return (
      <>
        {/* NOTE: The extra div nesting is a hack to avoid the div from filling
        the full width, so that the tooltip shows exactly to the right of the
        icon instead of the right of the end of the full width.*/}
        <div className="flex flex-row justify-between">
          <div className="flex hover:cursor-help items-center" data-tooltip-id={tooltipId}>
            <div className={`${tw2("font-input-label")} text-white pr-1`}>{label}</div>
            <img src={getIcon("info")} className="w-3 h-3" />
          </div>
          <div />
        </div>
        <div className="shrink-0 h-1" />
      </>
    )
  }
}
