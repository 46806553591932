import { useRef } from "react"
import { Link, useLocation } from "react-router-dom"
import { useHover } from "usehooks-ts"
import { useAccount } from "wagmi"

import { useAllTimeUserStats, useChainDetail, useHasNotifications, useUserState } from "../hooks"
import { tw, tw2 } from "../utils"
import { ConnectButton } from "./ConnectButton"
import { Logo } from "./Logo"
import { NetworkButton } from "./NetworkButton"
import { WalletButton } from "./WalletButton"
import { Icon, IconName } from "./icons"

export const NavbarDesktop = () => {
  const { address, isConnected } = useAccount()
  const { chain } = useChainDetail()
  const { data: userState } = useUserState()
  const { data: userStats } = useAllTimeUserStats({ chain, address })

  const hasUnreadNotifications = useHasNotifications({ chain, address })
  const notificationIconName = hasUnreadNotifications ? "bell-with-dot" : "bell"

  const widthCs = "w-[17rem]"
  const makeButton = () => {
    if (isConnected && address && userState && userStats) {
      return (
        <WalletButton
          widthCs={widthCs}
          address={address}
          userState={userState}
          userStats={userStats}
        />
      )
    } else {
      return <ConnectButton widthCs={widthCs} />
    }
  }

  return (
    <div
      className={
        "sticky flex-col top-0 w-full px-4 pt-5 " +
        "bg-[rgba(0,0,0,0.1)] text-white backdrop-blur-sm z-[20]"
      }
    >
      <div className="flex w-full items-center justify-between ">
        <div className="shrink-0 flex w-fit gap-10">
          <Link className="shrink-0 w-fit" to="/trade" style={{ textDecoration: "none" }}>
            <Logo sizeRem={2.5} />
          </Link>

          {/* Tabs */}
          <div className="flex shrink-0 justify-center items-center gap-3">
            <Tab linkTo="/trade" text="Trade" iconName="wallet" />
            <Tab linkTo="/portfolio" text="Portfolio" iconName="pie-chart" />
            <Tab linkTo="/notification" text="Notifications" iconName={notificationIconName} />
            <Tab linkTo="/leaderboard" text="Leaderboard" iconName="trophy" />
          </div>
        </div>

        <div className="shrink-0 flex h-full justify-end items-center gap-2">
          <div className={"shrink-0 flex items-center w-fit h-full box-border gap-[10px] "}>
            <NetworkButton />
            {makeButton()}
          </div>
        </div>
      </div>
    </div>
  )
}

interface TabProps {
  linkTo: string
  text: string
  iconName: IconName
}

const Tab = ({ linkTo, text, iconName }: TabProps) => {
  const { pathname } = useLocation()
  const selected = pathname === linkTo || pathname.startsWith(linkTo + "/")

  const menuFontCs = `${tw2("font-navbar-tab")} font-semibold`
  const selectedTabCs = "text-white border-b-2 border-cool-blue"
  const unselectedTabCs = "text-neutral-04 border-b-2 border-transparent"
  const tabCs = selected ? selectedTabCs : unselectedTabCs

  const ref = useRef(null) // eslint-disable-line no-null/no-null
  const isHover = useHover(ref)
  const iconFill = isHover ? "gradient" : selected ? "white" : "gray"

  return (
    <div className={`${tabCs} ${menuFontCs}`}>
      <Link to={linkTo}>
        <div
          ref={ref}
          className={`shrink-0 w-fit flex flex-row items-center gap-[2px] ${tw("hover:font-gradient")}`}
        >
          <div className="p-1">
            <Icon name={iconName} iconFill={iconFill} size={"1.125rem"} />
          </div>
          <div>{text}</div>
        </div>
      </Link>
    </div>
  )
}
