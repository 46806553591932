import { useNavigate } from "react-router-dom"

import { PositionSnapshot } from "silverkoi"

import { useScreenLayout } from "../hooks"
import { formatValue, getSymbolLogo } from "../utils"
import { CircleImage } from "./CircleImage"

interface Props {
  position: PositionSnapshot
  fullVersion?: boolean
  enableClick?: boolean
}

export const PositionCell = ({ position, fullVersion, enableClick: _enableClick }: Props) => {
  const { symbolMeta, size, leverage } = position
  const { symbol, displayName } = symbolMeta
  const navigate = useNavigate()
  const layout = useScreenLayout()

  const onNavigate = (symbol: string) => {
    navigate(`/trade/${symbol}`, { replace: true })
  }

  const colorCs = (() => {
    if (size.raw() > 0n) {
      return "text-green"
    } else if (size.raw() < 0n) {
      return "text-red"
    } else {
      return ""
    }
  })()

  const direction = (() => {
    if (size.raw() > 0n) {
      return "Long"
    } else if (size.raw() < 0n) {
      return "Short"
    } else {
      return ""
    }
  })()

  const leverageText = formatValue(leverage, { decimals: 1, suffix: "X" })
  const sizeText = formatValue(size, { decimals: 5 })
  const logoSize = layout === "mobile" ? "1.75rem" : fullVersion ? "1.5rem" : "1.5rem"
  const gapCs = layout === "mobile" ? "gap-1" : ""

  const enableClick = _enableClick ?? true
  const cursorCs = enableClick ? "cursor-pointer" : ""

  return (
    <div
      className={`flex flex-row items-center ${cursorCs} text-neutral-01`}
      onClick={enableClick ? () => onNavigate(symbol) : undefined}
    >
      <CircleImage src={getSymbolLogo(symbol)} size={logoSize} />
      <div className={`grow flex flex-col pl-2 ${gapCs}`}>
        <div>{displayName}</div>

        {size.isZero() ? (
          <></>
        ) : layout === "mobile" ? (
          <div className="shrink-0 flex">
            <div className={colorCs}>{direction}</div>
            <div className="shrink-0 w-1" />
            <div className={colorCs}>{sizeText}</div>
            <div className="shrink-0 w-1" />
            <div>{leverageText}</div>
          </div>
        ) : (
          <div className="shrink-0 flex">
            <div className={colorCs}>{direction}</div>
            <div className="shrink-0 w-4" />
            <div>{leverageText}</div>
          </div>
        )}
      </div>
    </div>
  )
}
