import { PropsWithChildren, SVGProps } from "react"

export type IconFill = "white" | "gray" | "red" | "gradient" | "gold" | "silver" | "bronze"

export type IconProps = {
  fill: string
}

export const IconSvg = (props: PropsWithChildren<SVGProps<SVGSVGElement>>) => {
  const { children, ...svgProps } = props
  return (
    <svg {...svgProps} width="100%" height="100%">
      {children}

      <linearGradient
        id="gradient"
        x1="26.8334"
        y1="2.33204"
        x2="10.2017"
        y2="-0.386961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A357FF" stopOpacity="0.84" />
        <stop offset="1" stopColor="#0BB5FF" />
      </linearGradient>
    </svg>
  )
}
