import { useIsMobile, useTime } from "~/hooks"
import { getUrl, tw2 } from "~/utils"
import { Icon } from "./icons/Icon"

// TODO: Get message from api backend instead of hard coding here.

const mobileFontCs = "text-[0.7rem] leading-[0.9rem] tracking-[-0.01em] text-white font-semibold"
const desktopFontCs = `${tw2("font-banner")} text-white font-semibold`

export const MessageBanner = () => {
  const isMobile = useIsMobile()
  return isMobile ? <MobileMessageBanner /> : <DesktopMessageBanner />
}

const onClickLearnMore = (e: React.SyntheticEvent) => {
  const url = getUrl("medium-intro")
  e.preventDefault()
  window.open(url, "_blank")
}

const text2 = "Go to instructions for Plume Testnet Campaign!"

export const DesktopMessageBanner = () => {
  const text1 = useText1()
  return text1 ? (
    <div className="flex w-full pt-1 pb-3">
      <div className="flex w-full h-[1.5rem] shrink-0 px-4">
        <div className="flex w-full h-full px-5 items-center justify-center rounded-lg bg-purple/80">
          <div className="flex gap-2 items-center">
            <div className="w-4 h-4">
              <Icon name="meteor-star" iconFill="gold" />
            </div>

            <div className={`flex items-center ${desktopFontCs}`}>
              <span className="underline hover:cursor-pointer" onClick={onClickLearnMore}>
                {text2}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="shrink-0 h-6" />
  )
}

const MobileMessageBanner = () => {
  const text1 = useText1()
  return text1 ? (
    <div className="flex w-full py-1">
      <div className="flex w-full h-[1.5rem] shrink-0 px-3">
        <div className="flex w-full h-full px-4 items-center justify-center rounded-lg bg-purple/80">
          <div className="flex gap-1 items-center">
            <div className="w-4 h-4">
              <Icon name="meteor-star" iconFill="gold" />
            </div>

            <div className={`flex items-center ${mobileFontCs}`}>
              <span className="underline" onClick={onClickLearnMore}>
                {text2}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="shrink-0 h-1" />
  )
}

const plumeCampaignStartDate = Date.parse("08 Aug 2024 08:00:00 EST")
const plumeCampaignEndDate = Date.parse("08 Sep 2024 08:00:00 EST")

const useText1 = (): string | undefined => {
  const now = useTime({ delayMs: 5000 })
  const started = now >= plumeCampaignStartDate
  const ended = now >= plumeCampaignEndDate

  if (!started) {
    return "Instructions For Plume Testnet campaign Here!"
  } else if (!ended) {
    return "Plume Campaign has begun!"
  } else {
    return undefined
  }
}
