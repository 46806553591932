import { createContext } from "react"

import { TooltipStore } from "../stores"

export interface TooltipContextData {
  useTooltipStore: TooltipStore
}

// NOTE: The default value will never appear in production. See
// https://react.dev/reference/react/createContext#parameters for details.
export const TooltipContext = createContext<TooltipContextData>(
  undefined as unknown as TooltipContextData,
)
