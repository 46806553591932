import { useDocumentTitle } from "usehooks-ts"
import { useAccount } from "wagmi"

import { useChainDetail, useHasNotifications } from "../../hooks"
import { tw } from "../../utils"
import { MessageBanner } from "../MessageBanner"
import { NotificationList } from "../NotificationList"
import { Icon } from "../icons"
import { MobileNavbar } from "./MobileNavbar"

export const MobileNotificationPage = () => {
  useDocumentTitle(`Silver Koi - Notifications`)

  return (
    <>
      <MobileNavbar title={<Title />} />
      <MessageBanner />

      <div className="flex flex-col grow overflow-hidden">
        <NotificationList />
      </div>
    </>
  )
}

const Title = () => {
  const { address } = useAccount()
  const { chain } = useChainDetail()
  const hasNotifications = useHasNotifications({ chain, address })
  const iconName = hasNotifications ? "bell-with-dot" : "bell"

  return (
    <div className="flex items-center gap-1">
      <div className="w-4 h-4">
        <Icon name={iconName} iconFill="white" />
      </div>

      <div className={`${tw("font-title-1-mobile")} text-white`}>Notifications</div>
    </div>
  )
}
