import { OrderSide } from "silverkoi"

import { tw2 } from "~/utils"

interface OrderBookRowProps {
  side: OrderSide
  size: string
  price: string
  barWidthPct: number
  onClick: () => void
}

export const OrderBookRow = ({ side, size, price, barWidthPct, onClick }: OrderBookRowProps) => {
  const isBid = side === "bid"
  const priceColorCs = isBid ? "text-green" : "text-red"
  const barCs = isBid ? "bg-green/[.15]" : "bg-red/[.15]"
  const barWidthPctCapped = Math.min(100, barWidthPct)
  return (
    <div className="flex flex-row h-[1.25rem] py-[1px] w-full">
      <div className="flex relative w-full">
        <div className="flex flex-row w-full">
          <div className={barCs} style={{ width: `${barWidthPctCapped}%` }}>
            <div className="flex grow">&nbsp;</div>
          </div>
        </div>

        <div className="flex w-full absolute top-1/2 -translate-y-1/2">
          <div className="flex flex-row w-full justify-between pr-6">
            <div
              className={`w-[5rem] ${tw2("font-orderbook-number")} text-white text-right font-bold`}
            >
              {size}
            </div>
            <div
              className={`${tw2("font-orderbook-number")} ${priceColorCs} hover:cursor-pointer`}
              onClick={onClick}
            >
              {price}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
