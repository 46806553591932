import { useState } from "react"

import { getIcon, tw2 } from "~/utils"
import { PortfolioTabId } from "./Portfolio"

interface SideBarProps {
  tab: PortfolioTabId
  setTab: (_: PortfolioTabId) => void
  page: string
}

export const SideBar = ({ tab, setTab, page }: SideBarProps) => {
  const [expanded, setExpanded] = useState<boolean>(true)

  const onClick = () => {
    setExpanded(!expanded)
  }

  const tabIds =
    page === "Portfolio" ? (["Positions", "Orders", "Triggers", "History"] as const) : ([] as const)

  const tabs = tabIds.map((tabId: PortfolioTabId) => {
    return <Tab tabId={tabId} expanded={expanded} tab={tab} setTab={setTab} />
  })

  const rotateCs = expanded ? "rotate-180" : ""

  return (
    <div className="shrink-0 flex flex-col h-full bg-black px-4 py-4 rounded-md">
      <div className="flex flex-col h-full gap-3">{...tabs}</div>

      <div
        className={`flex justify-center cursor-pointer relative right-[calc(-50%-1rem)] bottom-[8rem] ${rotateCs}`}
        onClick={onClick}
      >
        <ExpandIcon />
      </div>
    </div>
  )
}

const ExpandIcon = () => {
  return <img src={getIcon("expand")} className="object-scale-down w-8" />
}

const Tab = ({
  tabId,
  expanded,
  tab,
  setTab,
}: {
  tabId: PortfolioTabId
  expanded: boolean
  tab: PortfolioTabId
  setTab: (_: PortfolioTabId) => void
}) => {
  const selected = tab === tabId
  const icon = getIcons(tabId, selected)

  const colorCs = selected ? "text-neutral-00" : "text-neutral-04"
  const bgCs = selected ? "bg-[#272B30] shadow-md shadow-black" : "bg-none"
  return (
    <div
      key={tab}
      className={`flex items-center h-10 rounded-md px-2 gap-2 ${bgCs} cursor-pointer`}
      onClick={() => setTab(tabId)}
    >
      <img src={icon} className="w-5 h-5" />
      {expanded && <div className={`${tw2("font-sidebar")} ${colorCs} pr-[2rem]`}>{tabId}</div>}
    </div>
  )
}

const getIcons = (tabId: PortfolioTabId, selected: boolean) => {
  switch (tabId) {
    case "Positions":
      return selected ? getIcon("myposition-selected") : getIcon("myposition")
    case "Orders":
      return selected ? getIcon("open-order-selected") : getIcon("open-order")
    case "Triggers":
      return selected ? getIcon("gauge-white") : getIcon("gauge")
    case "History":
      return selected ? getIcon("history-selected") : getIcon("history")
  }
}
