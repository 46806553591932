import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { useMarket, useTradeContext } from "~/hooks"
import { InputState } from "~/types"
import { tw2 } from "~/utils"

type Props = {
  setPrice: (_: InputState<BigDecimal | undefined>) => void
}

export const PriceInputExtraInfo = ({ setPrice }: Props) => {
  const { symbol } = useTradeContext()
  const market = useMarket(symbol)

  const { decimals } = skoi.getSymbolConfig(symbol)
  const bestBid = market?.bestBid
  const bestAsk = market?.bestAsk
  const bestBidText = bestBid?.toString(decimals) ?? "-"
  const bestAskText = bestAsk?.toString(decimals) ?? "-"
  const onClickBestBid = () => {
    setPrice({ value: bestBid, text: bestBidText })
  }
  const onClickBestAsk = () => {
    setPrice({ value: bestAsk, text: bestAskText })
  }

  return (
    <div className={`flex flex-row gap-1 items-center ${tw2("font-input-extra-info")} text-pale`}>
      <span className="hover:cursor-pointer" onClick={onClickBestBid}>
        Bid ${bestBidText}
      </span>
      <span>•</span>
      <span className="hover:cursor-pointer" onClick={onClickBestAsk}>
        Ask ${bestAskText}
      </span>
    </div>
  )
}
