import { useEffect } from "react"

export const useWindowResizeObserver = (onResize: () => void) => {
  useEffect(() => {
    const _onResize = () => {
      onResize()
    }
    window.addEventListener("resize", _onResize)
    return () => window.removeEventListener("resize", _onResize)
  }, [onResize])
}
