import { ReactNode } from "react"

import errorIcon from "~/assets/redWarning.svg"
import warningIcon from "~/assets/warning.svg"
import { useChainDetail, useNeedNativeToken, useSilverKoiApi, useSimulationResult } from "~/hooks"
import { tw2 } from "~/utils"

interface Warning {
  isError: boolean
  message?: ReactNode | string
}

const defaultWarning = { isError: false }

export const TradeWarning = () => {
  const { faucetUrl, nativeCurrency } = useChainDetail()
  const { data: api } = useSilverKoiApi()
  const { data: simResult } = useSimulationResult({ enabled: true })
  const needNativeToken = useNeedNativeToken()

  const { isError, message } = ((): Warning => {
    if (api) {
      if (needNativeToken) {
        let message = `You don't have enough ${nativeCurrency.name} to pay gas`
        if (faucetUrl) {
          message = `${message}. Click below to get some ${nativeCurrency.name}`
        }
        return { isError: false, message }
      } else if (simResult?.err) {
        const message = simResult.val.error.message
        return { isError: false, message }
      }
    }
    return defaultWarning
  })()

  if (!message) return <></>

  const image = isError ? errorIcon : warningIcon
  const colorCs = isError ? "text-red" : "text-yellow"
  const fontCs = `${tw2("font-trade-warning")} ${colorCs} font-medium`

  return (
    <div className="flex items-center justify-center gap-1 pb-2">
      <img src={image} className="w-3 h-3" />
      <div className={fontCs}>{message}</div>
      <div className="w-2" />
    </div>
  )
}
