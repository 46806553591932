import React from "react"

interface NumberInputProps {
  placeholder: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
}

export const NumberInput = ({
  placeholder,
  value,
  onChange,
  onBlur,
  className,
  style,
  disabled,
}: NumberInputProps) => {
  // NOTE: There is no equivalent of e.preventDefault for wheel event, therefore
  // we hackily unfocus the input instead when the scroll wheel is used.
  return (
    <input
      type="number"
      className={className}
      style={style}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onWheel={(e) => e.currentTarget.blur()}
      onKeyDown={ignoreExtraneousKeys}
      disabled={disabled}
    ></input>
  )
}

function ignoreExtraneousKeys(e: React.KeyboardEvent<HTMLInputElement>) {
  if (
    e.key === "ArrowUp" ||
    e.key === "ArrowDown" ||
    e.key === "-" ||
    e.key === "+" ||
    e.key === "e"
  ) {
    e.preventDefault()
  }
}
