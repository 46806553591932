import { enableMapSet } from "immer"
import React from "react"
import ReactDOM from "react-dom/client"
import ReactModal from "react-modal"
import { App } from "./App"

import "@fontsource/inter"
import "@fontsource/inter/100.css"
import "@fontsource/inter/200.css"
import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/800.css"
import "@fontsource/inter/900.css"

enableMapSet()

// This is a hack to workaround issue where some tools (like zukeeper) use the
// builtin JSON serializer, which does not support bigint.
// eslint-disable-next-line @typescript-eslint/no-redeclare
Object.defineProperty(BigInt.prototype, "toJSON", {
  get() {
    "use strict"
    return () => String(this)
  },
})

// This is needed for screen readers. See
// https://github.com/reactjs/react-modal/blob/master/docs/accessibility/index.md#app-element
// for more info.
ReactModal.setAppElement(document.getElementById("root") as HTMLElement)

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
