import { IconProps, IconSvg } from "./IconSvg"

export const Edit = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 18 18">
      <path
        d="M 17.71 4.042 C 18.1 3.652 18.1 3.002 17.71 2.632 L 15.37 0.292 C 15 -0.098 14.35 -0.098 13.96 0.292 L 12.12 2.122 L 15.87 5.872 M 0 14.252 L 0 18.002 L 3.75 18.002 L 14.81 6.932 L 11.06 3.182 L 0 14.252 Z"
        fill={fill}
      ></path>
    </IconSvg>
  )
}
