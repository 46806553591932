import * as _ from "lodash"
import React from "react"

import { OrderSide } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { useMarket, useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { USD_SYMBOL, parseBigDecimal, tw2 } from "~/utils"
import { Tooltip } from "./Tooltip"
import { TradeNumberInput } from "./TradeNumberInput"

interface Props {
  withDirectionText?: boolean
}

export const TriggerPriceInput = ({ withDirectionText }: Props) => {
  const { symbol, useTradeContextStore } = useTradeContext()
  const triggerPrice = useTradeContextStore((s) => s.input.triggerPrice)
  const triggerIsFromAbove = useTradeContextStore((s) => s.input.triggerIsFromAbove)
  const side = useTradeContextStore((s) => s.input.side)
  const { setTriggerPrice, setTriggerIsFromAbove } = useTradeContextActions(useTradeContextStore)
  const market = useMarket(symbol)

  const { decimals } = skoi.getSymbolConfig(symbol)
  const bestBid = market?.bestBid
  const bestAsk = market?.bestAsk
  const bestBidText = bestBid?.toString(decimals) ?? "-"
  const bestAskText = bestAsk?.toString(decimals) ?? "-"

  const refSideText = side === "bid" ? "Ask" : "Bid"
  const directionText = triggerIsFromAbove ? ">" : "<"
  withDirectionText = withDirectionText ?? true

  const updateTriggerPrice = (value: BigDecimal | undefined, text: string) => {
    const refPrice = side === "bid" ? bestAsk : bestBid
    const fromAbove = value && refPrice ? refPrice.ge(value) : false
    setTriggerPrice({ value, text })
    setTriggerIsFromAbove(fromAbove)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, skoi.PRICE_INPUT_DECIMALS)
    updateTriggerPrice(value, text)
  }

  const onClickBestBid = () => {
    updateTriggerPrice(bestBid, bestBidText)
  }

  const onClickBestAsk = () => {
    updateTriggerPrice(bestAsk, bestAskText)
  }

  const tooltipId = _.uniqueId("trigger-price-tooltip-")
  const tooltipMessage = makeTooltipMessage({
    triggerPrice: triggerPrice.value,
    fromAbove: triggerIsFromAbove,
    side,
    bestBid,
    bestAsk,
  })

  const unit = withDirectionText ? (
    <span>
      <span className={`text-pale font-normal pr-2`}>
        {directionText} Best {refSideText}
      </span>
      {USD_SYMBOL}
    </span>
  ) : (
    USD_SYMBOL
  )

  // TODO: tooltip
  return (
    <>
      <div className="w-full">
        <TradeNumberInput
          label="Trigger Price"
          tooltipId={tooltipId}
          placeholder={"0.00"}
          value={triggerPrice.text}
          onChange={onChange}
          unit={unit}
        />

        <div className={`flex flex-row gap-1 items-center mt-2 ${tw2("font-input-extra-info")}`}>
          <div className="flex flex-row gap-[2px] hover:cursor-pointer" onClick={onClickBestBid}>
            <div className="text-pale">Bid</div>
            <div className="text-pale">${bestBidText}</div>
          </div>
          <div className="text-pale">•</div>
          <div className="flex flex-row gap-[2px] hover:cursor-pointer" onClick={onClickBestAsk}>
            <div className="text-pale">Ask</div>
            <div className="text-pale">${bestAskText}</div>
          </div>
        </div>
      </div>
      <Tooltip id={tooltipId} message={tooltipMessage} place="right" width="15rem" />
    </>
  )
}

const makeTooltipMessage = ({
  triggerPrice,
  fromAbove,
  side,
  bestBid,
  bestAsk,
}: {
  triggerPrice?: BigDecimal
  fromAbove: boolean
  side: OrderSide
  bestBid?: BigDecimal
  bestAsk?: BigDecimal
}) => {
  const bestOppSide = side === "bid" ? "best ask" : "best bid"
  const bestOpp = side === "bid" ? bestAsk : bestBid
  const bestOppStr = bestOpp ? `(currently ${bestOpp.toStringTrimmed()})` : ""
  const triggerPriceDesc = triggerPrice
    ? `trigger price of ${triggerPrice.toStringTrimmed()}`
    : "trigger price"
  const direction = side === "bid" ? "long" : "short"
  const action = fromAbove ? "drops below" : "rises above"

  const makeBold = (text: string) => {
    return <span className="text-white font-black">{text}</span>
  }

  return (
    <div className="text-pale">
      <span className="font-black">Trigger Price</span>
      <br />
      <br />
      When the {makeBold(bestOppSide)} {bestOppStr} {makeBold(action)} &nbsp;
      {makeBold(`the specified ${triggerPriceDesc}`)}, this trigger will create a{" "}
      {makeBold(direction)} market or limit order.
    </div>
  )
}
