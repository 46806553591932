import { IconProps, IconSvg } from "./IconSvg"

export const Cancel = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 20 20">
      <path
        d="M 6.4 15 L 10 11.4 L 13.6 15 L 15 13.6 L 11.4 10 L 15 6.4 L 13.6 5 L 10 8.6 L 6.4 5 L 5 6.4 L 8.6 10 L 5 13.6 L 6.4 15 Z M 10 20 C 8.617 20 7.317 19.737 6.1 19.212 C 4.884 18.686 3.825 17.974 2.925 17.075 C 2.025 16.175 1.313 15.116 0.788 13.9 C 0.264 12.683 0.001 11.383 0 10 C 0 8.616 0.263 7.316 0.788 6.1 C 1.314 4.883 2.026 3.825 2.925 2.925 C 3.825 2.025 4.884 1.312 6.1 0.788 C 7.317 0.263 8.617 0 10 0 C 11.384 0 12.684 0.262 13.9 0.788 C 15.117 1.313 16.175 2.025 17.075 2.925 C 17.975 3.825 18.688 4.883 19.213 6.1 C 19.739 7.316 20.001 8.616 20 10 C 20 11.383 19.738 12.683 19.212 13.9 C 18.687 15.116 17.975 16.175 17.075 17.075 C 16.175 17.975 15.117 18.687 13.9 19.213 C 12.684 19.738 11.384 20 10 20 Z M 10 18 C 12.234 18 14.125 17.225 15.675 15.675 C 17.225 14.125 18 12.233 18 10 C 18 7.766 17.225 5.875 15.675 4.325 C 14.125 2.775 12.234 2 10 2 C 7.767 2 5.875 2.775 4.325 4.325 C 2.775 5.875 2 7.766 2 10 C 2 12.233 2.775 14.125 4.325 15.675 C 5.875 17.225 7.767 18 10 18 Z"
        fill={fill}
      ></path>
    </IconSvg>
  )
}
