import { BigDecimal } from "silverkoi/math"

import * as skoi from "../../api/silverkoi"
import { useMobileMainPageContext } from "../../hooks"
import { MobileDetailView } from "./MobileDetailView"
import { PositionDetailViewData } from "./MobileMainPageContext"
import { MobileSmallButtonWithIcon } from "./MobileSmallButtonWithIcon"

type Props = {
  data: PositionDetailViewData
}

export const MobilePositionDetailView = ({ data }: Props) => {
  const {
    symbol,
    size,
    notional,
    entryPrice,
    twapMarketPrice,
    marketPrice,
    liquidationPrice,
    position,
  } = data.position
  const symbolMeta = skoi.getSymbolMeta(symbol)
  const { setViewData } = useMobileMainPageContext()

  const direction = (() => {
    if (size.isZero()) {
      return "-"
    } else if (size.gt(BigDecimal.zero())) {
      return "Long"
    } else {
      return "Short"
    }
  })()

  const onClickClose = () => {
    setViewData({ type: "reduce-position", symbolMeta, position: data.position })
  }
  const onClickEdit = () => {
    setViewData({ type: "edit-position", symbolMeta, position: data.position })
  }
  const onClickSLTP = () => {
    setViewData({ type: "create-trigger", symbolMeta, position: data.position })
  }

  const buttons = (
    <div className="shrink-0 flex gap-1">
      <MobileSmallButtonWithIcon text="Edit" icon="edit" colorCn="bg-blue" onClick={onClickEdit} />
      <MobileSmallButtonWithIcon
        text="SL/TP"
        icon="gauge"
        colorCn="bg-purple"
        onClick={onClickSLTP}
      />
      <MobileSmallButtonWithIcon
        text="Close"
        icon="cancel"
        colorCn="bg-red"
        onClick={onClickClose}
      />
    </div>
  )

  const detailData = [
    { label: "Side", value: direction },
    { label: "Position Size", value: size.toString(4) },
    { label: "Position Value", value: notional.toString(4) },
    { label: "Entry Price", value: entryPrice ? entryPrice.toString(4) : "-" },
    { label: "Market Price", value: marketPrice ? marketPrice.toString(4) : "-" },
    { label: "Twap Market Price", value: twapMarketPrice ? twapMarketPrice.toString(4) : "-" },
    { label: "Liquidation Price", value: liquidationPrice ? liquidationPrice.toString(4) : "-" },
    {
      label: "Bankruptcy Price",
      value: position.bankruptcyPrice ? position.bankruptcyPrice.toString(4) : "-",
    },
    { label: "Leverage", value: position.leverage ? position.leverage.toString(4) : "-" },
    {
      label: "Account Value",
      value: position.conservativeAccountValue
        ? position.conservativeAccountValue.toString(4)
        : "-",
    },
    {
      label: "Open Interest",
      value: position.openInterestNotional ? position.openInterestNotional.toString(4) : "-",
    },
    { label: "Collateral", value: position.collateral ? position.collateral.toString(4) : "-" },
    {
      label: "Unrealized PnL",
      value: position.unrealizedPnl ? position.unrealizedPnl.toString(4) : "-",
    },
    {
      label: "Unsettled Funding",
      value: position.unsettledFunding ? position.unsettledFunding.toString(4) : "-",
    },
  ]

  return <MobileDetailView symbolMeta={symbolMeta} buttons={buttons} data={detailData} />
}
