import { subscribeWithSelector } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

interface GlobalActions {
  updateSlowTimer: (timestampMs: number) => void
  updateFastTimer: (timestampMs: number) => void
  setBlockTag: (_: bigint) => void
}

interface GlobalState {
  slowTimer: bigint
  fastTimer: bigint
  blockTag?: bigint
  actions: GlobalActions
}

export const useGlobalStore = createWithEqualityFn<GlobalState>()(
  subscribeWithSelector(
    immer((set) => ({
      slowTimer: 0n,
      fastTimer: 0n,
      blockTag: undefined,
      actions: {
        updateSlowTimer: (timestampMs) =>
          set((s) => {
            s.slowTimer = BigInt(timestampMs)
          }),
        updateFastTimer: (timestampMs) =>
          set((s) => {
            s.fastTimer = BigInt(timestampMs)
          }),
        setBlockTag: (v) =>
          set((s) => {
            s.blockTag = v
          }),
      },
    })),
  ),
  shallow,
)

export const useSlowTimer = () => useGlobalStore((s) => s.slowTimer)
export const useFastTimer = () => useGlobalStore((s) => s.fastTimer)
export const useBlockTag = () => useGlobalStore((s) => s.blockTag)
export const useGlobalActions = () => useGlobalStore((s) => s.actions)
