import { useEffect } from "react"

import { PositionSnapshot } from "silverkoi"

import { useIsMobile, useMarket, useTooltipActions } from "~/hooks"
import { TooltipId } from "~/types"
import { getCollateralUsdLogo, getSymbolLogo, getTextColorCs, tw2 } from "~/utils"
import { TooltipMessageContent } from "./TooltipMessageContent"

interface Props {
  position: PositionSnapshot
}

export const CurrentSizeAndCollateralCards = ({ position }: Props) => {
  const { size, freeCollateral, symbolMeta } = position
  const { symbol } = symbolMeta
  const colorCs = getTextColorCs(size)

  const market = useMarket(symbol)
  const { add, remove } = useTooltipActions()

  const tooltipId = `tooltip-collateral-card`
  useEffect(() => {
    const message = (
      <TooltipMessageContent
        tooltipId={TooltipId.MaxCollateral}
        symbol={symbol}
        context={{ market }}
      />
    )
    add(tooltipId, { message, place: "right", width: "300px" })

    return () => {
      remove(tooltipId)
    }
  }, [add, remove, tooltipId, symbol, market])

  return (
    <div className="w-full flex flex-row gap-2">
      <Card
        title="Current Position Size"
        logo={getSymbolLogo(symbol)}
        value={size.toStringTrimmed()}
        valueColorCs={colorCs}
      />
      <Card
        title="Free Collateral"
        logo={getCollateralUsdLogo()}
        value={freeCollateral.toStringTrimmed()}
        valueColorCs={"text-white"}
        tooltipId={tooltipId}
      />
    </div>
  )
}

type CardProps = {
  title: string
  logo: string
  value: string
  valueColorCs: string
  tooltipId?: string
}

const Card = ({ title, logo, value, valueColorCs, tooltipId }: CardProps) => {
  const isMobile = useIsMobile()
  const baseTitleCs = isMobile ? tw2("font-mobile-card-title") : tw2("font-card-title")
  const titleCs = tooltipId
    ? `${baseTitleCs} underline decoration-dotted underline-offset-2`
    : baseTitleCs

  const valueCs = isMobile ? tw2("font-mobile-card-value") : tw2("font-card-value")
  const logoCs = isMobile ? "w-4 h-4" : "w-3 h-3"

  return (
    <div
      className="w-1/2 py-2 flex flex-col gap-2 items-center border border-light-gray rounded-lg"
      data-tooltip-id={tooltipId}
    >
      <div className={titleCs}>{title}</div>

      <div className={`shrink-0 flex flex-row items-center ${valueCs}`}>
        <img src={logo} className={logoCs} />
        <div className={`pl-1 ${valueColorCs}`}>{value}</div>
      </div>
    </div>
  )
}
