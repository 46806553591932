import { useState } from "react"
import { useDocumentTitle } from "usehooks-ts"
import { Portfolio, PortfolioTabId } from "~/components/Portfolio"
import { SideBar } from "~/components/SideBar"

export const PortfolioPage = () => {
  const [tab, setTab] = useState<PortfolioTabId>("Positions")

  useDocumentTitle(`Silver Koi - Portfolio`)

  return (
    <div className="flex gap-6 px-4 w-full h-full pb-4 overflow-hidden">
      <SideBar tab={tab} setTab={setTab} page={"Portfolio"} />
      <div className="grow flex min-w-0 overflow-hidden">
        <Portfolio tab={tab} />
      </div>
    </div>
  )
}
