import { ReactNode } from "react"
import ReactModal from "react-modal"

import { PositionSnapshot } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { UserState } from "~/types"
import { chunks, formatValue, getSymbolLogo, tw2, valueToColor } from "~/utils"

interface DetailedPositionModalProps {
  openModal: boolean
  handleCloseModal: () => void
  userState: UserState
  position?: PositionSnapshot
}

type DetailedPositionModalBodyProps = Required<DetailedPositionModalProps>

export const DetailedPositionModal = (props: DetailedPositionModalProps) => {
  const { position } = props
  // TODO: refactor, early exit with fragment is iffy
  if (!position) {
    return <></>
  } else {
    const bodyProps = { ...props, position }
    return <DetailedPositionModalBody {...bodyProps} />
  }
}

const DetailedPositionModalBody = ({
  openModal,
  handleCloseModal,
  userState,
  position,
}: DetailedPositionModalBodyProps) => {
  const [direction, directionColorCs] = (() => {
    if (position.size.isZero()) {
      // TODO: What to show here?
      return ["-", "text-white"]
    } else if (position.size.gt(BigDecimal.zero())) {
      return ["LONG", "text-green"]
    } else {
      return ["SHORT", "text-red"]
    }
  })()

  const symbol = position.symbolMeta.symbol
  const marketPrice = userState.getMarketPrice(symbol)
  const twapMarketPrice = userState.getTwapMarketPrice(symbol)
  const liquidationPrice = skoi.resolveCurrentPrice({
    price: position.liquidationPrice,
    marketPrice,
  })
  const bankruptcyPrice = skoi.resolveCurrentPrice({
    price: position.bankruptcyPrice,
    marketPrice,
  })

  const data = [
    {
      label: "Side",
      value: <div className={tw2("font-detail-value") + " " + directionColorCs}>{direction}</div>,
    },
    {
      label: "Position Size",
      value: <ValueCell noZero signed color value={position.size} decimals={5} />,
    },
    {
      label: "Position Value",
      value: <ValueCell noZero dollar signed color value={position.notional} decimals={2} />,
    },
    {
      label: "Entry Price",
      value: <ValueCell dollar value={position.entryPrice} decimals={2} />,
    },
    {
      label: "Market Price",
      value: <ValueCell dollar value={marketPrice} decimals={2} />,
    },
    {
      label: "Twap Market Price",
      value: <ValueCell dollar value={twapMarketPrice} decimals={2} />,
    },
    {
      label: "Liquidation Price",
      value: <ValueCell dollar value={liquidationPrice} decimals={2} />,
    },
    {
      label: "Bankruptcy Price",
      value: <ValueCell dollar value={bankruptcyPrice} decimals={2} />,
    },
    {
      label: "Leverage",
      value: <ValueCell suffix="X" value={position.leverage} decimals={2} />,
    },
    {
      label: "Account Value",
      value: (
        <ValueCell dollar signed color value={position.conservativeAccountValue} decimals={2} />
      ),
    },
    {
      label: "Open Interest",
      value: <ValueCell noZero dollar value={position.openInterestNotional} decimals={2} />,
    },
    {
      label: "Collateral",
      value: <ValueCell dollar signed color value={position.collateral} decimals={2} />,
    },
    {
      label: "Unrealized PnL",
      value: <ValueCell dollar signed color value={position.unrealizedPnl} decimals={2} />,
    },
    {
      label: "Unsettled Funding",
      value: <ValueCell dollar signed color value={position.unsettledFunding} decimals={2} />,
    },
  ]

  return (
    <ReactModal
      isOpen={openModal}
      onRequestClose={handleCloseModal}
      className={
        "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 " +
        "bg-black border-gradient-[1px-8px]"
      }
      overlayClassName="fixed inset-0 backdrop-blur-sm z-[22]"
      shouldCloseOnOverlayClick={true}
    >
      <div className="flex flex-col px-6 py-6">
        <div className="flex flex-row items-center gap-3">
          <img
            src={getSymbolLogo(position.symbolMeta.symbol)}
            className="w-[2rem] h-[2rem] rounded-[40px] align-middle"
          />
          <div className={tw2("font-detail-title")}>{position.symbolMeta.symbol}</div>
          <button
            className={
              "absolute right-8 " + "bg-black justify-center items-center text-white border-none"
            }
            onClick={handleCloseModal}
          >
            &#x2715;
          </button>
        </div>
        <div className="shrink-0 h-4" />
        <div className="shrink-0 w-full h-[1px] bg-neutral" />
        <div className="shrink-0 h-2" />

        <DetailsTable data={data} />
      </div>
    </ReactModal>
  )
}

interface ValueCellProps {
  value?: BigDecimal
  decimals: number
  noZero?: boolean
  dollar?: boolean
  signed?: boolean
  color?: boolean
  suffix?: string
}

const ValueCell = ({ value, decimals, noZero, dollar, signed, color, suffix }: ValueCellProps) => {
  const text = formatValue(value, {
    signed,
    decimals,
    suffix,
    dollar,
    noZero,
  })
  const style = value && color ? { color: valueToColor(value) } : { color: "white" }
  return (
    <div className="flex flex-row items-center justify-start align-middle gap-1">
      <div className={tw2("font-detail-value")} style={style}>
        {text}
      </div>
    </div>
  )
}

interface TableData {
  label: string
  value: ReactNode
}

const DetailsTable = ({ data }: { data: TableData[] }): ReactNode => {
  // Break data into chunks
  const rows: ReactNode[] = []
  for (const [i, chunk] of chunks(data, 4)) {
    rows.push(makeSingleRowTable(i, chunk))
    rows.push(<div key={`${i}-border`} className="shrink-0 w-full h-[1px] bg-neutral mb-2 mt-2" />)
  }

  return <>{rows}</>
}

const makeSingleRowTable = (index: number, data: TableData[]): ReactNode => {
  const headers = data.map(({ label }) => {
    return (
      <th key={label} className={tw2("font-detail-label") + " py-1"}>
        {label}
      </th>
    )
  })

  const values = data.map(({ label, value }) => {
    return <td key={label}>{value}</td>
  })

  // TODO: avoid hardcoding this table width
  return (
    <table key={`${index}-row`} className="table-fixed text-left w-[40rem]">
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>
        <tr>{values}</tr>
      </tbody>
    </table>
  )
}
