import { ReactNode } from "react"

import { DiscretizedOrderBookPriceLevel, OrderSide } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "../../api/silverkoi"
import { useMobileMainPageContext, useOrderBook } from "../../hooks"

const TEXT_CS = "font-sans text-[0.75rem] leading-[1rem] tracking-[-0.01em] font-medium"
const FULL_BAR_NOTIONAL = 500000
const MAX_LEVELS = 15

export const MobileOrderBook = () => {
  const { symbolMeta } = useMobileMainPageContext()
  const { symbol } = symbolMeta
  const { decimals } = skoi.getSymbolConfig(symbol)
  const minPriceStep = BigDecimal.fromRaw(1, decimals)
  const orderBookInput = { symbol, priceStep: minPriceStep }
  const { data: orderBook } = useOrderBook(orderBookInput)

  const makeOrderBookRows = (
    side: OrderSide,
    levels: DiscretizedOrderBookPriceLevel[],
  ): ReactNode[] => {
    const nodes: ReactNode[] = []
    let cumNotional = 0
    for (const [i, level] of levels.entries()) {
      if (i > MAX_LEVELS) break
      const key = `${side}-${i}`
      cumNotional += level.size.real() * level.price.real()
      const barWidthPct = Math.min(100, (cumNotional * 100) / FULL_BAR_NOTIONAL)
      if (level.price.isZero()) {
        break
      }

      const sizeDecimals = Math.max(5 - decimals, 0)
      nodes.push(
        <Row
          key={key}
          side={side}
          size={level.size.toString(sizeDecimals)}
          price={level.price.toString(decimals)}
          barWidthPct={barWidthPct}
        />,
      )
    }
    return nodes
  }

  return (
    <div className="grow-0 shrink-0 w-full flex grid-cols-2 bg-neutral-07 pt-[0.5rem]">
      <div className="flex flex-col w-full px-[0.5rem]">
        <div className={`flex justify-between ${TEXT_CS} text-white`}>
          <span>Amount</span>
          <span>Bids</span>
        </div>

        <div className="shrink-0 h-1" />

        <div className={`flex flex-col ${TEXT_CS} text-white gap-1`}>
          {makeOrderBookRows("bid", orderBook ? orderBook.bids : [])}
        </div>
      </div>

      <div className="flex flex-col w-full px-[0.5rem]">
        <div className={`flex justify-between ${TEXT_CS} text-white`}>
          <span>Asks</span>
          <span>Amount</span>
        </div>

        <div className="shrink-0 h-1" />

        <div className={`flex flex-col ${TEXT_CS} text-white gap-1`}>
          {makeOrderBookRows("ask", orderBook ? orderBook.asks : [])}
        </div>
      </div>
    </div>
  )
}

interface RowProps {
  side: OrderSide
  size: string
  price: string
  barWidthPct: number
}

const Row = ({ side, size, price, barWidthPct }: RowProps) => {
  const isBid = side === "bid"
  const barCs = isBid ? "bg-green/[.15]" : "bg-red/[.15]"
  const barWidthPctCapped = Math.min(100, barWidthPct)
  const justifyCs = isBid ? "justify-end" : "justify-start"
  return (
    <div className="flex flex-row py-[2px] w-full">
      <div className="flex relative w-full">
        <div className={`flex flex-row w-full ${justifyCs}`}>
          <div className={barCs} style={{ width: `${barWidthPctCapped}%` }}>
            <div className="flex grow">&nbsp;</div>
          </div>
        </div>

        <div className="flex w-full absolute top-1/2 -translate-y-1/2">
          <div className="flex flex-row w-full justify-between">
            <div>{size}</div>
            <div>{price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
