import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { toast } from "react-toastify"
import { z } from "zod"

import { Chain } from "silverkoi"

import { apiUrl } from "../utils"
import { QueryKey } from "./queries/types"

const EventSchema = z.object({
  event_name: z.string(),
  symbol: z.string(),
  data: z.any(),
})

export type Event = z.infer<typeof EventSchema>

const NotificationSchema = z.object({
  id: z.string(),
  type: z.string(),
  subject: z.string(),
  content: z.string(),
  event: EventSchema,
  address: z.string(),
  timestamp: z.number().transform(BigInt),
  internal_id: z.number().transform(BigInt),
})

export type Notification = z.output<typeof NotificationSchema>

const NotificationsPageSchema = z.object({
  notifications: z.array(NotificationSchema),
  next_cursor: z.number().transform(BigInt),
})

export type NotificationsPage = z.output<typeof NotificationsPageSchema>

interface NotificationArgs {
  chain: Chain
  address?: string
}

interface PageParam {
  cursor: bigint
}

export function useNotifications(args: NotificationArgs) {
  const queryKey: [QueryKey<NotificationArgs>] = [
    { tag: { type: "api", key: "notifications" }, args },
  ]

  const queryFn = async ({
    queryKey,
    pageParam,
  }: {
    queryKey: [QueryKey<NotificationArgs>]
    pageParam: PageParam
  }) => {
    const args = queryKey[0].args
    const { address } = args
    if (!address) {
      return {
        notifications: [],
        next_cursor: 0n,
      }
    }

    return await getNotificationsPage({
      ...args,
      ...pageParam,
      address,
    })
  }

  const initialPageParam: PageParam = {
    cursor: 0n,
  }

  const getNextPageParam = (lastPage: NotificationsPage): PageParam | undefined => {
    const param = {
      cursor: lastPage.next_cursor,
    }
    return param.cursor === 0n ? undefined : param
  }

  return useInfiniteQuery({
    queryKey,
    queryFn,
    initialPageParam,
    getNextPageParam,
    refetchInterval: 10_000,
    enabled: !!args.address,
    throwOnError: false,
  })
}

export function useHasNotifications({ chain, address }: { chain: Chain; address?: string }) {
  const { data } = useNotifications({ chain, address })
  const pages = data?.pages ?? []
  const notifications = pages.map((p) => p.notifications).flat(1)
  return notifications.length !== 0
}

interface NotificationRequest {
  chain: Chain
  address: string
  cursor: bigint
}

// Returns the notifications inside the page in descreasing order always.
async function getNotificationsPage(request: NotificationRequest): Promise<NotificationsPage> {
  const url = `${apiUrl()}/v0/notification/get`
  const params = {
    chain: request.chain,
    address: request.address,
    cursor: request.cursor,
  }
  const response = await axios.get(url, { params })
  const page = NotificationsPageSchema.parse(response.data)
  page.notifications.sort((a: Notification, b: Notification) => {
    return Number(b.internal_id - a.internal_id)
  })
  return page
}

interface MarkReadArgs {
  chain: Chain
  address?: string
  notificationId: string
}

export function useMarkRead() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async ({ chain, address, notificationId }: MarkReadArgs) => {
      if (!address) {
        return
      }
      const params = {
        chain,
        address,
        notification_id: notificationId,
      }
      return await axios.put(`${apiUrl()}/v0/notification/mark_read`, params)
    },
    onError: (error: unknown) => {
      console.error(error)
      toast.error("Failed to mark notification as read")
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ tag: { type: "api", key: "notifications" } }],
      })
    },
  })
  return mutation
}
