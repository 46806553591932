import { ReactNode, useEffect, useState } from "react"

import { OrderSide } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { useSimulationResult, useTooltipActions, useTradeContext } from "~/hooks"
import { Delta, InputMode, OperationSummary, OrderType, TooltipId } from "~/types"
import { FormatValueArgs, RIGHT_ARROW_TEXT, formatValue, getIcon, tw2 } from "~/utils"
import { TooltipContext, TooltipMessageContent } from "./TooltipMessageContent"

interface TxStat {
  tooltipId?: TooltipId
  tooltipContext?: TooltipContext
  label: string
  text: ReactNode
  fullDecimalText?: ReactNode
}

interface TriggerContext {
  side: OrderSide
  size?: BigDecimal
}

interface Props {
  whitelist?: string[]
  triggerContext?: TriggerContext
}

export const TransactionSummary = ({ whitelist, triggerContext }: Props) => {
  const { symbol, useTradeContextStore } = useTradeContext()
  const orderType = useTradeContextStore((s) => s.input.type)
  const inputMode = useTradeContextStore((s) => s.input.inputMode)
  const simResultQuery = useSimulationResult({ enabled: true })
  const { data: simResult } = simResultQuery
  const [isExpand, setIsExpand] = useState<boolean>(true)
  const summary = simResult?.ok ? simResult?.val.summary : undefined
  const requiredApproveAmount = skoi.getRequiredApproveAmount(simResult)

  const allStats = getSupportedStats({ triggerContext, summary })
  const filterStat = getStatFilter({ inputMode, orderType, summary, whitelist })
  const stats = allStats.filter(filterStat)

  const rows = stats.map((s) => <TxSummaryEntry key={s.label} stat={s} symbol={symbol} />)

  const showLoader = simResultQuery.isFetching

  if (requiredApproveAmount !== 0n) {
    return <></>
  } else {
    const rotateCs = isExpand ? "" : "-rotate-90"
    return (
      <div className="flex flex-col px-2 py-2 rounded-md bg-neutral-dark">
        <div
          className="flex w-full items-center justify-between hover:cursor-pointer"
          onClick={() => setIsExpand(!isExpand)}
        >
          <div className="flex flex-row gap-2">
            <div className={`${tw2("font-summary-title")} text-white`}>Transaction Summary</div>
            {showLoader && <img src={getIcon("loading-spin")} className="w-3 h-3" />}
          </div>

          <img src={getIcon("arrow")} className={`w-3 ${rotateCs}`} />
        </div>

        {isExpand && (
          <div className={`flex flex-col ${tw2("font-summary-content")}`}>
            <div className="shrink-0 w-full mt-2 h-[2px] bg-neutral" />
            <div className="flex flex-col pt-2 gap-3">{...rows}</div>
          </div>
        )}
      </div>
    )
  }
}

interface TxSummaryEntryProps {
  stat: TxStat
  symbol: string
}

const TxSummaryEntry = ({ stat, symbol }: TxSummaryEntryProps) => {
  const { add, remove } = useTooltipActions()
  const { tooltipId, tooltipContext, label, text, fullDecimalText } = stat

  const id = tooltipId === undefined ? undefined : `tooltip-tx-summary-${tooltipId}`
  useEffect(() => {
    if (!id || !tooltipId) return
    const message = makeTooltipMessage(symbol, tooltipId, tooltipContext, fullDecimalText)

    add(id, { message, place: "left", width: "300px" })

    return () => {
      remove(id)
    }
  }, [add, remove, id, symbol, tooltipId, tooltipContext, fullDecimalText])

  const baseLabelFontCs = `font-semibold text-white`
  const labelFontCs = id
    ? `${baseLabelFontCs} underline decoration-dotted underline-offset-2 hover:cursor-help`
    : baseLabelFontCs

  const valueFontCs = `text-yellow-warning text-right`

  return (
    <div data-tooltip-id={id} className="flex w-full items-center justify-between">
      <div className={labelFontCs}>{label}</div>
      {typeof text === "string" ? <div className={valueFontCs}>{text}</div> : text}
    </div>
  )
}

const makeTooltipMessage = (
  symbol: string,
  tooltipId: TooltipId,
  tooltipContext?: TooltipContext,
  fullDecimalText?: ReactNode,
) => {
  return (
    <div className="text-left text-pale">
      {fullDecimalText ? (
        <div>
          Full precision: &nbsp; {fullDecimalText}
          <hr className="my-2" />
        </div>
      ) : (
        <></>
      )}
      {TooltipMessageContent({ tooltipId, symbol, context: tooltipContext })}
    </div>
  )
}

const getSupportedStats = ({
  summary,
  triggerContext,
}: {
  summary?: OperationSummary
  triggerContext?: TriggerContext
}): TxStat[] => {
  const stats: TxStat[] = []
  stats.push(...getPositionOperationStats({ summary }))
  if (triggerContext) {
    stats.push(...getTriggerOperationStats({ ...triggerContext, summary }))
  }
  return stats
}

const getPositionOperationStats = ({ summary }: { summary?: OperationSummary }): TxStat[] => {
  return [
    {
      tooltipId: TooltipId.TradeEntryPrice,
      label: "Entry Price",
      text: <FormattedValue value={summary?.entryPrice} decimals={2} dollar />,
    },
    {
      tooltipId: TooltipId.TradeCollateralChange,
      label: "Collateral",
      text: <FormattedDelta delta={summary?.collateral} decimals={2} dollar />,
      fullDecimalText: <FormattedDelta delta={summary?.collateral} dollar />,
    },
    {
      tooltipId: TooltipId.TradePositionSizeChange,
      label: "Position Size",
      text: <FormattedDelta delta={summary?.positionSize} decimals={5} signed />,
    },
    {
      tooltipId: TooltipId.TradeOpenNotionalChange,
      label: "Open Notional",
      text: <FormattedDelta delta={summary?.openNotional} decimals={2} signed dollar />,
    },
    {
      label: "Open Interest Size",
      text: <FormattedDelta delta={summary?.openInterestSize} decimals={5} />,
    },
    {
      tooltipId: TooltipId.TradeOpenInterestChange,
      label: "Open Interest Notional",
      text: <FormattedDelta delta={summary?.openInterestNotional} decimals={2} dollar />,
      fullDecimalText: <FormattedDelta delta={summary?.openInterestNotional} decimals={6} dollar />,
    },
    {
      tooltipId: TooltipId.PriceImpact,
      label: "Price Impact",
      text: <FormattedValue value={summary?.priceImpactPct} decimals={2} suffix="%" />,
    },
    {
      tooltipId: TooltipId.LiquidationPrice,
      label: "Liquidation Price",
      text: (
        <FormattedDelta delta={summary?.liquidationPrice} minDecimals={2} decimals={2} dollar />
      ),
    },
    {
      tooltipId: TooltipId.Leverage,
      label: "Leverage",
      text: <FormattedDelta delta={summary?.leverage} decimals={2} suffix="X" />,
    },
    // TODO: add realized pnl
    {
      tooltipId: TooltipId.TransactionFee,
      label: "Transaction Fee",
      text: <FormattedValue value={summary?.transactionFee} dollar />,
    },
  ]
}

const getTriggerOperationStats = ({
  side,
  size,
  summary,
}: {
  side: OrderSide
  size?: BigDecimal
  summary?: OperationSummary
}): TxStat[] => {
  const isBid = side === "bid"
  const triggerDirection = isBid ? "Long" : "Short"
  const triggerSize = size?.format() ?? ""
  const triggerSizeText = `${triggerDirection} ${triggerSize}`

  const tpTooltipContext = {
    triggerSide: side,
    triggerFromAbove: isBid,
    triggerPrice: summary?.tpTriggerPrice?.newValue,
  }
  const slTooltipContext = {
    triggerSide: side,
    triggerFromAbove: !isBid,
    triggerPrice: summary?.slTriggerPrice?.newValue,
  }

  return [
    {
      tooltipId: TooltipId.TriggerOrder,
      label: "Order",
      text: triggerSizeText,
    },
    {
      tooltipId: TooltipId.TPTriggerPrice,
      tooltipContext: tpTooltipContext,
      label: `Take-Profit Trigger Price`,
      text: <FormattedDelta delta={summary?.tpTriggerPrice} decimals={2} dollar />,
      fullDecimalText: <FormattedDelta delta={summary?.tpTriggerPrice} decimals={7} dollar />,
    },
    {
      label: `Take-Profit Limit Price`,
      text: <FormattedDelta delta={summary?.tpLimitPrice} decimals={2} dollar />,
      fullDecimalText: <FormattedDelta delta={summary?.tpLimitPrice} decimals={7} dollar />,
    },
    {
      tooltipId: TooltipId.SLTriggerPrice,
      tooltipContext: slTooltipContext,
      label: `Stop-Loss Trigger Price`,
      text: <FormattedDelta delta={summary?.slTriggerPrice} decimals={2} dollar />,
      fullDecimalText: <FormattedDelta delta={summary?.slTriggerPrice} decimals={7} dollar />,
    },
    {
      label: `Stop-Loss Limit Price`,
      text: <FormattedDelta delta={summary?.slLimitPrice} decimals={2} dollar />,
      fullDecimalText: <FormattedDelta delta={summary?.slLimitPrice} decimals={7} dollar />,
    },
  ]
}

const getStatFilter = ({
  inputMode,
  orderType,
  summary,
  whitelist,
}: {
  inputMode?: InputMode
  orderType: OrderType
  summary?: OperationSummary
  whitelist?: string[]
}) => {
  const filter = (stat: TxStat) => {
    if (inputMode === undefined) return false

    const label = stat.label
    if (whitelist && !whitelist.includes(label)) {
      return false
    }

    if (orderType === "market") {
      if (label === "Open Interest Size") return false
      if (label === "Open Interest Notional") return false
    }

    if (
      inputMode === "NewPosition" ||
      inputMode === "ReducePosition" ||
      inputMode === "AddPosition"
    ) {
      if (summary && summary.positionSize?.diff.isZero()) {
        if (label === "Entry Price") return false
        if (label === "Liquidation Price") return false
        if (label === "Position Size") return false
        if (label === "Open Notional") return false
        if (label === "Price Impact") return false
      }

      if (summary && summary.openInterestSize?.diff.isZero()) {
        if (label === "Open Interest Size") return false
        if (label === "Open Interest Notional") return false
      }
    } else if (inputMode === "WithdrawCollateral" || inputMode === "DepositCollateral") {
      if (label === "Collateral") return true
      if (label === "Position Size") return true
      if (label === "Open Notional") return true
      if (label === "Liquidation Price") return true
      if (label === "Leverage") return true
      return false
    } else if (inputMode === "CreateSLTP" || inputMode === "EditTrigger") {
      if (label === "Order") return true
      if (label === "Take-Profit Trigger Price") return true
      if (label === "Take-Profit Limit Price") return true
      if (label === "Stop-Loss Trigger Price") return true
      if (label === "Stop-Loss Limit Price") return true
      return false
    }

    return true
  }
  return filter
}

const equal = (a: BigDecimal | undefined, b: BigDecimal | undefined): boolean => {
  if (a === undefined && b === undefined) return true
  if (!a || !b) return false
  return a.eq(b)
}

const FormattedDelta = (
  args: FormatValueArgs & {
    delta?: Delta<BigDecimal | undefined>
  },
) => {
  const { delta } = args
  if (delta) {
    if (equal(delta.oldValue, delta.newValue)) {
      const oldStr = formatValue(delta.oldValue, args)
      return <span className="text-gray">{oldStr}</span>
    } else {
      const oldStr = formatValue(delta.oldValue, args)
      const newStr = formatValue(delta.newValue, args)
      return (
        <span>
          <span className="text-gray">
            {oldStr} {RIGHT_ARROW_TEXT}
          </span>
          <span className="text-yellow-warning font-bold">&nbsp; {newStr}</span>
        </span>
      )
    }
  } else {
    return <span className="text-white">-</span>
  }
}

const FormattedValue = (
  args: FormatValueArgs & {
    value?: BigDecimal
  },
) => {
  const { value } = args
  if (value) {
    const str = formatValue(value, args)
    return <span className="text-yellow-warning font-bold">&nbsp; {str}</span>
  } else {
    return <span className="text-white">-</span>
  }
}
