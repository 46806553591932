import { useState } from "react"

import { LeftRightScrollableArea } from "../../components/LeftRightScrollableArea"
import { ScrollableArea } from "../../components/ScrollableArea"
import { TVChartContainer } from "../../components/TVChartContainer"
import { useChainDetail, useMobileMainPageContext, useUserState } from "../../hooks"
import { tw } from "../../utils"
import { AssetDropdown } from "../AssetDropdown"
import { MobileMainButton } from "./MobileMainButton"
import { OverviewTab, OverviewViewData } from "./MobileMainPageContext"
import { MobileOrderBook } from "./MobileOrderBook"
import { MobileOrderList } from "./MobileOrderList"
import { MobilePositionList } from "./MobilePositionList"
import { MobileProduct } from "./MobileProduct"
import { MobileTriggerList } from "./MobileTriggerList"

type Props = {
  data: OverviewViewData
}

export const MobileOverviewView = ({ data }: Props) => {
  const { symbolMeta } = useMobileMainPageContext()
  const { chain } = useChainDetail()
  return (
    <>
      <AssetDropdown symbolMeta={symbolMeta} height={"3rem"} />
      <ScrollableArea className="px-3">
        <div className="shrink-0 h-2" />

        <MobileProduct />

        <div className="shrink-0 h-2" />

        <div className="flex flex shrink-0 w-full h-[20rem]">
          <TVChartContainer chain={chain} symbol={symbolMeta.symbol} />
        </div>

        <InfoSection data={data} />
      </ScrollableArea>

      <div className="grow" />
      <MobileMainButton />
    </>
  )
}

const InfoSection = ({ data }: Props) => {
  const { initialTab } = data
  const [tabType, setTabType] = useState<OverviewTab>(initialTab)

  const makeBodyComponent = () => {
    switch (tabType) {
      case "positions":
        return <MobilePositionList />
      case "orders":
        return <MobileOrderList />
      case "triggers":
        return <MobileTriggerList />
      case "orderbook":
        return <MobileOrderBook />
      default:
        throw new Error(`unknown tab type: ${tabType}`)
    }
  }

  const positionsLabel = usePositionsTableLabel()
  const ordersLabel = useOrdersTableLabel()
  const triggersLabel = useTriggersLabel()

  return (
    <div className="flex flex-col gap-2.5">
      <LeftRightScrollableArea>
        <div className="flex flex-row gap-1 items-center pt-2 pb-1">
          <div className="flex flex-row items-center gap-[0.25rem]">
            <TabButton
              label={positionsLabel}
              value={"positions"}
              tabType={tabType}
              setTabType={setTabType}
            />
            <TabButton
              label={ordersLabel}
              value={"orders"}
              tabType={tabType}
              setTabType={setTabType}
            />
            <TabButton
              label={triggersLabel}
              value={"triggers"}
              tabType={tabType}
              setTabType={setTabType}
            />
            <TabButton
              label={"Order Book"}
              value={"orderbook"}
              tabType={tabType}
              setTabType={setTabType}
            />
          </div>
        </div>
      </LeftRightScrollableArea>

      <div className="flex w-full min-h-[200px]">{makeBodyComponent()}</div>
    </div>
  )
}

const usePositionsTableLabel = () => {
  const { data: userState } = useUserState()
  const positions = userState?.getNonEmptyPositionsThatAreNotZeroSizeWithNonZeroOpenInterest()
  const count = positions?.length ?? 0
  if (count === 0) {
    return "Positions"
  } else {
    return `Positions (${count})`
  }
}

const useOrdersTableLabel = () => {
  const { data: userState } = useUserState()
  const orders = userState?.getOpenOrders()
  const count = orders?.length ?? 0
  if (count === 0) {
    return "Orders"
  } else {
    return `Orders (${count})`
  }
}

const useTriggersLabel = () => {
  const { data: userState } = useUserState()
  const triggers = userState?.getTriggers()
  let count = 0
  if (triggers) {
    for (const trigger of triggers) {
      if (!trigger.fromAboveTriggerPrice.isZero()) {
        count++
      }
      if (!trigger.fromBelowTriggerPrice.isZero()) {
        count++
      }
    }
  }

  if (count === 0) {
    return "Triggers"
  } else {
    return `Triggers (${count})`
  }
}

type TabButtonProps = {
  label: string
  value: OverviewTab
  tabType: OverviewTab
  setTabType: (_: OverviewTab) => void
}

const TabButton = ({ label, value, tabType, setTabType }: TabButtonProps) => {
  const buttonCs =
    "w-fit h-[1.5rem] py-1 rounded-md border-px border-transparent " +
    "outline-none focus:outline-none"

  const textCs = `${tw("font-caption-2-bold")} text-neutral-03 whitespace-nowrap`
  const unselectedCs = `${textCs} ${buttonCs} bg-neutral-05 hover:border-pale-blue hover:font-extrabold`
  const selectedCs = `${textCs} ${buttonCs} bg-pale-blue hover:border-transparent`
  const selected = value === tabType

  return (
    <button className={selected ? selectedCs : unselectedCs} onClick={() => setTabType(value)}>
      <div className="w-[5.5rem]">{label}</div>
    </button>
  )
}
