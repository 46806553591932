import { useEffect } from "react"

import { BigDecimal } from "silverkoi/math"

import { CurrentSizeAndCollateralCards } from "~/components/CurrentSizeAndCollateralCards"
import { LeverageInput } from "~/components/LeverageInput"
import { OrderSizeInput } from "~/components/OrderSizeInput"
import { ScrollableArea } from "~/components/ScrollableArea"
import { ToggledPriceInput } from "~/components/ToggledPriceInput"
import { TradeWarning } from "~/components/TradeWarning"
import { TransactionSummary } from "~/components/TransactionSummary"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useDefaultLeverage, useTradeContext, useTradeContextActions } from "~/hooks"
import { MobileMainButton } from "./MobileMainButton"
import { CreateTriggerViewData } from "./MobileMainPageContext"

type Props = {
  data: CreateTriggerViewData
}

export const MobileCreateTriggerView = ({ data }: Props) => {
  const position = data.position.position
  return (
    <TradeContextProvider symbol={position.symbolMeta.symbol} defaultPosition={position}>
      <Body data={data} />
      <div className="grow" />
      <MobileMainButton />
    </TradeContextProvider>
  )
}

const Body = ({ data }: Props) => {
  const { position: positionData } = data
  const { position } = positionData

  const tradeContext = useTradeContext()
  const { symbol, useTradeContextStore } = tradeContext
  const {
    reset,
    setInputMode,
    setOrderType,
    setSide,
    setSlTriggerPrice,
    setTpTriggerPrice,
    setLeverage,
  } = useTradeContextActions()
  const { size, slTriggerPrice, tpTriggerPrice } = useTradeContextStore((s) => ({
    size: s.input.size,
    slTriggerPrice: s.input.slTriggerPrice,
    tpTriggerPrice: s.input.tpTriggerPrice,
  }))

  const defaultSize = position.size.abs()
  const side = position.size.gt(BigDecimal.zero()) ? "ask" : "bid"
  const defaultLeverage = useDefaultLeverage(position)

  useEffect(() => {
    reset()
    setInputMode("CreateSLTP")
    setOrderType("stop-limit")
    setSide(side)
    setLeverage(defaultLeverage)
    setSlTriggerPrice({ value: undefined, text: "" })
    setTpTriggerPrice({ value: undefined, text: "" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={"flex flex-col w-full h-full px-3 bg-black rounded-lg overflow-hidden"}>
      <div className="shrink-0 h-2" />
      <CurrentSizeAndCollateralCards position={position} />
      <div className="shrink-0 h-3" />

      <ScrollableArea>
        <ToggledPriceInput
          label={"TP Trigger Price"}
          input={tpTriggerPrice}
          setInput={setTpTriggerPrice}
          defaultEnabled={true}
        />
        <div className="shrink-0 h-4" />
        <ToggledPriceInput
          label={"SL Trigger Price"}
          input={slTriggerPrice}
          setInput={setSlTriggerPrice}
          defaultEnabled={true}
        />
        <div className="shrink-0 h-5" />

        <OrderSizeInput defaultSize={defaultSize} />
        <div className="shrink-0 h-3" />

        <LeverageInput symbol={symbol} />

        <div className="shrink-0 h-3" />

        <TradeWarning />

        <TransactionSummary triggerContext={{ side, size: size.value }} />
      </ScrollableArea>
    </div>
  )
}
