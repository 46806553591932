import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table"
import moment from "moment"
import { SyntheticEvent, useEffect, useMemo } from "react"

import * as sk from "silverkoi"
import { OrderSnapshot } from "silverkoi"

import * as skoi from "~/api/silverkoi"
import { OrderData, useOrderData, useSilverKoiApi, useSubmitTx, useTooltipActions } from "~/hooks"
import { useFastTimer } from "~/stores"
import { TooltipId } from "~/types"
import { formatTimestamp, getIcon } from "~/utils"
import { InfoTable } from "./InfoTable"
import { PositionCell } from "./PositionCell"
import { SideValue } from "./SideValue"
import { TableColumnLabel } from "./TableColumnLabel"
import { getMakeColumnDef } from "./TableHelpers"
import { TableValueCell } from "./TableValueCell"

export interface OrdersTableProps {
  fullVersion: boolean
}

export const OrdersTable = ({ fullVersion }: OrdersTableProps) => {
  const { data, isLoading } = useOrderData()

  // TODO: Revisit use of useMemo
  const columns = useMemo(() => {
    return makeColumns(fullVersion)
  }, [fullVersion])

  const columnPinning = {
    left: ["position"],
    right: ["cancel"],
  }

  const exclude = fullVersion ? [] : ["positionId", "orderId"]

  return (
    <InfoTable
      fullVersion={fullVersion}
      loading={isLoading}
      data={data ?? []}
      columns={columns}
      columnPinning={columnPinning}
      exclude={exclude}
    />
  )
}

const columnHelper = createColumnHelper<OrderData>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeColumns = (fullVersion: boolean): ColumnDef<OrderData, any>[] => {
  const tooltipPlace = fullVersion ? "bottom" : "top"
  const makeColumnDef = getMakeColumnDef({
    table: "orders",
    fullVersion,
    columnHelper,
  })

  return [
    makeColumnDef({
      accessor: "position",
      header: "Position",
      cell: (props) => {
        return <PositionCell position={props.getValue()} fullVersion={fullVersion} />
      },
    }),

    makeColumnDef({
      accessor: "positionId",
      header: "Position ID",
      cell: (props) => props.getValue().toString(),
    }),

    makeColumnDef({
      accessor: "orderId",
      header: "Order ID",
      cell: (props) => props.getValue().toString(),
    }),

    makeColumnDef({
      accessor: "side",
      header: () => (
        <TableColumnLabel
          labelText="Order Side"
          tooltipId={TooltipId.OrderSide}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: (props) => {
        return <SideValue side={props.getValue()} />
      },
    }),

    makeColumnDef({
      accessor: "price",
      header: () => (
        <TableColumnLabel
          labelText="Limit Price"
          tooltipId={TooltipId.OrderLimitPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: "size",
      header: "Order Size",
      cell: (props) => {
        return <TableValueCell value={props.getValue()} noZero decimals={5} />
      },
    }),

    makeColumnDef({
      accessor: "tif",
      header: "Time In Force",
      cell: (props) => (
        <span className="whitespace-nowrap">{sk.tifToLongString(props.getValue())}</span>
      ),
    }),

    makeColumnDef({
      accessor: (r) => ({ deadline: r.deadline, uniqueId: r.key }),
      id: "deadline",
      header: "Deadline",
      cell: (props) => <DeadlineCell {...props.getValue()} />,
    }),

    makeColumnDef({
      accessor: "executedSize",
      header: "Filled Size",
      cell: (props) => {
        return <TableValueCell value={props.getValue()} signed color decimals={5} />
      },
    }),

    makeColumnDef({
      accessor: "remainingSize",
      header: "Remaining Size",
      cell: (props) => {
        return <TableValueCell value={props.getValue()} signed color decimals={5} />
      },
    }),

    makeColumnDef({
      accessor: "order",
      id: "cancel",
      header: () => "Cancel",
      cell: (props) => {
        return (
          <div className="flex w-full justify-center">
            <CancelButton order={props.getValue()} />
          </div>
        )
      },
    }),
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderPrice = (props: CellContext<OrderData, any>) => {
  const symbol = props.row.original.symbol
  const { decimals } = skoi.getSymbolConfig(symbol)
  return (
    <TableValueCell value={props.getValue()} dollar decimals={decimals} minDecimals={decimals} />
  )
}

const CancelButton = ({ order }: { order: OrderSnapshot }) => {
  const { data: api } = useSilverKoiApi()
  const submitTx = useSubmitTx()

  const onClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      return await sk.cancelOrder({
        api,
        marketId: order.marketMeta.marketId,
        traderId: order.traderId,
        orderId: order.orderId,
      })
    }
    submitTx.mutate({ description: "cancel order", txHashFn })
  }

  const cs = submitTx.isPending ? "hover:cursor-wait grayscale" : "hover:cursor-pointer"

  return (
    <>
      <div className="flex w-full justify-center align-center">
        <img src={getIcon("cancel")} className={"w-4 h-4 " + cs} onClick={onClick} />
      </div>
    </>
  )
}

const formatDuration = (duration: moment.Duration): string => {
  const ms = duration.asMilliseconds()
  const negative = ms < 0
  duration = duration.abs()

  const parts: string[] = []

  const days = duration.days()
  if (days == 1) {
    parts.push("1 day")
  } else if (days > 0) {
    parts.push(`${days} days`)
  }

  const hours = duration.hours()
  if (hours == 1) {
    parts.push("1 hour")
  } else if (hours > 0) {
    parts.push(`${hours} hours`)
  }

  const minutes = duration.minutes()
  if (minutes == 1) {
    parts.push("1 minute")
  } else if (minutes > 0) {
    parts.push(`${minutes} minutes`)
  }

  const seconds = duration.seconds()
  if (seconds == 1) {
    parts.push("1 second")
  } else if (seconds > 0) {
    parts.push(`${seconds} seconds`)
  }

  const durationStr = parts.join(" ")
  if (negative) {
    return `${durationStr} ago`
  } else {
    return `in ${durationStr}`
  }
}

const DeadlineCell = ({ uniqueId, deadline }: { uniqueId: string; deadline: bigint }) => {
  const nowMs = useFastTimer()
  const { add, remove } = useTooltipActions()

  const id = `tooltip-order-deadline-${uniqueId}`
  useEffect(() => {
    if (deadline === 0n) return

    const durationMs = Number(deadline * 1000n - nowMs)
    const duration = moment.duration(durationMs, "milliseconds")
    const durationStr = formatDuration(duration)
    const message = <div>{durationStr}</div>

    add(id, { message, place: "bottom-start", width: "280px" })

    return () => {
      remove(id)
    }
  }, [id, deadline, nowMs, uniqueId, add, remove])

  const deadlineStr = formatTimestamp(deadline)
  return (
    <div className={`flex flex-row items-center justify-start align-middle gap-1 px-2 w-max `}>
      <div className={"w-max"} data-tooltip-id={id}>
        {deadlineStr}
      </div>
    </div>
  )
}
