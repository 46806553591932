import { useContext } from "react"

import { TradeContext, TradeContextData } from "../contexts/TradeContext"

export const useTradeContext = (): TradeContextData => {
  const context = useContext(TradeContext)
  if (context === undefined) {
    throw new Error('useTradeContext must be used within a "TradeContextProvider"')
  }
  return context
}

export const useTradeContextActions = () => {
  const { useTradeContextStore } = useTradeContext()
  return useTradeContextStore((s) => s.actions)
}
