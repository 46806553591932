import { tw2 } from "../utils"
import { History } from "./History"
import { OrdersTable } from "./OrdersTable"
import { PositionsTable } from "./PositionsTable"
import { TriggersTable } from "./TriggersTable"

export type PortfolioTabId = "Positions" | "Orders" | "Triggers" | "History"

interface PortfolioProps {
  tab: PortfolioTabId
}

export const Portfolio = ({ tab }: PortfolioProps) => {
  //const [displayHistory, setDisplayHistory] = useState(false)
  return (
    <div className="grow flex flex-col overflow-hidden bg-black rounded-lg">
      <div className="flex flex-row items-center">
        <div className={tw2("font-portfolio-table-title") + " px-5 py-3"}>{tab}</div>
      </div>

      {tab === "Positions" && <PositionsTable fullVersion={true} />}
      {tab === "Orders" && <OrdersTable fullVersion={true} />}
      {tab === "Triggers" && <TriggersTable fullVersion={true} />}
      {tab === "History" && <History />}
    </div>
  )
}
