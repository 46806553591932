import { useState } from "react"

import { ChildrenProps } from "~/types"
import { MobileContext } from "./MobileContext"

export const MobileContextProvider = ({ children }: ChildrenProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [walletMenuOpen, setWalletMenuOpen] = useState<boolean>(false)
  const value = {
    menuOpen,
    setMenuOpen,
    walletMenuOpen,
    setWalletMenuOpen,
  }
  return <MobileContext.Provider value={value}>{children}</MobileContext.Provider>
}
