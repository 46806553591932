import { useState } from "react"

import { useUserState } from "~/hooks"
import { tw2 } from "~/utils"
import { OrdersTable } from "./OrdersTable"
import { PositionsTable } from "./PositionsTable"
import { TriggersTable } from "./TriggersTable"

enum TabOption {
  Positions = 0,
  Orders = 1,
  Triggers = 2,
}

export const TableSection = () => {
  const [tabOption, setTabOption] = useState<TabOption>(TabOption.Positions)

  const makeBodyComponent = () => {
    switch (tabOption) {
      case TabOption.Positions:
        return <PositionsTable fullVersion={false} />
      case TabOption.Orders:
        return <OrdersTable fullVersion={false} />
      case TabOption.Triggers:
        return <TriggersTable fullVersion={false} />
      default:
        throw new Error(`unknown tab option: ${tabOption}`)
    }
  }

  const positionsLabel = usePositionsTableLabel()
  const ordersLabel = useOrdersTableLabel()
  const triggersLabel = useTriggersLabel()

  return (
    <div className="shrink-0 w-full h-[14rem] overflow-hidden bg-black">
      <div className="flex flex-col h-full">
        <div className="flex flex-row gap-1 items-center px-2 pt-2 pb-2">
          <div className="flex flex-row items-center gap-2">
            <TabButton
              label={positionsLabel}
              value={TabOption.Positions}
              tabOption={tabOption}
              setTabOption={setTabOption}
            />
            <TabButton
              label={ordersLabel}
              value={TabOption.Orders}
              tabOption={tabOption}
              setTabOption={setTabOption}
            />
            <TabButton
              label={triggersLabel}
              value={TabOption.Triggers}
              tabOption={tabOption}
              setTabOption={setTabOption}
            />
          </div>
        </div>

        {makeBodyComponent()}
      </div>
    </div>
  )
}

interface TabButtonProps {
  label: string
  value: TabOption
  tabOption: TabOption
  setTabOption: (_: TabOption) => void
}

const TabButton = ({ label, value, tabOption, setTabOption }: TabButtonProps) => {
  const buttonCs =
    "w-fit px-1 py-1 rounded border-px border-transparent " + "outline-none focus:outline-none"

  const textCs = `${tw2("font-table-tab")} text-neutral-03 whitespace-nowrap`
  const unselectedCs = `${textCs} ${buttonCs} bg-neutral-05 hover:border-pale-blue hover:font-extrabold`
  const selectedCs = `${textCs} ${buttonCs} bg-pale-blue hover:border-transparent`
  const selected = value === tabOption

  return (
    <button className={selected ? selectedCs : unselectedCs} onClick={() => setTabOption(value)}>
      <div className="w-[5.5rem]">{label}</div>
    </button>
  )
}

const usePositionsTableLabel = () => {
  const { data: userState } = useUserState()
  const positions = userState?.getNonEmptyPositionsThatAreNotZeroSizeWithNonZeroOpenInterest()
  const count = positions?.length ?? 0
  if (count === 0) {
    return "Positions"
  } else {
    return `Positions (${count})`
  }
}

const useOrdersTableLabel = () => {
  const { data: userState } = useUserState()
  const orders = userState?.getOpenOrders()
  const count = orders?.length ?? 0
  if (count === 0) {
    return "Orders"
  } else {
    return `Orders (${count})`
  }
}

const useTriggersLabel = () => {
  const { data: userState } = useUserState()
  const triggers = userState?.getTriggers()
  let count = 0
  if (triggers) {
    for (const trigger of triggers) {
      if (!trigger.fromAboveTriggerPrice.isZero()) {
        count++
      }
      if (!trigger.fromBelowTriggerPrice.isZero()) {
        count++
      }
    }
  }

  if (count === 0) {
    return "Triggers"
  } else {
    return `Triggers (${count})`
  }
}
