import { PropsWithChildren, ReactNode } from "react"
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip"

import { tw2 } from "~/utils"

interface TooltipProps {
  id: string
  message: ReactNode | string
  place?: PlacesType
  offset?: number
  className?: string
  isOpen?: boolean
  width?: string
}

// TODO: Why am I passing in via message instead of using child?
export const Tooltip = ({
  id,
  message,
  place = "right",
  offset = 10,
  className = "",
  isOpen,
  width,
}: PropsWithChildren<TooltipProps>) => {
  return (
    <div>
      <div className="tooltip-container">
        <ReactTooltip
          id={id}
          clickable
          isOpen={isOpen}
          delay-hide="150"
          place={place}
          offset={offset}
          opacity={1.0}
          className={`tooltip-inner-box flex z-10 h-fit ${className}`}
        >
          <div
            className={`flex flex-col bg-neutral-darker p-3 h-fit rounded-[4px] ${tw2("font-tooltip")} font-medium`}
            style={{ width }}
          >
            {message}
          </div>
        </ReactTooltip>
      </div>
    </div>
  )
}
