import { ReactNode } from "react"

import { MarketSnapshot, OrderSide } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { TooltipId } from "~/types"

export interface TooltipContext {
  market?: MarketSnapshot

  triggerSide?: OrderSide
  triggerFromAbove?: boolean
  triggerPrice?: BigDecimal
}

export function TooltipMessageContent({
  tooltipId,
  symbol,
  context,
}: {
  tooltipId: TooltipId
  symbol?: string
  context?: TooltipContext
}): ReactNode | undefined {
  const makeBold = (text: string) => {
    return <span className="text-white font-bold">{text}</span>
  }

  const makeBoldRed = (text: string) => {
    return <span className="text-red font-bold">{text}</span>
  }

  switch (tooltipId) {
    case TooltipId.MarketPrice:
      return (
        <div>
          The average of the best bid and ask for one {symbol ? symbol : "unit of the given market"}{" "}
          on this exchange.
        </div>
      )

    case TooltipId.TwapMarketPrice:
      return <div>30-minute time-weighted average market price.</div>

    case TooltipId.OraclePrice:
      return (
        <div>
          The price of 1 {symbol ? symbol : "unit of the given market"} according to our oracle.
          <br />
          <br />
          Conceptually, the oracle price is computed by downweighting collusive groups of wallet
          addresses and then computing the weighted median of recent N trades for some N.
          <br />
          <br />
          Please see our documentation for more details on exactly how the oracle price is computed.
        </div>
      )

    case TooltipId.DailyChange:
      return <div>The 24-hour change in market price.</div>

    case TooltipId.DailyVolume:
      return (
        <div>
          The{" "}
          {makeBold(
            `total notional value of the positions for ${
              symbol ? symbol : "the given symbol"
            } in USD`,
          )}{" "}
          that were opened and closed in the last 24 hours.
          <br />
          <br />
          This is {makeBold("NOT the same as the collateral deposited and withdrawn")} by traders.
          <br />
          <br />
          For example, if a trader opens a short position on MOON with $100 for collateral and 5x
          leverage, then the trader contributes $500 worth of volume.
          <br />
          <br />
          Similarly, suppose a trader closes an existing short position of 0.8 MOON for an average
          exit price of $2850. Then the trader contributes 0.8 * $2850 = $2280 to the trading
          volume.
        </div>
      )

    case TooltipId.DailyHigh:
      return (
        <div>
          The {makeBold("highest")} market price that was achieved within the last 24 hours.
        </div>
      )

    case TooltipId.DailyLow:
      return (
        <div>The {makeBold("lowest")} market price that was achieved within the last 24 hours.</div>
      )

    case TooltipId.FundingRate: {
      const extraText = (() => {
        const longHourlyRate = context?.market?.longHourlyRate
        if (longHourlyRate === undefined) return <></>

        const absHourlyRatePct =
          longHourlyRate.abs().mul(BigDecimal.fromString("100")).format({
            decimals: 6,
          }) + "%"

        let text: string
        if (longHourlyRate.lt(BigDecimal.zero())) {
          text = `Currently, long positions GET ${absHourlyRatePct} and short positions PAY ${absHourlyRatePct}`
        } else {
          text = `Currently, long positions PAY ${absHourlyRatePct} and short positions GET ${absHourlyRatePct}`
        }
        return (
          <>
            {text}
            <br />
            <br />
          </>
        )
      })()

      return (
        <div>
          The {makeBold("HOURLY")} funding rate.
          <br />
          <br />
          {extraText}
          Funding rate changes continuously and funding is paid continuously as well.
          <br />
          <br />
          This is different from how most other perpetuals exchanges work. In other exchange,
          funding is paid periodically in intervals of time called the "funding interval".
          <br />
          <br />
          Our protocol is specially designed so that funding is distributed to all traders
          continuously over time!
        </div>
      )
    }

    case TooltipId.AvgEntryPrice:
      return (
        <div>
          The average entry price for your current position.
          <br />
          <br />
          For example, suppose you opened a long position of
          {makeBold(` 2 PPG at $50000/PPG `)}
          and later you opened a long position of
          {makeBold(` 1 PPG at $53000/PPG`)}.
          <br />
          <br />
          Then your average entry price would be {makeBold(` $51000/PPG`)}.
        </div>
      )

    case TooltipId.OrderSide:
      return <div>The side of this order: either long or short</div>

    case TooltipId.OrderLimitPrice:
      return <div>The limit price of this order.</div>

    case TooltipId.PositionSize:
      return (
        <div>
          {symbol
            ? `The size of your ${symbol} position.`
            : `The size of your position for each symbol.`}
          <br />
          <br />A positive value means you are long. A negative value means you are short.
        </div>
      )

    case TooltipId.PositionValue:
      return (
        <div>
          The USD value of your position.
          <br />
          <br />
          This is equal to the TWAP market price times your position size.
          <br />
          <br />A positive value means you are long. A negative value means you are short.
        </div>
      )

    case TooltipId.VaultBalance:
      return (
        <div>
          This is the amount of your USD collateral present in the vault.
          <br />
          <br />
          This {makeBold("does NOT include any pnl or funding")}!
        </div>
      )

    case TooltipId.Collateral:
      return (
        <div>
          The amount of collateral currently deposited in the vault which counts towards your margin
          requirement for this position.
          <br />
          <br />
          This includes unsettled funding but does NOT include unrealized pnl.
        </div>
      )

    case TooltipId.AccountValue:
      return (
        <div>
          This is the total value of your account for {symbol ? symbol : "each symbol"}.
          <br />
          <br />
          This includes your vault collateral balance, realized pnl, unrealized pnl and funding.
        </div>
      )

    case TooltipId.OpenNotional:
      return (
        <div>
          This is the USD value of your position {makeBold("at the time you created your position")}
          .
          <br />
          <br />
          If you adjusted your position multiple times, then this value is equal to the{" "}
          {makeBold("sum of the open notionals corresponding to each action")}.
          <br />
          <br />
          For example, suppose you first long 1.5 MOON at $3000 (ignoring price impact). Then your
          open notional will be $4500.
          <br />
          <br />
          Then suppose price moves to $3200 and you short 0.5 MOON at this price (again, ignoring
          price impact). Then the open notional corresponding to this short is -$1600.
          <br />
          <br />
          Therefore, the open notional for your position overall will be 4500 - 1600 ={" "}
          {makeBold("$2900")}.
        </div>
      )

    case TooltipId.OpenInterest:
      return <div>The USD value of all your open orders.</div>

    case TooltipId.Leverage:
      return (
        <div>
          The effective leverage of your position.
          <br />
          <br />
          This is equal to the ratio of your current debt to your collateral
          {makeBold(` plus negative unrealized pnl`)}.
        </div>
      )

    case TooltipId.Action:
      return <div>This is to view detailed position.</div>

    case TooltipId.LiquidationPrice: {
      return (
        <div>
          The price at which {makeBold(` your position may begin to be liquidated`)}.
          <br />
          <br />
          This price is equal to the value at which your collateral and negative unrealized pnl
          falls below maintenance margin.
        </div>
      )
    }

    case TooltipId.UnrealizedPnl:
      return (
        <div>
          This is the unrealized pnl of your current position.
          <br />
          <br />
          {makeBold(`PnL`)} stand for {makeBold(`profit and loss`)}.
          <br />
          <br />
          This value represents how much additional money you would make (if positive) or lose (if
          negative) if you were to fully close your position right now at TWAP market price. This
          does not take into account price impact or fees.
          <br />
          <br />
          This value does NOT include already realized pnl.
        </div>
      )

    case TooltipId.RealizedPnl:
      return <div>The pnl that is realized as a result of this order.</div>

    case TooltipId.UnsettledFunding:
      return (
        <div>
          The amount of funding this position has accrued since the last time this position was
          updated.
          <br />
          <br />
          Whenever you change this position (either by increasing the size or closing some size),
          unsettled funding will be settled to your vault balance, and this value will reset to
          zero.
        </div>
      )

    case TooltipId.Funding:
      return (
        <div>
          This is the amount of funding you paid or received.
          <br />
          <br />
          If positive, you received this amount in funding. If negative, you payed this amount in
          funding.
          <br />
          <br />
          This value {makeBold(` is already included in the collateral column `)}
          and is shown here for your convenience.
        </div>
      )

    case TooltipId.MaxPositionSize:
      return (
        <div>
          This is the maximum amount of position size you can reduce by.
          <br />
          <br />
          If you reduce by this maximum amount, you will end up with zero position size.
          <br />
          <br />
          In other words, this is the absolute value of the position size you currently own.
        </div>
      )

    case TooltipId.MaxCollateral: {
      return (
        <div>
          The maximum amount of collateral you can withdraw without violating initial margin
          requirement.
          <br />
          <br />
          {makeBoldRed("WARNING:")} This value highly depends on current market conditions. If you
          hold a nonzero position, this value can fluctuate greatly and cause your transaction to
          fail.
        </div>
      )
    }

    case TooltipId.BadDebt: {
      return (
        <div>
          Amount of USD you owe the exchange after liquidation. A position can accumulate bad debt
          if it is liquidated at a price worse than bankruptcy price. This can happen in volatile
          market conditions.
          <br />
          <br />
          You must deposit USD to cover this bad debt in order to open any more orders on this
          position.
        </div>
      )
    }

    case TooltipId.TradeEntryPrice:
      return (
        <div>
          This is the expected price for this trade.
          <br />
          <br />
          This is equal the ratio of the open notional for this position to the position size.
          <br />
          <br />
          For example, suppose you want to open a long position of
          {makeBold(` 2 MOON`)} and at an expected notional of
          {makeBold(` $6000`)}.
          <br />
          <br />
          Then the expected entry price for the trade would be (6000 / 2) ={makeBold(` $3000/MOON`)}
          .
        </div>
      )

    case TooltipId.TradeCollateralChange:
      return (
        <div>
          This is the expected change in your vault collateral balance.
          <br />
          <br />A positive value means you are depositing USD into the vault. A negative value means
          you are withdrawing USD from the vault.
        </div>
      )

    case TooltipId.TradePositionSizeChange:
      return (
        <div>
          The expected change in your {symbol || ""} position size.
          <br />
          <br />
          A positive value means you are opening or increasing a long position, or reducing a short
          position.
          <br />
          <br />A negative value means you are opening or increasing a short position. or reducing a
          long position.
        </div>
      )

    case TooltipId.TradeOpenNotionalChange:
      return (
        <div>
          The expected change in the open notional of this {symbol || ""} position.
          <br />
          <br />
          Open notional refers to the USD value of your position
          {makeBold(" at the time you created your position")}.
          <br />
          <br />
          If you adjusted your position multiple times, then this value is equal to the sum of the
          open notionals corresponding to each action.
          <br />
          <br />
          For example, suppose you first long 1.5 MOON at $3000 (ignoring price impact). Then your
          open notional will be $4500.
          <br />
          <br />
          Then suppose price moves to $3200 and you short 0.5 MOON at this price (again, ignoring
          price impact). Then the open notional corresponding to this short is -$1600.
          <br />
          <br />
          Therefore, the open notional for your position overall will be 4500 - 1600 = $2900.
        </div>
      )

    case TooltipId.TradeOpenInterestChange:
      return (
        <div>
          This is the expected change in the open interest for this position.
          <br />
          <br />
          Open interest refers to the absolute USD value of all open orders for this position.
        </div>
      )

    case TooltipId.PriceImpact:
      return (
        <div>
          This is the expected price impact of this trade.
          <br />
          <br />
          Price impact refers to price movement caused by the execution of your trade.
          <br />
          <br />
          This value is computed as the percentage different between the market price and the
          expected market price after your trade is executed.
        </div>
      )

    case TooltipId.TransactionFee:
      return (
        <div>
          This is the transaction fee you are expected to pay for this trade. This does{" "}
          {makeBold("not")} include gas fees.
          <br />
          <br />
          Taker fee and maker fee are currently hard-coded at {makeBold("0.5%")}
          and {makeBold("0.2%")}, respectively.
          <br />
          <br />
          There will be rebates and discounts in future versions of Silver Koi, so keep an eye out
          for that!
        </div>
      )

    case TooltipId.SLTP:
      return (
        <div>
          Click the icon below to create SL (stop-loss) and TP (take-profit) triggers for a
          position.
        </div>
      )

    case TooltipId.TriggerOrder:
      return <div>The side and size of the order this trigger will generate when activated.</div>

    case TooltipId.TriggerPrice:
    case TooltipId.SLTriggerPrice:
    case TooltipId.TPTriggerPrice: {
      const triggerSide = context?.triggerSide
      const fromAbove = context?.triggerFromAbove
      const triggerPrice = context?.triggerPrice
      if (
        !!triggerPrice &&
        !triggerPrice.isZero() &&
        triggerSide !== undefined &&
        fromAbove !== undefined
      ) {
        const isBid = triggerSide === "bid"
        const oppSide = isBid ? "ask" : "bid"
        const direction = fromAbove ? "falls below" : "rises above"

        return (
          <div>
            This trigger will activate when the &nbsp;
            {makeBold(`best ${oppSide} ${direction} $${triggerPrice.format({ minDecimals: 0 })}`)}
          </div>
        )
      } else {
        return (
          <div>
            This is the price when this trigger will activate.
            <br />
            <br />
            The direction of the trigger is determined by the current best bid and ask. Whether the
            best bid or ask is used as the reference price depends on whether you create a long or
            short trigger.
            <br />
            <br />
            For example, if you are creating a long trigger and this trigger price is above the
            current best ask, then this trigger will be a "from-below" trigger and will be activated
            when the best ask rises above the trigger price.
          </div>
        )
      }
    }

    default:
      return undefined
  }
}
