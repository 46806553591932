import { ColumnDef } from "@tanstack/react-table"

export class ColumnDefError<T> extends Error {
  public column: ColumnDef<T>

  constructor(message: string, column: ColumnDef<T>) {
    super(message)
    this.name = "ColumnDefError"
    this.column = column
  }
}
