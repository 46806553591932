import { AssetDropdown } from "~/components/AssetDropdown"
import { ScrollableArea } from "~/components/ScrollableArea"
import { useMobileMainPageContext } from "~/hooks"
import { MobileMainButton } from "./MobileMainButton"
import { MobileProduct } from "./MobileProduct"
import { MobileTradingPanel } from "./MobileTradingPanel"

export const MobileTradeView = () => {
  const { symbolMeta } = useMobileMainPageContext()
  return (
    <>
      <AssetDropdown symbolMeta={symbolMeta} height={"3rem"} />
      <ScrollableArea className="px-3">
        <div className="shrink-0 h-2" />
        <MobileProduct />
        <div className="shrink-0 h-2" />
        <MobileTradingPanel />
      </ScrollableArea>

      <div className="grow" />
      <MobileMainButton />
    </>
  )
}
