import * as skoi from "../../api/silverkoi"
import { OrderData, useMobileMainPageContext, useOrderData } from "../../hooks"
import { formatValue, getIcon, tw } from "../../utils"
import { PositionCell } from "../PositionCell"

export const MobileOrderList = () => {
  const { data, isLoading } = useOrderData()

  const cards = (data ?? []).map((d) => <Card key={d.key} data={d} />)

  return isLoading ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-full h-fit gap-[0.5rem]">{cards}</div>
  )
}

const Card = ({ data }: { data: OrderData }) => {
  const { symbol, tif, remainingSize, side, price, position } = data
  const { decimals } = skoi.getSymbolConfig(symbol)

  const sideText = side === "bid" ? "Bid" : "Ask"
  const tifText = tif.toUpperCase()
  const orderDesc = price.isZero()
    ? `Market ${sideText}`
    : `${tifText} ${sideText} @ $${formatValue(price, { decimals, minDecimals: decimals })}`
  const remainingSizeText = formatValue(remainingSize)
  const { setViewData } = useMobileMainPageContext()

  const onClick = () => {
    setViewData({ type: "order-detail", symbolMeta: skoi.getSymbolMeta(symbol), order: data })
  }

  return (
    <div
      className={`flex items-center px-3 py-2 bg-neutral-07 text-neutral-01 ${tw("font-caption-1")} justify-between cursor-pointer`}
      onClick={onClick}
    >
      <PositionCell position={position} />

      <div className="grow-0 shrink-0 flex flex-col pl-3 gap-1 items-end">
        <div>{orderDesc}</div>
        <div>
          <span className="text-neutral-04">Remaining: </span>
          {remainingSizeText}
        </div>
      </div>
    </div>
  )
}

const Loading = () => (
  <div className="flex flex-col w-full h-[10rem] items-center justify-center py-3 gap-5">
    <img src={getIcon("loading-spin-blue")} className="w-12 h-12" />
  </div>
)
