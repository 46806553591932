import React from "react"

export interface ToggleClassNames {
  height?: string
  beforeBackground: string
  checkedBackground: string
  beforeBorder: string
  border: string
  toggleText: string
}

export interface ToggleProps {
  checked: boolean
  uncheckedText: string
  checkedText: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  classNames: ToggleClassNames
}

export const Toggle = ({
  checked,
  uncheckedText,
  checkedText,
  onChange,
  classNames,
}: ToggleProps) => {
  const toggleTextCs = classNames.toggleText + " justify-center text-center w-1/2 z-[1]"

  return (
    <label className={`relative ${classNames.height ?? "h-10"} shrink-0`}>
      <input
        type="checkbox"
        className={
          "peer w-0 h-0 opacity-0 " + "checked:bg-blue focus:shadow-lg focus:shadow-neutral "
        }
        onChange={onChange}
        checked={checked}
      />
      <span
        className={
          `absolute inset-0 bg-neutral-dark duration-300 border-none ${classNames.border} ` +
          "cursor-pointer " +
          "before:content-[''] before:absolute before:h-full before:w-1/2 before:bottom-0 before:left-0 " +
          `${
            checked ? classNames.checkedBackground : classNames.beforeBackground
          } before:duration-300 ${classNames.beforeBorder} ` +
          "before:peer-checked:translate-x-full"
        }
      >
        <div className="flex flex-row items-center justify-between w-full h-full align-middle">
          <div className={toggleTextCs}>{uncheckedText}</div>
          <div className={toggleTextCs}>{checkedText}</div>
        </div>
      </span>
    </label>
  )
}
