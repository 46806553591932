import React, { ReactNode, useEffect } from "react"
import { useDebounceValue } from "usehooks-ts"

import { DiscretizedOrderBookPriceLevel, OrderSide } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { useInputState, useOrderBook, useTradeContext, useTradeContextActions } from "~/hooks"
import { tw2 } from "~/utils"
import { NumberInput } from "./NumberInput"
import { OrderBookRow } from "./OrderBookRow"

const FULL_BAR_NOTIONAL = 500000

interface Props {
  symbol: string
}

export const OrderBook = ({ symbol }: Props) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const orderType = useTradeContextStore((s) => s.input.type)
  const { setLimitPrice } = useTradeContextActions()

  const { decimals } = skoi.getSymbolConfig(symbol)
  const priceStepInc = BigDecimal.fromRaw(1, decimals)
  const minPriceStep = BigDecimal.fromRaw(1, decimals)
  const maxPriceStep = BigDecimal.fromReal(1000, 1)
  const placeholder = minPriceStep.toString()

  const priceStep = useInputState<BigDecimal>({
    value: minPriceStep,
    text: minPriceStep.toString(),
  })

  // Reset price step to default when symbol changes.
  useEffect(() => {
    priceStep.update({ value: minPriceStep, text: minPriceStep.toString() })
  }, [symbol]) // eslint-disable-line react-hooks/exhaustive-deps

  const sanitizedPriceStep = priceStep.input.value.isZero() ? minPriceStep : priceStep.input.value
  const [deboucedPriceStep] = useDebounceValue(sanitizedPriceStep, 150, {
    equalityFn: (a, b) => {
      return a.eq(b)
    },
    trailing: true,
  })
  const orderBookInput = { symbol, priceStep: deboucedPriceStep }

  const { data: orderBook } = useOrderBook(orderBookInput)

  const spread: BigDecimal | undefined = (() => {
    if (!orderBook || orderBook.bids.length === 0 || orderBook.asks.length === 0) {
      return undefined
    } else {
      return orderBook.bestAsk.sub(orderBook.bestBid)
    }
  })()
  const spreadStr = spread ? spread.toString(decimals) : "-"

  const onPriceStepInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const value = BigDecimal.fromString(e.target.value)
      const text = value.decimals() <= BigInt(decimals) ? e.target.value : value.toString(decimals)
      priceStep.update({ value: value.round(decimals), text })
    } else {
      priceStep.update({ value: minPriceStep, text: "" })
    }
  }

  const onMinusButtonClick = () => {
    if (priceStep.input.value.le(minPriceStep)) return
    const newPriceStep = priceStep.input.value.sub(priceStepInc)
    priceStep.update({ value: newPriceStep, text: newPriceStep.toString(decimals) })
  }

  const onPlusButtonClick = () => {
    if (priceStep.input.value.ge(maxPriceStep)) return
    const newPriceStep = priceStep.input.value.add(priceStepInc)
    priceStep.update({ value: newPriceStep, text: newPriceStep.toString(decimals) })
  }

  //const [orderBookTab, setOrderBookTab] = useState<string>(
  //  OrderBookTab.OrderBook,
  //)
  //const onOrderBookTabChange = (input: string) => {
  //  setOrderBookTab(input)
  //}

  const makeOrderBookRows = (
    side: OrderSide,
    levels: DiscretizedOrderBookPriceLevel[],
  ): ReactNode[] => {
    const nodes: ReactNode[] = []
    let cumNotional = 0
    for (const i in levels) {
      const level = levels[i]
      const key = `${side}-${i}`
      cumNotional += level.size.real() * level.price.real()
      const barWidthPct = Math.min(100, (cumNotional * 100) / FULL_BAR_NOTIONAL)
      if (level.price.isZero()) {
        break
      }

      const onClick = () => {
        if (orderType === "market") return
        setLimitPrice({ value: level.price, text: level.price.toString(decimals) })
      }

      const sizeDecimals = Math.max(5 - decimals, 0)
      nodes.push(
        <OrderBookRow
          key={key}
          side={side}
          size={level.size.toString(sizeDecimals)}
          price={level.price.toString(decimals)}
          barWidthPct={barWidthPct}
          onClick={onClick}
        />,
      )
    }
    return nodes
  }

  return (
    <div className="grow-0 shrink-0 flex flex-col bg-black rounded-lg w-[292px]">
      {/* TODO: use button instead of label once trade history is supported
      <div className="flex flex-row gap-3">
        <button
          className={
            orderBookTab === OrderBookTab.OrderBook ? TAB_SELECTED_CS : TAB_CS
          }
          onClick={() => onOrderBookTabChange(OrderBookTab.OrderBook)}
        >
          <div className={TAB_CS_TEXT}>Order Book</div>
        </button>
        <button
          className={
            orderBookTab === OrderBookTab.TradeHistory
              ? TAB_SELECTED_CS
              : TAB_CS
          }
          onClick={() => onOrderBookTabChange(OrderBookTab.TradeHistory)}
        >
          <div className={TAB_CS_TEXT}>Trade History</div>
        </button>
      </div>
      */}

      <div className="flex flex-row justify-center pt-2 pb-1">
        <div className={tw2("font-orderbook-title")}>Order Book</div>
      </div>

      <div className="flex flex-row gap-2 px-5 items-center">
        <button
          className={
            "flex justify-center items-center bg-black border-[#6F767E] " +
            "rounded-md focus:outline-none hover:text-white " +
            "hover:border-[#6F767E] py-2 w-[30px] h-[30px]"
          }
          onClick={onMinusButtonClick}
        >
          <div className="text-[#6F767E] text-[1rem]">-</div>
        </button>

        <NumberInput
          className={
            "inline-block items-center justify-center text-center " +
            "rounded-md border border-[#6F767E] bg-black " +
            "py-[5px] box-border w-full " +
            `${tw2("font-input-value")} text-white h-[1.5rem] `
          }
          placeholder={placeholder}
          value={priceStep.input.text}
          onChange={onPriceStepInputChange}
        />

        <button
          className={
            "flex justify-center items-center bg-black border-[#6F767E] " +
            "rounded-md focus:outline-none hover:text-white " +
            "hover:border-[#6F767E] py-2 w-[30px] h-[30px]"
          }
          onClick={onPlusButtonClick}
        >
          <div className="text-[#6F767E] text-[1rem]">+</div>
        </button>
      </div>

      <div className="shrink-0 h-1" />

      <div className="flex flex-row justify-between pr-6 pb-1">
        <div className={tw2("font-orderbook-label") + " w-[5rem] text-right"}>Amount</div>
        <div className={tw2("font-orderbook-label")}>Price (USD)</div>
      </div>

      <div className="flex flex-col-reverse h-1/2 overflow-x-hidden overflow-y-scroll no-scrollbar">
        {makeOrderBookRows("ask", orderBook ? orderBook.asks : [])}
      </div>

      <div
        className={
          "shrink-0 flex flex-row justify-between items-center " +
          "px-6 border-[#6F767E] border-y border-[#6F767E] py-1"
        }
      >
        <div className={tw2("font-orderbook-label")}>Spread (USD)</div>
        <div className={`${tw2("font-orderbook-number")} text-white`}>{spreadStr}</div>
      </div>

      <div className="h-1/2 overflow-x-hidden overflow-y-scroll no-scrollbar">
        {makeOrderBookRows("bid", orderBook ? orderBook.bids : [])}
      </div>
    </div>
  )
}
