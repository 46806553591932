import { Chain } from "silverkoi"

import { env } from "~/env"

export function pricefeedHttpUrl(): string {
  return env.VITE_SKOI_PRICEFEED_HTTP_URL
}

export function pricefeedWsBaseUrl(): string {
  return env.VITE_SKOI_PRICEFEED_WS_URL
}

export function pricefeedWsUrl(
  chain: Chain,
  symbol: string,
  resolution: string,
  snapTimestamp: number,
  isOracle: boolean,
): string {
  const url = pricefeedWsBaseUrl()
  return (
    `${url}/ws/price` +
    `?chain=${chain}` +
    `&symbol=${symbol}` +
    `&resolution=${resolution}` +
    `&snap_timestamp=${snapTimestamp}` +
    `&is_oracle=${isOracle}`
  )
}
