import { MdClose } from "react-icons/md"
import { Link, useLocation } from "react-router-dom"
import { useAccount } from "wagmi"

import { useChainDetail, useHasNotifications, useMobileContext } from "../../hooks"
import { Logo } from "../Logo"
import { Icon, IconName } from "../icons"

const maxWidthCs = "max-w-[18rem]"
const navLinkFontCs = "text-[0.9375rem]"

export const MobileNavbarMenu = () => {
  const { menuOpen } = useMobileContext()
  return menuOpen ? (
    <div className="absolute flex w-full h-full bg-white/10 backdrop-blur-sm z-[21]">
      <MobileMenuBody />
    </div>
  ) : (
    <></>
  )
}

const MobileMenuBody = () => {
  const { setMenuOpen } = useMobileContext()
  const { address } = useAccount()
  const { chain } = useChainDetail()
  const hasNotifications = useHasNotifications({ chain, address })
  const notificationIconName = hasNotifications ? "bell-with-dot" : "bell"

  const onClick = () => {
    setMenuOpen(false)
  }

  return (
    <div className={`flex flex-col grow ${maxWidthCs} h-full px-3 pt-3 bg-neutral-07`}>
      <div className="flex h-[2rem] items-center justify-between">
        <CloseButton />

        <Logo sizeRem={2} hover={false} />
      </div>

      <div className="flex-col w-full shrink-0 justify-center items-center gap-6">
        <div className="pt-3 gap-2">
          <NavLink linkTo="/trade" text="Trade" iconName="chart" onClick={onClick} />
          <NavLink
            linkTo="/notification"
            text="Notifications"
            iconName={notificationIconName}
            onClick={onClick}
          />
          <NavLink linkTo="/leaderboard" text="Leaderboard" iconName="trophy" onClick={onClick} />
        </div>
      </div>
    </div>
  )
}

interface NavLinkProps {
  linkTo: string
  text: string
  iconName: IconName
  onClick: () => void
}

const NavLink = ({ linkTo, text, iconName, onClick }: NavLinkProps) => {
  const { pathname } = useLocation()
  const selected = pathname === linkTo || pathname.startsWith(linkTo + "/")

  const menuFontCs = `${navLinkFontCs} font-semibold`
  const selectedTabCs = "text-white"
  const unselectedTabCs = "text-neutral-04"
  const tabCs = selected ? selectedTabCs : unselectedTabCs
  const iconFill = selected ? "white" : "gray"

  const boxCs = selected ? "bg-neutral-06 rounded-lg shadow-xl" : ""
  const selectedBoxShadow =
    "0px -2px 1px 0px rgba(0, 0, 0, 0.40) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.11) inset"
  const boxShadow = selected ? selectedBoxShadow : ""

  return (
    <div className={`${tabCs} ${menuFontCs}`}>
      <Link to={linkTo} onClick={onClick}>
        <div
          className={`shrink-0 w-full flex flex-row items-center px-2 py-2 gap-[2px] ${boxCs}`}
          style={{ boxShadow }}
        >
          <div className="w-7 h-7 p-1">
            <Icon name={iconName} iconFill={iconFill} />
          </div>
          <div>{text}</div>
        </div>
      </Link>
    </div>
  )
}

const CloseButton = () => {
  const { setMenuOpen } = useMobileContext()
  return <MdClose size="1.5rem" color="white" onClick={() => setMenuOpen(false)} />
}
