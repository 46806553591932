import { PropsWithChildren, useRef, useState } from "react"
import { FaFaucetDrip } from "react-icons/fa6"
import { MdContentCopy } from "react-icons/md"
import { toast } from "react-toastify"
import { useCopyToClipboard, useOnClickOutside } from "usehooks-ts"
import { useDisconnect } from "wagmi"

import * as sk from "silverkoi"
import { UserBalances } from "silverkoi"

import logout from "~/assets/logout.svg"
import { UserStats, useNativeTokenMeta, useSilverKoiApi, useSubmitTx, useTraderId } from "~/hooks"
import { UserState } from "~/types"
import {
  USD_SYMBOL,
  formatAddress,
  formatUsdcValue,
  formatValue,
  getCollateralUsdLogo,
  getIcon,
  tw2,
} from "~/utils"
import { Button } from "./Button"
import { Icon } from "./icons"

interface Props {
  address: string
  userState: UserState
  userStats: UserStats
  widthCs: string
}

export const WalletButton = ({ address, userState, userStats, widthCs }: Props) => {
  const [dropped, setDropped] = useState(false)

  const { disconnect } = useDisconnect()

  const onDisconnectClick = () => {
    disconnect()
    setDropped(false)
  }

  const onClickOutside = () => {
    setDropped(false)
  }

  // eslint-disable-next-line no-null/no-null
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, onClickOutside)

  return (
    <div ref={ref} className="flex">
      <ConnectedWalletButton
        dropped={dropped}
        setDropped={setDropped}
        account={formatAddress(address, 11)}
        widthCs={widthCs}
      />
      {dropped && (
        <Dropdown
          address={address}
          userBalances={userState.userBalances}
          points={userStats.points}
          onDisconnectClick={onDisconnectClick}
          widthCs={widthCs}
        />
      )}
    </div>
  )
}

interface ConnectedWalletButtonProps {
  dropped: boolean
  setDropped: React.Dispatch<React.SetStateAction<boolean>>
  account: string
  widthCs: string
}

const ConnectedWalletButton = ({
  dropped,
  setDropped,
  account,
  widthCs,
}: ConnectedWalletButtonProps) => {
  const onClick = () => {
    setDropped(!dropped)
  }

  return (
    <div
      className={`shrink-0 flex ${widthCs} ${tw2("button-navbar")} p-px background-gradient-bright rounded-md`}
    >
      <Button
        className={
          `flex w-full h-full px-3 items-center justify-between gap-2 rounded-md ` +
          `bg-gradient-to-r from-[#2F1F42] to-[#0E1925] ` +
          `${tw2("font-navbar-button")} font-semibold text-white`
        }
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          <Icon name={"wallet"} iconFill={"gradient"} size={"1rem"} />
          <Text>{account}</Text>
        </div>
        <div className="shrink-0 w-4 h-4">
          {dropped ? (
            <img src={getIcon("arrow-up-white")} />
          ) : (
            <img src={getIcon("arrow-down-white")} />
          )}
        </div>
      </Button>
    </div>
  )
}

interface DropdownProps {
  address: string
  userBalances: UserBalances
  points: bigint
  onDisconnectClick: (e: React.SyntheticEvent) => void
  widthCs: string
}

const Dropdown = ({ address, userBalances, points, onDisconnectClick, widthCs }: DropdownProps) => {
  const nativeTokenMeta = useNativeTokenMeta()

  const { data: api } = useSilverKoiApi()
  const { data: traderId } = useTraderId()
  const traderIsRegistered = traderId !== undefined
  const hasFaucet = !!api?.faucet
  const showFaucetButton = hasFaucet && traderIsRegistered
  const buttonsGridCs = showFaucetButton ? "grid grid-cols-2" : ""

  const [, copy] = useCopyToClipboard()
  const onClickAddress = () => {
    try {
      copy(address)
      toast.info("Copied address to clipboard!")
    } catch (_: unknown) {
      toast.error("Failed to copy address to clipboard!")
    }
  }

  return (
    <div
      className={`absolute mt-12 flex ${widthCs} background-image-gradient-bright p-px rounded-md ${tw2("font-menu")} text-white`}
    >
      <div
        className={
          "flex flex-col w-full px-3 py-3 items-center justify-center rounded-md " +
          "bg-gradient-to-r from-[#2F1F42] to-[#0E1925] w-96"
        }
      >
        <div
          className="flex items-center gap-1 font-semibold hover:cursor-pointer"
          onClick={onClickAddress}
        >
          <Text>{formatAddress(address, 12)}</Text>
          <MdContentCopy size="0.75rem" color="white" />
        </div>

        <div className="shrink-0 h-3" />

        <div className="flex flex-col border border-[#5484FF] px-3 py-3 gap-2 rounded-md w-full">
          <div className="flex w-full h-4 items-center justify-between">
            <div className="flex flex-row gap-2 font-semibold items-center">
              <Text>Total Account Value:</Text>
            </div>
            <Text>{formatUsdcValue(userBalances.accountValue)}</Text>
          </div>

          <div className="flex w-full h-4 justify-between">
            <div className="flex flex-row gap-2 font-semibold items-center">
              <img src={getCollateralUsdLogo()} className="w-4 h-4" />
              <Text>{USD_SYMBOL}</Text>
            </div>
            <Text>{formatUsdcValue(userBalances.usdcBalance)}</Text>
          </div>

          <div className="flex w-full h-4 justify-between">
            <div className="flex flex-row gap-2 font-semibold items-center">
              <img src={nativeTokenMeta.image} className="w-4 h-4" />
              <Text>{nativeTokenMeta.name}</Text>
            </div>
            <Text>{userBalances.nativeBalance.toString(4)}</Text>
          </div>

          <div className="flex w-full h-4 justify-between">
            <div className="flex flex-row gap-2 font-semibold items-center">
              <img src={getIcon("coin")} className="w-4 h-4" />
              <Text>Points</Text>
            </div>
            <Text>{formatValue(points)}</Text>
          </div>
        </div>

        <div className="shrink-0 h-3" />

        <div className={`flex w-full ${buttonsGridCs} gap-2`}>
          {showFaucetButton && <FaucetButton />}
          <DisconnectButton onDisconnectClick={onDisconnectClick} />
        </div>
      </div>
    </div>
  )
}

interface TextProps {
  className?: string
}

const Text = ({ className, children }: PropsWithChildren<TextProps>) => {
  const cs = `${className ?? ""}`
  return <div className={cs}>{children}</div>
}

const FaucetButton = () => {
  const { data: api } = useSilverKoiApi()

  const submitTx = useSubmitTx()

  const onClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!api || !api.faucet) return
    const txHashFn = async () => {
      return await sk.replenishUsdcFromFaucet({ api })
    }
    submitTx.mutate({ description: "get testnet token", txHashFn })
  }

  const disabled = submitTx.isPending

  return (
    <button
      className={
        "flex w-full py-[0.375rem] gap-1 items-center justify-center font-bold " +
        " font-bold bg-[#151B2B] rounded-xl border border-solid border-white/50"
      }
      onClick={onClick}
      disabled={disabled}
    >
      <FaFaucetDrip size="1rem" color="white" />
      <div>Get {USD_SYMBOL}</div>
    </button>
  )
}

const DisconnectButton = ({ onDisconnectClick }: Pick<DropdownProps, "onDisconnectClick">) => {
  return (
    <button
      className={
        "flex w-full py-[0.375rem] items-center justify-center font-bold " +
        " bg-[#151B2B] rounded-xl border border-solid border-white/50"
      }
      onClick={onDisconnectClick}
    >
      <img src={logout} className="w-4 h-4" />
      <div className="text-red">Disconnect</div>
    </button>
  )
}
