import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { useDebounceValue } from "usehooks-ts"
import {
  Address,
  BaseError,
  ContractFunctionRevertedError,
  Hash,
  UserRejectedRequestError,
} from "viem"
import { useAccount, usePublicClient, useWalletClient } from "wagmi"
import { shallow } from "zustand/shallow"

import * as sk from "silverkoi"
import {
  Chain,
  DiscretizedOrderBook,
  MarketSnapshot,
  OrderSide,
  PositionSnapshot,
  SilverKoiApi,
  TimeInForce,
} from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { TxInfoMessage } from "~/components/TxInfoMessage"
import { useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { InputState, OperationInput, OrderType, PartialOperationContext, UserState } from "~/types"
import * as utils from "~/utils"
import { useChainDetail } from "./chain"
import { QueryFnArgs } from "./queries/types"

// TODO: rename this file to hooks/queries/*.ts

export function useSilverKoiApi() {
  const { chain } = useChainDetail()
  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()

  interface Args {
    chain: Chain
    publicClientUid?: string
    walletClientUid?: string
  }

  const query = useQuery({
    queryKey: [
      {
        tag: { type: "blockchain", key: "silverkoi-api" },
        args: {
          chain,
          publicClientUid: publicClient?.uid,
          walletClientUid: walletClient?.uid,
        },
      },
    ],
    queryFn: async ({ queryKey }: QueryFnArgs<Args>) => {
      try {
        // eslint-disable-next-line no-null/no-null
        if (!publicClient) return null

        const client = { public: publicClient, wallet: walletClient }
        const { chain } = queryKey[0].args
        const api = await sk.loadSilverKoiApi({ chain, client })
        return api
      } catch (err: unknown) {
        console.error(err)
        // eslint-disable-next-line no-null/no-null
        return null
      }
    },
  })
  return { ...query, data: query.data ?? undefined }
}

// TODO: Consider breaking up the query into market-specific queries.
export function useMarkets() {
  const { data: api } = useSilverKoiApi()
  const uid = api?.uid

  // NOTE: react-query does not support returning undefined, hence the null hack
  const query = useQuery({
    queryKey: [{ tag: { type: "blockchain", key: "market-snapshots" }, args: { uid } }],
    queryFn: async () => {
      try {
        // eslint-disable-next-line no-null/no-null
        if (!api) return null
        const blockNumber = await api.client.public.getBlockNumber()
        const markets = await skoi.queryAllMarketSnapshots({ api, blockNumber })
        return markets
      } catch (err: unknown) {
        console.error(err)
        // eslint-disable-next-line no-null/no-null
        return null
      }
    },
    enabled: !!uid,
    refetchInterval: 5000,
  })

  return { ...query, data: query.data ?? undefined }
}

export function useMarket(symbol: string): MarketSnapshot | undefined {
  const { data: markets } = useMarkets()
  return markets?.get(symbol)
}

export function useUserState() {
  const { address: account } = useAccount()
  const { data: api } = useSilverKoiApi()
  const uid = api?.uid

  interface Args {
    uid?: string
    account?: Address
  }

  const query = useQuery({
    queryKey: [
      { tag: { type: "blockchain", key: "user-state" }, args: { uid: api?.uid, account } },
    ],
    queryFn: async ({ queryKey }: QueryFnArgs<Args>) => {
      const { account } = queryKey[0].args
      // eslint-disable-next-line no-null/no-null
      if (!account || !api) return null
      return await skoi.queryUserState({ api, account })
    },
    enabled: !!uid,
    refetchInterval: 5000,
  })

  return { ...query, data: query.data ?? undefined }
}

export function useNeedNativeToken() {
  const { minNativeTokenForGas } = useChainDetail()
  const { data: userState } = useUserState()
  const nativeBalance = userState?.userBalances.nativeBalance
  return nativeBalance && nativeBalance.lt(minNativeTokenForGas)
}

export function useNeedUsd() {
  const { data: userState } = useUserState()
  const balance = userState?.userBalances.usdcBalance
  return balance && balance.lt(utils.MIN_USD_REQUIRED)
}

export function useTraderId() {
  const { isPending, data: userState } = useUserState()
  return { isPending, data: userState?.traderId }
}

export function useOrderBook({ symbol, priceStep }: { symbol: string; priceStep: BigDecimal }) {
  const { data: api } = useSilverKoiApi()
  const uid = api?.uid

  interface Args {
    uid?: string
    symbol: string
    priceStep: BigDecimal
  }

  // NOTE: react-query does not support returning undefined, hence the null hack
  const query = useQuery({
    queryKey: [{ tag: { type: "blockchain", key: "orderbook" }, args: { uid, symbol, priceStep } }],
    queryFn: async ({ queryKey }: QueryFnArgs<Args>) => {
      const { symbol, priceStep } = queryKey[0].args
      // eslint-disable-next-line no-null/no-null
      return (await queryOrderBook({ api, symbol, priceStep })) ?? null
    },
    enabled: !!uid && !priceStep.isZero(),
    refetchInterval: 1000,
  })

  return { ...query, data: query.data ?? undefined }
}

// Pre: `priceStep` is not zero
const queryOrderBook = async ({
  api,
  symbol,
  priceStep,
}: {
  api?: SilverKoiApi
  symbol: string
  priceStep: BigDecimal
}): Promise<DiscretizedOrderBook | undefined> => {
  if (!api) return undefined

  const NUM_PRICE_LEVELS = 100n
  const blockNumber = await api.client.public.getBlockNumber()
  const marketId = api.markets.getBySymbol(symbol).marketId
  return await sk.getDiscretizedOrderBook({
    api,
    marketId,
    depth: NUM_PRICE_LEVELS,
    priceStep,
    blockNumber,
  })
}

interface OrderSizeEstimateArgs {
  unitIsUsdc: boolean
  marketId?: bigint
  type: OrderType
  side: OrderSide
  notional?: BigDecimal
  limitPrice?: BigDecimal
  tif: TimeInForce
  postOnly: boolean
}

export function useOrderSizeEstimate(
  args: OrderSizeEstimateArgs,
  setSize: (_: InputState<BigDecimal | undefined>) => void,
) {
  const { data: api } = useSilverKoiApi()
  const uid = api?.uid

  type Args = {
    uid?: string
    args: OrderSizeEstimateArgs
    setSize: (_: InputState<BigDecimal | undefined>) => void
  }

  // NOTE: react-query does not support returning undefined, hence the null hack
  const query = useQuery({
    queryKey: [{ tag: { type: "blockchain", key: "size-estimate" }, args: { args, uid, setSize } }],
    queryFn: async ({ queryKey }: QueryFnArgs<Args>) => {
      const { args, setSize } = queryKey[0].args
      const { unitIsUsdc, marketId, notional } = args
      // eslint-disable-next-line no-null/no-null
      if (!api || !unitIsUsdc || !marketId) return null

      const blockNumber = await api.client.public.getBlockNumber()
      const sizeEstimate = await skoi.estimateOrderSize({
        ...args,
        api,
        marketId,
        notional,
        blockNumber,
      })
      setSize({ value: sizeEstimate, text: sizeEstimate?.toStringTrimmed() ?? "" })
      return sizeEstimate ?? null // eslint-disable-line no-null/no-null
    },
    enabled: !!uid && args.unitIsUsdc,
  })
  return { ...query, data: query.data ?? undefined }
}

export function useDefaultLeverage(position: PositionSnapshot): InputState<BigDecimal> {
  const market = useMarket(position.symbolMeta.symbol)

  const leverage = (() => {
    if (!market || !position.leverage) {
      return BigDecimal.one()
    }

    const { maxInitialLeverage } = market
    if (position.leverage.ge(maxInitialLeverage)) {
      return maxInitialLeverage
    } else if (position.leverage.le(BigDecimal.one())) {
      return BigDecimal.one()
    } else {
      return position.leverage.round(1)
    }
  })()
  return { value: leverage, text: leverage.toStringTrimmed(1) }
}

// TODO: How to typecheck that name matches T["name"]?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractViemError<T extends BaseError>(error: any, name: string): T | undefined {
  if (!error?.name) return undefined
  if (error.name === name) return error
  if (!error?.walk) return undefined
  const e = (error as BaseError).walk((e) => (e as BaseError).name === name) as T | null
  // eslint-disable-next-line no-null/no-null
  return e ?? undefined
}

interface SubmitTxArgs {
  description: string
  txHashFn: () => Promise<Hash>
}

export function useSubmitTx() {
  const queryClient = useQueryClient()
  const { data: api } = useSilverKoiApi()

  const waitForTx = useWaitForTx()

  const mutation = useMutation({
    mutationFn: async ({ description, txHashFn }: SubmitTxArgs) => {
      const txHash = await txHashFn()
      // NOTE: We intentionally fire and forget
      waitForTx.mutateAsync({ description, txHash })
      return { description, txHash }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (error?.name) {
        {
          const e = extractViemError<UserRejectedRequestError>(error, "UserRejectedRequestError")
          if (e) {
            toast.error("User rejected transaction")
            return
          }
        }
        {
          const e = extractViemError<ContractFunctionRevertedError>(
            error,
            "ContractFunctionRevertedError",
          )
          if (e && api) {
            const err = skoi.parseSmartContractError({ api, error: e })
            toast.error(err.message)
            return
          }
        }
      }

      console.log(error)
      toast.error("Failed to send transaction")
    },
    onSuccess: ({ txHash }) => {
      toast.info(<TxInfoMessage txHash={txHash} type="submit" />)
      queryClient.invalidateQueries({
        queryKey: [{ tag: { type: "blockchain" } }],
      })
    },
  })

  return mutation
}

export function useWaitForTx() {
  const queryClient = useQueryClient()
  const publicClient = usePublicClient()

  const mutation = useMutation({
    mutationFn: async ({ description, txHash }: { description: string; txHash: Hash }) => {
      if (!publicClient) {
        throw new Error(`no public client`)
      }
      const rc = await publicClient.waitForTransactionReceipt({ hash: txHash })
      if (rc.status !== "success") {
        throw new Error(`failed to get receipt for ${description} tx: ${txHash}`)
      }
      return { txHash, rc }
    },
    onError: (error, { txHash }) => {
      toast.error(<TxInfoMessage txHash={txHash} type="error" />)
      console.error(error)
    },
    onSuccess: ({ txHash }) => {
      toast.success(<TxInfoMessage txHash={txHash} type="success" />)
      queryClient.invalidateQueries({
        queryKey: [{ tag: { type: "blockchain" } }],
      })
    },
  })

  return mutation
}

export function useResetInputs() {
  const { useTradeContextStore } = useTradeContext()
  const { reset } = useTradeContextActions(useTradeContextStore)
  return reset
}

interface QuerySimulationResultArgs {
  userState?: UserState
  position?: PositionSnapshot
  input: OperationInput
}

export function useSimulationResult({ enabled }: { enabled?: boolean }) {
  const { isConnected } = useAccount()
  const { data: api } = useSilverKoiApi()
  const uid = api?.uid
  const { data: userState } = useUserState()

  const { position, useTradeContextStore } = useTradeContext()
  const input = useTradeContextStore((s) => s.input)
  const [debouncedInput] = useDebounceValue(input, 150, { equalityFn: shallow, trailing: true })

  // TODO: Avoid passing in the full userState?
  const args = { uid, userState, position, input: debouncedInput }

  // NOTE: react-query does not support returning undefined, hence the null hack
  const query = useQuery({
    queryKey: [{ tag: { type: "blockchain", key: "tx-simulation" }, args }],
    queryFn: async ({ queryKey }: QueryFnArgs<QuerySimulationResultArgs & { uid?: string }>) => {
      const { uid: _, ...args } = queryKey[0].args
      // eslint-disable-next-line no-null/no-null
      if (!enabled || !isConnected || !api || !userState) return null

      const simResult = await querySimulationResult({ ...args, api })
      // eslint-disable-next-line no-null/no-null
      return simResult ?? null
    },
    enabled: !!enabled && isConnected && !!uid && !!userState,
    refetchInterval: 5000,
  })
  return { ...query, data: query.data ?? undefined }
}

async function querySimulationResult({
  api,
  userState,
  position,
  input,
}: QuerySimulationResultArgs & { api: SilverKoiApi }) {
  if (!api || !userState) return undefined

  const blockNumber = await api.client.public.getBlockNumber()

  const symbol = input.symbol
  const market = await skoi.queryMarketSnapshot({ api, symbol, blockNumber })
  const marketId = market.marketId
  // TODO: don't hard code this limit of 10M
  const notional = BigDecimal.fromString("10000000")
  const orderBook = await sk.getOrderBook({ api, marketId, notional, blockNumber })

  const traderId = userState.traderId
  const traderConfig = traderId
    ? await sk.getTraderConfig({ api, marketId, traderId, blockNumber })
    : undefined

  const context: PartialOperationContext = {
    symbol,
    market,
    orderBook,
    traderConfig,
    position,
    currentTimestamp: BigInt(Date.now()) / 1000n,
  }

  const simResult = await skoi.simulateOperation({ api, input, context })
  return simResult
}
