import * as _ from "lodash"
import { ReactNode } from "react"

import { useTooltips } from "../hooks"
import { TooltipId } from "../types"
import { Tooltip } from "./Tooltip"
import { TooltipMessageContent } from "./TooltipMessageContent"

interface Props {
  legacy?: boolean // TODO: remove once tooltips are migrated to newer model
}

export const Tooltips = ({ legacy }: Props) => {
  const nodes: ReactNode[] = []

  if (legacy) {
    for (const [, value] of TOOLTIP_NODES) {
      nodes.push(value.node)
    }
  }

  const tooltips = useTooltips()
  for (const [id, props] of tooltips) {
    nodes.push(<Tooltip key={id} id={id} {...props} />)
  }

  return <>{nodes}</>
}

interface TooltipNode {
  id: string
  node: ReactNode
}

// TODO: Move all of these to tooltip store
const createAllTooltipNodes = (): Map<TooltipId, TooltipNode> => {
  const nodes = new Map<TooltipId, TooltipNode>()

  for (const _key in TooltipId) {
    const tooltipIdNum = Number(_key)
    if (isNaN(tooltipIdNum)) continue
    const tooltipId = tooltipIdNum as TooltipId
    const node = createTooltipNode(tooltipId)
    nodes.set(tooltipId, node)
  }

  return nodes
}

const createTooltipNode = (tooltipId: TooltipId): TooltipNode => {
  const id = _.uniqueId("tooltip-")
  const tooltipMessage = (
    <div className="text-left text-pale">
      <TooltipMessageContent tooltipId={tooltipId} />
    </div>
  )
  const tooltip = (
    <Tooltip key={id} id={id} message={tooltipMessage} place="bottom-start" width="250px" />
  )
  return { id, node: tooltip }
}

const TOOLTIP_NODES = createAllTooltipNodes()

// eslint-disable-next-line react-refresh/only-export-components
export const useTooltipNodes = () => {
  return TOOLTIP_NODES
}
