import { IconProps, IconSvg } from "./IconSvg"

export const MeteorStar = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 24 24">
      <path
        id="Vector"
        d="M2.36276 12.4809L9.25566 14.3981C9.33794 14.4218 9.41285 14.466 9.47341 14.5266C9.53396 14.5871 9.57817 14.6621 9.60191 14.7443L11.5191 21.6372C11.549 21.7417 11.612 21.8337 11.6988 21.8991C11.7856 21.9646 11.8913 22 12 22C12.1087 22 12.2144 21.9646 12.3012 21.8991C12.388 21.8337 12.451 21.7417 12.4809 21.6372L14.3981 14.7443C14.4218 14.6621 14.466 14.5871 14.5266 14.5266C14.5871 14.466 14.6621 14.4218 14.7443 14.3981L21.6372 12.4809C21.7417 12.451 21.8337 12.388 21.8991 12.3012C21.9646 12.2144 22 12.1087 22 12C22 11.8913 21.9646 11.7856 21.8991 11.6988C21.8337 11.612 21.7417 11.549 21.6372 11.5191L14.7443 9.60191C14.6621 9.57817 14.5871 9.53396 14.5266 9.47341C14.466 9.41285 14.4218 9.33794 14.3981 9.25566L12.4809 2.36276C12.451 2.25825 12.388 2.16632 12.3012 2.10086C12.2144 2.03541 12.1087 2 12 2C11.8913 2 11.7856 2.03541 11.6988 2.10086C11.612 2.16632 11.549 2.25825 11.5191 2.36276L9.60191 9.25566C9.57817 9.33794 9.53396 9.41285 9.47341 9.47341C9.41285 9.53396 9.33794 9.57817 9.25566 9.60191L2.36276 11.5191C2.25825 11.549 2.16632 11.612 2.10086 11.6988C2.03541 11.7856 2 11.8913 2 12C2 12.1087 2.03541 12.2144 2.10086 12.3012C2.16632 12.388 2.25825 12.451 2.36276 12.4809Z"
        fill={fill}
        stroke="#FCD34D"
        strokeWidth="0.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconSvg>
  )
}
