// Common compositions of tailwind utilities.

// TODO: Fix all definitions to use rem instead of px
// TODO: Use tailwindcss-capsize instead of manually adjusting leading property

type CustomTailwindUtilityV2 =
  | "font-gradient"
  | "font-navbar-tab"
  | "font-navbar-button"
  | "font-menu"
  | "font-banner"
  | "font-product-label"
  | "font-product-value"
  | "font-product-value-large"
  | "font-asset-name-selected"
  | "font-asset-symbol-selected"
  | "font-asset-name-dropdown"
  | "font-asset-symbol-dropdown"
  | "font-asset-price-dropdown"
  | "font-asset-change-dropdown"
  | "font-long-short-toggle"
  | "font-order-type-button"
  | "font-mobile-order-type-button"
  | "font-input-label"
  | "font-input-value"
  | "font-input-value-small"
  | "font-input-unit"
  | "font-input-unit-button"
  | "font-input-extra-info"
  | "font-leverage-button"
  | "font-percentage-button"
  | "font-trade-warning"
  | "font-submit-button"
  | "font-summary-title"
  | "font-summary-content"
  | "font-modal-title"
  | "font-modal-tab"
  | "font-card-title"
  | "font-card-value"
  | "font-mobile-card-title"
  | "font-mobile-card-value"
  | "font-table-tab"
  | "font-table-header"
  | "font-table-value"
  | "font-detail-title"
  | "font-detail-label"
  | "font-detail-value"
  | "font-portfolio-table-title"
  | "font-notification-list-title"
  | "font-notification"
  | "font-notification-timestamp"
  | "font-leaderboard-title"
  | "font-leaderboard-card"
  | "font-leaderboard-card-small"
  | "font-leaderboard-global-stats"
  | "font-leaderboard-button"
  | "font-leaderboard-table"
  | "font-orderbook-title"
  | "font-orderbook-label"
  | "font-orderbook-number"
  | "font-sidebar"
  | "font-tooltip"
  | "button-navbar"

const fontTw = (tw: string): string => {
  return `tracking-[-0.01em] leading-[1.25em] ${tw}`
}

export const tw2 = (name: CustomTailwindUtilityV2): string => {
  switch (name) {
    case "font-gradient": {
      return "background-image-gradient-bright bg-clip-text text-transparent"
    }
    case "font-navbar-tab": {
      return fontTw("text-[0.875rem]")
    }
    case "font-navbar-button": {
      return fontTw("text-[0.75rem]")
    }
    case "font-menu": {
      return fontTw("text-[0.75rem]")
    }
    case "font-banner": {
      return fontTw("text-[0.75rem]")
    }
    case "font-product-label": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-product-value": {
      return fontTw("text-[0.75rem] font-bold")
    }
    case "font-product-value-large": {
      return fontTw("text-[1rem] font-bold")
    }
    case "font-asset-name-selected": {
      return fontTw("text-[1rem] font-semibold")
    }
    case "font-asset-symbol-selected": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-asset-name-dropdown": {
      return fontTw("text-[0.875rem] font-semibold")
    }
    case "font-asset-symbol-dropdown": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-asset-price-dropdown": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-asset-change-dropdown": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-long-short-toggle": {
      return fontTw("text-[0.75rem] font-bold")
    }
    case "font-order-type-button": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-mobile-order-type-button": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-input-label": {
      return fontTw("text-[0.625rem] font-semibold text-white")
    }
    case "font-input-value": {
      return fontTw("text-[0.75rem] font-medium")
    }
    case "font-input-value-small": {
      return fontTw("text-[0.6255rem] font-medium")
    }
    case "font-input-unit": {
      return fontTw("text-[0.75rem] font-normal")
    }
    case "font-input-unit-button": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-input-extra-info": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-leverage-button": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-percentage-button": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-trade-warning": {
      return fontTw("text-[0.625rem]")
    }
    case "font-submit-button": {
      return fontTw("text-[0.75rem] font-bold")
    }
    case "font-summary-title": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-summary-content": {
      return fontTw("text-[0.625rem]")
    }
    case "font-modal-title": {
      return fontTw("text-[1.125rem] font-bold text-white")
    }
    case "font-modal-tab": {
      return fontTw("text-[1rem] font-semibold")
    }
    case "font-card-title": {
      return fontTw("text-[0.625rem] font-semibold text-light-gray")
    }
    case "font-card-value": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-mobile-card-title": {
      return fontTw("text-[0.75rem] font-semibold text-light-gray")
    }
    case "font-mobile-card-value": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-table-tab": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-table-header": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-table-value": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "font-detail-title": {
      return fontTw("text-[1.25rem] font-semibold text-white")
    }
    case "font-detail-label": {
      return fontTw("text-[0.875rem] font-semibold text-neutral-shades-04-75")
    }
    case "font-detail-value": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-portfolio-table-title": {
      return fontTw("text-[1.25rem] font-semibold text-neutral-03")
    }
    case "font-notification-list-title": {
      return fontTw("text-[1.25rem] font-semibold text-neutral-03")
    }
    case "font-notification": {
      return fontTw("text-[0.875rem]")
    }
    case "font-notification-timestamp": {
      return fontTw("text-[0.625rem] text-neutral-shades-04-75")
    }
    case "font-leaderboard-title": {
      return fontTw("text-[1.25rem] font-semibold text-neutral-03")
    }
    case "font-leaderboard-card": {
      return fontTw("text-[0.9375rem] font-semibold")
    }
    case "font-leaderboard-card-small": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-leaderboard-global-stats": {
      return fontTw("text-[0.75rem] font-semibold")
    }
    case "font-leaderboard-button": {
      return fontTw("text-[0.75rem]")
    }
    case "font-leaderboard-table": {
      return fontTw("text-[0.875rem]")
    }
    case "font-orderbook-title": {
      return fontTw("text-[0.75rem] font-semibold text-white")
    }
    case "font-orderbook-label": {
      return fontTw("text-[0.625rem] font-semibold text-white")
    }
    case "font-orderbook-number": {
      return fontTw("font-number text-[0.625rem] font-semibold")
    }
    case "font-sidebar": {
      return fontTw("text-[1rem] font-semibold")
    }
    case "font-tooltip": {
      return fontTw("text-[0.625rem] font-semibold")
    }
    case "button-navbar": {
      return "h-[2rem]"
    }
  }
}

type CustomTailwindUtility =
  | "font-base-1"
  | "font-base-1-bold" // TODO: remove in favor of font-base-1
  | "font-base-2"
  | "font-body-1-semibold"
  | "font-button-1-mobile"
  | "font-button-1"
  | "font-button-2"
  | "font-caption-1"
  | "font-caption-1-medium"
  | "font-caption-2-medium"
  | "font-caption-2-bold"
  | "font-gradient"
  | "hover:font-gradient"
  | "font-tab"
  | "font-h-4"
  | "font-h-5"
  | "font-title-1"
  | "font-title-1-mobile"
  | "font-title-1-semibold"
  | "font-table-header-big"
  | "font-fallback-title"
  | "font-fallback-paragraph"

export const tw = (name: CustomTailwindUtility): string => {
  switch (name) {
    case "font-base-1": {
      return "font-sans text-[0.9375rem] leading-[1.5rem] tracking-[-0.01em]"
    }
    case "font-base-1-bold": {
      return "font-sans text-[0.9375rem] leading-[1.5rem] tracking-[-0.01em] font-bold"
    }
    case "font-base-2": {
      return "font-sans text-[0.875rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
    }
    case "font-body-1-semibold": {
      return "font-sans text-[0.9375rem] leading-[1.5rem] tracking-[-0.01em] font-semibold"
    }
    case "font-button-1-mobile": {
      return "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-bold"
    }
    case "font-button-1": {
      return "font-sans text-[0.9375rem] leading-[1.125em] tracking-[-0.01em] font-bold"
    }
    case "font-button-2": {
      return "font-sans text-[0.8125rem] leading-[1.125em] tracking-[-0.01em] font-bold"
    }
    case "font-caption-1": {
      return "font-sans text-[0.8125rem] leading-[1rem] tracking-[-0.01em] font-semibold"
    }
    case "font-caption-1-medium": {
      return "font-sans text-[0.8125rem] leading-[1.125em] capsize tracking-[-0.01em] font-medium"
    }
    case "font-caption-2-medium": {
      return "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-medium"
    }
    case "font-caption-2-bold": {
      return "font-sans text-[0.75rem] leading-[1rem] tracking-[-0.01em] font-bold"
    }
    case "font-gradient": {
      return "background-image-gradient-bright bg-clip-text text-transparent"
    }
    case "hover:font-gradient": {
      return "hover:background-image-gradient-bright hover:bg-clip-text hover:text-transparent"
    }
    case "font-h-4": {
      return "font-sans text-[2rem] leading-[2.5rem] tracking-[-0.01em] font-semibold"
    }
    case "font-h-5": {
      return "font-sans text-[1.5rem] leading-[2rem] tracking-[-0.01em] font-semibold"
    }
    case "font-title-1-mobile": {
      return "font-sans text-[1.125rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
    }
    case "font-title-1-semibold": {
      return "font-sans text-[1.25rem] leading-[2rem] tracking-[-0.01em] font-semibold"
    }
    case "font-title-1": {
      return "font-sans text-[1.25rem] leading-[2rem] tracking-[-0.01em]"
    }
    case "font-table-header-big": {
      return "font-sans text-[14px] leading-[1.125em] tracking-[-0.01em] font-semibold"
    }
    case "font-fallback-title": {
      return "font-sans font-bold text-[2.5rem]"
    }
    case "font-fallback-paragraph": {
      return "font-sans font-normal text-[1rem]"
    }
    case "font-tab": {
      return "font-sans text-[1.125rem] leading-[1.5rem] tracking-[-0.01em] font-semibold"
    }
  }
}
