import { useEffect } from "react"

import { PositionSnapshot, TriggerSnapshot } from "silverkoi"

import { useDefaultLeverage, useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { TRADING_MODAL_WIDTH } from "~/utils"
import { ActionModal } from "./ActionModal"
import { CurrentSizeAndCollateralCards } from "./CurrentSizeAndCollateralCards"
import { ScrollableArea } from "./ScrollableArea"
import { SubmitButton } from "./SubmitButton"
import { ToggledPriceInput } from "./ToggledPriceInput"
import { TradeWarning } from "./TradeWarning"
import { TransactionSummary } from "./TransactionSummary"
import { TriggerPriceInput } from "./TriggerPriceInput"

interface Props {
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  position: PositionSnapshot
  trigger: TriggerSnapshot
  fromAbove: boolean
}

export const EditTriggerModal = ({ isOpen, setIsOpen, position, trigger, fromAbove }: Props) => {
  return (
    <ActionModal position={position} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Body
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={trigger}
        fromAbove={fromAbove}
        position={position}
      />
    </ActionModal>
  )
}

const Body = ({ isOpen, setIsOpen, trigger, fromAbove, position }: Props) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const { limitPrice } = useTradeContextStore((s) => ({
    limitPrice: s.input.limitPrice,
  }))
  const {
    reset,
    setInputMode,
    setLeverage,
    setOrderType,
    setSide,
    setTriggerIsFromAbove,
    setTriggerPrice,
    setLimitPrice,
    setReferenceTrigger,
  } = useTradeContextActions(useTradeContextStore)

  const referenceLimitPrice = fromAbove ? trigger.fromAboveLimitPrice : trigger.fromBelowLimitPrice
  const referenceIsMarket = referenceLimitPrice.isZero()
  const defaultLeverage = useDefaultLeverage(position)

  useEffect(() => {
    reset()
    setInputMode("EditTrigger")
    setOrderType("stop-limit")
    setSide(trigger.side)
    setTriggerIsFromAbove(fromAbove)
    setLeverage(defaultLeverage)
    const triggerPrice = fromAbove ? trigger.fromAboveTriggerPrice : trigger.fromBelowTriggerPrice
    setTriggerPrice({ value: triggerPrice, text: triggerPrice.toStringTrimmed() })
    const limitPrice = referenceIsMarket ? undefined : referenceLimitPrice
    setLimitPrice({ value: limitPrice, text: limitPrice?.toStringTrimmed() ?? "" })
    setReferenceTrigger(trigger)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // NOTE: pr needs to be adjusted if scrollbar width changes
  return (
    <div
      className={"flex flex-col h-full bg-black rounded-lg px-6 py-4"}
      style={{ width: TRADING_MODAL_WIDTH }}
    >
      <div className="flex flex-row justify-center pb-5">
        <div className="text-[20px] text-white font-bold self-center py-1">Edit Trigger</div>

        <button
          className={
            "absolute right-4 " + "bg-black justify-center items-center text-white border-none"
          }
          onClick={() => setIsOpen(false)}
        >
          &#x2715;
        </button>
      </div>

      <div className="shrink-0 h-2" />

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <TriggerPriceInput withDirectionText={false} />

        <div className="shrink-0 h-2" />

        <ToggledPriceInput
          label={"Limit Price"}
          input={limitPrice}
          setInput={setLimitPrice}
          defaultEnabled={!referenceIsMarket}
        />

        <div className="shrink-0 h-2" />

        <TradeWarning />

        <SubmitButton
          simEnabled={isOpen}
          onSuccess={() => {
            setIsOpen(false)
          }}
          txDescription={"replace trigger"}
          idleText={"Submit"}
          pendingText={"Submiting..."}
        />

        <div className="shrink-0 h-2" />

        <TransactionSummary triggerContext={{ side: trigger.side, size: trigger.size }} />
      </ScrollableArea>
    </div>
  )
}
