import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { useMemo, useState } from "react"

import { PositionSnapshot } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { PositionData, usePositionData } from "~/hooks"
import { TooltipId, UserState } from "~/types"
import { getIcon } from "~/utils"
import { CreateTriggerModal } from "./CreateTriggerModal"
import { DepositCollateralModal } from "./DepositCollateralModal"
import { DetailedPositionModal } from "./DetailedPositionModal"
import { EditPositionModal } from "./EditPositionModal"
import { InfoTable } from "./InfoTable"
import { PositionCell } from "./PositionCell"
import { ReducePositionModal } from "./ReducePositionModal"
import { TableColumnLabel } from "./TableColumnLabel"
import { getMakeColumnDef } from "./TableHelpers"
import { TableValueCell } from "./TableValueCell"
import { WithdrawCollateralModal } from "./WithdrawCollateralModal"

export interface PositionsTableProps {
  fullVersion: boolean
}

export const PositionsTable = ({ fullVersion }: PositionsTableProps) => {
  const { data, isLoading } = usePositionData()

  // TODO: Revisit use of useMemo
  const columns = useMemo(() => {
    return makeColumns(fullVersion)
  }, [fullVersion])

  const columnPinning = {
    left: ["position"],
    right: ["close", "edit", "sltp", "details"],
  }

  const exclude = fullVersion
    ? []
    : [
        "positionId",
        "collateral",
        "openInterestNotional",
        "marketPrice",
        "twapMarketPrice",
        "unsettledFunding",
        "details",
      ]

  return (
    <InfoTable
      fullVersion={fullVersion}
      loading={isLoading}
      data={data ?? []}
      columns={columns}
      columnPinning={columnPinning}
      exclude={exclude}
    />
  )
}

const columnHelper = createColumnHelper<PositionData>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeColumns = (fullVersion: boolean): ColumnDef<PositionData, any>[] => {
  const tooltipPlace = fullVersion ? "bottom" : "top"
  const makeColumnDef = getMakeColumnDef({
    table: "positions",
    fullVersion,
    columnHelper,
  })

  return [
    makeColumnDef({
      accessor: "position",
      header: () => <>Position</>,
      cell: (props) => <PositionCell position={props.getValue()} fullVersion={fullVersion} />,
    }),

    makeColumnDef({
      accessor: "positionId",
      header: () => <>Position ID</>,
      cell: (props) => <>{props.getValue().toString()}</>,
    }),

    makeColumnDef({
      accessor: "size",
      header: () => (
        <TableColumnLabel
          labelText="Size"
          tooltipId={TooltipId.PositionSize}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: (props) => <TableValueCell value={props.getValue()} signed color noZero decimals={5} />,
    }),

    makeColumnDef({
      accessor: "notional",
      header: () => (
        <TableColumnLabel
          labelText="Value"
          tooltipId={TooltipId.PositionValue}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderNotional,
    }),

    makeColumnDef({
      accessor: "openInterestNotional",
      header: () => (
        <TableColumnLabel
          labelText="Open Value"
          tooltipId={TooltipId.OpenInterest}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderNotional,
    }),

    makeColumnDef({
      accessor: "marketPrice",
      header: () => (
        <TableColumnLabel
          labelText="Market Price"
          tooltipId={TooltipId.MarketPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: "twapMarketPrice",
      header: () => (
        <TableColumnLabel
          labelText="TWAP Market Price"
          tooltipId={TooltipId.TwapMarketPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: "entryPrice",
      header: () => (
        <TableColumnLabel
          labelText="Entry Price"
          tooltipId={TooltipId.AvgEntryPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: "liquidationPrice",
      header: () => (
        <TableColumnLabel
          labelText="Liquidation Price"
          tooltipId={TooltipId.LiquidationPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: "collateral",
      header: () => (
        <TableColumnLabel
          labelText="Collateral"
          tooltipId={TooltipId.Collateral}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderNotional,
    }),

    makeColumnDef({
      accessor: "unrealizedPnl",
      header: () => (
        <TableColumnLabel
          labelText="Unrealized PnL"
          tooltipId={TooltipId.UnrealizedPnl}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderNotional,
    }),

    makeColumnDef({
      accessor: "unsettledFunding",
      header: () => (
        <TableColumnLabel
          labelText="Unsettled Funding"
          tooltipId={TooltipId.UnsettledFunding}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderNotionalFullPrecision,
    }),

    makeColumnDef({
      accessor: "freeCollateral",
      header: () => (
        <TableColumnLabel
          labelText="Withdrawable Collateral"
          tooltipId={TooltipId.MaxCollateral}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: (props) => (
        <TableValueCell
          value={props.getValue()}
          dollar
          colorCsIfNotZero={"text-green"}
          decimals={6}
          minDecimals={2}
        />
      ),
    }),

    makeColumnDef({
      accessor: "badDebt",
      header: () => (
        <TableColumnLabel
          labelText="Bad Debt"
          tooltipId={TooltipId.BadDebt}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: (props) => (
        <TableValueCell
          value={props.getValue()}
          dollar
          colorCsIfNotZero={"text-red"}
          decimals={6}
          minDecimals={2}
        />
      ),
    }),

    makeColumnDef({
      accessor: "position",
      id: "close",
      header: () => <>Close</>,
      cell: (props) => {
        return (
          <div className="flex w-full justify-center">
            <CloseButton position={props.getValue()} />
          </div>
        )
      },
    }),

    makeColumnDef({
      accessor: "position",
      id: "edit",
      header: () => <>Edit</>,
      cell: (props) => {
        return (
          <div className="flex w-full justify-center">
            <EditButton position={props.getValue()} />
          </div>
        )
      },
    }),

    makeColumnDef({
      accessor: "position",
      id: "sltp",
      header: () => <>SL/TP</>,
      cell: (props) => {
        return (
          <div className="flex w-full justify-center">
            <CreateTriggerButton position={props.getValue()} />
          </div>
        )
      },
    }),

    makeColumnDef({
      accessor: (row) => ({ position: row.position, userState: row.userState }),
      id: "details",
      header: () => <>Details</>,
      cell: (props) => {
        return <DetailsButton {...props.getValue()} />
      },
    }),
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderNotionalFullPrecision = (props: CellContext<PositionData, any>) => {
  return (
    <TableValueCell value={props.getValue()} dollar signed color decimals={6} minDecimals={2} />
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderNotional = (props: CellContext<PositionData, any>) => {
  return (
    <TableValueCell value={props.getValue()} dollar signed color decimals={2} minDecimals={2} />
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderPrice = (props: CellContext<PositionData, any>) => {
  const symbol = props.row.original.symbol
  const { decimals } = skoi.getSymbolConfig(symbol)
  return (
    <TableValueCell value={props.getValue()} dollar decimals={decimals} minDecimals={decimals} />
  )
}

const CloseButton = ({ position }: { position: PositionSnapshot }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const noPositionSize = position.size.isZero()

  const onClick = noPositionSize
    ? undefined
    : () => {
        setOpenModal(true)
      }

  const iconCs = noPositionSize ? "w-4 h-4 grayscale" : "w-4 h-4 hover:cursor-pointer"

  // TODO: Reuse same edit position modal component instead of creating one
  // per row.
  return (
    <>
      <div className="flex w-4 justify-center align-center">
        <img src={getIcon("cancel")} className={iconCs} onClick={onClick} />
      </div>

      {!noPositionSize && (
        <ReducePositionModal isOpen={openModal} setIsOpen={setOpenModal} position={position} />
      )}
    </>
  )
}

const CreateTriggerButton = ({ position }: { position: PositionSnapshot }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const noPositionSize = position.size.isZero()

  const onClick = noPositionSize
    ? undefined
    : () => {
        setOpenModal(true)
      }

  const iconCs = noPositionSize ? "w-4 h-4 grayscale" : "w-4 h-4 hover:cursor-pointer"

  // TODO: Reuse same edit position modal component instead of creating one
  // per row.
  return (
    <>
      <div className="flex w-4 justify-center align-center">
        <img src={getIcon("gauge-blue")} className={iconCs} onClick={onClick} />
      </div>

      {!noPositionSize && (
        <CreateTriggerModal isOpen={openModal} setIsOpen={setOpenModal} position={position} />
      )}
    </>
  )
}

const EditButton = ({ position }: { position: PositionSnapshot }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  // TODO: Reuse same edit position modal component instead of creating one
  // per row.
  return (
    <>
      <div className="shrink-0 flex w-4 justify-center align-center">
        <img
          src={getIcon("edit")}
          className="hover:cursor-pointer w-4 h-4"
          onClick={() => {
            setOpenModal(true)
          }}
        />
      </div>

      {position.size.isZero() ? (
        position.collateral.gt(BigDecimal.zero()) ? (
          <WithdrawCollateralModal
            isOpen={openModal}
            setIsOpen={setOpenModal}
            position={position}
          />
        ) : (
          <DepositCollateralModal isOpen={openModal} setIsOpen={setOpenModal} position={position} />
        )
      ) : (
        <EditPositionModal isOpen={openModal} setIsOpen={setOpenModal} position={position} />
      )}
    </>
  )
}

const DetailsButton = ({
  userState,
  position,
}: {
  userState: UserState
  position: PositionSnapshot
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const onClick = () => {
    setOpenModal(true)
  }

  return (
    <div className="shrink-0 flex w-max">
      <div
        className="shrink-0 flex flex-row w-fit py-[0.25rem] px-3 gap-1 justify-center items-center bg-[#144992] rounded-md cursor-pointer"
        onClick={onClick}
      >
        <img src={getIcon("eye")} className="w-4 h-4" />
        <div className={"shrink-0 text-neutral-01"}>Details</div>
      </div>

      <DetailedPositionModal
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
        userState={userState}
        position={position}
      />
    </div>
  )
}
