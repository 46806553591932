import { useMutationObserver } from "@react-hooks-library/core"
import React, { useCallback, useState } from "react"

import { useWindowResizeObserver } from "./windowResizeObserver"

interface ScrollView {
  atTop: boolean
  atBottom: boolean
  atLeft: boolean
  atRight: boolean
  onScroll: React.UIEventHandler<HTMLDivElement>
}

export const useScrollView = (ref: React.RefObject<HTMLDivElement | null>): ScrollView => {
  const [atTop, setAtTop] = useState<boolean>(true)
  const [atBottom, setAtBottom] = useState<boolean>(true)
  const [atLeft, setAtLeft] = useState<boolean>(true)
  const [atRight, setAtRight] = useState<boolean>(true)

  // TODO: There is a slight but noticeable delay between the ref updating and
  // its scroll height updating. The mutation observer doesn't react instantly.
  // A more correct solution probably involves rendering the ref invisibly first
  // to determine the scroll height before it actually gets visibly rendered.

  const updateScrollView = (e: HTMLElement) => {
    const atTop = e.scrollTop === 0
    const atBottom = e.scrollHeight - e.scrollTop - e.clientHeight <= 2
    setAtTop(atTop)
    setAtBottom(atBottom)

    const atLeft = e.scrollLeft === 0
    const atRight = e.scrollWidth - e.scrollLeft - e.clientWidth <= 2
    setAtLeft(atLeft)
    setAtRight(atRight)
  }

  const onScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    updateScrollView(event.target as HTMLElement)
  }, [])

  const update = useCallback(() => {
    if (!ref.current) return
    updateScrollView(ref.current)
  }, [ref])

  useMutationObserver(ref, update, {
    subtree: true,
    childList: true,
    attributes: true,
  })

  useWindowResizeObserver(update)

  return { atTop, atBottom, atLeft, atRight, onScroll }
}
