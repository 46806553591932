import { useContext } from "react"

import { MobileContext, MobileContextData } from "../components/mobile/MobileContext"
import {
  MobileMainPageContext,
  MobileMainPageContextData,
} from "../components/mobile/MobileMainPageContext"

export const useMobileContext = (): MobileContextData => {
  const context = useContext(MobileContext)
  if (context === undefined) {
    throw new Error('useMobileContext must be used within a "MobileContextProvider"')
  }
  return context
}

export const useMobileMainPageContext = (): MobileMainPageContextData => {
  const context = useContext(MobileMainPageContext)
  if (context === undefined) {
    throw new Error(
      'useMobileMainPageContext must be used within a "MobileMainPageContextProvider"',
    )
  }
  return context
}
