import { Hash } from "viem"
import { useChainId } from "wagmi"

import {
  ChainDetail,
  NativeTokenMeta,
  getChainDetail,
  getDefaultChainDetail,
  getNativeTokenMeta,
} from "../utils"

export function useChainDetail(): ChainDetail {
  const chainId = useChainId()
  const detail = getChainDetail(BigInt(chainId))
  const result = detail ?? getDefaultChainDetail()
  return result
}

export function useDefaultChainDetail(): bigint {
  const { chainId } = getDefaultChainDetail()
  return chainId
}

export function useBlockExplorerUrl(txHash: Hash): string | undefined {
  const detail = useChainDetail()
  const url = detail.blockExplorerUrls.at(0)
  if (!url) return undefined
  return `${url}/tx/${txHash}`
}

export function useNativeTokenMeta(): NativeTokenMeta {
  const { chain } = useChainDetail()
  return getNativeTokenMeta(chain)
}
