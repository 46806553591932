import { ReactNode } from "react"
import { MdArrowBack, MdOutlineMenu } from "react-icons/md"
import { useAccount } from "wagmi"

import { useMobileContext } from "../../hooks"
import { Logo } from "../Logo"
import { Icon } from "../icons"
import { MobileNavbarMenu } from "./MobileNavbarMenu"
import { MobileWalletMenu } from "./MobileWalletMenu"

type MobileNavbarProps = {
  title?: ReactNode
  onBackClick?: () => void
}

export const MobileNavbar = ({ title, onBackClick }: MobileNavbarProps) => {
  const { isConnected } = useAccount()

  return (
    <>
      <div className="sticky flex-col top-0 w-full py-3 items-center bg-neutral-07 text-white z-[20]">
        <div className="flex grid grid-cols-3 w-full h-[2rem] items-center px-3">
          <MenuButton onBackClick={onBackClick} />

          <div className="flex items-center justify-center">
            {title ?? <Logo sizeRem={2} hover={false} />}
          </div>

          <div className="flex h-full gap-1 items-center">
            <div className="grow" />
            {isConnected && <WalletButton />}
          </div>
        </div>
      </div>

      <MobileNavbarMenu />
      <MobileWalletMenu />
    </>
  )
}

type MenuButtonProps = Pick<MobileNavbarProps, "onBackClick">

const MenuButton = ({ onBackClick }: MenuButtonProps) => {
  const { setMenuOpen } = useMobileContext()
  return onBackClick ? (
    <MdArrowBack size="1.5rem" color="white" onClick={onBackClick} />
  ) : (
    <MdOutlineMenu
      className="HamburgerMenu"
      size="1.5rem"
      color="white"
      onClick={() => setMenuOpen(true)}
    />
  )
}

const WalletButton = () => {
  const { setWalletMenuOpen } = useMobileContext()
  return (
    <div className="w-[1.25rem] h-[1.25rem]" onClick={() => setWalletMenuOpen(true)}>
      <Icon name="wallet" iconFill="gradient" />
    </div>
  )
}
