import * as skoi from "../../api/silverkoi"
import { TriggerData, useMobileMainPageContext, useTriggerData } from "../../hooks"
import { formatValue, getIcon, tw } from "../../utils"
import { PositionCell } from "../PositionCell"

export const MobileTriggerList = () => {
  const { data, isLoading } = useTriggerData()

  const cards = (data ?? []).map((d) => <Card key={d.key} data={d} />)

  return isLoading ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-full h-fit gap-[0.5rem]">{cards}</div>
  )
}

const Card = ({ data }: { data: TriggerData }) => {
  const { setViewData } = useMobileMainPageContext()
  const { symbol, trigger, type: triggerType, triggerPrice, side, limitPrice, position } = data
  const { tif, size } = trigger
  const { decimals } = skoi.getSymbolConfig(symbol)
  const sideText = side === "bid" ? "Bid" : "Ask"
  const triggerTypeText = (() => {
    if (triggerType === "Stop Loss") return "SL"
    if (triggerType === "Take Profit") return "TP"
    return `Stop`
  })()

  const triggerPriceText = formatValue(triggerPrice, { decimals, minDecimals: decimals })

  const sizeText = formatValue(size)
  const tifText = tif.toUpperCase()
  const desc = limitPrice.isZero()
    ? `Market ${sideText} for ${sizeText}`
    : `${tifText} ${sideText} @ $${formatValue(limitPrice, { decimals, minDecimals: decimals })}`

  const onClick = () => {
    setViewData({ type: "trigger-detail", symbolMeta: skoi.getSymbolMeta(symbol), trigger: data })
  }

  return (
    <div
      className={`flex items-center px-3 py-2 bg-neutral-07 text-neutral-01 ${tw("font-caption-1")} justify-between`}
      onClick={onClick}
    >
      <PositionCell position={position} />

      <div className="grow-0 shrink-0 flex flex-col pl-3 gap-1 items-end">
        <div>
          {triggerTypeText} {sizeText} @ ${triggerPriceText}
        </div>

        <div className="text-neutral-04">{desc}</div>
      </div>
    </div>
  )
}

const Loading = () => (
  <div className="flex flex-col w-full h-[10rem] items-center justify-center py-3 gap-5">
    <img src={getIcon("loading-spin-blue")} className="w-12 h-12" />
  </div>
)
