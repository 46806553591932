import { SyntheticEvent } from "react"

import { Icon, IconName } from "../icons"

const buttonFontCs =
  "font-sans text-[0.8125rem] leading-[1rem] tracking-[-0.01em] text-white font-semibold"

type Props = {
  text: string
  icon: IconName
  colorCn: string
  onClick: (e: SyntheticEvent) => void
}

export const MobileSmallButtonWithIcon = ({ text, icon, colorCn, onClick }: Props) => {
  return (
    <button
      className={`flex h-[1.5rem] px-3 gap-1 items-center ${colorCn} rounded-[0.75rem] ${buttonFontCs}`}
      onClick={onClick}
    >
      <div className="flex justify-center items-center">{text}</div>
      <div className="shrink-0 flex h-3">
        <Icon name={icon} iconFill="white" />
      </div>
    </button>
  )
}
