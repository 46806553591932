import { useParams } from "react-router"

import { Chain, SymbolMeta } from "silverkoi"

import * as skoi from "~/api/silverkoi"
import { AssetDropdown } from "~/components/AssetDropdown"
import { OrderBook } from "~/components/OrderBook"
import { Product } from "~/components/Product"
import { TVChartContainer } from "~/components/TVChartContainer"
import { TableSection } from "~/components/TableSection"
import { TradingPageTitle } from "~/components/TradingPageTitle"
import { TradingPanel } from "~/components/TradingPanel"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useChainDetail, useScreenLayout } from "~/hooks"
import { TRADING_PANEL_WIDTH } from "~/utils"

export const TradingPage = () => {
  const { symbol: maybeSymbol } = useParams()
  const symbol: string = maybeSymbol!.toUpperCase()!
  const symbolMeta = skoi.getSymbolMeta(symbol)
  const { chain } = useChainDetail()
  const layout = useScreenLayout()
  const topHeight = "3.5rem"
  return (
    <>
      <TradingPageTitle symbol={symbol} />

      <TradeContextProvider symbol={symbol}>
        <div className={`flex h-full w-full pb-5 px-4 gap-5 overflow-hidden`}>
          {layout === "extra-wide" && (
            <LayoutExtraWide chain={chain} symbolMeta={symbolMeta} topHeight={topHeight} />
          )}
          {layout === "wide" && (
            <LayoutWide chain={chain} symbolMeta={symbolMeta} topHeight={topHeight} />
          )}
          {layout === "normal" && (
            <LayoutNormal chain={chain} symbolMeta={symbolMeta} topHeight={topHeight} />
          )}
        </div>
      </TradeContextProvider>
    </>
  )
}

interface LayoutProps {
  chain: Chain
  symbolMeta: SymbolMeta
  topHeight: string
}

const LayoutExtraWide = ({ chain, symbolMeta, topHeight }: LayoutProps) => {
  const { symbol } = symbolMeta
  return (
    <>
      <TradeColumn symbolMeta={symbolMeta} topHeight={topHeight} />

      <div className="grow shrink overflow-hidden">
        <div className="flex flex-col h-full gap-5">
          <div className="shrink-0 grow-0 overflow-auto w-full h-fit">
            <Product symbolMeta={symbolMeta} height={topHeight} />
          </div>

          <div className="grow shrink flex w-full">
            <TVChartContainer chain={chain} symbol={symbol} />
          </div>

          <TableSection />
        </div>
      </div>

      <OrderBook symbol={symbol} />
    </>
  )
}

const LayoutWide = ({ chain, symbolMeta, topHeight }: LayoutProps) => {
  const { symbol } = symbolMeta
  return (
    <>
      <TradeColumn symbolMeta={symbolMeta} topHeight={topHeight} />

      <div className="grow shrink overflow-hidden">
        <div className="flex flex-col h-full gap-5">
          <div className="shrink-0 grow-0 overflow-auto w-full h-fit">
            <Product symbolMeta={symbolMeta} height={topHeight} />
          </div>

          <div className="grow shrink flex flex w-full h-full gap-5 overflow-hidden">
            <div className="grow shrink flex flex-col h-full gap-5 overflow-hidden">
              <div className="grow shrink flex w-full">
                <TVChartContainer chain={chain} symbol={symbol} />
              </div>
              <TableSection />
            </div>
            <OrderBook symbol={symbol} />
          </div>
        </div>
      </div>
    </>
  )
}

const LayoutNormal = ({ chain, symbolMeta, topHeight }: LayoutProps) => {
  const { symbol } = symbolMeta
  return (
    <>
      <TradeColumn symbolMeta={symbolMeta} topHeight={topHeight} />

      <div className="grow shrink overflow-hidden">
        <div className="flex flex-col h-full gap-5">
          <div className="shrink-0 grow-0 overflow-auto w-full h-fit">
            <Product symbolMeta={symbolMeta} height={topHeight} />
          </div>

          <div className="grow shrink flex flex w-full h-full gap-5 overflow-hidden">
            <div className="grow shrink flex flex-col h-full gap-5 overflow-hidden">
              <div className="grow shrink flex w-full">
                <TVChartContainer chain={chain} symbol={symbol} />
              </div>
            </div>
            <OrderBook symbol={symbol} />
          </div>

          <TableSection />
        </div>
      </div>
    </>
  )
}

const TradeColumn = ({ symbolMeta, topHeight }: Omit<LayoutProps, "chain">) => {
  return (
    <div className="grow-0 shrink-0 bg-black" style={{ width: TRADING_PANEL_WIDTH }}>
      <div className="flex flex-col w-full h-full">
        <AssetDropdown symbolMeta={symbolMeta} height={topHeight} />

        <div className="shrink-0 h-1" />

        <TradingPanel />
      </div>
    </div>
  )
}
