import { SyntheticEvent } from "react"

import * as sk from "silverkoi"

import { tifToLongString } from "../../../../ts/silverkoi/src/util"
import * as skoi from "../../api/silverkoi"
import { useMobileMainPageContext, useSilverKoiApi, useSubmitTx } from "../../hooks"
import { formatTimestamp } from "../../utils"
import { MobileDetailView } from "./MobileDetailView"
import { OrderDetailViewData } from "./MobileMainPageContext"
import { MobileSmallButtonWithIcon } from "./MobileSmallButtonWithIcon"

type Props = {
  data: OrderDetailViewData
}

export const MobileOrderDetailView = ({ data }: Props) => {
  const { symbol, price, size, tif, deadline, remainingSize } = data.order
  const symbolMeta = skoi.getSymbolMeta(symbol)
  const { data: api } = useSilverKoiApi()
  const submitTx = useSubmitTx()
  const { setViewData } = useMobileMainPageContext()

  const direction = (() => {
    if (size.raw() > 0n) {
      return "Long"
    } else if (size.raw() < 0n) {
      return "Short"
    } else {
      return ""
    }
  })()

  const onClickCancel = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      const res = await sk.cancelOrder({
        api,
        marketId: data.order.order.marketMeta.marketId,
        traderId: data.order.order.traderId,
        orderId: data.order.orderId,
      })
      setViewData({ type: "overview", symbolMeta, initialTab: "orderbook" })
      return res
    }
    submitTx.mutate({ description: "cancel order", txHashFn })
  }

  const buttons = (
    <MobileSmallButtonWithIcon
      text="Close"
      icon="cancel"
      colorCn="bg-red"
      onClick={onClickCancel}
    />
  )

  const detailData = [
    { label: "Limit Price", value: price.toString(4) },
    { label: "Side", value: direction },
    { label: "Remaining Size", value: remainingSize.toString(4) + " / " + size.toString(4) },
    { label: "Time In Force", value: tifToLongString(tif) },
    { label: "Deadline", value: deadline === 0n ? "N/A" : formatTimestamp(deadline) },
    { label: "", value: "" },
  ]

  return <MobileDetailView symbolMeta={symbolMeta} buttons={buttons} data={detailData} />
}
