import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { useMemo, useRef, useState } from "react"
import { useDocumentTitle, useWindowSize } from "usehooks-ts"
import { useAccount } from "wagmi"

import { BigDecimal } from "silverkoi/math"

import { LeaderboardEntry, useAllTimeStats, useChainDetail, useUserRankedStats } from "../../hooks"
import {
  IconType,
  format3SigFigDollars,
  formatAddress,
  formatValue,
  getCampaignId,
  getIcon,
  tw,
} from "../../utils"
import { InfoTable } from "../InfoTable"
import { MessageBanner } from "../MessageBanner"
import { ProfileCircle } from "../ProfileCircle"
import { Icon } from "../icons"
import { MobileNavbar } from "./MobileNavbar"

export const MobileLeaderboardPage = () => {
  useDocumentTitle(`Silver Koi - Leaderboard`)

  return (
    <>
      <MobileNavbar title={<Title />} />
      <MessageBanner />

      <div className="flex flex-col w-full grow gap-2 overflow-hidden">
        <div className="flex px-3">
          <CampaignTitle />
        </div>

        <div className="flex px-3">
          <UserRanking />
        </div>

        <div className="flex grow px-3 overflow-hidden">
          <GlobalRanking />
        </div>
      </div>
    </>
  )
}

type RankType = "volume" | "pnl" | "points"

const titleFontCs = "text-[0.8125rem] leading-[1rem] tracking-[-0.01em]"
const cardFont1Cs = "text-[0.8125rem] leading-[1rem] tracking-[-0.01em]"
const cardFont2Cs = "text-[0.75rem] leading-[1rem] tracking-[-0.01em]"
const buttonFontCs = "text-[0.625rem] leading-[1.125rem] tracking-[-0.01em]"
const tableFontCs = "text-[0.75rem] leading-[1rem] tracking-[-0.01em] font-medium"
const statsFontCs = "text-[0.75rem] leading-[0.75rem] tracking-[-0.01em] font-medium"

const Title = () => {
  return (
    <div className="flex items-center gap-1">
      <div className="w-4 h-4">
        <Icon name="trophy" iconFill="white" />
      </div>

      <div className={`${tw("font-title-1-mobile")} text-white`}>Leaderboard</div>
    </div>
  )
}

const CampaignTitle = () => {
  // TODO: Update once closer to campaign.
  //const { chain } = useChainDetail()
  //const campaignId = getCampaignId(chain)
  //const { data } = useCampaignStats({ chain, campaignId })
  //const title = (() => {
  //  if (!campaignId) return undefined
  //  const title = getCampaignTitle(campaignId)
  //  if (!data) return title
  //  const start = formatCampaignDate(data.startUtcDate)
  //  const end = formatCampaignDate(data.endUtcDate)
  //  return `${title}: ${start} - ${end}`
  //})()
  const title = "Plume Testnet Campaign 2: TBD"

  return (
    <div className="flex flex-col w-full px-4 py-3 gap-3 bg-neutral-06/70 rounded-lg">
      <div className={`w-fit py-[0.125rem] ${titleFontCs} text-white font-bold rounded`}>
        {title}
      </div>
    </div>
  )
}

const AllTimeStats = () => {
  const { chain } = useChainDetail()
  const { data } = useAllTimeStats({ chain })

  const totalUsers = formatValue(data?.totalUsers, {})
  const totalTrades = formatValue(data?.totalTrades, {})
  const totalVolume = data?.totalVolume ? format3SigFigDollars(data.totalVolume) : "-"

  const result = (
    <div className="flex grid grid-cols-3 w-full">
      <StatsCard label="Total Users" value={totalUsers} />
      <StatsCard label="Total Trades" value={totalTrades} />
      <StatsCard label="Total Volume" value={totalVolume} />
    </div>
  )
  return result
}

const StatsCard = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className={`flex flex-col ${statsFontCs} justify-center items-center gap-1`}>
      <div className="text-neutral-04">{label}</div>
      <div className="text-white">{value}</div>
    </div>
  )
}

const UserRanking = () => {
  const { chain } = useChainDetail()
  const { address } = useAccount()
  const campaignId = getCampaignId(chain)
  const { data: userStats } = useUserRankedStats({
    chain,
    address,
    campaignId,
  })

  const volume = userStats?.volume
  const volumeStr = formatValue(volume, { dollar: true, decimals: 2 })
  const volumeRankStr = userStats?.volumeRankStr ?? "-"

  const pnl = userStats?.realizedPnl
  const [pnlStr, pnlColor] = (() => {
    const text = formatValue(pnl, { dollar: true, decimals: 2, signed: true })
    const color = (() => {
      if (!pnl || pnl.isZero()) {
        return "text-white"
      } else if (pnl.lt(BigDecimal.zero())) {
        return "text-red"
      } else {
        return "text-green"
      }
    })()
    return [text, color]
  })()
  const pnlRankStr = userStats?.pnlRankStr ?? "-"

  const pointsStr = formatValue(userStats?.points)
  const pointsRankStr = userStats?.pointRankStr ?? "-"

  return (
    <div className="flex flex-col w-full px-4 py-3 gap-3 bg-neutral-06/70 rounded-lg">
      <div className={`w-fit py-[0.125rem] ${titleFontCs} text-white font-bold rounded`}>
        My Ranking
      </div>

      <RankCard
        icon="barchart"
        rank={volumeRankStr}
        rankLabel={"Volume Rank"}
        stat={volumeStr}
        statLabel={"Volume"}
      />

      <RankCard
        icon="cash"
        rank={pnlRankStr}
        rankLabel={"PnL Rank"}
        stat={pnlStr}
        statLabel={"PnL"}
        statColorCs={pnlColor}
      />

      <RankCard
        icon="coin"
        rank={pointsRankStr}
        rankLabel={"Points Rank"}
        stat={pointsStr}
        statLabel={"Points"}
      />
    </div>
  )
}

type RankCardProps = {
  icon: IconType
  rank: string
  rankLabel: string
  stat: string
  statLabel: string
  statColorCs?: string
}

const RankCard = ({
  icon,
  rank,
  rankLabel,
  stat,
  statLabel,
  statColorCs: _statColorCs,
}: RankCardProps) => {
  const statColorCs = _statColorCs ?? "text-white"
  return (
    <div className="flex items-center h-[4rem] px-5 gap-5 bg-neutral-07 rounded-[0.5rem] border-gradient-bright-[1px-0.5rem]">
      <div className="w-6 h-6">
        <img src={getIcon(icon)} />
      </div>

      <div className="flex flex-col h-full py-3 justify-between">
        <div className={`${cardFont1Cs} text-blue-03 font-semibold items-center`}>{rank}</div>

        <div className={`${cardFont2Cs} text-neutral-04 font-semibold items-center`}>
          {rankLabel}
        </div>
      </div>

      <div className="grow" />

      <div className="flex flex-col h-full py-3 justify-between items-end">
        <div className={`${cardFont1Cs} font-semibold items-center ${statColorCs}`}>{stat}</div>

        <div className={`${cardFont2Cs} text-neutral-04 font-semibold items-center`}>
          {statLabel}
        </div>
      </div>
    </div>
  )
}

const GlobalRanking = () => {
  // TODO: Update once closer to campaing.
  //const { chain } = useChainDetail()
  //const campaignId = getCampaignId(chain)
  //const { data, isLoading: loading } = useCampaignStats({ chain, campaignId })
  //const volumeRanking = data?.leaderboard.volumeRanking ?? []
  //const pnlRanking = data?.leaderboard.pnlRanking ?? []
  //const pointsRanking = data?.leaderboard.pointRanking ?? []
  const loading = false
  const volumeRanking: LeaderboardEntry[] = []
  const pnlRanking: LeaderboardEntry[] = []
  const pointRanking: LeaderboardEntry[] = []

  const volumeTableProps = {
    type: "volume" as const,
    loading,
    entries: volumeRanking,
  }
  const pnlTableProps = {
    type: "pnl" as const,
    loading,
    entries: pnlRanking,
  }
  const pointsTableProps = {
    type: "points" as const,
    loading,
    entries: pointRanking,
  }

  const [rankType, setRankType] = useState<RankType>("volume")

  const result = (
    <div className="flex flex-col w-full px-4 py-3 bg-neutral-06/70 rounded-lg overflow-hidden">
      <div className={`w-fit py-[0.125rem] ${titleFontCs} text-white font-bold rounded`}>
        Global Ranking
      </div>

      <div className="shrink-0 h-2" />

      <AllTimeStats />

      <div className="shrink-0 h-2" />

      <RankTypeButtons rankType={rankType} setRankType={setRankType} />

      <div className="shrink-0 h-3" />

      <div className="flex grow overflow-hidden">
        {rankType === "volume" && <RankTable {...volumeTableProps} />}
        {rankType === "pnl" && <RankTable {...pnlTableProps} />}
        {rankType === "points" && <RankTable {...pointsTableProps} />}
      </div>
    </div>
  )
  return result
}

type RankTypeButtonsProps = {
  rankType: RankType
  setRankType: (_: RankType) => void
}

const RankTypeButtons = ({ rankType, setRankType }: RankTypeButtonsProps) => {
  const makeButton = (rt: RankType) => {
    const selected = rankType === rt
    const name = getRankTypeName(rt)
    const icon = getRankTypeIcon(rt)
    const commonCs = `flex h-[1.5rem] ${buttonFontCs} items-center justify-center gap-2 rounded-[1.5rem]`
    const buttonCs = selected
      ? `${commonCs} text-white bg-blue`
      : `${commonCs} text-neutral-04 bg-none`
    const onClick = () => {
      setRankType(rt)
    }
    return (
      <button className={buttonCs} onClick={onClick}>
        <div className="w-4 h-4">
          <img src={getIcon(icon)} />
        </div>
        <div>{name}</div>
      </button>
    )
  }

  return (
    <div
      className={`flex w-full shrink-0 grid grid-cols-3 items-center justify-center h-[1.875rem] px-[0.25rem] gap-4 ${buttonFontCs} font-bold bg-neutral-08 rounded-[1.875rem]`}
    >
      {makeButton("volume")}
      {makeButton("pnl")}
      {makeButton("points")}
    </div>
  )
}

const getRankTypeName = (rankType: RankType) => {
  switch (rankType) {
    case "volume":
      return "Volume"
    case "pnl":
      return "PnL"
    case "points":
      return "Points"
  }
}

const getRankTypeIcon = (rankType: RankType): IconType => {
  switch (rankType) {
    case "volume":
      return "barchart"
    case "pnl":
      return "cash"
    case "points":
      return "coin"
  }
}

interface RankTableProps {
  type: RankType
  loading: boolean
  entries: LeaderboardEntry[]
}

const RankTable = ({ type, loading, entries }: RankTableProps) => {
  const ref = useRef<HTMLDivElement>(null) // eslint-disable-line no-null/no-null
  const { width: screenWidth } = useWindowSize()

  const data: DataType[] = entries.map((e, i) => ({
    key: `${i}`,
    type,
    rank: e.rank,
    address: e.address,
    value: e.value,
  }))

  const columns = useMemo(() => {
    return makeColumns({ type, screenWidth })
  }, [type, screenWidth])

  const table = (
    <div ref={ref} className={`shrink grow-0 flex flex-col w-full gap-[1.25rem] overflow-hidden`}>
      <InfoTable
        fullVersion={false} // TODO: remove, not used
        loading={loading}
        data={data}
        columns={columns}
      />
    </div>
  )
  return table
}

interface DataType {
  key: string
  type: RankType
  rank: number
  address: string
  value: BigDecimal
}

const columnHelper = createColumnHelper<DataType>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ColumnType = ColumnDef<DataType, any>

const makeColumns = ({
  type,
  screenWidth,
}: {
  type: RankType
  screenWidth: number
}): ColumnType[] => {
  return [
    columnHelper.accessor("rank", {
      id: "rank",
      size: 24,
      header: () => {
        return (
          <div className={`flex w-[3rem] ${tableFontCs} justify-center items-center px-3 h-[2rem]`}>
            Rank
          </div>
        )
      },
      cell: (props) => {
        const rank = props.getValue()
        const content = (() => {
          switch (rank) {
            case 1:
              return (
                <div className="w-[1.125rem] h-[1.125rem]">
                  <Icon name="solid-trophy" iconFill="gold" />
                </div>
              )
            case 2:
              return (
                <div className="w-[1.125rem] h-[1.125rem]">
                  <Icon name="solid-trophy" iconFill="silver" />
                </div>
              )
            case 3:
              return (
                <div className="w-[1.125rem] h-[1.125rem]">
                  <Icon name="solid-trophy" iconFill="bronze" />
                </div>
              )
            default:
              return `${rank}`
          }
        })()

        return (
          <div
            className={`flex w-[3rem] ${tableFontCs} justify-center items-center px-3 h-[2.5rem]`}
          >
            {content}
          </div>
        )
      },
    }),

    columnHelper.accessor("address", {
      id: "address",
      header: () => {
        return <div className={`flex ${tableFontCs} items-center h-[2rem]`}>Trader</div>
      },
      cell: (props) => {
        const address = props.getValue().toString()
        const count = getAddressHexCount(screenWidth)
        const addressStr = count === undefined ? address : formatAddress(address, count)
        return (
          <div className={`flex ${tableFontCs} items-center h-[2.5rem] gap-2`}>
            <ProfileCircle size="1.125rem" address={address} />
            <div className="items-center">{addressStr}</div>
          </div>
        )
      },
    }),

    columnHelper.accessor("value", {
      id: "value",
      header: () => {
        return (
          <div className={`flex px-3 ${tableFontCs} justify-end items-center h-[2rem]`}>
            {getRankTypeName(type)}
          </div>
        )
      },
      cell: (props) => {
        const { text, color } = getValueTextAndColor(type, props.getValue())
        return (
          <div className={`flex ${tableFontCs} ${color} justify-end items-center px-3 h-[2.5rem]`}>
            {text}
          </div>
        )
      },
    }),
  ]
}

const getAddressHexCount = (screenWidth: number) => {
  if (screenWidth >= 550) {
    return undefined
  } else if (screenWidth >= 530) {
    return 20
  } else if (screenWidth >= 505) {
    return 18
  } else if (screenWidth >= 480) {
    return 16
  } else if (screenWidth >= 465) {
    return 14
  } else if (screenWidth >= 450) {
    return 12
  } else if (screenWidth >= 430) {
    return 11
  } else if (screenWidth >= 410) {
    return 10
  } else if (screenWidth >= 370) {
    return 9
  } else if (screenWidth >= 350) {
    return 7
  } else if (screenWidth >= 330) {
    return 6
  } else {
    return 5
  }
}

const getValueTextAndColor = (type: RankType, value?: BigDecimal) => {
  if (!value) return { text: "-", color: "text-white" }

  switch (type) {
    case "volume": {
      const text = formatValue(value, { dollar: true, decimals: 2 })
      return { text, color: "text-blue-03" }
    }
    case "pnl": {
      const text = formatValue(value, { dollar: true, decimals: 2, signed: true })

      const color = (() => {
        if (value.isZero()) {
          return "text-white"
        } else if (value.lt(BigDecimal.zero())) {
          return "text-red"
        } else {
          return "text-green"
        }
      })()
      return { text, color }
    }
    case "points": {
      const text = value.format({ locale: "en-US" })
      return { text, color: "text-blue-03" }
    }
  }
}
