import { ReactNode, useEffect } from "react"

import { MarketSnapshot, SymbolMeta } from "silverkoi"

import * as skoi from "../api/silverkoi"
import { MarketStats, useChainDetail, useMarket, useMarketStats, useTooltipActions } from "../hooks"
import { TooltipId } from "../types"
import { COLORS, formatValue, tw2 } from "../utils"
import { LeftRightScrollableArea } from "./LeftRightScrollableArea"
import { TooltipMessageContent } from "./TooltipMessageContent"

interface ProductProps {
  symbolMeta: SymbolMeta
  height: string
}

export const Product = (props: ProductProps) => {
  // TODO: The z-index is only set to make the tooltip display over the tvchart.
  // Revist this.
  return (
    <div className="flex w-full bg-black z-[3]" style={{ height: props.height }}>
      <LeftRightScrollableArea>
        <ProductStats {...props} />
      </LeftRightScrollableArea>
    </div>
  )
}

interface FormattedValue {
  text: string
  color: string
}

interface Stat {
  tooltipId?: TooltipId
  label: string
  text: ReactNode | string
  color?: string
}

const DEFAULT_STAT = {
  text: "-",
}

interface FormattedStats {
  marketPrice: Stat
  twapMarketPrice: Stat
  oraclePrice: Stat
  dailyChange: Stat
  dailyVolume: Stat
  dailyHigh: Stat
  dailyLow: Stat
  fundingRate: Stat
}

function getPctFmtVal(value: number, signed: boolean = true, decimals: number = 2): FormattedValue {
  if (!value) {
    return { text: "0.00%", color: COLORS.WHITE }
  } else if (value < 0) {
    return {
      text: (signed ? "-" : "") + Math.abs(value).toFixed(decimals) + "%",
      color: COLORS.RED,
    }
  } else {
    return {
      text: (signed ? "+" : "") + Math.abs(value).toFixed(decimals) + "%",
      color: COLORS.GREEN,
    }
  }
}

function formatVolume(value: number): string {
  if (value < 1e3) {
    return value.toFixed(0)
  } else if (value < 1e6) {
    return (value / 1e3).toPrecision(4) + "K"
  } else if (value < 1e9) {
    return (value / 1e6).toPrecision(4) + "M"
  } else {
    return (value / 1e9).toPrecision(4) + "B"
  }
}

function getFormattedStats(
  symbol: string,
  marketStats?: MarketStats,
  market?: MarketSnapshot,
): FormattedStats {
  const stats: FormattedStats = {
    marketPrice: {
      tooltipId: TooltipId.MarketPrice,
      label: "Market Price",
      ...DEFAULT_STAT,
    },
    twapMarketPrice: {
      tooltipId: TooltipId.TwapMarketPrice,
      label: "Twap Market Price",
      ...DEFAULT_STAT,
    },
    oraclePrice: {
      tooltipId: TooltipId.OraclePrice,
      label: "Oracle Price",
      ...DEFAULT_STAT,
    },
    dailyChange: {
      label: "24H Change",
      ...DEFAULT_STAT,
    },
    dailyVolume: {
      label: "24H Volume",
      ...DEFAULT_STAT,
    },
    dailyHigh: {
      label: "24H High",
      ...DEFAULT_STAT,
    },
    dailyLow: {
      label: "Volume",
      ...DEFAULT_STAT,
    },
    fundingRate: {
      tooltipId: TooltipId.FundingRate,
      label: "Funding Rate",
      ...DEFAULT_STAT,
    },
  }

  if (marketStats) {
    stats.dailyChange = {
      label: "24H Change",
      ...getPctFmtVal(marketStats.daily_stats.change_pct, true),
    }
    stats.dailyHigh = {
      label: "24H High",
      text: formatValue(marketStats.daily_stats.high, {
        minDecimals: 2,
        decimals: 2,
        dollar: true,
      }),
    }
    stats.dailyLow = {
      label: "24H Low",
      text: formatValue(marketStats.daily_stats.low, {
        minDecimals: 2,
        decimals: 2,
        dollar: true,
      }),
    }
    stats.dailyVolume = {
      label: "Volume",
      text: `$${formatVolume(marketStats.total_stats.volume)}`,
    }
  }

  if (market) {
    const { decimals } = skoi.getSymbolConfig(symbol)

    stats.fundingRate = {
      tooltipId: TooltipId.FundingRate,
      label: "Funding Rate",
      ...getPctFmtVal(market.longHourlyRate.real() * 100, true, 6),
    }

    stats.marketPrice = {
      tooltipId: TooltipId.MarketPrice,
      label: "Market Price",
      text: formatValue(market.marketPrice, {
        minDecimals: decimals,
        decimals,
        dollar: true,
      }),
    }

    stats.twapMarketPrice = {
      tooltipId: TooltipId.TwapMarketPrice,
      label: "Twap Market Price",
      text: formatValue(market.twapMarketPrice, {
        minDecimals: decimals,
        decimals,
        dollar: true,
      }),
    }

    stats.oraclePrice = {
      tooltipId: TooltipId.OraclePrice,
      label: "Oracle Price",
      text: formatValue(market.oraclePrice, {
        minDecimals: decimals,
        decimals,
        dollar: true,
      }),
    }
  }

  return stats
}
const ProductStats = ({ symbolMeta }: ProductProps) => {
  const { symbol } = symbolMeta
  const { chain } = useChainDetail()
  const market = useMarket(symbol)
  const { data: marketStats } = useMarketStats(chain, symbol)

  const stats = getFormattedStats(symbol, marketStats, market)

  const verticalLine = <div className="shrink-0 w-px h-8 m-2 bg-dark-gray" />

  return (
    <div className={"flex flex-row items-center px-3 py-1 w-full justify-between"}>
      <StatComponent market={market} symbol={symbol} stat={stats.marketPrice} nolabel={true} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.twapMarketPrice} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.oraclePrice} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.dailyChange} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.dailyHigh} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.dailyLow} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.dailyVolume} />
      {verticalLine}
      <StatComponent market={market} symbol={symbol} stat={stats.fundingRate} />
    </div>
  )
}

interface StatComponentProps {
  market?: MarketSnapshot
  symbol: string
  stat: Stat
  nolabel?: boolean
}

const StatComponent = ({ market, symbol, stat, nolabel }: StatComponentProps) => {
  const { tooltipId } = stat

  const textDecorationCs = tooltipId ? "underline decoration-dotted underline-offset-2" : ""
  const labelFont = `${tw2("font-product-label")} font-semibold text-neutral-04 ${textDecorationCs}`
  const normalValueFont = `${tw2("font-product-value")} text-white`
  const largeValueFont = `${tw2("font-product-value-large")} text-white`
  const hoverCs = tooltipId ? "hover:cursor-help" : ""

  const { add, remove } = useTooltipActions()
  const id = `tooltip-product-${tooltipId}`
  useEffect(() => {
    if (!tooltipId) return

    const context = { market }
    const message = (
      <TooltipMessageContent symbol={symbol} tooltipId={tooltipId} context={context} />
    )
    add(id, { message, place: "bottom", width: "300px" })

    return () => {
      remove(id)
    }
  }, [add, remove, id, tooltipId, market, symbol])

  const label = <div className={labelFont + " whitespace-nowrap"}>{stat.label}</div>
  const valueFont = nolabel ? largeValueFont : normalValueFont

  return (
    <div
      data-tooltip-id={id}
      className={`shrink-0 flex flex-col items-start justify-start ${hoverCs}`}
    >
      {nolabel ? <></> : label}
      {!nolabel && <div className="h-1" />}

      <div
        className={valueFont + " items-center align-middle flex-none shrink-0 whitespace-nowrap"}
        style={{ color: stat.color || COLORS.WHITE }}
      >
        {stat.text}
      </div>
    </div>
  )
}
