import {
  Column,
  ColumnDef,
  ColumnPinningState,
  Row,
  Table,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React, { useRef, useState } from "react"
import { useHover } from "usehooks-ts"

import { ColumnDefError, getIcon } from "../utils"

interface Props<T> {
  fullVersion: boolean // TODO: remove, not used
  loading: boolean
  data: T[]
  columns: ColumnDef<T>[]
  columnPinning?: ColumnPinningState
  exclude?: string[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InfoTable<T extends Record<string, any>>(props: Props<T>) {
  const {
    loading,
    data,
    columns: _columns,
    exclude: _exclude,
    columnPinning: _defaultColumnPinning,
  } = props

  const exclude = _exclude ?? []
  const originalColumns = _columns.filter((c) => !exclude.includes(c?.id ?? ""))
  const defaultColumnPinning = _defaultColumnPinning ?? { left: [], right: [] }

  const cellRefs = useRef<Cells>(newCells())

  const trackCellRef = React.useCallback(
    (el: HTMLDivElement | null, row: string, col: string) => {
      if (!el) return
      if (!cellRefs.current) {
        cellRefs.current = newCells()
      }
      if (!cellRefs.current.get(col)) {
        cellRefs.current.set(col, new Map<string, HTMLElement>())
      }
      cellRefs.current.get(col)!.set(row, el)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalColumns.length],
  )

  const columns = (() => {
    return originalColumns.map((column) => {
      if (!column.id) throw new ColumnDefError("column def must specify id", column)
      return {
        size: getColumnSize(cellRefs.current, column.id),
        ...column,
      }
    })
  })()

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>(defaultColumnPinning)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: { columnPinning },
    onColumnPinningChange: setColumnPinning,
    initialState: {
      columnPinning,
    },
  })

  const heightCs = loading ? "" : "h-fit"

  return (
    <div className="flex flex-col grow overflow-auto scrollbar-thin rounded-xl">
      <table className={`table-auto w-full ${heightCs} border-collapse`}>
        <thead className={"text-neutral-shades-04-75 bg-neutral-06 border-b border-neutral-05"}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    ref={(el) => {
                      trackCellRef(el, "header", header.column.id)
                    }}
                    key={header.id}
                    className={"bg-neutral-06 whitespace-nowrap"}
                    style={{ ...getCommonPinningStyles(table, header.column, true) }}
                  >
                    {header.isPlaceholder ? (
                      <></>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}{" "}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <DataTr
              key={row.id}
              table={table}
              row={row}
              rowIndex={rowIndex}
              trackCellRef={trackCellRef}
            />
          ))}
        </tbody>
      </table>

      {loading && <Loading />}
    </div>
  )
}

function DataTr<T>(props: {
  table: Table<T>
  row: Row<T>
  rowIndex: number
  trackCellRef: (_el: HTMLDivElement | null, _row: string, _col: string) => void
}) {
  const { table, row, rowIndex, trackCellRef } = props

  const ref = useRef(null) // eslint-disable-line no-null/no-null
  const hover = useHover(ref)
  const bgCs = hover ? "bg-neutral-06" : "bg-neutral-07"

  return (
    <tr ref={ref} className="border-b border-neutral-05">
      {row.getVisibleCells().map((cell) => {
        const column = cell.column
        return (
          <td
            ref={(el) => {
              trackCellRef(el, rowIndex.toString(), column.id)
            }}
            key={column.id}
            className={`grow-0 text-neutral-01 ${bgCs}`}
            style={{ ...getCommonPinningStyles(table, column, false) }}
          >
            {flexRender(column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

// Map from column => row => element
type Cells = Map<string, Map<string, HTMLElement>>

function newCells(): Cells {
  return new Map<string, Map<string, HTMLElement>>()
}

const getColumnSize = (cells: Cells, columnId: string) => {
  const colCells = cells.get(columnId)
  if (!colCells) return undefined
  if (colCells.size === 0) return undefined // Should never happen

  const sizes: number[] = []
  for (const [_, el] of colCells) {
    const { width } = el.getBoundingClientRect()
    sizes.push(width)
  }
  return Math.max(...sizes)
}

const Loading = () => (
  <div className="flex flex-col h-full items-center justify-center py-3 gap-5">
    <img src={getIcon("loading-spin-blue")} className="w-12 h-12" />
  </div>
)

function getCommonPinningStyles<T>(
  _table: Table<T>,
  column: Column<T>,
  isHeader: boolean,
): React.CSSProperties {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left")
  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right")

  const left = isPinned === "left" ? `${column.getStart("left")}px` : undefined
  const right = isPinned === "right" ? `${column.getAfter("right")}px` : undefined
  const top = isHeader ? "0px" : undefined
  const zIndex = (() => {
    const a = isHeader ? 2 : 0
    const b = isPinned ? 1 : 0
    const z = a + b
    return z ? z : undefined
  })()

  const pl = isFirstRightPinnedColumn ? "1px" : "0px"
  const pr = isLastLeftPinnedColumn ? "1px" : "0px"

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -4px gray inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -4px gray inset"
        : undefined,
    paddingLeft: pl,
    paddingRight: pr,
    left,
    right,
    top,
    position: isPinned || isHeader ? "sticky" : "relative",
    width: column.getSize(),
    zIndex,
  }
}
