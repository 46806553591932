import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import { useWindowSize } from "usehooks-ts"
import { shallow } from "zustand/shallow"

import * as sk from "silverkoi"
import { PositionSnapshot } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { TooltipContextProvider } from "~/contexts/TooltipContextProvider"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useDefaultLeverage, useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { TRADING_MODAL_WIDTH, tw2 } from "~/utils"
import { CollateralInput } from "./CollateralInput"
import { CurrentSizeAndCollateralCards } from "./CurrentSizeAndCollateralCards"
import { OrderTypeButtons } from "./OrderTypeButtons"
import { ScrollableArea } from "./ScrollableArea"
import { SubmitButton } from "./SubmitButton"
import { Toggle } from "./Toggle"
import { TradeWarning } from "./TradeWarning"
import { TradingPanelInputs } from "./TradingPanelInputs"
import { TransactionSummary } from "./TransactionSummary"

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  position: PositionSnapshot
}

export const EditPositionModal = ({ isOpen, setIsOpen, position }: Props) => {
  const onRequestClose = () => {
    setIsOpen(false)
  }

  const { height: windowHeight } = useWindowSize()
  const height = windowHeight * 0.75

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={
        "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " +
        "background-gradient-bright rounded-lg p-px outline-0 "
      }
      overlayClassName="fixed inset-0 backdrop-blur-sm z-[21]"
      shouldCloseOnOverlayClick={true}
    >
      <div style={{ height }}>
        <TooltipContextProvider>
          <EditPositionModalBody isOpen={isOpen} setIsOpen={setIsOpen} position={position} />
        </TooltipContextProvider>
      </div>
    </ReactModal>
  )
}

const EditPositionModalBody = ({ isOpen, setIsOpen, position }: Props) => {
  const [tab, setTab] = useState<string>("collateral")

  // NOTE: pr needs to be adjusted if scrollbar width changes
  return (
    <div
      className={"flex flex-col h-full bg-black rounded-lg py-4"}
      style={{ width: TRADING_MODAL_WIDTH }}
    >
      <div className="flex flex-col">
        <div className={`${tw2("font-modal-title")} self-center py-3`}>Edit</div>

        <button
          className={
            "absolute right-4 " + "bg-black justify-center items-center text-white border-none"
          }
          onClick={() => setIsOpen(false)}
        >
          &#x2715;
        </button>
      </div>

      {/* Tabs */}
      <div className="flex justify-center items-center pb-2 gap-10">
        <Tab text="Collateral" value="collateral" tab={tab} setTab={setTab} />
        <Tab text="Position" value="position" tab={tab} setTab={setTab} />
      </div>

      {tab === "position" ? (
        <TradeContextProvider symbol={position.symbolMeta.symbol} defaultPosition={position}>
          <EditPositionView isOpen={isOpen} setIsOpen={setIsOpen} position={position} />
        </TradeContextProvider>
      ) : (
        <TradeContextProvider symbol={position.symbolMeta.symbol} defaultPosition={position}>
          <EditCollateralView isOpen={isOpen} setIsOpen={setIsOpen} position={position} />
        </TradeContextProvider>
      )}
    </div>
  )
}

interface TabProps {
  text: string
  value: string
  tab: string
  setTab: React.Dispatch<React.SetStateAction<string>>
}

const Tab = ({ text, value, tab, setTab }: TabProps) => {
  const menuFontCs = `${tw2("font-modal-tab")} hover:cursor-pointer`
  const selectedCs = `${menuFontCs} text-white underline underline-offset-4 decoration-cool-blue`
  const unselectedTabCs = `${menuFontCs} text-neutral-04`
  const tabCs = tab === value ? selectedCs : unselectedTabCs

  const onClick = () => {
    setTab(value)
  }

  return (
    <div className={tabCs} onClick={onClick}>
      {text}
    </div>
  )
}

const EditCollateralView = ({ isOpen, setIsOpen, position }: Props) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const inputMode = useTradeContextStore((s) => s.input.inputMode)
  const { reset, setInputMode } = useTradeContextActions(useTradeContextStore)

  const isWithdraw = inputMode === "WithdrawCollateral"
  const txDescription = isWithdraw ? "withdraw collateral" : "deposit collateral"
  const idleText = isWithdraw ? "Withdraw Collateral" : "Deposit Collateral"
  const pendingText = isWithdraw ? "Withdrawing Collateral ..." : "Depositing Collateral ..."

  useEffect(() => {
    reset()
    setInputMode("DepositCollateral")
  }, [reset, setInputMode])

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setInputMode("WithdrawCollateral")
    } else {
      setInputMode("DepositCollateral")
    }
  }

  return (
    <div className="flex flex-col h-full min-w-0 min-h-0 bg-black rounded-lg px-6">
      <Toggle
        checked={isWithdraw}
        onChange={onToggleChange}
        uncheckedText="Deposit"
        checkedText="Withdraw"
        classNames={{
          height: "h-8",
          checkedBackground: "before:bg-blue",
          beforeBackground: "before:bg-blue",
          beforeBorder: "before:rounded-[20px]",
          border: "rounded-[20px]",
          toggleText: `${tw2("font-long-short-toggle")} text-neutral-01`,
        }}
      />

      <div className="shrink-0 h-2" />

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <CollateralInput />

        <div className="shrink-0 h-2" />

        <TradeWarning />
        <SubmitButton
          simEnabled={isOpen}
          onSuccess={() => {
            setIsOpen(false)
          }}
          txDescription={txDescription}
          idleText={idleText}
          pendingText={pendingText}
        />

        <div className="shrink-0 h-2" />

        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}

const EditPositionView = ({ isOpen, setIsOpen, position }: Props) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const inputMode = useTradeContextStore((s) => s.input.inputMode, shallow)
  const { reset, setInputMode, setLeverage, setSide } = useTradeContextActions(useTradeContextStore)

  const isReduce = inputMode === "ReducePosition"
  const side = position.size.gt(BigDecimal.zero()) ? "bid" : "ask"
  const defaultLeverage = useDefaultLeverage(position)

  const posDir = position.size.gt(BigDecimal.zero()) ? "Long" : "Short"
  const txDescription = isReduce ? "reduce position" : "add position"
  const idleText = isReduce ? `Reduce ${posDir} Position` : `Add ${posDir} Position`
  const pendingText = isReduce ? `Reducing ${posDir} Position ...` : `Adding ${posDir} Position ...`

  const submitButtonProps = {
    simEnabled: isOpen,
    onSuccess: () => {
      setIsOpen(false)
    },
    txDescription,
    idleText,
    pendingText,
  }

  useEffect(() => {
    reset()
    setInputMode("AddPosition")
    setSide(side)
    setLeverage(defaultLeverage)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setInputMode("ReducePosition")
      setSide(sk.getOppositeSide(side))
    } else {
      setInputMode("AddPosition")
      setSide(side)
    }
  }

  const checkedBackground = side === "bid" ? "before:bg-red" : "before:bg-green"
  const beforeBackground = side === "ask" ? "before:bg-red" : "before:bg-green"

  return (
    <div className="flex flex-col h-full min-w-0 min-h-0 bg-black rounded-lg px-6">
      <Toggle
        checked={isReduce}
        onChange={onToggleChange}
        uncheckedText="Add"
        checkedText="Reduce"
        classNames={{
          height: "h-8",
          checkedBackground,
          beforeBackground,
          beforeBorder: "before:rounded-[20px]",
          border: "rounded-[20px]",
          toggleText: `${tw2("font-long-short-toggle")} text-neutral-01`,
        }}
      />

      <div className="shrink-0 h-2" />

      <OrderTypeButtons excludeStopLimit={true} />

      <div className="shrink-0 h-2" />

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <TradingPanelInputs withLeverage={true} submitButtonProps={submitButtonProps} />
        <div className="shrink-0 h-5" />
        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}
