import { useNavigate } from "react-router-dom"
import Select, {
  DropdownIndicatorProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select"

import { SymbolMeta } from "silverkoi"
import { BigDecimal, Sign } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { useChainDetail, useIsMobile, useMarket, useMarketStats } from "~/hooks"
import { getIcon, getSymbolLogo, tw2 } from "~/utils"
import { CircleImage } from "./CircleImage"

interface Props {
  symbolMeta: SymbolMeta
  height: string
}

interface OptionData extends Props {
  index: number
}

export const AssetDropdown = ({ symbolMeta, height }: Props) => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const { chain } = useChainDetail()
  const symbolMetas = skoi.getSupportedSymbolMetas(chain)
  const options: OptionData[] = []
  for (const [i, symbolMeta] of symbolMetas.entries()) {
    options.push({ symbolMeta, height, index: i })
  }
  const currentOption = { symbolMeta, height, index: 0 }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectChange = (newValue: any) => {
    const { symbol: newSymbol } = newValue.symbolMeta
    if (newSymbol === symbolMeta.symbol) return
    navigate(`/trade/${newSymbol}`, { replace: true })
  }

  return (
    <Select
      className="flex w-full h-fit z-[15]"
      components={{ DropdownIndicator, Menu, Option, SingleValue }}
      isSearchable={false}
      unstyled
      options={options}
      defaultValue={currentOption}
      value={currentOption}
      onChange={onSelectChange}
      classNames={{
        control: () => {
          return "flex grow-0 w-full h-fit"
        },
        menu: () => {
          return isMobile
            ? "flex flex-col grow w-full h-[5000px] grow rounded-lg backdrop-blur-sm"
            : "flex flex-col grow w-full rounded-lg shadow-[0_30px_50px_0_rgba(39,117,202,0.40)]"
        },
        menuList: () => {
          return isMobile
            ? "flex flex-col grow shrink-0 w-full !max-h-[1000px] h-full border-gradient-[1px-8px] scrollbar-thin"
            : "flex flex-col grow shrink-0 w-full !max-h-[400px] h-full border-gradient-[1px-8px] scrollbar-thin"
        },
      }}
    />
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps<OptionData>) => {
  const { menuIsOpen } = props.selectProps
  const icon = getIcon(menuIsOpen ? "arrow-up-white" : "arrow-down-white")
  return (
    <components.DropdownIndicator {...props}>
      <div className="pr-4">
        <img src={icon} className="h-7 w-7" />
      </div>
    </components.DropdownIndicator>
  )
}

// TODO: How to make full name div width equal parent width while making
// truncate work as expected?
const SingleValue = (props: SingleValueProps<OptionData>) => {
  const isMobile = useIsMobile()
  const { symbolMeta, height } = props.data
  const { symbol, name, displayName } = symbolMeta
  const logo = getSymbolLogo(symbol)

  const symbolFontCs = "font-sans text-[1rem] leading-[1.125em] tracking-[-0.01em] font-semibold"

  const renderSelectedValue = () => {
    if (isMobile) {
      return (
        <div
          className="shrink-0 flex items-center py-2 px-3 hover:cursor-pointer"
          style={{ height }}
        >
          <div className="flex grow items-center h-full">
            <CircleImage src={logo} size="1.75rem" />
            <div className="shrink-0 w-2" />

            <div className="flex grow w-full h-full gap-2">
              <div className={`flex items-center ${symbolFontCs} text-white truncate`}>{name}</div>
              <div className={`flex items-center ${symbolFontCs} ${tw2("font-gradient")} truncate`}>
                {displayName}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className="shrink-0 flex items-center bg-neutral-07 py-3 px-4 hover:cursor-pointer"
          style={{ height }}
        >
          <div className="flex grow items-center h-full">
            <CircleImage src={logo} size="2rem" />
            <div className="shrink-0 w-5" />

            <div className="flex flex-col grow w-full h-full justify-between gap-1">
              <div
                className={`flex items-center ${tw2("font-asset-name-selected")} w-[184px] text-white truncate`}
              >
                {name}
              </div>

              <div
                className={`flex items-center ${tw2("font-asset-symbol-selected")} ${tw2("font-gradient")} truncate`}
              >
                {displayName}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return <components.SingleValue {...props}>{renderSelectedValue()}</components.SingleValue>
}

const Menu = (props: MenuProps<OptionData>) => {
  const isMobile = useIsMobile()
  const cs = isMobile ? "h-[200px]" : "h-full"
  return (
    <components.Menu {...props}>
      <div className={`flex flex-col ${cs}`}>{props.children}</div>
    </components.Menu>
  )
}

const Option = (props: OptionProps<OptionData>) => {
  const { symbolMeta, index } = props.data
  const { symbol, name, displayName } = symbolMeta
  const logo = getSymbolLogo(symbol)
  const { chain } = useChainDetail()
  const isMobile = useIsMobile()

  const { decimals } = skoi.getSymbolConfig(symbol)
  const market = useMarket(symbol)
  const marketPriceText = market
    ? `$${market.marketPrice.format({ decimals, locale: "en-US" })}`
    : "-"

  const { data: marketStats } = useMarketStats(chain, symbol)
  const changePct = marketStats
    ? BigDecimal.fromReal(marketStats.daily_stats.change_pct, 2)
    : undefined
  const changePctText = changePct ? `${changePct.format({ minDecimals: 0 })}%` : "-"
  const changePctSign = changePct ? changePct.sign() : Sign.Zero
  const changePctColorCs = (() => {
    switch (changePctSign) {
      case Sign.Positive:
        return "text-green"
      case Sign.Negative:
        return "text-red"
      case Sign.Zero:
        return "text-white"
    }
  })()
  const changePctArrowIcon = (() => {
    switch (changePctSign) {
      case Sign.Positive:
        return <img src={getIcon("tailed-arrow-green")} className="w-[0.5rem] h-[0.5rem]" />
      case Sign.Negative:
        return (
          <img src={getIcon("tailed-arrow-red")} className="w-[0.5rem] h-[0.5rem] rotate-180" />
        )
      case Sign.Zero:
        return <></>
    }
  })()

  const borderCs = index === 0 ? "" : "border-t border-neutral-04 border-opacity-[0.5]"

  const logoSize = isMobile ? "1.75rem" : "2rem"
  const paddingCs = isMobile ? "p-2" : "px-4 py-2"
  const nameFontCs = isMobile
    ? "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
    : tw2("font-asset-name-dropdown")
  const symbolFontCs = isMobile
    ? "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
    : tw2("font-asset-symbol-dropdown")
  const priceFontCs = isMobile
    ? "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
    : tw2("font-asset-price-dropdown")
  const changeFontCs = isMobile
    ? "font-sans text-[0.75rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
    : tw2("font-asset-change-dropdown")

  return (
    <components.Option {...props}>
      <div
        className={`shrink-0 flex items-center ${paddingCs} bg-neutral-06 hover:cursor-pointer hover:bg-neutral-05 ${borderCs}`}
      >
        <div className="flex w-full h-full items-center">
          <CircleImage src={logo} size={logoSize} />
          <div className="shrink-0 w-4" />

          <div className="grow flex flex-col w-full justify-between">
            <div className="flex flex-row w-full items-center justify-between gap-1">
              <div className={`${nameFontCs} text-gray-dropdown-name`}>{name}</div>
              <div className={`${symbolFontCs} ${tw2("font-gradient")} align-middle`}>
                {displayName}
              </div>
            </div>

            <div className="flex flex-row w-full items-center justify-between">
              <div className={`${priceFontCs} text-white`}>{marketPriceText}</div>

              <div className="flex flex-row items-center h-full">
                {changePctArrowIcon}
                <div className={`${changeFontCs} align-middle ${changePctColorCs} pl-1`}>
                  {changePctText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </components.Option>
  )
}
