import { PropsWithChildren, useEffect, useRef } from "react"

import { PositionSnapshot } from "silverkoi"

import { useUserState } from "../hooks"
import { TradeContextStore, createTradeContextStore } from "../stores"
import { TradeContext } from "./TradeContext"

interface Props {
  symbol: string
  defaultPosition?: PositionSnapshot
}

export const TradeContextProvider = ({
  symbol,
  defaultPosition,
  children,
}: PropsWithChildren<Props>) => {
  const { data: userState } = useUserState()

  const useTradeContextStoreRef = useRef<TradeContextStore>()
  if (!useTradeContextStoreRef.current) {
    useTradeContextStoreRef.current = createTradeContextStore({ symbol })
  }
  const useTradeContextStore = useTradeContextStoreRef.current

  // TODO: This is gross. Need to figure out a more robust way to handle input
  // forms
  const setSymbol = useTradeContextStore((s) => s.actions.setSymbol)
  useEffect(() => {
    setSymbol(symbol)
  }, [symbol]) // eslint-disable-line react-hooks/exhaustive-deps

  const position = defaultPosition ?? userState?.getEmptyPosition(symbol)

  const value = {
    symbol,
    position,
    useTradeContextStore,
  }

  return <TradeContext.Provider value={value}>{children}</TradeContext.Provider>
}
