import { IconProps, IconSvg } from "./IconSvg"

export const BellWithDot = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 24 24">
      <path
        d="M 0 17.515 C 0 17.058 0.185 16.62 0.514 16.303 L 1.749 15.11 C 2.219 14.657 2.47 9.594 2.47 9.594 C 2.454 4.3 6.741 0 12.034 0 C 13.114 0 14.152 0.179 15.12 0.509 C 14.651 1.163 14.31 1.915 14.135 2.729 C 13.455 2.514 12.731 2.399 11.98 2.399 C 8.015 2.399 4.804 5.619 4.816 9.585 L 4.828 13.368 C 4.831 14.658 4.316 15.893 3.4 16.798 L 20.552 16.798 C 19.652 15.898 19.146 14.677 19.146 13.404 L 19.146 9.94 C 19.425 9.979 19.71 10 20 10 C 20.554 10 21.091 9.925 21.6 9.784 L 21.6 13.406 C 21.6 14.043 21.852 14.653 22.303 15.103 L 23.503 16.303 C 23.821 16.621 24 17.053 24 17.503 C 24 18.44 23.24 19.2 22.303 19.2 L 16.8 19.2 C 16.8 21.85 14.651 24 11.999 24 C 9.349 24 7.2 21.85 7.2 19.2 L 1.685 19.2 C 0.754 19.2 0 18.446 0 17.515 Z M 9.546 19.198 C 9.546 20.524 10.621 21.597 11.945 21.597 C 13.271 21.597 14.346 20.524 14.346 19.198 Z"
        fill={fill}
      />
      <ellipse
        style={{
          strokeWidth: "0px",
          stroke: "rgb(109, 51, 43)",
          paintOrder: "fill",
          fill: "rgb(255, 106, 85)",
        }}
        cx="20"
        cy="4"
        rx="4"
        ry="4"
      />
    </IconSvg>
  )
}
