import { useEffect } from "react"

import { PositionSnapshot } from "silverkoi"

import { useTradeContext } from "../hooks"
import { useTradeContextActions } from "../stores"
import { ActionModal } from "./ActionModal"
import { CollateralInput } from "./CollateralInput"
import { CurrentSizeAndCollateralCards } from "./CurrentSizeAndCollateralCards"
import { SubmitButton } from "./SubmitButton"
import { TradeWarning } from "./TradeWarning"
import { TransactionSummary } from "./TransactionSummary"

interface Props {
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  position: PositionSnapshot
}

export const DepositCollateralModal = ({ isOpen, setIsOpen, position }: Props) => {
  return (
    <ActionModal position={position} isOpen={isOpen} setIsOpen={setIsOpen} maxHeight={"500px"}>
      <Body isOpen={isOpen} setIsOpen={setIsOpen} position={position} />
    </ActionModal>
  )
}

const Body = ({ isOpen, setIsOpen, position }: Props) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const { reset, setCollateralAmount, setInputMode } = useTradeContextActions(useTradeContextStore)

  const badDebt = position.collateral.neg()

  useEffect(() => {
    reset()
    setInputMode("DepositCollateral")
    setCollateralAmount({ value: badDebt, text: badDebt.toStringTrimmed() })
  }, [reset, setCollateralAmount, setInputMode, badDebt])

  return (
    <div className={"flex flex-col w-[400px] h-full bg-black rounded-lg px-10 py-5"}>
      <div className="flex flex-row justify-center pb-5">
        <div className="text-[20px] text-white font-bold self-center py-1">Deposit Collateral</div>

        <button
          className={
            "absolute right-4 " + "bg-black justify-center items-center text-white border-none"
          }
          onClick={() => setIsOpen(false)}
        >
          &#x2715;
        </button>
      </div>

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-4" />

      <CollateralInput fixedValue={position.collateral} />

      <div className="shrink-0 h-5" />

      <TradeWarning />

      <SubmitButton
        simEnabled={isOpen}
        onSuccess={() => {
          setIsOpen(false)
        }}
        txDescription={"deposit collateral"}
        idleText={"Deposit Collateral"}
        pendingText={"Depositing..."}
      />

      <div className="shrink-0 h-6" />

      <TransactionSummary whitelist={["Collateral"]} />
    </div>
  )
}
