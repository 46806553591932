import { BigDecimal } from "silverkoi/math"

import { useTradeContext, useTradeContextActions } from "~/hooks"
import { LeverageInput } from "./LeverageInput"
import { LimitPriceInput } from "./LimitPriceInput"
import { OrderSizeInput } from "./OrderSizeInput"
import { SlippageInput } from "./SlippageInput"
import { SubmitButton, SubmitButtonProps } from "./SubmitButton"
import { TimeInForceInput } from "./TimeInForceInput"
import { ToggledPriceInput } from "./ToggledPriceInput"
import { TradeWarning } from "./TradeWarning"
import { TriggerPriceInput } from "./TriggerPriceInput"

interface Props {
  withLeverage: boolean
  defaultSize?: BigDecimal
  withSLTP?: boolean
  withTif?: boolean
  submitButtonProps: SubmitButtonProps
}

export const TradingPanelInputs = ({
  withLeverage,
  defaultSize,
  withSLTP,
  withTif,
  submitButtonProps,
}: Props) => {
  const tradeContext = useTradeContext()
  const { symbol, useTradeContextStore } = tradeContext
  const { inputMode, orderType, slTriggerPrice, tpTriggerPrice } = useTradeContextStore((s) => ({
    inputMode: s.input.inputMode,
    orderType: s.input.type,
    slTriggerPrice: s.input.slTriggerPrice,
    tpTriggerPrice: s.input.tpTriggerPrice,
  }))
  const { setSlTriggerPrice, setTpTriggerPrice } = useTradeContextActions()

  const isLimit = orderType === "limit"
  const isStopLimit = orderType === "stop-limit"
  const isCreateSLTP = inputMode === "CreateSLTP"
  const excludeGtd = isStopLimit
  const isReduce = isCreateSLTP || inputMode === "ReducePosition"
  withTif = withTif ?? true

  return (
    <div className="shrink-0 flex flex-col w-full h-fit">
      {withSLTP && (
        <>
          <ToggledPriceInput
            label={"TP Trigger Price"}
            input={tpTriggerPrice}
            setInput={setTpTriggerPrice}
            defaultEnabled={true}
          />
          <div className="shrink-0 h-1" />
          <ToggledPriceInput
            label={"SL Trigger Price"}
            input={slTriggerPrice}
            setInput={setSlTriggerPrice}
            defaultEnabled={true}
          />
          <div className="shrink-0 h-5" />
        </>
      )}

      {!isCreateSLTP && isStopLimit && (
        <>
          <TriggerPriceInput />
          <div className="shrink-0 h-2" />
        </>
      )}

      {!isCreateSLTP && (isLimit || isStopLimit) && (
        <>
          <LimitPriceInput />
          <div className="shrink-0 h-2" />
        </>
      )}

      <>
        <OrderSizeInput defaultSize={defaultSize} />
        <div className="shrink-0 h-2" />
      </>

      {!isCreateSLTP && !isLimit && !isStopLimit && (
        <>
          <SlippageInput />
          <div className="shrink-0 h-2" />
        </>
      )}

      {withLeverage && (
        <>
          <LeverageInput symbol={symbol} />
          <div className="shrink-0 h-2" />
        </>
      )}

      {!isCreateSLTP && (isLimit || isStopLimit) && withTif && (
        <>
          <TimeInForceInput excludeGtd={excludeGtd} excludePostOnly={isReduce} />
          <div className="shrink-0 h-2" />
        </>
      )}

      <div className="shrink-0 h-2" />
      <TradeWarning />

      <SubmitButton {...submitButtonProps} />
    </div>
  )
}
