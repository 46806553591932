// TODO: Make blockNumber required across in all applicable methods below. To
// accommodated this, we will need to store the latest block nubmer in a react
// contet or zustand state.

import { Address } from "viem"

import * as sk from "silverkoi"
import {
  CurrentPrice,
  isCurrentPrice,
  MarketSnapshot,
  OrderSide,
  OrderSnapshot,
  PositionSnapshot,
  SilverKoiApi,
  TriggerSnapshot,
} from "silverkoi"
import { BigDecimal, BigIntMath } from "silverkoi/math"

import { UserState } from "../../types"
import { getSupportedSymbols } from "./universe"

const NUM_POSITION_SUB_IDS = 4n // TODO: Increase to 8 if necessary

export function resolveCurrentPrice({
  price,
  marketPrice,
}: {
  price: BigDecimal | CurrentPrice | undefined
  marketPrice: BigDecimal | undefined
}): BigDecimal | undefined {
  // TODO: how to handle case where market price is undefined (i.e. invalid
  // market state)
  return isCurrentPrice(price) ? marketPrice : price
}

// TODO: This takes 3+ seconds
export async function queryUserState({
  api,
  account,
  blockNumber,
}: {
  api: SilverKoiApi
  account: Address
  blockNumber?: bigint
}): Promise<UserState> {
  blockNumber = blockNumber ?? (await api.client.public.getBlockNumber())

  const traderId = await sk.getTraderId({ api, account })

  const userBalances = await sk.getUserBalances({ api, account, traderId, blockNumber })

  const positions = new Map<string, Map<bigint, PositionSnapshot>>()
  const openOrders = new Map<string, Map<bigint, OrderSnapshot>>()
  const triggers = new Map<string, Map<bigint, TriggerSnapshot>>()

  // Initialize empty maps for each symbol.
  const symbols = getSupportedSymbols(api.chain)
  for (const sym of symbols) {
    positions.set(sym, new Map<bigint, PositionSnapshot>())
    openOrders.set(sym, new Map<bigint, OrderSnapshot>())
    triggers.set(sym, new Map<bigint, TriggerSnapshot>())
  }

  // Retrieve all market snapshots.
  const marketsByMarketId = await getMarketSnapshots({ api, symbols, blockNumber })
  const markets = new Map<string, MarketSnapshot>()
  for (const [marketId, marketSnapshot] of marketsByMarketId) {
    const symbol = api.markets.getByMarketId(marketId).symbol
    markets.set(symbol, marketSnapshot)
  }

  // Retrieve all positions.
  const positionsArray = await getPositionsForAllMarkets({ api, traderId, blockNumber })
  for (const position of positionsArray) {
    const innerMap = positions.get(position.symbolMeta.symbol)
    if (!innerMap) continue
    innerMap.set(position.positionId, position)
  }

  // Retrieve all open orders.
  const openOrdersArray = await getOpenOrdersForAllMarkets({ api, traderId, blockNumber })
  for (const order of openOrdersArray) {
    const innerMap = openOrders.get(order.symbolMeta.symbol)
    if (!innerMap) continue
    innerMap.set(order.orderId, order)
  }

  // Retrieve all open orders.
  const triggersArray = await getOpenTriggersForAllMarkets({ api, traderId, blockNumber })
  for (const trigger of triggersArray) {
    const innerMap = triggers.get(trigger.symbolMeta.symbol)
    if (!innerMap) continue
    innerMap.set(trigger.triggerId, trigger)
  }

  const userState = new UserState(
    account,
    traderId,
    userBalances,
    markets,
    positions,
    openOrders,
    triggers,
  )
  return userState
}

// TODO: Rename the get* methods to query* to more accurately reflect that we
// are hitting an external endpoint (indirectly through ethers api).

export async function queryMarketSnapshot({
  api,
  symbol,
  blockNumber,
}: {
  api: SilverKoiApi
  symbol: string
  blockNumber?: bigint
}): Promise<MarketSnapshot> {
  const marketId = api.markets.getBySymbol(symbol).marketId
  const marketSnapshots = await sk.getMarketSnapshots({ api, marketIds: [marketId], blockNumber })
  return marketSnapshots.get(marketId)!
}

export async function getMarketSnapshots({
  api,
  symbols,
  blockNumber,
}: {
  api: SilverKoiApi
  symbols: string[]
  blockNumber?: bigint
}): Promise<Map<bigint, MarketSnapshot>> {
  const marketIds = symbols.map((symbol: string) => api.markets.getBySymbol(symbol).marketId)
  return await sk.getMarketSnapshots({ api, marketIds, blockNumber })
}

export async function queryAllMarketSnapshots({
  api,
  blockNumber,
}: {
  api: SilverKoiApi
  blockNumber?: bigint
}): Promise<Map<string, MarketSnapshot>> {
  const symbols = getSupportedSymbols(api.chain)
  const snapshotsById = await getMarketSnapshots({ api, symbols, blockNumber })
  const snapshots = new Map<string, MarketSnapshot>()
  for (const [_, snapshot] of snapshotsById) {
    snapshots.set(snapshot.symbol, snapshot)
  }
  return snapshots
}

export async function getPositionsForMarket({
  api,
  traderId,
  symbol,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  symbol: string
  blockNumber?: bigint
}): Promise<PositionSnapshot[]> {
  const marketId = api.markets.getBySymbol(symbol).marketId
  const marketIds = [marketId]
  const marketSnapshots = await sk.getMarketSnapshots({ api, marketIds, blockNumber })
  const positions = await sk.getPositionSnapshots({
    api,
    traderId,
    marketIds: [marketId],
    marketSnapshots,
    blockNumber,
  })
  return positions
}

export async function getPositionsForAllMarkets({
  api,
  traderId,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  blockNumber?: bigint
}): Promise<PositionSnapshot[]> {
  const symbols = getSupportedSymbols(api.chain)
  const marketIds = symbols.map((symbol: string) => api.markets.getBySymbol(symbol).marketId)
  const marketSnapshots = await sk.getMarketSnapshots({ api, marketIds, blockNumber })

  // TODO: The implementation getBestBidAndAsk has to iterate from the top of
  // book to ignore expired orders. If the keeper isn't running, this
  // implementation can be very inefficient. For now, retrieve midpoint prices
  // first and then query the rest of the position states in smaller batches.
  //return await getPositionSnapshots(api, traderId, marketIds, marketSnapshots)
  const positions: PositionSnapshot[] = []
  for (const marketId of marketIds) {
    const marketPositions = await sk.getPositionSnapshots({
      api,
      traderId,
      marketIds: [marketId],
      marketSnapshots,
      blockNumber,
    })
    for (const p of marketPositions) {
      positions.push(p)
    }
  }
  return positions
}

export async function getAvailablePositionId({
  api,
  traderId,
  symbol,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  symbol: string
  blockNumber?: bigint
}): Promise<bigint | undefined> {
  if (!traderId) return undefined

  const positionSubId = await getAvailablePositionSubId({ api, traderId, symbol, blockNumber })
  if (!positionSubId) return undefined

  return sk.encodePositionId({ traderId, positionSubId })
}

async function getAvailablePositionSubId({
  api,
  traderId,
  symbol,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  symbol: string
  blockNumber?: bigint
}): Promise<bigint | undefined> {
  const positions = await getPositionsForMarket({ api, traderId, symbol, blockNumber })
  const candidates: bigint[] = []
  for (const position of positions.values()) {
    if (position.empty) {
      candidates.push(position.positionSubId)
    }
  }
  if (candidates.length === 0) {
    return undefined
  } else {
    return candidates.reduce(BigIntMath.min, NUM_POSITION_SUB_IDS)
  }
}

export async function getOpenOrdersForAllMarkets({
  api,
  traderId,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  blockNumber?: bigint
}): Promise<OrderSnapshot[]> {
  const symbols = getSupportedSymbols(api.chain)
  const marketIds = symbols.map((symbol: string) => api.markets.getBySymbol(symbol).marketId)

  // TODO: If there are too many symbols, need to break up this call into
  // multiple batches.
  return await sk.getOpenOrderSnapshots({
    api,
    traderId,
    marketIds,
    blockNumber,
  })
}

export async function getOpenTriggersForAllMarkets({
  api,
  traderId,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  blockNumber?: bigint
}): Promise<TriggerSnapshot[]> {
  const symbols = getSupportedSymbols(api.chain)
  const marketIds = symbols.map((symbol: string) => api.markets.getBySymbol(symbol).marketId)

  // TODO: If there are too many symbols, need to break up this call into
  // multiple batches.
  return await sk.getOpenTriggerSnapshots({
    api,
    traderId,
    marketIds,
    blockNumber,
  })
}

export async function getOrderSizeForOrderNotional({
  api,
  symbol,
  side,
  notional,
  blockNumber,
}: {
  api: SilverKoiApi
  symbol: string
  side: OrderSide
  notional: BigDecimal
  blockNumber: bigint
}): Promise<{
  fillSize: BigDecimal
  fillNotional: BigDecimal
}> {
  const marketId = api.markets.getBySymbol(symbol).marketId
  return await sk.getOrderSizeForOrderNotional({ api, marketId, side, notional, blockNumber })
}
