import React from "react"

import * as skoi from "../api/silverkoi"
import { useTradeContext } from "../hooks"
import { useTradeContextActions } from "../stores"
import { parseBigDecimal, tw2 } from "../utils"
import { NumberInput } from "./NumberInput"

export const SlippageInput = () => {
  const { useTradeContextStore } = useTradeContext()
  const slippage = useTradeContextStore((s) => s.input.slippage)
  const { setSlippage } = useTradeContextActions(useTradeContextStore)

  const defaultSlippage = skoi.DEFAULT_SLIPPAGE
  const placeholder = defaultSlippage.toString(1)

  const onLeverageTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, 2, skoi.MIN_SLIPPAGE, skoi.MAX_SLIPPAGE)
    setSlippage({ value, text })
  }

  return (
    <div className="relative w-full">
      <div className="flex items-center justify-between w-full">
        <div className={`${tw2("font-input-label")} text-white`}>Slippage</div>
        <NumberInput
          className={
            "flex text-center w-[2rem] px-5 py-[0.25rem] " +
            "border border-neutral-shade-dark2 rounded-xl bg-black focus:outline-none focus:border-white " +
            `text-white ${tw2("font-input-value-small")} font-medium `
          }
          placeholder={placeholder}
          value={(slippage ?? defaultSlippage).text}
          onChange={onLeverageTextInputChange}
        />
        <div className={"flex absolute text-center right-[0.75rem]"}>
          <div className={`flex ${tw2("font-input-value-small")} text-gray`}>%</div>
        </div>
      </div>
    </div>
  )
}
