import { env } from "~/env"

import { BigDecimal } from "silverkoi/math"

export const MAX_UINT256 = 2n ** 256n - 1n

export const WINDOW_SIZE_FAUCET_BUTTON = 1400

export const RIGHT_ARROW_TEXT = "\u27F6"

export const TRADING_PANEL_WIDTH = "18rem"
export const TRADING_MODAL_WIDTH = "20rem"
export const MIN_USD_REQUIRED = BigDecimal.fromString("0.001")

// TODO: Query symbol info from contract via hook instead of hard coding this.
export const USD_SYMBOL = "pUSD"
export const USD_DECIMALS = 6n

export function apiUrl(): string {
  return env.VITE_SKOI_API_URL
}
