import { PropsWithChildren, useRef } from "react"

import { useScrollView } from "../hooks"

interface Props {
  className?: string
}

export const ScrollableArea = ({ className, children }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement | null>(null) // eslint-disable-line no-null/no-null
  const { atTop, atBottom, onScroll } = useScrollView(ref)

  return (
    <div className={`${className ?? ""} relative flex flex-col min-w-0 min-h-0 overflow-hidden`}>
      {!atTop && (
        <div className="absolute top-0 shrink-0 w-full h-2 background-white-gradient-[0deg] z-10" />
      )}

      <div ref={ref} onScroll={onScroll} className="flex flex-col overflow-y-scroll no-scrollbar">
        {children}
      </div>

      {!atBottom && (
        <div className="absolute bottom-0 shrink-0 w-full h-2 background-white-gradient-[180deg] z-[10]" />
      )}
    </div>
  )
}
