import moment from "moment"

import { BigDecimal, Sign } from "silverkoi/math"

export const formatBalance = (rawBalance: string) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(2)
  return balance
}

export const formatChainAsNum = (chainIdHex: string) => {
  const chainIdNum = parseInt(chainIdHex)
  return chainIdNum
}

export const formatAddress = (address: string, count: number) => {
  const front = address.slice(0, count)
  const back = address.slice(-count)
  return `${front}...${back}`
}

export function formatDecimal(value: number, decimals: number, nanString?: string): string {
  if (Number.isNaN(value)) {
    if (nanString === undefined) {
      return value.toString()
    } else {
      return nanString
    }
  } else {
    return (Math.round(value * 10 ** decimals) / 10 ** decimals).toFixed(decimals)
  }
}

export const formatUsdcValue = (
  value: BigDecimal | number | bigint | undefined,
): string | undefined => {
  if (value === undefined) return undefined

  if (typeof value === "number" || typeof value === "bigint") {
    value = BigDecimal.fromString(`${value}`)
  }

  const absValue = value.abs()
  const absText = (() => {
    if (absValue.lt(BigDecimal.fromReal(1, 0))) {
      return absValue.toString(5)
    } else if (absValue.lt(BigDecimal.fromReal(1000, 0))) {
      return absValue.toString(2)
    } else if (absValue.lt(BigDecimal.fromReal(1000000, 0))) {
      return absValue.mul(BigDecimal.fromRaw(1, 3)).toString(2) + " K"
    } else if (absValue.lt(BigDecimal.fromReal(1000000000, 0))) {
      return absValue.mul(BigDecimal.fromRaw(1, 6)).toString(2) + " M"
    } else if (absValue.lt(BigDecimal.fromReal(1000000000000, 0))) {
      return absValue.mul(BigDecimal.fromRaw(1, 9)).toString(2) + " B"
    } else {
      return absValue.mul(BigDecimal.fromRaw(1, 12)).toString(2) + " T"
    }
  })()

  const sign = value.lt(BigDecimal.zero()) ? "-" : ""
  return `${sign}$${absText}`
}

// Returns the value formatted to 3 sigfigs.
export const format3SigFigDollars = (value: BigDecimal | number | bigint): string => {
  if (value === undefined) return "-"
  if (typeof value === "number" || typeof value === "bigint") {
    value = BigDecimal.fromString(`${value}`)
  }

  const sign = value.sign() === Sign.Negative ? "-" : ""

  const absValue = value.abs()

  const [scaled, suffix] = (() => {
    if (absValue.lt(bd("1000"))) {
      return [absValue, ""]
    } else if (absValue.lt(bd("1000000"))) {
      return [absValue.div(bd("1000"), 3), "K"]
    } else if (absValue.lt(bd("1000000000"))) {
      return [absValue.div(bd("1000000"), 3), "M"]
    } else if (absValue.lt(bd("1000000000000"))) {
      return [absValue.div(bd("1000000000"), 3), "B"]
    } else {
      return [absValue.div(bd("1000000000000"), 3), "T"]
    }
  })()

  const text = (() => {
    if (scaled.lt(bd("10"))) {
      return scaled.toString(2)
    } else if (scaled.lt(bd("100"))) {
      return scaled.toString(1)
    } else {
      return scaled.toString(0)
    }
  })()

  return sign + "$" + text + suffix
}

const bd = (text: string): BigDecimal => {
  return BigDecimal.fromString(text)
}

export interface FormatValueArgs {
  signed?: boolean
  minDecimals?: number
  decimals?: number
  prefix?: string
  suffix?: string
  dollar?: boolean
  noZero?: boolean
}

export const formatValue = (
  value: BigDecimal | number | bigint | undefined,
  args?: FormatValueArgs,
) => {
  const { signed, minDecimals, decimals, prefix, suffix, dollar, noZero } = args ?? {}

  if (typeof value === "number" || typeof value === "bigint") {
    value = BigDecimal.fromString(`${value}`)
  }
  if (value && value.isZero() && noZero) {
    value = undefined
  }
  if (value === undefined) {
    return "-"
  }

  let text = value.format({
    decimals: decimals,
    minDecimals: minDecimals ?? 0,
    locale: "en-US",
    signed: signed ?? false,
  })

  if (dollar) {
    const first = text.at(0)
    if (first === "+" || first === "-") {
      text = `${first}$${text.slice(1)}`
    } else {
      text = `$${text}`
    }
  }
  if (suffix) {
    text = `${text}${suffix}`
  }
  if (prefix) {
    text = `${prefix}${text}`
  }
  return text
}

export const formatTimestamp = (timestamp: bigint): string => {
  if (timestamp === 0n) return ""
  return moment.unix(Number(timestamp)).format("YYYY-MM-DD HH:mm:SS Z")
}

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const formatCampaignDate = (isoDate: string): string => {
  const date = new Date(isoDate)
  const day = date.getDate()
  const month = MONTHS[date.getMonth()]
  const year = date.getFullYear()
  return `${month} ${day} ${year}`
}
