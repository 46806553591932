import { useMediaQuery, useWindowSize } from "usehooks-ts"

export type ScreenLayout = "mobile" | "normal" | "wide" | "extra-wide"

export const useScreenLayout = (): ScreenLayout => {
  const { width } = useWindowSize()
  if (width > 1700) {
    return "extra-wide"
  } else if (width > 1350) {
    return "wide"
  } else if (width > 800) {
    return "normal"
  } else {
    return "mobile"
  }
}

export const useIsMobile = (): boolean => {
  const layout = useScreenLayout()
  return layout === "mobile"
}

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl"

// TODO: Load tailwind config instead
const breakpoints: Record<Breakpoint, number> = {
  xs: 475,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const

export const useIsLessThanBreakpoint = (bp: Breakpoint): boolean => {
  const bpPx = breakpoints[bp]
  return !useMediaQuery(`(min-width: ${bpPx}px)`)
}
