import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { SyntheticEvent, useMemo, useState } from "react"

import * as sk from "silverkoi"
import { PositionSnapshot, TriggerSnapshot } from "silverkoi"

import * as skoi from "~/api/silverkoi"
import { TriggerData, useSilverKoiApi, useSubmitTx, useTriggerData } from "~/hooks"
import { TooltipId } from "~/types"
import { getIcon } from "~/utils"
import { EditTriggerModal } from "./EditTriggerModal"
import { InfoTable } from "./InfoTable"
import { PositionCell } from "./PositionCell"
import { SideValue } from "./SideValue"
import { TableColumnLabel } from "./TableColumnLabel"
import { getMakeColumnDef } from "./TableHelpers"
import { TableValueCell } from "./TableValueCell"

export interface TriggersTableProps {
  fullVersion: boolean
}

export const TriggersTable = ({ fullVersion }: TriggersTableProps) => {
  const { data, isLoading } = useTriggerData()

  // TODO: Revisit use of useMemo
  const columns = useMemo(() => {
    return makeColumns(fullVersion)
  }, [fullVersion])

  const columnPinning = {
    left: ["position"],
    right: ["cancel", "edit"],
  }

  const exclude = fullVersion ? [] : ["positionId", "triggerId"]

  return (
    <InfoTable
      fullVersion={fullVersion}
      loading={isLoading}
      data={data ?? []}
      columns={columns}
      columnPinning={columnPinning}
      exclude={exclude}
    />
  )
}

const columnHelper = createColumnHelper<TriggerData>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeColumns = (fullVersion: boolean): ColumnDef<TriggerData, any>[] => {
  const tooltipPlace = fullVersion ? "bottom" : "top"
  const makeColumnDef = getMakeColumnDef({
    table: "triggers",
    fullVersion,
    columnHelper,
  })

  return [
    makeColumnDef({
      accessor: "position",
      header: "Position",
      cell: (props) => {
        return <PositionCell position={props.getValue()} fullVersion={fullVersion} />
      },
    }) as ColumnDef<TriggerData>,

    makeColumnDef({
      accessor: "positionId",
      header: "Position ID",
      cell: (props) => props.getValue().toString(),
    }),

    makeColumnDef({
      accessor: "triggerId",
      header: "Trigger ID",
      cell: (props) => props.getValue().toString(),
    }),

    makeColumnDef({
      accessor: "size",
      header: "Order Size",
      cell: (props) => {
        return <TableValueCell value={props.getValue()} signed color noZero decimals={5} />
      },
    }),

    makeColumnDef({
      accessor: "type",
      header: "Type",
      cell: (props) => <span className="whitespace-nowrap">{props.getValue()}</span>,
    }),

    makeColumnDef({
      accessor: "side",
      header: () => "Trigger Order Side",
      cell: (props) => {
        return <SideValue side={props.getValue()} />
      },
    }),

    makeColumnDef({
      accessor: "marketPrice",
      header: () => (
        <TableColumnLabel
          labelText="Market Price"
          tooltipId={TooltipId.MarketPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: (r) => ({ triggerPrice: r.triggerPrice, fromAbove: r.fromAbove }),
      id: "triggerPrice",
      header: () => (
        <TableColumnLabel
          labelText="Trigger Price"
          tooltipId={TooltipId.TriggerPrice}
          tooltipPlace={tooltipPlace}
        />
      ),
      cell: (props) => {
        const symbol = props.row.original.symbol
        const { decimals } = skoi.getSymbolConfig(symbol)
        const { triggerPrice, fromAbove } = props.getValue()
        const prefix = fromAbove ? "<" : ">"
        return (
          <TableValueCell
            value={triggerPrice}
            prefix={prefix}
            dollar
            decimals={decimals}
            minDecimals={decimals}
          />
        )
      },
    }),

    makeColumnDef({
      accessor: "limitPrice",
      header: () => <TableColumnLabel labelText="Limit Price" />,
      cell: renderPrice,
    }),

    makeColumnDef({
      accessor: "trigger",
      id: "cancel",
      header: () => <div className="grow-0">Cancel</div>,
      cell: (props) => {
        return <CancelButton trigger={props.getValue()} />
      },
    }),

    makeColumnDef({
      accessor: (r) => ({ trigger: r.trigger, fromAbove: r.fromAbove, position: r.position }),
      id: "edit",
      header: () => <div className="grow-0">Edit</div>,
      cell: (props) => {
        return <EditButton {...props.getValue()} />
      },
    }),
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderPrice = (props: CellContext<TriggerData, any>) => {
  const symbol = props.row.original.symbol
  const { decimals } = skoi.getSymbolConfig(symbol)
  return (
    <TableValueCell
      value={props.getValue()}
      dollar
      decimals={decimals}
      minDecimals={decimals}
      noZero
    />
  )
}

const CancelButton = ({ trigger }: { trigger: TriggerSnapshot }) => {
  const { data: api } = useSilverKoiApi()
  const submitTx = useSubmitTx()

  const onClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      return await sk.cancelTrigger({
        api,
        marketId: trigger.marketMeta.marketId,
        traderId: trigger.traderId,
        triggerId: trigger.triggerId,
      })
    }
    submitTx.mutate({ description: "cancel trigger", txHashFn })
  }

  const cs = submitTx.isPending ? "hover:cursor-wait grayscale" : "hover:cursor-pointer"

  return (
    <>
      <div className="grow-0 flex w-4 justify-center align-center">
        <img src={getIcon("cancel")} className={"w-4 h-4 " + cs} onClick={onClick} />
      </div>
    </>
  )
}

const EditButton = ({
  trigger,
  fromAbove,
  position,
}: {
  trigger: TriggerSnapshot
  fromAbove: boolean
  position: PositionSnapshot
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  // TODO: Reuse same modal instead of creating one per row.
  return (
    <>
      <div className="grow-0 flex w-4 justify-center align-center">
        <img
          src={getIcon("edit")}
          className="hover:cursor-pointer w-4 h-4"
          onClick={() => {
            setOpenModal(true)
          }}
        />
      </div>

      <EditTriggerModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        trigger={trigger}
        fromAbove={fromAbove}
        position={position}
      />
    </>
  )
}
