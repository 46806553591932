import React, { useEffect } from "react"
import { useAccount } from "wagmi"

import { OrderSide } from "silverkoi"

import * as skoi from "~/api/silverkoi"
import { useTradeContext, useTradeContextActions } from "~/hooks"
import { OrderType } from "~/types"
import { tw2 } from "~/utils"
import { OrderTypeButtons } from "./OrderTypeButtons"
import { ScrollableArea } from "./ScrollableArea"
import { SubmitButtonProps } from "./SubmitButton"
import { Toggle } from "./Toggle"
import { TradingPanelInputs } from "./TradingPanelInputs"
import { TransactionSummary } from "./TransactionSummary"

export const TradingPanel = () => {
  const { address, isConnected } = useAccount()
  const tradeContext = useTradeContext()
  const { symbol, useTradeContextStore } = tradeContext
  const side = useTradeContextStore((s) => s.input.side)
  const orderType = useTradeContextStore((s) => s.input.type)
  const { reset, setInputMode, setOrderType, setTif, setLeverage, setSide, setSymbol } =
    useTradeContextActions()

  const resetInputs = () => {
    reset()
    setSymbol(symbol)
    setInputMode("NewPosition")
    setOrderType("market")
    setTif("gtc")
    setLeverage({ value: skoi.MAX_LEVERAGE, text: skoi.MAX_LEVERAGE.toStringTrimmed() })
  }

  // Clear inputs when wallet changes.
  useEffect(() => {
    resetInputs()
  }, [symbol, isConnected, address]) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSide(e.target.checked ? "ask" : "bid")
    // TODO: reset trigger prices
  }

  const submitButtonProps = getSubmitButtonProps(side, orderType, resetInputs)

  return (
    <div className="flex flex-col min-h-0 min-w-0 pb-1">
      <div className="flex flex-col px-3">
        <Toggle
          checked={side === "ask"}
          onChange={onToggleChange}
          uncheckedText="LONG"
          checkedText="SHORT"
          classNames={{
            height: "h-8",
            beforeBackground: `before:bg-green`,
            checkedBackground: `before:bg-red`,
            beforeBorder: "before:rounded-[24px]",
            border: "rounded-[24px]",
            toggleText: `${tw2("font-long-short-toggle")} text-neutral-01`,
          }}
        />

        <div className="shrink-0 h-2" />

        <OrderTypeButtons />
      </div>

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <div className="flex flex-col px-3">
          <TradingPanelInputs withLeverage={true} submitButtonProps={submitButtonProps} />
        </div>
        <div className="shrink-0 h-2" />
        <div className="flex flex-col px-1">
          <TransactionSummary />
        </div>
      </ScrollableArea>
    </div>
  )
}

const getSubmitButtonProps = (
  side: OrderSide,
  orderType: OrderType,
  resetInputs: () => void,
): SubmitButtonProps => {
  const direction = side === "bid" ? "Long" : "Short"

  const { txDescription, idleText, pendingText } = (() => {
    switch (orderType) {
      case "market":
        return {
          txDescription: "new market order",
          idleText: `Open ${direction} Position`,
          pendingText: `Opening ${direction} Position ...`,
        }

      case "limit":
        return {
          txDescription: "new limit order",
          idleText: `Open ${direction} Position`,
          pendingText: `Opening ${direction} Position ...`,
        }

      case "stop-limit":
        return {
          txDescription: "new stop limit order",
          idleText: `Create ${direction} Stop Limit Order`,
          pendingText: `Creating ${direction} Stop Limit Order ...`,
        }
    }
  })()

  return {
    simEnabled: true,
    onSuccess: resetInputs,
    txDescription,
    idleText,
    pendingText,
  }
}
