import { type CaipNetworkId } from "@reown/appkit-common"
import { useAppKit, useAppKitState } from "@reown/appkit/react"

import * as sk from "silverkoi"

import { getChainLogo, tw2 } from "~/utils"
import { Button } from "./Button"

export const NetworkButton = () => {
  const { selectedNetworkId } = useAppKitState()
  const chainId = selectedNetworkId ? getChainId(selectedNetworkId) : undefined
  const chain = chainId ? sk.getChain(chainId) : undefined

  const { open } = useAppKit()

  const onClick = () => {
    open({ view: "Networks" })
  }

  const text = chain ? sk.getChainName(chain) : "Select Network"

  return (
    <Button
      className={
        `flex flex-row px-3 ${tw2("button-navbar")} ` +
        `bg-neutral-07 rounded-[1rem] border-neutral-05 border-[0.1rem]`
      }
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        <img src={getChainLogo({ chain })} className="w-[0.75rem] h-[0.75rem]" />
        <div className={`${tw2("font-navbar-button")} font-semibold`}>{text}</div>
      </div>
    </Button>
  )
}

const getChainId = (networkId: CaipNetworkId): bigint => {
  return BigInt(networkId.split(":")[1])
}
