import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { ZodError, z } from "zod"

import { Chain } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { CampaignId, apiUrl } from "../../utils"
import { QueryFnArgs, QueryKey } from "./types"

interface UserCampaignStatsArgs {
  chain: Chain
  address?: string
  campaignId?: CampaignId
}

export function useUserCampaignStats(args: UserCampaignStatsArgs) {
  const queryKey: [QueryKey<UserCampaignStatsArgs>] = [
    { tag: { type: "api", key: "user-stats" }, args },
  ]
  const url = `${apiUrl()}/v0/campaign/user`

  const queryFn = async ({ queryKey }: QueryFnArgs<UserCampaignStatsArgs>) => {
    try {
      const { chain, address, campaignId } = queryKey[0].args
      if (!address || !campaignId) return null // eslint-disable-line no-null/no-null
      const params = {
        chain,
        address,
        campaign_id: campaignId,
      }
      const response = await axios.get(url, { params })
      return UserStatsSchema.parse(response.data)
    } catch (err: unknown) {
      if (err instanceof ZodError) {
        throw err
      } else {
        console.error(err)
      }
    }
  }

  const query = useQuery({
    queryKey,
    queryFn,
    refetchInterval: 5000,
    enabled: !!args.address && !!args.campaignId,
    throwOnError: false,
  })
  return { ...query, data: query.data ?? undefined }
}

interface UserStatsArgs {
  chain: Chain
  address?: string
  startDateUtc?: string
  endDateUtc?: string
}

export function useUserStats(args: UserStatsArgs) {
  const queryKey: [QueryKey<UserStatsArgs>] = [{ tag: { type: "api", key: "user-stats" }, args }]
  const url = `${apiUrl()}/v0/user`

  const queryFn = async ({ queryKey }: QueryFnArgs<UserStatsArgs>) => {
    try {
      const { chain, address, startDateUtc, endDateUtc } = queryKey[0].args
      if (!address || !startDateUtc || !endDateUtc) return null // eslint-disable-line no-null/no-null
      const params = {
        chain,
        address,
        start_date_utc: startDateUtc,
        end_date_utc: endDateUtc,
      }
      const response = await axios.get(url, { params })
      return UserStatsSchema.parse(response.data)
    } catch (err: unknown) {
      if (err instanceof ZodError) {
        throw err
      } else {
        console.error(err)
      }
    }
  }

  const query = useQuery({
    queryKey,
    queryFn,
    refetchInterval: 5000,
    enabled: !!args.address && !!args.startDateUtc && !!args.endDateUtc,
    throwOnError: false,
  })
  return { ...query, data: query.data ?? undefined }
}

type AllTimeUserStatsArgs = Pick<UserStatsArgs, "chain" | "address">

export function useAllTimeUserStats(args: AllTimeUserStatsArgs) {
  return useUserStats({
    ...args,
    startDateUtc: "2024-01-01",
    endDateUtc: "2100-01-01",
  })
}

export const UserStatsSchema = z
  .object({
    volumeX12: z.bigint().or(z.number()),
    realizedPnlX6: z.bigint().or(z.number()),
    points: z.bigint().or(z.number()),
  })
  .transform((v) => {
    return {
      volume: BigDecimal.fromRaw(BigInt(v.volumeX12), 12),
      realizedPnl: BigDecimal.fromRaw(BigInt(v.realizedPnlX6), 6),
      points: BigInt(v.points),
    }
  })

export type UserStats = z.infer<typeof UserStatsSchema>

export const LeaderboardEntrySchema = z
  .object({
    address: z.string(),
    rank: z.number(),
    value: z
      .bigint()
      .or(z.number())
      .transform((v) => BigInt(v)),
    valueDecimals: z.number(),
  })
  .transform((v) => {
    return {
      address: v.address,
      rank: v.rank,
      value: BigDecimal.fromRaw(v.value, v.valueDecimals),
    }
  })

export type LeaderboardEntry = z.output<typeof LeaderboardEntrySchema>

export const LeaderboardStatsSchema = z.object({
  volumeRanking: z.array(LeaderboardEntrySchema),
  pnlRanking: z.array(LeaderboardEntrySchema),
  pointRanking: z.array(LeaderboardEntrySchema),
})

export const CampaignStatsSchema = z.object({
  startUtcDate: z.string(),
  endUtcDate: z.string(),
  leaderboard: LeaderboardStatsSchema,
})

export type CampaignStats = z.output<typeof CampaignStatsSchema>

interface CampaignStatsArgs {
  chain: Chain
  campaignId?: CampaignId
}

export function useCampaignStats(args: CampaignStatsArgs) {
  const queryKey: [QueryKey<CampaignStatsArgs>] = [
    { tag: { type: "api", key: "campaign-stats" }, args },
  ]
  const url = `${apiUrl()}/v0/campaign`

  const queryFn = async ({ queryKey }: QueryFnArgs<CampaignStatsArgs>) => {
    try {
      const { chain, campaignId } = queryKey[0].args
      if (!campaignId) return null // eslint-disable-line no-null/no-null
      const params = { chain, campaign_id: campaignId }
      const response = await axios.get(url, { params })
      const stats = CampaignStatsSchema.parse(response.data)
      return stats
    } catch (err: unknown) {
      if (err instanceof ZodError) {
        throw err
      } else {
        console.error(err)
      }
    }
  }

  const query = useQuery({
    queryKey,
    queryFn,
    refetchInterval: 5000,
    enabled: !!args.campaignId,
    throwOnError: false,
  })
  return { ...query, data: query.data ?? undefined }
}

export interface RankedUserStats extends UserStats {
  volumeRankStr: string
  pnlRankStr: string
  pointRankStr: string
}

interface UserRankedStatsArgs extends CampaignStatsArgs {
  address?: string
}

export function useUserRankedStats(args: UserRankedStatsArgs) {
  const campaignStatsArgs = {
    chain: args.chain,
    campaignId: args.campaignId,
  }
  const { data: campaignStats, isLoading: campaignStatsIsLoading } =
    useCampaignStats(campaignStatsArgs)

  const { data: userStats, isLoading: userStatsIsLoading } = useUserCampaignStats(args)

  if (userStatsIsLoading || campaignStatsIsLoading) {
    return { data: undefined, isLoading: true }
  }
  const address = args.address
  if (!address || !userStats || !campaignStats) {
    return { data: undefined, isLoading: false }
  }

  const maxAddresses = 100 // WARNING: This is hard-coded in the api backend

  const getRank = (entries: LeaderboardEntry[]) => {
    for (const entry of entries) {
      if (entry.address.toLowerCase() === address.toLowerCase()) {
        return `#${entry.rank}`
      }
    }
    if (entries.length === maxAddresses) {
      return `> #${maxAddresses}`
    } else {
      return `-`
    }
  }
  const volumeRank = getRank(campaignStats.leaderboard.volumeRanking)
  const pnlRank = getRank(campaignStats.leaderboard.pnlRanking)
  const pointRank = getRank(campaignStats.leaderboard.pointRanking)

  return {
    data: {
      ...userStats,
      volumeRankStr: volumeRank,
      pnlRankStr: pnlRank,
      pointRankStr: pointRank,
    },
    isLoading: false,
  }
}

type AllTimeStatsArg = {
  chain: Chain
}

export function useAllTimeStats(args: AllTimeStatsArg) {
  const queryKey: [QueryKey<AllTimeStatsArg>] = [
    { tag: { type: "api", key: "all-time-stats" }, args },
  ]
  const url = `${apiUrl()}/v0/stats`

  const queryFn = async ({ queryKey }: QueryFnArgs<AllTimeStatsArg>) => {
    try {
      const { chain } = queryKey[0].args
      const params = { chain }
      const response = await axios.get(url, { params })
      const data = AllTimeStatsSchema.parse(response.data)
      // We add 50M to account for volume from previously deployed contracts.
      return { ...data, totalVolume: data.totalVolume + 50_000_000n }
    } catch (err: unknown) {
      if (err instanceof ZodError) {
        throw err
      } else {
        console.error(err)
      }
    }
  }

  const query = useQuery({
    queryKey,
    queryFn,
    refetchInterval: 5000,
    throwOnError: false,
  })
  return { ...query, data: query.data ?? undefined }
}

const AllTimeStatsSchema = z
  .object({
    total_users: z.bigint().or(z.number()),
    total_trades: z.bigint().or(z.number()),
    total_volume: z.bigint().or(z.number()),
  })
  .transform((v) => {
    return {
      totalUsers: BigInt(v.total_users),
      totalTrades: BigInt(v.total_trades),
      totalVolume: BigInt(v.total_volume),
    }
  })

export type AllTimeStats = z.infer<typeof AllTimeStatsSchema>
