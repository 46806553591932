import axios from "axios"
import type {
  Bar,
  DatafeedConfiguration,
  ErrorCallback,
  HistoryCallback,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SearchSymbolsCallback,
  SubscribeBarsCallback,
  SymbolResolveExtension,
} from "../charting_library"

import { Chain, validateChain } from "silverkoi"

import * as priceApi from "~/api/price"
import * as skoi from "~/api/silverkoi"

const SUPPORTED_RESOLUTIONS = [
  "1" as ResolutionString,
  "5" as ResolutionString,
  "10" as ResolutionString,
  "60" as ResolutionString,
  "240" as ResolutionString,
  "360" as ResolutionString,
  "1D" as ResolutionString,
  "1W" as ResolutionString,
]

const configurationData: DatafeedConfiguration = {
  supported_resolutions: SUPPORTED_RESOLUTIONS,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  exchanges: [{ value: "SilverKoi", name: "Silver Koi", desc: "Silver Koi" }],
  symbols_types: [{ name: "Perps", value: "persp" }],
}

interface SubscriptionHandler {
  uid: string
  callback: SubscribeBarsCallback
}

interface Subscription {
  id: string
  isOracle: boolean
  chain: Chain
  symbol: string
  resolution: ResolutionString
  lastBar: Bar
  handlers: SubscriptionHandler[]
}

// Map from (symbol, resolution) => streaming subscription info
const subscriptionsCache = new Map<string, Subscription>()
const websocketsCache = new Map<string, WebSocket>()
const lastBarsCache = new Map<string, Bar>()

export const DATAFEED: IExternalDatafeed & IDatafeedChartApi = {
  onReady: (callback: OnReadyCallback) => {
    setTimeout(() => callback(configurationData))
  },

  searchSymbols: (
    _userInput: string,
    _exchange: string,
    _symbolType: string,
    _onResult: SearchSymbolsCallback,
  ) => {
    throw new Error("not implemented")
  },

  resolveSymbol: async (
    symbolEncoding: string,
    onResolve: ResolveCallback,
    onError: ErrorCallback,
    _extension?: SymbolResolveExtension,
  ) => {
    try {
      const { isOracle, symbol } = decodeSymbolEncoding(symbolEncoding)
      const symbolMeta = skoi.getSymbolMeta(symbol)
      const config = skoi.getSymbolConfig(symbol)
      const pricescale = 10 ** config.decimals
      if (isOracle) {
        const symbolInfo: LibrarySymbolInfo = {
          name: symbolEncoding,
          full_name: symbolMeta.name,
          listed_exchange: "",
          format: "price",
          description: symbolMeta.displayName,
          type: "Oracle",
          session: "24x7",
          timezone: "Etc/UTC",
          exchange: "Silver Koi",
          minmov: 1,
          pricescale,
          has_intraday: true,
          visible_plots_set: "ohlcv",
          has_weekly_and_monthly: false,
          supported_resolutions: SUPPORTED_RESOLUTIONS,
          volume_precision: 2,
          data_status: "streaming",
        }
        setTimeout(() => {
          onResolve(symbolInfo)
        }, 0)
      } else {
        const symbolInfo: LibrarySymbolInfo = {
          name: symbolEncoding,
          full_name: symbolMeta.name,
          listed_exchange: "",
          format: "price",
          description: symbolMeta.displayName,
          type: "Perpetuals",
          session: "24x7",
          timezone: "Etc/UTC",
          exchange: "Silver Koi",
          minmov: 1,
          pricescale,
          has_intraday: true,
          visible_plots_set: "ohlcv",
          has_weekly_and_monthly: false,
          supported_resolutions: SUPPORTED_RESOLUTIONS,
          volume_precision: 2,
          data_status: "streaming",
        }
        setTimeout(() => {
          onResolve(symbolInfo)
        }, 0)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setTimeout(() => {
        onError(err.message)
      }, 0)
    }
  },

  getBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: ErrorCallback,
  ) => {
    const symbolEncoding = symbolInfo.name
    const { isOracle, chain, symbol } = decodeSymbolEncoding(symbolEncoding)
    console.debug(
      `get bars: symbolEncoding=${symbolEncoding} symbol=${symbol} ` +
        `resolution=${resolution} periodParams=${periodParams}`,
    )
    const params = {
      chain,
      symbol,
      end_timestamp: periodParams.to,
      count_back: periodParams.countBack,
      resolution: resolution,
      is_oracle: isOracle,
    }
    const url = priceApi.pricefeedHttpUrl()
    // TODO: Define respons type
    let response: any // eslint-disable-line @typescript-eslint/no-explicit-any
    // TODO: Handle connection reset
    try {
      response = await axios.get(`${url}/price`, { params })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e)
      onError(e)
      return
    }

    const bars: Bar[] = []
    for (const elem of response.data) {
      // TODO: proper validation
      bars.push({
        time: elem.time * 1000,
        open: elem.open,
        high: elem.high,
        low: elem.low,
        close: elem.close,
        volume: elem.volume,
      })
    }

    const noData = bars.length === 0
    if (!noData) {
      const id = `${symbolEncoding}:${resolution}`
      lastBarsCache.set(id, { ...bars[bars.length - 1] })
    }

    onResult(bars, { noData })
  },

  subscribeBars: async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberUID: string,
    _onResetCacheNeededCallback: () => void,
  ) => {
    const symbolEncoding = symbolInfo.name
    const { isOracle, chain, symbol } = decodeSymbolEncoding(symbolEncoding)
    console.debug(
      `subscribe bars: symbolEncoding=${symbolEncoding} resolution=${resolution} ` +
        `uid=${subscriberUID}`,
    )

    const id = `${symbolEncoding}:${resolution}`
    const handler: SubscriptionHandler = {
      uid: subscriberUID,
      callback: onRealtimeCallback,
    }

    let subscription: Subscription | undefined = subscriptionsCache.get(id)
    if (subscription !== undefined) {
      // Already subscribed to channel. Use existing subscription.
      subscription.handlers.push(handler)
      return
    }

    const lastBar = lastBarsCache.get(id)
    if (!lastBar) {
      throw new Error(`missing price data for ${lastBar}`)
    }

    subscription = {
      id: id,
      isOracle: isOracle,
      chain: chain,
      symbol: symbol,
      resolution,
      lastBar: lastBar,
      handlers: [handler],
    }
    subscriptionsCache.set(id, subscription)

    // Create websocket price feed connection.
    const url = priceApi.pricefeedWsUrl(chain, symbol, resolution, lastBar.time / 1000, isOracle)
    const ws = new WebSocket(url)
    ws.onopen = () => {
      console.debug("price feed connected:", url)
    }
    ws.onclose = () => {
      console.debug("price feed disconnected:", url)
      // TODO: Reconnect
    }
    ws.onmessage = (evt) => {
      const data = JSON.parse(evt.data)
      console.debug(`[subscriberUID=${subscriberUID}]: received`, evt)
      if (!data) {
        console.error(`[subscriberUID=${subscriberUID}]: received null`, evt)
        return
      }

      // TODO: validate data

      let id = `${data.chain.string_id}:${data.symbol}:${data.resolution}`
      if (data.is_oracle) {
        id = `ORACLE:${id}`
      }
      const subscription = subscriptionsCache.get(id)
      if (!subscription) {
        console.error(`[subscriberUID=${subscriberUID}]: cannot find subscription for id: ${id}`)
        return
      }

      const bar = {
        time: data.time * 1000,
        open: data.open,
        high: data.high,
        low: data.low,
        close: data.close,
      }

      subscription.lastBar = bar
      subscription.handlers.forEach((handler) => handler.callback(bar))
    }
    websocketsCache.set(id, ws)
  },

  unsubscribeBars: async (subscriberUID: string) => {
    console.debug(`unsubscribe bars: uid=${subscriberUID}`)

    // Find subscription with id === subscriberUID
    for (const id of subscriptionsCache.keys()) {
      const subscription = subscriptionsCache.get(id)!
      const handlerIndex = subscription.handlers.findIndex(
        (handler) => handler.uid === subscriberUID,
      )

      if (handlerIndex !== -1) {
        // Remove from handlers
        subscription.handlers.splice(handlerIndex, 1)

        // Unsubscribe from the channel if it is the last handler
        if (subscription.handlers.length === 0) {
          const ws = websocketsCache.get(id)
          if (ws) {
            ws.close()
          }
          subscriptionsCache.delete(id)
          websocketsCache.delete(id)
          break
        }
      }
    }
  },
}

export const encodeSymbolEncoding = (chain: Chain, symbol: string): string => {
  return `${chain}:${symbol}`
}

export const decodeSymbolEncoding = (
  symbolEncoding: string,
): {
  isOracle: boolean
  chain: Chain
  symbol: string
} => {
  const parts = symbolEncoding.split(":")
  if (parts.length > 3) {
    throw new Error(`invalid symbol: ${symbolEncoding}`)
  }
  if (parts.length === 3) {
    if (parts[0] !== "ORACLE") {
      throw new Error(`invalid symbol: ${symbolEncoding}`)
    }
    const chain = validateChain(parts[1])
    const symbol = parts[2]
    return { isOracle: true, chain, symbol }
  } else {
    const chain = validateChain(parts[0])
    const symbol = parts[1]
    return { isOracle: false, chain, symbol }
  }
}
