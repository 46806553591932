import { useEffect } from "react"

import { CurrentSizeAndCollateralCards } from "~/components/CurrentSizeAndCollateralCards"
import { ScrollableArea } from "~/components/ScrollableArea"
import { ToggledPriceInput } from "~/components/ToggledPriceInput"
import { TradeWarning } from "~/components/TradeWarning"
import { TransactionSummary } from "~/components/TransactionSummary"
import { TriggerPriceInput } from "~/components/TriggerPriceInput"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useDefaultLeverage, useTradeContext, useTradeContextActions } from "~/hooks"
import { MobileMainButton } from "./MobileMainButton"
import { EditTriggerViewData } from "./MobileMainPageContext"

type Props = {
  data: EditTriggerViewData
}

export const MobileEditTriggerView = ({ data }: Props) => {
  const { trigger, position } = data.trigger
  return (
    <TradeContextProvider symbol={trigger.symbolMeta.symbol} defaultPosition={position}>
      <Body data={data} />
      <div className="grow" />
      <MobileMainButton />
    </TradeContextProvider>
  )
}

const Body = ({ data }: Props) => {
  const { fromAbove, position, trigger } = data.trigger

  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const { limitPrice } = useTradeContextStore((s) => ({
    limitPrice: s.input.limitPrice,
  }))
  const {
    reset,
    setInputMode,
    setLeverage,
    setOrderType,
    setSide,
    setTriggerIsFromAbove,
    setTriggerPrice,
    setLimitPrice,
    setReferenceTrigger,
  } = useTradeContextActions()

  const referenceLimitPrice = fromAbove ? trigger.fromAboveLimitPrice : trigger.fromBelowLimitPrice
  const referenceIsMarket = referenceLimitPrice.isZero()
  const defaultLeverage = useDefaultLeverage(position)

  useEffect(() => {
    reset()
    setInputMode("EditTrigger")
    setOrderType("stop-limit")
    setSide(trigger.side)
    setTriggerIsFromAbove(fromAbove)
    setLeverage(defaultLeverage)
    const triggerPrice = fromAbove ? trigger.fromAboveTriggerPrice : trigger.fromBelowTriggerPrice
    setTriggerPrice({ value: triggerPrice, text: triggerPrice.toStringTrimmed() })
    const limitPrice = referenceIsMarket ? undefined : referenceLimitPrice
    setLimitPrice({ value: limitPrice, text: limitPrice?.toStringTrimmed() ?? "" })
    setReferenceTrigger(trigger)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={"flex flex-col w-full h-full px-3 bg-black rounded-lg overflow-hidden"}>
      <div className="shrink-0 h-2" />
      <CurrentSizeAndCollateralCards position={position} />
      <div className="shrink-0 h-3" />

      <ScrollableArea>
        <TriggerPriceInput withDirectionText={false} />
        <div className="shrink-0 h-5" />

        <ToggledPriceInput
          label={"Limit Price"}
          input={limitPrice}
          setInput={setLimitPrice}
          defaultEnabled={!referenceIsMarket}
        />

        <div className="shrink-0 h-3" />

        <TradeWarning />

        <TransactionSummary triggerContext={{ side: trigger.side, size: trigger.size }} />
      </ScrollableArea>
    </div>
  )
}
