import { css } from "@emotion/css"

import { getIcon, tw } from "../utils"

type Props = {
  sizeRem: number
  excludeText?: boolean
  hover?: boolean
}

export const Logo = ({ sizeRem, excludeText, hover: _hover }: Props) => {
  const heightRem = sizeRem
  const fontSizeRem = (heightRem / 5) * 3
  const includeText = !(excludeText ?? false)
  const hover = _hover ?? true

  const textCs = css`
    font-size: ${fontSizeRem}rem;
  `
  const iconCs = css`
    height: ${heightRem.toFixed(4)}rem;
  `

  const gapRem = fontSizeRem / 4
  const gapWidthCs = css`
    width: ${gapRem}rem;
  `

  return (
    <div className={`flex ${textCs}`}>
      <img src={getIcon("silverkoi")} className={`object-scale-down ${iconCs}`} />
      {includeText && (
        <>
          <div className={`shrink-0 ${gapWidthCs}`} />
          <LogoText hover={hover} />
        </>
      )}
    </div>
  )
}

const LogoText = ({ hover }: { hover?: boolean }) => {
  const hoverCs = hover ? tw("hover:font-gradient") : ""
  return (
    <div
      className={`flex text-white font-['Inter'] font-semibold leading-[1.2em] tracking-[-0.025em] mt-[0.3em] ${hoverCs} whitespace-nowrap`}
    >
      Silver Koi
    </div>
  )
}
