import React, { useEffect, useRef } from "react"
import * as TradingView from "../charting_library"
import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  LanguageCode,
  PineJS,
  RawStudyMetaInfoId,
  ResolutionString,
  StudyLinePlotInfo,
} from "../charting_library"

import { Chain } from "silverkoi"

import { useScreenLayout } from "../hooks"
import { DATAFEED, encodeSymbolEncoding } from "./datafeed"

export interface TVChartContainerProps {
  chain: Chain
  symbol: string
}

const getLanguageFromURL = (): LanguageCode | undefined => {
  const regex = new RegExp("[\\?&]lang=([^&#]*)")
  const results = regex.exec(location.search)
  return results ? (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode) : undefined
}

export const TVChartContainer = ({ chain, symbol }: TVChartContainerProps) => {
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>

  const layout = useScreenLayout()
  const isMobile = layout === "mobile"

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: encodeSymbolEncoding(chain, symbol),
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      datafeed: DATAFEED,
      interval: "5" as ChartingLibraryWidgetOptions["interval"],
      container: chartContainerRef.current,
      library_path: "/charting_library/",

      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "header_compare",
        "header_fullscreen_button",
        "header_saveload",
        "header_settings",
        "header_symbol_search",
        "show_chart_property_page",
        "use_localstorage_for_settings",
      ],
      enabled_features: ["hide_left_toolbar_by_default", "iframe_loading_compatibility_mode"],
      loading_screen: {
        foregroundColor: "#2A85FF",
        backgroundColor: "#1A1D1F",
      },
      fullscreen: false,
      autosize: true,
      toolbar_bg: "#1A1D1F",
      theme: "dark",
      overrides: {
        "paneProperties.background": "#1A1D1F",
        "paneProperties.backgroundType": "solid",
        "paneProperties.vertGridProperties.color": "#1A1D1F",
        "scalesProperties.crosshairLabelBgColorLight": "#1A1D1F",
        "scalesProperties.fontSize": 10,
      },
      custom_css_url: "/charting_library/custom_theme.css",
      time_frames: [
        {
          text: "1Y",
          resolution: "1D" as ResolutionString,
          description: "1 Year",
        },
        {
          text: "3M",
          resolution: "1D" as ResolutionString,
          description: "3 Months",
        },
        {
          text: "1M",
          resolution: "360" as ResolutionString,
          description: "1 Month",
        },
        {
          text: "2W",
          resolution: "240" as ResolutionString,
          description: "2 Weeks",
        },
        {
          text: "1W",
          resolution: "60" as ResolutionString,
          description: "1 Week",
        },
        {
          text: "1D",
          resolution: "5" as ResolutionString,
          description: "1 Day",
        },
        {
          text: "6H",
          resolution: "5" as ResolutionString,
          description: "6 Hours",
        },
        {
          text: "3H",
          resolution: "1" as ResolutionString,
          description: "3 Hours",
        },
        {
          text: "1000y",
          resolution: "1D" as ResolutionString,
          description: "All",
          title: "All",
        },
      ],
      custom_indicators_getter: (PineJS: PineJS): Promise<readonly CustomIndicator[]> => {
        return Promise.resolve([getOracleIndicatorTemplate(PineJS)])
      },
    }

    const widget = new TradingView.widget(widgetOptions)

    widget.onChartReady(() => {
      // Add oracle price as custom indicator. Hide by default.
      widget.activeChart().createStudy("Oracle", true, false, {}, { visible: false })

      // Set default time frame to 1D.
      //const chart = widget.chart();
      //chart.setTimeFrame({
      //  val: { type: "period-back", value: "1D" },
      //  res: "5",
      //});
    })

    return () => {
      widget.remove()
    }
  })

  const heightCs = isMobile ? "h-full" : "h-full"

  return (
    <div className={`grow flex w-full ${heightCs} min-w-0 pl-px pb-px pr-px`}>
      <div
        className="grow flex flex-col w-full h-full min-h-0 min-h-0 bg-black"
        ref={chartContainerRef}
      />
    </div>
  )
}

const getOracleIndicatorTemplate = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Oracle",
    metainfo: {
      _metainfoVersion: 51,
      id: "oracle@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "Oracle",
      shortDescription: "Oracle",
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 5,
      },
      plots: [
        {
          id: "plot_0",
          type: "line",
        } as StudyLinePlotInfo,
      ],
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 1, // Make the line thinner
            plottype: 0, // Plot type is Line
            trackPrice: true, // Show price line
            color: "#FFFFFF", // Set the plotted line color to white
          },
        },
        inputs: {},
      },
      styles: {
        plot_0: {
          title: "Oracle",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context
        this._input = inputCallback
        const symbol = "ORACLE:" + PineJS.Std.ticker(this._context)
        this._context.new_sym(symbol, PineJS.Std.period(this._context))
        context.new_sym(symbol, PineJS.Std.period(this._context))
      }

      this.main = function (context, inputCallback) {
        this._context = context
        this._input = inputCallback
        // Select the main symbol
        this._context.select_sym(0)
        const mainSymbolTime = this._context.new_var(this._context.symbol.time)

        // Select the secondary symbol
        this._context.select_sym(1)
        const secondarySymbolTime = this._context.new_var(this._context.symbol.time)

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolClose = this._context.new_var(PineJS.Std.close(this._context))
        const alignedClose = secondarySymbolClose.adopt(secondarySymbolTime, mainSymbolTime, 1)

        // Select the main symbol again
        this._context.select_sym(0)

        return [alignedClose]
      }
    },
  }
}
