import { CSSProperties } from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import { useAccount } from "wagmi"
import { z } from "zod"

import { BigDecimal } from "silverkoi/math"

import {
  Notification,
  NotificationsPage,
  useChainDetail,
  useIsMobile,
  useNotifications,
} from "~/hooks"
import { formatTimestamp, formatValue, getIcon, getSymbolLogo, tw2 } from "~/utils"

interface NotificationListProps {
  pageSize?: number
  limit?: number
}

export const NotificationList = ({ pageSize: _pageSize, limit: _limit }: NotificationListProps) => {
  const isMobile = useIsMobile()
  const { chain } = useChainDetail()
  const { address } = useAccount()

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useNotifications({
    chain,
    address,
  })
  const pages: NotificationsPage[] = data?.pages ?? []
  const notifications = pages.map((p) => p.notifications).flat(1)

  const itemRenderer = ({ index, style }: { index: number; style: CSSProperties }) => {
    if (isItemLoaded(index)) {
      const notification = notifications[index]
      return (
        <div style={style}>
          {isMobile ? (
            <MobileNotificationItem notification={notification} />
          ) : (
            <NotificationItem notification={notification} />
          )}
        </div>
      )
    } else {
      return (
        <div style={style}>
          <Loading />
        </div>
      )
    }
  }

  // TODO: if hasNextPage is true, add one to notifications.length and show a
  // special loading card for the last item.
  const itemCount = hasNextPage ? notifications.length + 1 : notifications.length

  const loadNextPage = async () => {
    await fetchNextPage()
  }

  const loadMoreItems = isFetchingNextPage ? () => {} : loadNextPage

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < notifications.length

  const itemHeightPx = isMobile ? 100 : 90

  if (isLoading) {
    return <Loading />
  } else if (notifications.length === 0) {
    return <NoData />
  } else {
    return (
      <div className="flex w-full h-full">
        <div style={{ flex: "1 1 auto" }}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      className="scrollbar-thin"
                      height={height}
                      width={width}
                      itemSize={itemHeightPx}
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                    >
                      {itemRenderer}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )
            }}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

const Loading = () => (
  <div className="flex flex-col h-full items-center justify-center py-3 gap-5">
    <img src={getIcon("loading-spin-blue")} className="w-12 h-12" />
  </div>
)

const NoData = () => (
  <div className="flex flex-col h-full items-center justify-center py-3 gap-5 text-[1.25rem] text-neutral-04 font-semibold">
    <img src={getIcon("magnifying-glass")} className="w-[5rem] h-[5rem]" />
    Nothing to see!
  </div>
)

const getSubjectColorTw = (eventName: string): string => {
  switch (eventName) {
    case "Position_Liquidated":
      return "bg-orange"
    default:
      return "bg-light-purple"
  }
}

const getContent = (notification: Notification): string => {
  const { event_name: eventName, symbol, data } = notification.event
  try {
    switch (eventName) {
      case "Position_Liquidated": {
        const event = PositionLiquidatedEventSchema.parse(data)
        return `Your ${symbol} position with ID ${event.positionId} was liquidated.`
      }
      case "Trigger_Activated": {
        const event = TriggerActivatedEventSchema.parse(data)
        return `Your ${symbol} trigger with ID ${event.triggerId} was activated.`
      }
      case "Trigger_OrderFailed": {
        const event = TriggerOrderFailedEventSchema.parse(data)
        return `Your ${symbol} trigger order with ID ${event.triggerId} failed.`
      }
      case "OrderBook_TakerOrderFilled": {
        const event = OrderBookTakerOrderFilledEventSchema.parse(data)
        const sizeStr = formatValue(event.executedSize)
        const priceStr = formatValue(event.price, { minDecimals: 2, dollar: true })
        return `${sizeStr} units of ${symbol} was filled at ${priceStr}`
      }
      case "OrderBook_MakerOrderFilled": {
        const event = OrderBookMakerOrderFilledEventSchema.parse(data)
        const sizeStr = formatValue(event.executedSize)
        const priceStr = formatValue(event.price, { minDecimals: 2, dollar: true })
        return `${sizeStr} units of ${symbol} was filled at ${priceStr}`
      }
      case "MarketOperationFailed": {
        return `One of your ${symbol} operation failed. Please contact support for help.`
      }
    }
  } catch (err: unknown) {
    console.error(err)
  }
  return notification.content
}

const mobileSymbolFontCs = `text-[0.9375rem] leading-[1.5rem] tracking-[-0.01em]`
const mobileTimestampFontCs = `text-[0.8125rem] leading-[1rem] tracking-[-0.01em]`
const mobileSubjectFontCs = `text-[0.75rem] tracking-[-0.01em]`
const mobileContentFontCs = `text-[0.75rem] leading-[1.25rem] tracking-[-0.01em]`

const MobileNotificationItem = ({ notification }: { notification: Notification }) => {
  const subjectBgColorCs = getSubjectColorTw(notification.event.event_name)
  const subjectCs = `${mobileSubjectFontCs} font-semibold text-neutral-07 border-2 rounded-lg px-2 ${subjectBgColorCs}`
  const content = getContent(notification)

  return (
    <div className={`flex w-full h-full px-3 py-3 items-center`}>
      <div
        className={
          "flex flex-col w-full bg-neutral-07 px-4 py-3 " +
          "items-start rounded-2xl border-2 border-neutral-06"
        }
      >
        <div className="flex w-full gap-2">
          <div>
            <img
              src={getSymbolLogo(notification.event.symbol)}
              className="w-10 h-10 rounded-3xl align-middle cursor-pointer"
            />
          </div>

          <div className="flex flex-col grow shrink-0">
            <div className="flex shrink-0">
              <div className={`${mobileSymbolFontCs} text-white font-bold`}>
                {notification.event.symbol}
              </div>

              <div className="grow" />

              <div className={subjectCs}>{notification.subject}</div>
            </div>

            <div className={`${mobileTimestampFontCs} text-neutral-04`}>
              {formatTimestamp(notification.timestamp)}
            </div>
          </div>
        </div>

        <div className="shrink-0 h-2" />

        <div className={`${mobileContentFontCs} text-white`}>{content}</div>
      </div>
    </div>
  )
}

const NotificationItem = ({ notification }: { notification: Notification }) => {
  const subjectBgColorTw = getSubjectColorTw(notification.event.event_name)
  const subjectTw = `${tw2("font-notification")} text-black border-2 rounded-lg px-2 font-semibold ${subjectBgColorTw}`
  const content = getContent(notification)

  return (
    <div className="flex h-full px-3 py-1 items-center">
      <div
        className={
          "h-full grow grid grid-cols-[40%_55%] bg-midnight-black p-4 " +
          "items-start justify-between rounded-2xl border-2 border-neutral-06 " +
          tw2("font-notification")
        }
      >
        <div className="flex flex-col h-full justify-between">
          <div className="flex items-center grid grid-cols-[30%_70%] gap-6">
            <div className="flex flex-row items-center gap-2">
              <img
                src={getSymbolLogo(notification.event.symbol)}
                className="w-5 h-5 rounded-3xl align-middle cursor-pointer"
              />
              <div className={`${tw2("font-notification")} text-white font-semibold`}>
                {notification.event.symbol}
              </div>
            </div>
            <div className="flex">
              <div className={subjectTw}>{notification.subject}</div>
            </div>
          </div>

          <div className={`flex items-end ${tw2("font-notification-timestamp")}`}>
            {formatTimestamp(notification.timestamp)}
          </div>
        </div>

        <div className="text-white">{content}</div>
      </div>
    </div>
  )
}

const PositionLiquidatedEventSchema = z.object({
  positionId: z.number().transform(BigInt),
})

const TriggerActivatedEventSchema = z.object({
  triggerId: z.number().transform(BigInt),
})

const TriggerOrderFailedEventSchema = z.object({
  triggerId: z.number().transform(BigInt),
  reason: z.string(),
})

const OrderBookMakerOrderFilledEventSchema = z
  .object({
    orderId: z.number().transform(BigInt),
    positionSubId: z.number().transform(BigInt),
    isBid: z.boolean(),
    executedSizeX5: z.number().transform(BigInt),
    executedNotionalX12: z.number().transform(BigInt),
    takerOrderId: z.number().transform(BigInt),
    removed: z.boolean(),
  })
  .transform((v) => {
    return {
      orderId: v.orderId,
      positionId: v.positionSubId,
      isBid: v.isBid,
      side: v.isBid ? "bid" : "ask",
      executedSize: BigDecimal.fromRaw(v.executedSizeX5, 5),
      executedNotional: BigDecimal.fromRaw(v.executedNotionalX12, 12),
      price: BigDecimal.fromRaw(v.executedNotionalX12 / v.executedSizeX5, 7),
      takerOrderId: v.takerOrderId,
      removed: v.removed,
    }
  })

const OrderBookTakerOrderFilledEventSchema = z
  .object({
    orderId: z.number().transform(BigInt),
    positionSubId: z.number().transform(BigInt),
    isBid: z.boolean(),
    executedSizeX5: z.number().transform(BigInt),
    executedNotionalX12: z.number().transform(BigInt),
  })
  .transform((v) => {
    return {
      orderId: v.orderId,
      positionId: v.positionSubId,
      isBid: v.isBid,
      side: v.isBid ? "bid" : "ask",
      executedSize: BigDecimal.fromRaw(v.executedSizeX5, 5),
      executedNotional: BigDecimal.fromRaw(v.executedNotionalX12, 12),
      price: BigDecimal.fromRaw(v.executedNotionalX12 / v.executedSizeX5, 7),
    }
  })
