import React, { useState } from "react"
import Toggle from "react-toggle"

import { TimeInForce } from "silverkoi"

import { useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { DurationUnit } from "~/types"
import { castToDurationUnit, parseBigDecimal, tw2 } from "~/utils"
import { NumberInput } from "./NumberInput"

interface TimeInForceInputProps {
  excludeGtd: boolean
  excludePostOnly?: boolean
}

export const TimeInForceInput = ({ excludeGtd, excludePostOnly }: TimeInForceInputProps) => {
  const { useTradeContextStore } = useTradeContext()
  const tif = useTradeContextStore((s) => s.input.tif)

  const makeTimeInForceTypeComponent = () => {
    switch (tif) {
      case "ioc": {
        return <React.Fragment />
      }
      case "fok": {
        return <React.Fragment />
      }
      case "gtc": {
        return excludePostOnly ? (
          <></>
        ) : (
          <div className="shrink-0 flex flex-col h-fit pt-2">
            <PostOnlyInput />
          </div>
        )
      }
      case "gtd": {
        return (
          <div className="shrink-0 flex flex-col h-fit pt-2 gap-2">
            {excludePostOnly ? <></> : <PostOnlyInput />}
            <CancelAfterInput />
          </div>
        )
      }
    }
  }

  return (
    <>
      <TimeInForceChoice excludeGtd={excludeGtd} />
      {makeTimeInForceTypeComponent()}
    </>
  )
}

const PostOnlyInput = () => {
  const { useTradeContextStore } = useTradeContext()
  const postOnly = useTradeContextStore((s) => s.input.postOnly)
  const { setPostOnly } = useTradeContextActions(useTradeContextStore)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostOnly(e.target.checked)
  }

  return (
    <div className="w-full">
      <div className="flex items-center w-full justify-between">
        <div className={tw2("font-input-label")}>Post Only</div>
        <Toggle defaultChecked={postOnly} icons={false} onChange={onChange} />
      </div>
    </div>
  )
}

const CancelAfterInput = () => {
  const { useTradeContextStore } = useTradeContext()
  const cancelAfter = useTradeContextStore((s) => s.input.cancelAfter)
  const { setCancelAfter } = useTradeContextActions(useTradeContextStore)

  const placeholder = "1"
  const defaultUnit = "day" as const
  const [unit, setUnit] = useState<DurationUnit>(cancelAfter.value?.unit ?? defaultUnit)
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, 1)
    const newDuration = value ? { unit, length: value } : undefined
    setCancelAfter({ value: newDuration, text })
  }

  const onUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const length = cancelAfter.value?.length
    const newUnit = castToDurationUnit(e.target.value)
    const newDuration = length ? { unit: newUnit, length } : undefined
    setCancelAfter({ value: newDuration, text: cancelAfter.text })
    setUnit(newUnit)
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full">
        <div className={tw2("font-input-label")}>Cancel After</div>
        <div className="flex flex-row gap-2">
          <NumberInput
            className={
              "flex w-14 py-[0.25rem] " +
              `${tw2("font-input-value-small")} text-center text-white font-medium ` +
              "border border-neutral-shade-dark2 rounded-lg bg-black " +
              "focus:outline-none"
            }
            placeholder={placeholder}
            value={cancelAfter.text}
            onChange={onValueChange}
          />
          <select
            className={
              "flex text-center w-[75px] py-1 " +
              "border border-neutral-shade-dark2 rounded-lg bg-black focus:outline-none " +
              `${tw2("font-input-value-small")} text-neutral-shades-04-75 font-medium `
            }
            value={unit}
            onChange={onUnitChange}
          >
            <option value="second">Seconds</option>
            <option value="minute">Minutes</option>
            <option value="hour">Hours</option>
            <option value="day">Days</option>
            <option value="week">Weeks</option>
          </select>
        </div>
      </div>
    </div>
  )
}

const TimeInForceChoice = ({ excludeGtd }: { excludeGtd: boolean }) => {
  const { useTradeContextStore } = useTradeContext()
  const tif = useTradeContextStore((s) => s.input.tif)
  const { setPostOnly, setTif } = useTradeContextActions(useTradeContextStore)

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tif = e.target.value as TimeInForce
    setTif(tif)

    if (tif === "ioc" || tif === "fok") {
      setPostOnly(false)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full">
        <div className={tw2("font-input-label")}>Time In Force</div>

        <select
          className={
            "flex text-center w-[8rem] py-[0.25rem] " +
            "border border-neutral-shade-dark2 rounded-lg bg-black focus:outline-none " +
            `text-neutral-shades-04-75 ${tw2("font-input-value-small")} font-medium`
          }
          value={tif}
          onChange={onChange}
        >
          <option value={"ioc"}>Immediate Or Cancel</option>
          <option value={"fok"}>Fill Or Kill</option>
          <option value={"gtc"}>Good Til Canceled</option>
          {excludeGtd ? <></> : <option value={"gtd"}>Good Til Date</option>}
        </select>
      </div>
    </div>
  )
}
