import { useEffect } from "react"

import { PositionSnapshot } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { useDefaultLeverage, useTradeContext, useTradeContextActions } from "~/hooks"
import { TRADING_MODAL_WIDTH, tw2 } from "~/utils"
import { ActionModal } from "./ActionModal"
import { CurrentSizeAndCollateralCards } from "./CurrentSizeAndCollateralCards"
import { LeverageInput } from "./LeverageInput"
import { OrderSizeInput } from "./OrderSizeInput"
import { ScrollableArea } from "./ScrollableArea"
import { SubmitButton } from "./SubmitButton"
import { ToggledPriceInput } from "./ToggledPriceInput"
import { TradeWarning } from "./TradeWarning"
import { TransactionSummary } from "./TransactionSummary"

interface Props {
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  position: PositionSnapshot
}

export const CreateTriggerModal = ({ isOpen, setIsOpen, position }: Props) => {
  return (
    <ActionModal position={position} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Body isOpen={isOpen} setIsOpen={setIsOpen} position={position} />
    </ActionModal>
  )
}

const Body = ({ isOpen, setIsOpen, position }: Props) => {
  const tradeContext = useTradeContext()
  const { symbol, useTradeContextStore } = tradeContext
  const {
    reset,
    setInputMode,
    setOrderType,
    setSide,
    setSlTriggerPrice,
    setTpTriggerPrice,
    setLeverage,
  } = useTradeContextActions()
  const { size, slTriggerPrice, tpTriggerPrice } = useTradeContextStore((s) => ({
    size: s.input.size,
    slTriggerPrice: s.input.slTriggerPrice,
    tpTriggerPrice: s.input.tpTriggerPrice,
  }))

  const defaultSize = position.size.abs()
  const side = position.size.gt(BigDecimal.zero()) ? "ask" : "bid"
  const defaultLeverage = useDefaultLeverage(position)

  useEffect(() => {
    reset()
    setInputMode("CreateSLTP")
    setOrderType("stop-limit")
    setSide(side)
    setLeverage(defaultLeverage)
    setSlTriggerPrice({ value: undefined, text: "" })
    setTpTriggerPrice({ value: undefined, text: "" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const triggerType = (() => {
    const hasTP = tpTriggerPrice.value && !tpTriggerPrice.value.isZero()
    const hasSL = slTriggerPrice.value && !slTriggerPrice.value.isZero()
    if (hasSL && hasTP) {
      return "Two-Sided Trigger"
    } else if (hasSL) {
      return "Stop-Loss Trigger"
    } else if (hasTP) {
      return "Take-Profit Trigger"
    } else {
      return "Trigger"
    }
  })()
  const idleText = `Create ${triggerType}`
  const pendingText = `Creating ${triggerType} ...`

  // NOTE: pr needs to be adjusted if scrollbar width changes
  return (
    <div
      className={"flex flex-col h-full bg-black rounded-lg px-6 py-4"}
      style={{ width: TRADING_MODAL_WIDTH }}
    >
      <div className="flex flex-row justify-center">
        <div className={`${tw2("font-modal-title")} self-center py-3`}>Create SL/TP</div>

        <button
          className={
            "absolute right-4 " + "bg-black justify-center items-center text-white border-none"
          }
          onClick={() => setIsOpen(false)}
        >
          &#x2715;
        </button>
      </div>

      <div className="shrink-0 h-2" />

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <ToggledPriceInput
          label={"TP Trigger Price"}
          input={tpTriggerPrice}
          setInput={setTpTriggerPrice}
          defaultEnabled={true}
        />

        <div className="shrink-0 h-2" />

        <ToggledPriceInput
          label={"SL Trigger Price"}
          input={slTriggerPrice}
          setInput={setSlTriggerPrice}
          defaultEnabled={true}
        />

        <div className="shrink-0 h-2" />

        <OrderSizeInput defaultSize={defaultSize} />

        <div className="shrink-0 h-2" />

        <LeverageInput symbol={symbol} />

        <div className="shrink-0 h-3" />

        <TradeWarning />

        <SubmitButton
          simEnabled={isOpen}
          onSuccess={() => {
            setIsOpen(false)
          }}
          txDescription={"create sltp trigger"}
          idleText={idleText}
          pendingText={pendingText}
        />

        <div className="shrink-0 h-2" />

        <TransactionSummary triggerContext={{ side, size: size.value }} />
      </ScrollableArea>
    </div>
  )
}
