import { PropsWithChildren } from "react"
import ReactModal from "react-modal"
import { useWindowSize } from "usehooks-ts"

import { PositionSnapshot } from "silverkoi"

import { TooltipContextProvider } from "../contexts/TooltipContextProvider"
import { TradeContextProvider } from "../contexts/TradeContextProvider"

interface Props {
  position: PositionSnapshot
  isOpen: boolean
  setIsOpen: (_: boolean) => void
  maxHeight?: string
}

export const ActionModal = ({
  position,
  isOpen,
  setIsOpen,
  maxHeight,
  children,
}: PropsWithChildren<Props>) => {
  const { height: windowHeight } = useWindowSize()
  const height = windowHeight * 0.75

  const onRequestClose = () => {
    setIsOpen(false)
  }

  return (
    <TradeContextProvider symbol={position.symbolMeta.symbol} defaultPosition={position}>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className={
          "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " +
          "background-gradient-bright rounded-lg p-px outline-0"
        }
        overlayClassName="fixed inset-0 backdrop-blur-sm z-[21]"
        shouldCloseOnOverlayClick={true}
      >
        <div style={{ height, maxHeight }}>
          <TooltipContextProvider>{children}</TooltipContextProvider>
        </div>
      </ReactModal>
    </TradeContextProvider>
  )
}
