import { EIP1193Provider } from "mipd"

import { Chain } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { env } from "~/env"
import { getChainLogo } from "./assets"

interface ChainCurrency {
  name: string
  decimals: number
  symbol: string
}

export interface ChainDetail {
  chain: Chain
  chainId: bigint
  chainIdHex: string
  name: string
  url: string
  nativeCurrency: ChainCurrency
  blockExplorerUrls: string[]
  logoSvg: string
  minNativeTokenForGas: BigDecimal
  faucetUrl?: string
}

const buildChainDetail = (detail: Omit<ChainDetail, "chainIdHex">): ChainDetail => {
  const { chainId } = detail
  const chainIdHex = "0x" + chainId.toString(16)

  let { url } = detail
  const chainRpcUrlOverride = env.VITE_CHAIN_RPC_URL_OVERRIDE
    ? parseChainRpcUrlOverride(env.VITE_CHAIN_RPC_URL_OVERRIDE)
    : undefined
  if (chainRpcUrlOverride && chainId === chainRpcUrlOverride.chainId) {
    url = chainRpcUrlOverride.url
  }

  return { ...detail, chainIdHex, url }
}

const CHAIN_DETAILS_LIST: ChainDetail[] = [
  buildChainDetail({
    chain: "localhost",
    chainId: 1337n,
    name: "Localhost",
    url: "http://127.0.0.1:8545",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
    logoSvg: getChainLogo({ chain: "localhost" }),
    minNativeTokenForGas: BigDecimal.fromString("0.00001"),
  }),
  buildChainDetail({
    chain: "arbitrum-sepolia",
    chainId: 421614n,
    name: "Arbitrum Sepolia",
    url: "https://sepolia-rollup.arbitrum.io/rpc",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
    logoSvg: getChainLogo({ chain: "arbitrum-sepolia" }),
    minNativeTokenForGas: BigDecimal.fromString("0.00001"),
    faucetUrl: "https://www.alchemy.com/faucets/arbitrum-sepolia",
  }),
  buildChainDetail({
    chain: "plume-devnet",
    chainId: 98864n,
    name: "Plume Devnet",
    url: "https://test-rpc.plumenetwork.xyz",
    nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://test-explorer.plumenetwork.xyz"],
    logoSvg: getChainLogo({ chain: "plume-devnet" }),
    minNativeTokenForGas: BigDecimal.fromString("0.0001"),
    faucetUrl: "https://faucet.plumenetwork.xyz/",
  }),
]

const CHAIN_DETAILS_BY_CHAIN: Map<Chain, ChainDetail> = (() => {
  const details = new Map<Chain, ChainDetail>()

  for (const detail of CHAIN_DETAILS_LIST) {
    const { chain } = detail
    if (details.has(chain)) {
      throw new Error(`duplicate chain: ${chain}`)
    }
    details.set(chain, detail)
  }

  return details
})()

const CHAIN_DETAILS: Map<bigint, ChainDetail> = (() => {
  const details = new Map<bigint, ChainDetail>()

  for (const detail of CHAIN_DETAILS_LIST) {
    const { chainId } = detail
    if (details.has(chainId)) {
      throw new Error(`duplicate chain id: ${chainId}`)
    }
    details.set(chainId, detail)
  }

  return details
})()

const DEFAULT_CHAIN: Chain = "plume-devnet"

const DEFAULT_CHAIN_ID: bigint = (() => {
  const defaultChain = env.VITE_SKOI_DEFAULT_CHAIN ?? DEFAULT_CHAIN
  const defaultChainDetail = CHAIN_DETAILS_BY_CHAIN.get(defaultChain)
  if (!defaultChainDetail) {
    throw new Error(`no details specified for chain: ${defaultChain}`)
  }
  return defaultChainDetail.chainId
})()

export function getDefaultChainId(): bigint {
  return DEFAULT_CHAIN_ID
}

export function getDefaultChainDetail(): ChainDetail {
  const chainId = DEFAULT_CHAIN_ID
  const detail = CHAIN_DETAILS.get(chainId)
  if (!detail) {
    throw new Error(`no defaults defined for chain id ${chainId}`)
  }
  return detail
}

export function getChainDetail(chainId: number | bigint): ChainDetail | undefined {
  chainId = BigInt(chainId)
  const detail = CHAIN_DETAILS.get(chainId)
  if (!detail) {
    return undefined
  }
  return detail
}

export async function getChainId(provider: EIP1193Provider): Promise<bigint> {
  const chainIdStr = await provider.request({ method: "eth_chainId" })
  return BigInt(chainIdStr)
}

export async function switchEthereumChain(provider: EIP1193Provider, chainId: bigint) {
  await provider.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x" + chainId.toString(16) }],
  })
}

function parseChainRpcUrlOverride(value: string): { chainId: bigint; url: string } {
  const parts = value.split("=>")
  if (parts.length !== 2) {
    throw new Error(`invalid chain rpc url override: ${value}`)
  }
  const [chainIdStr, url] = parts
  const chainId = BigInt(chainIdStr)
  return { chainId, url }
}
