import { useEffect, useState } from "react"

import * as sk from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { CollateralInput } from "~/components/CollateralInput"
import { CurrentSizeAndCollateralCards } from "~/components/CurrentSizeAndCollateralCards"
import { OrderTypeButtons } from "~/components/OrderTypeButtons"
import { ScrollableArea } from "~/components/ScrollableArea"
import { Toggle } from "~/components/Toggle"
import { TradeWarning } from "~/components/TradeWarning"
import { TransactionSummary } from "~/components/TransactionSummary"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useDefaultLeverage, useTradeContext, useTradeContextActions } from "~/hooks"
import { MobileMainButton } from "./MobileMainButton"
import { EditPositionViewData } from "./MobileMainPageContext"
import { MobileTradingPanelInputs } from "./MobileTradingPanelInputs"

type TabName = "collateral" | "position"

type Props = {
  data: EditPositionViewData
}

export const MobileEditPositionView = ({ data }: Props) => {
  const position = data.position.position
  return (
    <TradeContextProvider symbol={position.symbolMeta.symbol} defaultPosition={position}>
      <Body data={data} />
      <div className="grow" />
      <MobileMainButton />
    </TradeContextProvider>
  )
}

const Body = ({ data }: Props) => {
  const { position: positionData } = data
  const { position } = positionData
  const [tab, setTab] = useState<TabName>("collateral")

  return (
    <div className={"flex flex-col w-full h-full px-3 bg-black rounded-lg overflow-hidden"}>
      <div className="shrink-0 h-2" />

      <div className="flex justify-center items-center gap-10">
        <Tab text="Collateral" value="collateral" tab={tab} setTab={setTab} />
        <Tab text="Position" value="position" tab={tab} setTab={setTab} />
      </div>

      <div className="shrink-0 h-3" />

      <CurrentSizeAndCollateralCards position={position} />

      {tab === "collateral" && <EditCollateralView />}
      {tab === "position" && <EditPositionView position={position} />}
    </div>
  )
}

interface TabProps {
  text: string
  value: TabName
  tab: TabName
  setTab: (_: TabName) => void
}

const menuFontCs = "text-[1rem] text-white font-semibold"
const selectedCs = `${menuFontCs} underline underline-offset-8 decoration-cool-blue`
const unselectedTabCs = `${menuFontCs} text-[var(--neutral-04, #6F767E)]`

const Tab = ({ text, value, tab, setTab }: TabProps) => {
  const tabCs = tab === value ? selectedCs : unselectedTabCs

  const onClick = () => {
    setTab(value)
  }

  return (
    <div className={tabCs} onClick={onClick}>
      {text}
    </div>
  )
}

const EditCollateralView = () => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const inputMode = useTradeContextStore((s) => s.input.inputMode)
  const { reset, setInputMode } = useTradeContextActions()

  const isWithdraw = inputMode === "WithdrawCollateral"

  useEffect(() => {
    reset()
    setInputMode("DepositCollateral")
  }, [reset, setInputMode])

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setInputMode("WithdrawCollateral")
    } else {
      setInputMode("DepositCollateral")
    }
  }

  return (
    <div className="flex flex-col h-full min-w-0 min-h-0 bg-black rounded-lg py-4 font-sans">
      <Toggle
        checked={isWithdraw}
        onChange={onToggleChange}
        uncheckedText="Deposit"
        checkedText="Withdraw"
        classNames={{
          checkedBackground: "before:bg-blue",
          beforeBackground: "before:bg-blue",
          beforeBorder: "before:rounded-[20px]",
          border: "rounded-[20px]",
          toggleText: "flex text-white text-[15px] font-semibold",
        }}
      />

      <div className="shrink-0 h-4" />

      <ScrollableArea>
        <CollateralInput />

        <div className="shrink-0 h-5" />

        <TradeWarning />

        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}

const EditPositionView = ({ position }: { position: sk.PositionSnapshot }) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const inputMode = useTradeContextStore((s) => s.input.inputMode)
  const { reset, setInputMode, setLeverage, setSide } = useTradeContextActions()

  const isReduce = inputMode === "ReducePosition"
  const side = position.size.gt(BigDecimal.zero()) ? "bid" : "ask"
  const defaultLeverage = useDefaultLeverage(position)

  useEffect(() => {
    reset()
    setInputMode("AddPosition")
    setSide(side)
    setLeverage(defaultLeverage)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setInputMode("ReducePosition")
      setSide(sk.getOppositeSide(side))
    } else {
      setInputMode("AddPosition")
      setSide(side)
    }
  }

  const checkedBackground = side === "bid" ? "before:bg-red" : "before:bg-green"
  const beforeBackground = side === "ask" ? "before:bg-red" : "before:bg-green"

  return (
    <div className="flex flex-col h-full min-w-0 min-h-0 bg-black rounded-lg py-4 font-sans">
      <Toggle
        checked={isReduce}
        onChange={onToggleChange}
        uncheckedText="Add"
        checkedText="Reduce"
        classNames={{
          checkedBackground,
          beforeBackground,
          beforeBorder: "before:rounded-[20px]",
          border: "rounded-[20px]",
          toggleText: "flex text-white text-[15px] font-semibold",
        }}
      />

      <div className="shrink-0 h-3" />

      <OrderTypeButtons excludeStopLimit={true} />

      <div className="shrink-0 h-3" />

      <ScrollableArea>
        <MobileTradingPanelInputs withLeverage={true} />
        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}
