import { useContext } from "react"

import { TooltipContext, TooltipContextData } from "../contexts/TooltipContext"

export const useTooltipContext = (): TooltipContextData => {
  const context = useContext(TooltipContext)
  if (context === undefined) {
    throw new Error('useTooltipContext must be used within a "TooltipContextProvider"')
  }
  return context
}

export const useTooltips = () => {
  const { useTooltipStore } = useTooltipContext()
  return useTooltipStore((s) => s.tooltips)
}

export const useTooltipActions = () => {
  const { useTooltipStore } = useTooltipContext()
  return useTooltipStore((s) => s.actions)
}
