import { ReactNode } from "react"
import * as viem from "viem"
import { z } from "zod"

import {
  MarketSnapshot,
  OrderBook,
  OrderSide,
  OrderSideSchema,
  OrderSnapshot,
  PositionSnapshot,
  TimeInForce,
  TimeInForceSchema,
  TraderConfig,
  TriggerSnapshot,
  UserBalances,
} from "silverkoi"
import { BigDecimal } from "silverkoi/math"

export type Empty = Record<PropertyKey, never>

export type ChildrenProps = { children: ReactNode }

export type PropsWithChildren<P = unknown> = P & ChildrenProps

export interface Duration {
  unit: "second" | "minute" | "hour" | "day" | "week"
  length: BigDecimal
}

export type DurationUnit = Duration["unit"]

// Represents a value with a text representation to be used in an html input.
// This is necessary for scenarios where we want to control not only the value
// but also the displayed text.
export interface InputState<T> {
  value: T
  text: string
}

export type OrderType = "market" | "limit" | "stop-limit"

export type InputMode =
  | "NewPosition"
  | "AddPosition"
  | "ReducePosition"
  | "DepositCollateral"
  | "WithdrawCollateral"
  | "CreateSLTP"
  | "EditTrigger"

// TODO: replace with string constants
export enum TooltipId {
  NotUsed,
  MarketPrice,
  TwapMarketPrice,
  OraclePrice,
  DailyChange,
  DailyVolume,
  DailyHigh,
  DailyLow,
  FundingRate,
  AvgEntryPrice,
  LiquidationPrice,
  Action,
  Leverage,
  PositionSize,
  PositionValue,
  OpenNotional,
  OpenInterest,
  VaultBalance,
  Collateral,
  AccountValue,
  UnrealizedPnl,
  RealizedPnl,
  UnsettledFunding,
  Funding,
  BadDebt,
  MaxPositionSize,
  MaxCollateral,
  TradeEntryPrice,
  TradeCollateralChange,
  TradePositionSizeChange,
  TradeOpenNotionalChange,
  TradeOpenInterestChange,
  PriceImpact,
  TransactionFee,
  TriggerPrice,
  SLTriggerPrice,
  TPTriggerPrice,
  SLTP,
  TriggerOrder,
  OrderSide,
  OrderLimitPrice,
}

// TODO: rename, this contains both market and user info
// TODO: change to interface with independent helper methods?
// TODO: expose uid field to use in dependency lists
export class UserState {
  public address: viem.Address
  public traderId?: bigint
  public userBalances: UserBalances

  // Map from symbol => market snapshot
  public markets: Map<string, MarketSnapshot>
  // Map from symbol => position id => position
  public positions: Map<string, Map<bigint, PositionSnapshot>>
  // Map from symbol => order id => order
  public openOrders: Map<string, Map<bigint, OrderSnapshot>>
  // Map from symbol => trigger id => trigger
  public triggers: Map<string, Map<bigint, TriggerSnapshot>>

  public constructor(
    address: viem.Address,
    traderId: bigint | undefined,
    userBalances: UserBalances,
    markets: Map<string, MarketSnapshot>,
    positions: Map<string, Map<bigint, PositionSnapshot>>,
    openOrders: Map<string, Map<bigint, OrderSnapshot>>,
    triggers: Map<string, Map<bigint, TriggerSnapshot>>,
  ) {
    this.address = address
    this.traderId = traderId
    this.userBalances = userBalances
    this.markets = markets
    this.positions = positions
    this.openOrders = openOrders
    this.triggers = triggers
  }

  public getMarketPrice(symbol: string): BigDecimal | undefined {
    const market = this.markets.get(symbol)
    if (!market) return undefined
    const { marketPrice } = market
    return marketPrice.isZero() ? undefined : marketPrice
  }

  public getTwapMarketPrice(symbol: string): BigDecimal | undefined {
    const market = this.markets.get(symbol)
    if (!market) return undefined
    const { twapMarketPrice } = market
    return twapMarketPrice.isZero() ? undefined : twapMarketPrice
  }

  // Returns non-empty positions that are NOT zero-size with nonzero open
  // interest.
  public getNonEmptyPositionsThatAreNotZeroSizeWithNonZeroOpenInterest(): PositionSnapshot[] {
    const candidates = []
    for (const marketPositions of this.positions.values()) {
      for (const position of marketPositions.values()) {
        if (position.empty) continue
        if (position.size.isZero() && !position.openInterestSize.isZero()) continue
        candidates.push(position)
      }
    }
    return candidates
  }

  public getOpenOrders(): OrderSnapshot[] {
    const orders = []
    for (const marketOrders of this.openOrders.values()) {
      for (const order of marketOrders.values()) {
        orders.push(order)
      }
    }
    return orders
  }

  public getTriggers(): TriggerSnapshot[] {
    const triggers = []
    for (const marketTriggers of this.triggers.values()) {
      for (const trigger of marketTriggers.values()) {
        triggers.push(trigger)
      }
    }
    return triggers
  }

  public getPosition({
    symbol,
    positionId,
  }: {
    symbol: string
    positionId: bigint
  }): PositionSnapshot | undefined {
    return this.positions.get(symbol)?.get(positionId)
  }

  // Returns any available empty position, if it exists.
  public getEmptyPosition(symbol: string): PositionSnapshot | undefined {
    const positions = this.positions.get(symbol)
    if (!positions) return undefined
    for (const [_, position] of positions) {
      if (position.empty) {
        return position
      }
    }
    return undefined
  }
}

export const BigDecimalSchema = z.string().transform(BigDecimal.fromString)

export const WithdrawCollateralRequestSchema = z.object({
  type: z.literal("WithdrawCollateral"),
  traderId: z.bigint(),
  marketId: z.bigint(),
  positionSubId: z.bigint(),
  amount: BigDecimalSchema,
})
export type WithdrawCollateralRequest = z.output<typeof WithdrawCollateralRequestSchema>

export const DepositCollateralRequestSchema = z.object({
  type: z.literal("DepositCollateral"),
  traderId: z.bigint(),
  marketId: z.bigint(),
  positionSubId: z.bigint(),
  amount: BigDecimalSchema,
})
export type DepositCollateralRequest = z.output<typeof DepositCollateralRequestSchema>

export const CancelOrderRequestSchema = z.object({
  type: z.literal("CancelOrder"),
  traderId: z.bigint(),
  marketId: z.bigint(),
  clientOrderId: z.bigint(),
})
export type CancelOrderRequest = z.output<typeof CancelOrderRequestSchema>

export const PlaceOrderRequestSchema = z.object({
  type: z.literal("PlaceOrder"),
  traderId: z.bigint(),
  marketId: z.bigint(),
  positionSubId: z.bigint(),
  clientOrderId: z.bigint(),
  side: OrderSideSchema,
  size: BigDecimalSchema,
  price: BigDecimalSchema,
  tick: z.bigint(),
  tif: TimeInForceSchema,
  deadline: z.bigint(),
  postOnly: z.boolean(),
  reduceOnly: z.boolean(),
  targetLeverage: BigDecimalSchema,
  maintainLeverage: z.boolean(),
})
export type PlaceOrderRequest = z.output<typeof PlaceOrderRequestSchema>

export const CreateTriggerRequestSchema = z.object({
  type: z.literal("CreateTrigger"),
  traderId: z.bigint(),
  marketId: z.bigint(),
  positionSubId: z.bigint(),
  clientOrderId: z.bigint(),
  side: OrderSideSchema,
  size: BigDecimalSchema,
  fromAboveTriggerPrice: BigDecimalSchema,
  fromBelowTriggerPrice: BigDecimalSchema,
  fromAboveLimitPrice: BigDecimalSchema,
  fromBelowLimitPrice: BigDecimalSchema,
  fromAboveTriggerTick: z.bigint(),
  fromBelowTriggerTick: z.bigint(),
  fromAboveLimitTick: z.bigint(),
  fromBelowLimitTick: z.bigint(),
  tif: TimeInForceSchema,
  postOnly: z.boolean(),
  reduceOnly: z.boolean(),
  targetLeverage: BigDecimalSchema,
  maintainLeverage: z.boolean(),
})
export type CreateTriggerRequest = z.output<typeof CreateTriggerRequestSchema>

export const ReplaceTriggerRequestSchema = z.object({
  type: z.literal("ReplaceTrigger"),

  fromAbove: z.boolean(),
  oldClientOrderId: z.bigint(),

  traderId: z.bigint(),
  marketId: z.bigint(),
  positionSubId: z.bigint(),
  clientOrderId: z.bigint(),
  side: OrderSideSchema,
  size: BigDecimalSchema,
  fromAboveTriggerPrice: BigDecimalSchema,
  fromBelowTriggerPrice: BigDecimalSchema,
  fromAboveLimitPrice: BigDecimalSchema,
  fromBelowLimitPrice: BigDecimalSchema,
  fromAboveTriggerTick: z.bigint(),
  fromBelowTriggerTick: z.bigint(),
  fromAboveLimitTick: z.bigint(),
  fromBelowLimitTick: z.bigint(),
  tif: TimeInForceSchema,
  postOnly: z.boolean(),
  reduceOnly: z.boolean(),
  targetLeverage: BigDecimalSchema,
  maintainLeverage: z.boolean(),
})
export type ReplaceTriggerRequest = z.output<typeof ReplaceTriggerRequestSchema>

export const CancelTriggerRequestSchema = z.object({
  type: z.literal("CancelTrigger"),
  traderId: z.bigint(),
  marketId: z.bigint(),
  clientOrderId: z.bigint(),
})
export type CancelTriggerRequest = z.output<typeof CancelTriggerRequestSchema>

export interface PartialOperationContext {
  symbol: string
  market: MarketSnapshot
  orderBook: OrderBook
  traderConfig?: TraderConfig
  position?: PositionSnapshot
  currentTimestamp: bigint
}

export type OperationContext = Required<PartialOperationContext>

export function isOperationContext(
  context: PartialOperationContext | OperationContext,
): context is OperationContext {
  return context.traderConfig !== undefined && context.position !== undefined
}

export type WithOperationContext<T> = { request: T; context: OperationContext }

export interface OperationInput {
  symbol: string
  inputMode?: InputMode

  // For deposit/withdraw collateral
  collateralAmount: InputState<BigDecimal | undefined>

  // For place order
  type: OrderType
  side: OrderSide
  size: InputState<BigDecimal | undefined>
  notional: InputState<BigDecimal | undefined> // NOTE: only exists for UI
  limitPrice: InputState<BigDecimal | undefined>
  tif: TimeInForce
  cancelAfter: InputState<Duration | undefined>
  postOnly: boolean
  leverage: InputState<BigDecimal | undefined>
  slippage: InputState<BigDecimal | undefined>

  triggerPrice: InputState<BigDecimal | undefined>
  triggerIsFromAbove: boolean

  slTriggerPrice: InputState<BigDecimal | undefined>
  tpTriggerPrice: InputState<BigDecimal | undefined>

  referenceTrigger?: TriggerSnapshot // For edit trigger
}

export interface Delta<Type> {
  oldValue: Type
  newValue: Type
  diff: Type
}

// TODO: consider creating multiple summary types and unioning them
export interface OperationSummary {
  symbol: string

  // These are specific to non-triggers`
  collateral?: Delta<BigDecimal>
  positionSize?: Delta<BigDecimal>
  // TODO: position notional
  openNotional?: Delta<BigDecimal>
  openInterestSize?: Delta<BigDecimal>
  openInterestNotional?: Delta<BigDecimal>
  leverage?: Delta<BigDecimal | undefined>
  liquidationPrice?: Delta<BigDecimal | undefined>
  // TODO: pnl
  entryPrice?: BigDecimal
  priceImpactPct?: BigDecimal
  transactionFee?: BigDecimal

  // These are specific to triggers
  triggerSide?: OrderSide
  triggerSize?: BigDecimal
  tpTriggerPrice?: Delta<BigDecimal | undefined>
  tpLimitPrice?: Delta<BigDecimal | undefined>
  slTriggerPrice?: Delta<BigDecimal | undefined>
  slLimitPrice?: Delta<BigDecimal | undefined>
  // TODO: transaction fee estimate
}

export interface FormattedOperationSummary {
  description: string
  descriptionColor: string
  symbol: string
  ticker: string
  entryPrice: string
  collateral: string
  positionSize: string
  openNotional: string
  openInterestNotional: string
  leverage: string
  priceImpact: string
  liquidationPrice: string
  transactionFee: string
}
