import { IconProps, IconSvg } from "./IconSvg"

export const Wallet = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 24 24">
      <g
        transform="matrix(1.028572, 0, 0, 1.02857, -2.333369, -2.33203)"
        style={{ transformOrigin: "2.33337px 2.33203px" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3334 7.19785V5.83203C23.3334 3.89903 21.7664 2.33203 19.8334 2.33203H5.83337C3.90038 2.33203 2.33337 3.89903 2.33337 5.83203V22.1654C2.33337 24.0984 3.90038 25.6654 5.83337 25.6654H22.1667C24.0997 25.6654 25.6667 24.0984 25.6667 22.1654V10.4987C25.6667 8.97478 24.6928 7.67833 23.3334 7.19785ZM4.66671 5.83203C4.66671 5.1877 5.18904 4.66536 5.83337 4.66536H19.8334C20.4777 4.66536 21 5.1877 21 5.83203V6.9987H5.83337C5.18904 6.9987 4.66671 6.47636 4.66671 5.83203ZM4.66671 9.13287C5.03162 9.26185 5.4243 9.33203 5.83337 9.33203H22.1667C22.811 9.33203 23.3334 9.85436 23.3334 10.4987V12.832H19.8334C17.9004 12.832 16.3334 14.399 16.3334 16.332C16.3334 18.265 17.9004 19.832 19.8334 19.832H23.3334V22.1654C23.3334 22.8097 22.811 23.332 22.1667 23.332H5.83337C5.18904 23.332 4.66671 22.8097 4.66671 22.1654V9.13287ZM19.8334 15.1654H23.3334V17.4987H19.8334C19.189 17.4987 18.6667 16.9764 18.6667 16.332C18.6667 15.6877 19.189 15.1654 19.8334 15.1654Z"
          fill={fill}
        />
      </g>
    </IconSvg>
  )
}
