import { IconProps, IconSvg } from "./IconSvg"

export const Trophy = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 24 24">
      <g transform="matrix(0.935066, 0, 0, 1.028573, -1.090914, -2.399981)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16667 4.66665H19.8333V11.6666C19.8333 14.8883 17.2217 17.5 14 17.5C10.7783 17.5 8.16667 14.8883 8.16667 11.6666V4.66665ZM5.83334 4.66665C5.83334 3.37798 6.87801 2.33331 8.16667 2.33331H19.8333C21.122 2.33331 22.1667 3.37798 22.1667 4.66665H24.5C25.7887 4.66665 26.8333 5.71132 26.8333 6.99998V9.33331C26.8333 11.9106 24.744 14 22.1667 14H21.8285C20.932 17.0122 18.3444 19.2961 15.1667 19.7506V23.3333H18.6667C19.311 23.3333 19.8333 23.8556 19.8333 24.5C19.8333 25.1443 19.311 25.6666 18.6667 25.6666H9.33334C8.68901 25.6666 8.16667 25.1443 8.16667 24.5C8.16667 23.8556 8.68901 23.3333 9.33334 23.3333H12.8333V19.7506C9.65561 19.2961 7.06801 17.0122 6.17153 14H5.83334C3.25601 14 1.16667 11.9106 1.16667 9.33331V6.99998C1.16667 5.71132 2.21134 4.66665 3.50001 4.66665L5.83334 4.66665ZM5.83334 6.99998H3.50001V9.33331C3.50001 10.622 4.54467 11.6666 5.83334 11.6666V6.99998ZM22.1667 6.99998V11.6666C23.4553 11.6666 24.5 10.622 24.5 9.33331V6.99998H22.1667Z"
          fill={fill}
        />
      </g>
    </IconSvg>
  )
}
