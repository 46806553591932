import { IconProps, IconSvg } from "./IconSvg"

export const Gauge = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 20 17.4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 10 2 C 5.582 2 2 5.582 2 10 C 2 12.212 2.897 14.213 4.349 15.662 C 4.739 16.052 4.74 16.686 4.35 17.076 C 3.96 17.467 3.327 17.468 2.936 17.078 C 1.123 15.269 0 12.765 0 10 C 0 4.477 4.477 0 10 0 C 15.523 0 20 4.477 20 10 C 20 12.761 18.88 15.262 17.071 17.071 C 16.68 17.462 16.047 17.462 15.657 17.071 C 15.266 16.68 15.266 16.047 15.657 15.657 C 17.106 14.208 18 12.209 18 10 C 18 5.582 14.418 2 10 2 Z"
        fill={fill}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 14.707 6.707 C 15.098 6.317 15.098 5.683 14.707 5.293 C 14.317 4.902 13.683 4.902 13.293 5.293 L 11.293 7.292 C 10.902 7.105 10.463 7 10 7 C 8.343 7 7 8.343 7 10 C 7 11.657 8.343 13 10 13 C 11.657 13 13 11.657 13 10 C 13 9.537 12.895 9.098 12.708 8.707 L 14.707 6.707 Z M 9 10 C 9 9.448 9.448 9 10 9 C 10.552 9 11 9.448 11 10 C 11 10.552 10.552 11 10 11 C 9.448 11 9 10.552 9 10 Z"
        fill={fill}
      ></path>
    </IconSvg>
  )
}
