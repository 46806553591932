import React, { ReactNode } from "react"

import { BigDecimal } from "silverkoi/math"

import { useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { USD_DECIMALS, USD_SYMBOL, parseBigDecimal } from "~/utils"
import { PercentageButtons } from "./PercentageButtons"
import { TradeNumberInput } from "./TradeNumberInput"

const ZERO = BigDecimal.fromReal(0, 0)

interface Props {
  fixedValue?: BigDecimal
}

export const CollateralInput = ({ fixedValue }: Props): ReactNode => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const { inputMode, collateralAmount } = useTradeContextStore((s) => ({
    inputMode: s.input.inputMode,
    collateralAmount: s.input.collateralAmount,
  }))
  const { setCollateralAmount } = useTradeContextActions(useTradeContextStore)

  const isWithdraw = inputMode === "WithdrawCollateral"
  const freeCollateral = tradeContext.position?.freeCollateral.max(ZERO) || ZERO

  const onCollateralInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, USD_DECIMALS)
    setCollateralAmount({ value, text })
  }

  const disabled = !!fixedValue
  const placeholder = fixedValue ? fixedValue.toStringTrimmed() : "0.00"

  return (
    <div className="w-full">
      <TradeNumberInput
        label="Collateral"
        placeholder={placeholder}
        value={collateralAmount.text}
        onChange={onCollateralInputChange}
        disabled={disabled}
        unit={USD_SYMBOL}
      />

      {/* TODO: support multiple positions */}
      {isWithdraw && !fixedValue && (
        <React.Fragment>
          <div className="shrink-0 h-1" />
          <PercentageButtons
            setInput={setCollateralAmount}
            maxDecimals={USD_DECIMALS}
            referenceValue={freeCollateral}
          />
        </React.Fragment>
      )}

      {/* TODO: Do we want to show this on 100% button? Will need to move this
          out to higher div to avoid z-index issues
      */}
      {/*<Tooltip
        id={tooltipId}
        message={TooltipMessageContent(TooltipId.MaxCollateral)}
        width="250px"active
        place="right-start"
      />*/}
    </div>
  )
}
