import * as _ from "lodash"
import React, { ReactNode, useEffect, useState } from "react"
import { useDebounceValue } from "usehooks-ts"

import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import switchIcon from "~/assets/switch.svg"
import { useMarket, useOrderSizeEstimate, useSimulationResult, useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { USD_SYMBOL, getIcon, parseBigDecimal, tw2 } from "~/utils"
import { PercentageButtons } from "./PercentageButtons"
import { Tooltip } from "./Tooltip"
import { TradeNumberInput } from "./TradeNumberInput"

interface PositionSizeInputProps {
  defaultSize?: BigDecimal
}

export const OrderSizeInput = ({ defaultSize }: PositionSizeInputProps): ReactNode => {
  const tradeContext = useTradeContext()
  const { symbol, useTradeContextStore } = tradeContext
  const { inputMode, orderType, side, size, notional, limitPrice, tif, postOnly } =
    useTradeContextStore((s) => ({
      inputMode: s.input.inputMode,
      orderType: s.input.type,
      size: s.input.size,
      side: s.input.side,
      notional: s.input.notional,
      limitPrice: s.input.limitPrice,
      tif: s.input.tif,
      postOnly: s.input.postOnly,
    }))
  const { setSize, setNotional } = useTradeContextActions(useTradeContextStore)
  const { data: simResult } = useSimulationResult({ enabled: true })

  const market = useMarket(symbol)

  const isReduce = inputMode === "ReducePosition" || inputMode === "CreateSLTP"
  const positionSize = tradeContext.position?.size.abs() || BigDecimal.zero()
  const sizeDecimals = 5n // TODO: don't hardcode

  const [unitIsUsdc, setUnitIsUsdc] = useState<boolean>(!defaultSize)

  const onOrderSizeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, skoi.SIZE_DECIMALS)
    setSize({ value, text })
  }

  const onOrderValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, skoi.NOTIONAL_INPUT_DECIMALS)
    setNotional({ value, text })
  }

  // On first render, set size to default value
  useEffect(() => {
    setSize({ value: defaultSize, text: defaultSize?.toStringTrimmed() ?? "" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const estimateOrderSizeArgs = {
    unitIsUsdc,
    marketId: market?.marketId,
    type: orderType,
    side,
    notional: notional.value,
    limitPrice: limitPrice.value,
    tif,
    postOnly,
  }
  const [debouncedEstimateOrderSizeArgs] = useDebounceValue(estimateOrderSizeArgs, 150, {
    equalityFn: _.isEqual,
  })

  useOrderSizeEstimate(debouncedEstimateOrderSizeArgs, setSize)

  const amountTooltipId = _.uniqueId("order-amount-label-tooltip-")
  const amountTooltipMessage = makeAmountTooltipMessage(symbol)

  const estValueTooltipId = _.uniqueId("order-est-value-tooltip-")
  const estValueTooltipMessage = makeEstValueTooltipMessage()

  const estAmountTooltipId = _.uniqueId("order-est-amount-tooltip-")
  const estAmountTooltipMessage = makeEstAmountTooltipMessage(symbol)

  // This is the estimated notional when the user specifies order size
  const notionalEst: BigDecimal | undefined = (() => {
    if (unitIsUsdc || !size.value) return undefined
    // TODO: Need proper "pending" state for sim result
    if (!simResult?.ok) return undefined

    const summary = simResult.val.summary

    if (orderType === "market") {
      const entryPrice = summary.entryPrice
      if (!entryPrice) return undefined
      const notional = size.value.mul(entryPrice)
      return notional
    } else {
      if (!limitPrice.value) return undefined
      if (!summary.openNotional) return undefined
      if (!summary.positionSize) return undefined
      const executedValue = summary.openNotional.diff.abs()
      const executedSize = summary.positionSize.diff.abs()
      const remainingSize = size.value.sub(executedSize)
      const remainingValue = remainingSize.mul(limitPrice.value)
      const notional = remainingValue.add(executedValue)
      return notional
    }
  })()

  const makeUnitButton = (isUsdc: boolean) => {
    const text = isUsdc ? USD_SYMBOL : symbol
    const selected = isUsdc === unitIsUsdc
    const onClick = () => {
      if (selected) return
      setUnitIsUsdc(isUsdc)
      if (isUsdc) {
        setSize({ value: undefined, text: "" })
        setNotional({ value: undefined, text: "" })
      }
    }

    const colorCs = selected ? "bg-pale-blue" : "bg-neutral-05"
    const hoverCs = selected ? "hover:cursor-not-allowed" : "hover:cursor-pointer"
    const cs = `flex justify-center items-center w-[4rem] px-4 py-[0.125rem] rounded ${colorCs} ${tw2("font-input-unit-button")} ${hoverCs}`

    return (
      <div className={cs} onClick={onClick}>
        {text}
      </div>
    )
  }

  const makeExtraInfoBody = () => {
    if (unitIsUsdc) {
      // TODO: Show loading when pending
      return (
        <>
          <div className="pr-1 hover:cursor-help" data-tooltip-id={estAmountTooltipId}>
            <span className="text-pale">Estimated Amount:&nbsp;</span>
            {size.value?.toStringTrimmed(5) || "-"} {symbol}
          </div>
        </>
      )
    } else {
      // TODO: Show loading when undefined
      return (
        <>
          <div className="pr-1 hover:cursor-help" data-tooltip-id={estValueTooltipId}>
            <span className="text-pale">Estimated Value:&nbsp;</span>
            {notionalEst?.toString(2) ?? "-"} USD
          </div>
        </>
      )
    }
  }

  const makeExtraInfo = () => {
    return (
      <div
        className={`flex flex-row gap-1 items-center pt-2 ${tw2("font-input-extra-info")} text-neutral-01`}
      >
        {makeExtraInfoBody()}
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-row justify-between">
        <div className="flex hover:cursor-help items-center" data-tooltip-id={amountTooltipId}>
          <div className={`flex ${tw2("font-input-label")} text-white pr-1`}>Amount</div>
          <img src={getIcon("info")} className="w-3 h-3" />
        </div>
        <div className={`flex gap-1 text-neutral-03`}>
          {makeUnitButton(false)}
          <img src={switchIcon} />
          {makeUnitButton(true)}
        </div>
      </div>
      <div className="shrink-0 h-1" />
      {unitIsUsdc ? (
        <TradeNumberInput
          placeholder={"0.00"}
          value={notional.text}
          onChange={onOrderValueInputChange}
          unit={USD_SYMBOL}
        />
      ) : (
        <TradeNumberInput
          placeholder={"0.00"}
          value={size.text}
          onChange={onOrderSizeInputChange}
          unit={symbol}
        />
      )}

      {makeExtraInfo()}

      {isReduce && !unitIsUsdc && (
        <React.Fragment>
          <div className="shrink-0 h-1" />
          <PercentageButtons
            setInput={setSize}
            maxDecimals={sizeDecimals}
            referenceValue={positionSize}
          />
        </React.Fragment>
      )}

      <Tooltip id={amountTooltipId} message={amountTooltipMessage} place="right" width="15rem" />

      <Tooltip
        id={estValueTooltipId}
        message={estValueTooltipMessage}
        place="right"
        width="15rem"
      />

      <Tooltip
        id={estAmountTooltipId}
        message={estAmountTooltipMessage}
        place="right"
        width="15rem"
      />
    </div>
  )
}

const makeTooltipBold = (text: string) => {
  return <span className="text-neutral-01 font-black">{text}</span>
}

const makeAmountTooltipMessage = (symbol: string) => {
  return (
    <div className="text-white">
      Amount of {symbol} or {USD_SYMBOL} to long or short.
      <br />
      <br />
      This is the absolute amount your newly created position will have when the order is filled.
      <br />
      <br />
      Order size in units of {symbol} can have at most {makeTooltipBold("5 decimals")} of precision.
      Therefore, the smallest order size allowed is {makeTooltipBold(`0.00001`)} {symbol}.
    </div>
  )
}

const makeEstValueTooltipMessage = () => {
  return (
    <div className="text-white">
      Estimated {USD_SYMBOL} value of the order.
      <br />
      <br />
      This is an estimate of the absolute {USD_SYMBOL} amount your newly created position will be
      worth if the order is fully filled.
      <br />
      <br />
      Please note that this value is only an estimate. The actual value can be different, depending
      on execution time and market conditions.
    </div>
  )
}

const makeEstAmountTooltipMessage = (symbol: string) => {
  return (
    <div className="text-white">
      Estimated amount of {symbol} to long or short
      <br />
      <br />
      This is an estimate of the absolute amount your newly created position will have when the
      order is filled.
      <br />
      <br />
      Please note that this value is only an estimate. The actual order amount can be different,
      depending on execution time and market conditions.
    </div>
  )
}
