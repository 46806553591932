import { IconFill } from "./IconSvg"

import { Bell } from "./Bell"
import { BellWithDot } from "./BellWithDot"
import { Cancel } from "./Cancel"
import { Chart } from "./Chart"
import { Edit } from "./Edit"
import { Gauge } from "./Gauge"
import { Logout } from "./Logout"
import { MeteorStar } from "./MeteorStar"
import { PieChart } from "./PieChart"
import { SolidTrophy } from "./SolidTrophy"
import { Trophy } from "./Trophy"
import { Wallet } from "./Wallet"

export type IconName =
  | "bell"
  | "bell-with-dot"
  | "cancel"
  | "chart"
  | "edit"
  | "gauge"
  | "logout"
  | "meteor-star"
  | "pie-chart"
  | "solid-trophy"
  | "trophy"
  | "wallet"

type IconArgs = {
  name: IconName
  iconFill: IconFill // TODO: rename to fill
  size?: string
}

export const Icon = ({ name, iconFill, size }: IconArgs) => {
  const Component = getComponent(name)
  const fill = getFill(iconFill)
  if (size) {
    return (
      <div style={{ width: size, height: size }}>
        <Component fill={fill} />
      </div>
    )
  } else {
    return <Component fill={fill} />
  }
}

const getComponent = (name: IconName) => {
  switch (name) {
    case "bell":
      return Bell
    case "bell-with-dot":
      return BellWithDot
    case "pie-chart":
      return PieChart
    case "trophy":
      return Trophy
    case "solid-trophy":
      return SolidTrophy
    case "wallet":
      return Wallet
    case "edit":
      return Edit
    case "gauge":
      return Gauge
    case "cancel":
      return Cancel
    case "chart":
      return Chart
    case "logout":
      return Logout
    case "meteor-star":
      return MeteorStar
    default:
      throw new Error(`unknown icon name: ${name}`)
  }
}

const getFill = (iconFill: IconFill): string => {
  switch (iconFill) {
    case "white":
      return "white"
    case "red":
      return "red"
    case "gold":
      return "#FFBF43"
    case "silver":
      return "#D3D3D3"
    case "bronze":
      return "#FF9146"
    case "gray":
      return "#6F767E"
    case "gradient":
      return "url(#gradient)"
    default:
      throw new Error(`unknown icon fill: ${iconFill}`)
  }
}
