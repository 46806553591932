import React, { useRef } from "react"

import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { InputState } from "~/types"
import { parseBigDecimal, tw2 } from "~/utils"
import { NumberInput } from "./NumberInput"

interface Props {
  symbol: string
}

export const LeverageInput = ({ symbol }: Props) => {
  const { useTradeContextStore } = useTradeContext()
  const leverage = useTradeContextStore((s) => s.input.leverage)
  const { setLeverage } = useTradeContextActions(useTradeContextStore)
  const { maxLeverage: maxLeverageNum } = skoi.getSymbolConfig(symbol)
  const maxLeverage = BigDecimal.fromReal(maxLeverageNum, 1)

  const ref = useRef<HTMLInputElement | null>(null) // eslint-disable-line no-null/no-null

  const defaultLeverage = maxLeverage
  const placeholder = defaultLeverage.toString(0)
  const onLeverageTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, 1, skoi.MIN_LEVERAGE, maxLeverage)
    setLeverage({ value, text })
  }

  const onLeverageSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, 1)
    setLeverage({ value, text })
  }

  return (
    <div className="relative w-full">
      <div className="flex items-center justify-between w-full">
        <div className={`${tw2("font-input-label")} text-white`}>Leverage</div>
        <NumberInput
          className={
            "flex text-center w-[2rem] px-5 py-[0.25rem] " +
            "border border-neutral-shade-dark2 rounded-xl bg-black focus:outline-none focus:border-white " +
            `text-white ${tw2("font-input-value-small")} font-medium `
          }
          placeholder={placeholder}
          value={leverage.text}
          onChange={onLeverageTextInputChange}
        />
      </div>

      <div className="flex flex-col pt-2 gap-3">
        <input
          ref={ref}
          type="range"
          min="1"
          value={(leverage.value ?? defaultLeverage).real()}
          onChange={onLeverageSliderChange}
          max={maxLeverageNum}
          step="0.1"
          className="w-full h-[2px] appearance-none bg-navy-blue leverage-range cursor-pointer focus:outline-none"
        />
        <div className="flex w-full">
          <Button value={1} setLeverage={setLeverage} />
          <Button value={(maxLeverageNum * 1) / 5} setLeverage={setLeverage} />
          <Button value={(maxLeverageNum * 1) / 2} setLeverage={setLeverage} />
          <Button value={(maxLeverageNum * 3) / 5} setLeverage={setLeverage} />
          <Button value={(maxLeverageNum * 4) / 5} setLeverage={setLeverage} />
          <Button value={maxLeverageNum} setLeverage={setLeverage} />
        </div>
      </div>
    </div>
  )
}

type ButtonProps = {
  value: number
  setLeverage: (_: InputState<BigDecimal | undefined>) => void
}

const Button = ({ value, setLeverage }: ButtonProps) => {
  const text = `${value}x`
  const onClick = () => {
    const v = BigDecimal.fromReal(value, 1)
    setLeverage({ value: v, text: v.toStringTrimmed() })
  }

  return (
    <div className="flex w-1/5 px-[2px]">
      <button
        className={
          "flex w-full justify-center items-center rounded py-[0.125rem] " +
          "bg-neutral-06 hover:bg-neutral-05 border-transparent border-px " +
          "focus:outline-none " +
          `${tw2("font-leverage-button")} text-neutral-04 hover:text-neutral-03`
        }
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  )
}
