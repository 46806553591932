import { useDocumentTitle } from "usehooks-ts"

import { NotificationList } from "~/components/NotificationList"
import { tw2 } from "~/utils"

export const NotificationPage = () => {
  useDocumentTitle(`Silver Koi - Notifications`)

  return (
    <div className="flex gap-6 px-4 justify-center w-full h-full">
      <div className="grow flex flex-col min-w-0 max-w-[45rem] h-full bg-black rounded-lg">
        <div className="flex flex-row items-center">
          <div className={tw2("font-notification-list-title") + " px-5 py-4"}>Notifications</div>
        </div>

        <div className="grow w-full">
          <NotificationList />
        </div>
      </div>
    </div>
  )
}
