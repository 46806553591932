import { useIsMobile, useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { OrderType } from "~/types"
import { tw2 } from "~/utils"

const ORDER_TYPE_CS =
  "bg-neutral-dark rounded border-transparent border-px focus:outline-none hover:text-white hover:border-pale-blue py-1"
const ORDER_TYPE_SELECTED_CS =
  " rounded bg-pale-blue outline-none border-px border-transparent py-1 focus:outline-none hover:border-transparent"

interface Props {
  excludeStopLimit?: boolean
}

export const OrderTypeButtons = ({ excludeStopLimit }: Props) => {
  const { useTradeContextStore } = useTradeContext()
  const orderType = useTradeContextStore((s) => s.input.type)
  const { setOrderType } = useTradeContextActions(useTradeContextStore)

  const isMobile = useIsMobile()
  const fontCs = isMobile
    ? `${tw2("font-mobile-order-type-button")} text-neutral-01`
    : `${tw2("font-order-type-button")} text-neutral-01`

  const widthCs = excludeStopLimit ? "w-1/2" : "w-1/3"

  const onOrderTypeChange = (value: OrderType) => {
    setOrderType(value)
  }

  const makeOrderTypeButton = (name: string, choice: OrderType) => {
    const match = orderType === choice
    const cs =
      widthCs +
      " flex border-0 justify-center items-center align-middle " +
      (match ? ORDER_TYPE_SELECTED_CS : ORDER_TYPE_CS)

    return (
      <button className={cs} onClick={() => onOrderTypeChange(choice)}>
        <span className={fontCs}>{name}</span>
      </button>
    )
  }

  return (
    <div className="grow-0 h-fit flex flex-row justify-center gap-2">
      {makeOrderTypeButton("Market", "market")}
      {makeOrderTypeButton("Limit", "limit")}
      {!excludeStopLimit && makeOrderTypeButton("Stop Limit", "stop-limit")}
    </div>
  )
}
