import { subscribeWithSelector } from "zustand/middleware"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

import { OrderSide, TimeInForce, TriggerSnapshot } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { Duration, InputMode, InputState, OperationInput, OrderType } from "~/types"

interface TradeContextActions {
  setSymbol: (_: string) => void
  setInputMode: (_: InputMode) => void
  setOrderType: (_: OrderType) => void
  setCollateralAmount: (_: InputState<BigDecimal | undefined>) => void
  setSide: (_: OrderSide) => void
  setSize: (_: InputState<BigDecimal | undefined>) => void
  setNotional: (_: InputState<BigDecimal | undefined>) => void
  setLimitPrice: (_: InputState<BigDecimal | undefined>) => void
  setTif: (_: TimeInForce) => void
  setPostOnly: (_: boolean) => void
  setCancelAfter: (_: InputState<Duration | undefined>) => void
  setSlTriggerPrice: (_: InputState<BigDecimal | undefined>) => void
  setTpTriggerPrice: (_: InputState<BigDecimal | undefined>) => void
  setLeverage: (_: InputState<BigDecimal | undefined>) => void
  setSlippage: (_: InputState<BigDecimal | undefined>) => void
  setTriggerPrice: (_: InputState<BigDecimal | undefined>) => void
  setTriggerIsFromAbove: (_: boolean) => void
  setReferenceTrigger: (_?: TriggerSnapshot) => void

  resetOrderType: () => void
  resetTif: () => void

  reset: () => void
}

// TODO: store operation context here too?
type TradeContextState = {
  input: OperationInput
  actions: TradeContextActions
}

export const createTradeContextStore = ({ symbol }: { symbol: string }) => {
  const defaultOrderType = "market"
  const defaultSide = "bid"
  const defaultTif = "gtc"
  const defaultCancelAfter = { unit: "day", length: BigDecimal.fromString("1") } as const
  const defaultCancelAfterInput = { value: defaultCancelAfter, text: "1" }

  const defaultInput: OperationInput = {
    symbol,
    inputMode: undefined,
    collateralAmount: { value: undefined, text: "" },
    type: defaultOrderType,
    side: defaultSide,
    size: { value: undefined, text: "" },
    notional: { value: undefined, text: "" },
    limitPrice: { value: undefined, text: "" },
    tif: defaultTif,
    cancelAfter: defaultCancelAfterInput,
    postOnly: false,
    leverage: { value: undefined, text: "" },
    slippage: {
      value: skoi.DEFAULT_SLIPPAGE,
      text: skoi.DEFAULT_SLIPPAGE.toString(1),
    },
    triggerPrice: { value: undefined, text: "" },
    triggerIsFromAbove: false,
    slTriggerPrice: { value: undefined, text: "" },
    tpTriggerPrice: { value: undefined, text: "" },
    referenceTrigger: undefined,
  }

  return createWithEqualityFn<TradeContextState>()(
    subscribeWithSelector((set) => ({
      input: defaultInput,
      actions: {
        setSymbol: (symbol) => {
          set((s) => ({ ...s, input: { ...s.input, symbol } }))
        },
        setInputMode: (inputMode) => {
          set((s) => ({ ...s, input: { ...s.input, inputMode } }))
        },
        setOrderType: (type) => {
          set((s) => ({ ...s, input: { ...s.input, type } }))
        },
        setCollateralAmount: (collateralAmount) => {
          set((s) => ({ ...s, input: { ...s.input, collateralAmount } }))
        },
        setSide: (side) => {
          set((s) => ({ ...s, input: { ...s.input, side } }))
        },
        setSize: (size) => {
          set((s) => ({ ...s, input: { ...s.input, size } }))
        },
        setNotional: (notional) => {
          set((s) => ({ ...s, input: { ...s.input, notional } }))
        },
        setLimitPrice: (limitPrice) => {
          set((s) => ({ ...s, input: { ...s.input, limitPrice } }))
        },
        setTriggerPrice: (triggerPrice) => {
          set((s) => ({ ...s, input: { ...s.input, triggerPrice } }))
        },
        setTriggerIsFromAbove: (triggerIsFromAbove) => {
          set((s) => ({ ...s, input: { ...s.input, triggerIsFromAbove } }))
        },
        setTif: (tif) => {
          set((s) => ({ ...s, input: { ...s.input, tif } }))
        },
        setPostOnly: (postOnly) => {
          set((s) => ({ ...s, input: { ...s.input, postOnly } }))
        },
        setCancelAfter: (cancelAfter) => {
          set((s) => ({ ...s, input: { ...s.input, cancelAfter } }))
        },
        setSlTriggerPrice: (slTriggerPrice) => {
          set((s) => ({ ...s, input: { ...s.input, slTriggerPrice } }))
        },
        setTpTriggerPrice: (tpTriggerPrice) => {
          set((s) => ({ ...s, input: { ...s.input, tpTriggerPrice } }))
        },
        setReferenceTrigger: (referenceTrigger) => {
          set((s) => ({ ...s, input: { ...s.input, referenceTrigger } }))
        },
        setLeverage: (leverage) => {
          set((s) => ({ ...s, input: { ...s.input, leverage } }))
        },
        setSlippage: (slippage) => {
          set((s) => ({ ...s, input: { ...s.input, slippage } }))
        },
        resetOrderType: () => {
          set((s) => ({ ...s, input: { ...s.input, type: defaultOrderType } }))
        },
        resetTif: () => {
          set((s) => ({ ...s, input: { ...s.input, tif: defaultTif } }))
        },
        reset: () => {
          set((s) => ({ ...s, input: defaultInput }))
        },
      },
    })),
    shallow,
  )
}

export type TradeContextStore = ReturnType<typeof createTradeContextStore>

export const useTradeContextActions = (useStore: TradeContextStore) => {
  return useStore((s) => s.actions)
}
