import { simpleHash } from "../utils"

type Props = {
  size: string
  address: string
}

export const ProfileCircle = ({ size, address }: Props) => {
  const color1 = generateColor(address + ":1")
  const color2 = generateColor(address + ":2")
  const color3 = generateColor(address + ":3")
  const color4 = generateColor(address + ":4")

  return (
    <div style={{ height: size, width: size }}>
      <svg viewBox="0 0 18 18">
        <g clipPath="url(#clip0_2768_2676)">
          <rect width="18" height="18" rx="9" fill="white" />
          <rect x="-5.0625" y="-5.0625" width="18" height="18" rx="9" fill={color1} />
          <rect y="3.9375" width="18" height="18" rx="9" fill="url(#paint0_linear_2768_2676)" />
          <rect x="9" y="-1.6875" width="18" height="18" rx="9" fill={color2} />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2768_2676"
            x1="7.83"
            y1="3.9375"
            x2="18.3556"
            y2="6.94715"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color3} stopOpacity="0.53" />
            <stop offset="1" stopColor={color4} />
          </linearGradient>
          <clipPath id="clip0_2768_2676">
            <rect width="18" height="18" rx="9" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

const generateColor = (seed: string): string => {
  return "#" + simpleHash(seed)[0].toString(16).slice(-6)
}
