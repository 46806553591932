const {
  MODE,
  VITE_APPKIT_PROJECT_ID,
  VITE_CHAIN_RPC_URL_OVERRIDE,
  VITE_SKOI_DEFAULT_CHAIN,
  VITE_SKOI_API_URL,
  VITE_SKOI_PRICEFEED_HTTP_URL,
  VITE_SKOI_PRICEFEED_WS_URL,
  VITE_WALLET_CONNECT_PROJECT_ID,
  VITE_SENTRY_DSN,
  VITE_SENTRY_TRACES_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
} = import.meta.env

export const env = {
  MODE,
  VITE_APPKIT_PROJECT_ID,
  VITE_CHAIN_RPC_URL_OVERRIDE,
  VITE_SKOI_DEFAULT_CHAIN,
  VITE_SKOI_API_URL,
  VITE_SKOI_PRICEFEED_HTTP_URL,
  VITE_SKOI_PRICEFEED_WS_URL,
  VITE_WALLET_CONNECT_PROJECT_ID,
  VITE_SENTRY_DSN,
  VITE_SENTRY_TRACES_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
}
