import { ReactNode } from "react"

import { BigDecimal } from "silverkoi/math"

import { InputState } from "~/types"
import { tw2 } from "~/utils"

const buttonCs =
  "bg-transparent w-1/5 rounded-md " +
  "border border-neutral-shade-dark2 p-1 outline-none " +
  "active:bg-pale-blue active:outline-none " +
  "focus:outline-none " +
  "hover:border-neutral-shade-dark hover:text-white "

const textCs = `${tw2("font-percentage-button")} text-neutral-shade-dark hover:text-white`

interface PercentageButtonsProps {
  setInput: (_: InputState<BigDecimal | undefined>) => void
  maxDecimals: bigint
  referenceValue: BigDecimal
}

export const PercentageButtons = ({
  setInput,
  maxDecimals,
  referenceValue,
}: PercentageButtonsProps) => {
  const onClick = (input: number) => {
    const ratio = BigDecimal.fromReal(input / 100, 2)
    const newValue = referenceValue.mul(ratio).round(maxDecimals)
    setInput({ value: newValue, text: newValue.toStringTrimmed(maxDecimals) })
  }

  const makeButton = (value: number): ReactNode => {
    return (
      <button
        className={buttonCs}
        onClick={() => {
          onClick(value)
        }}
      >
        <div className={textCs}>{value}%</div>
      </button>
    )
  }

  return (
    <div className="flex flex-row gap-1">
      {makeButton(10)}
      {makeButton(25)}
      {makeButton(50)}
      {makeButton(75)}
      {makeButton(100)}
    </div>
  )
}
