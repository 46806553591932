import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { useMemo, useRef, useState } from "react"
import { useDocumentTitle, useWindowSize } from "usehooks-ts"
import { useAccount } from "wagmi"

import { BigDecimal } from "silverkoi/math"

import { InfoTable } from "~/components/InfoTable"
import { ProfileCircle } from "~/components/ProfileCircle"
import { Icon } from "~/components/icons"
import { LeaderboardEntry, useAllTimeStats, useChainDetail, useUserRankedStats } from "~/hooks"
import {
  IconType,
  format3SigFigDollars,
  formatAddress,
  formatValue,
  getCampaignId,
  getIcon,
  tw2,
} from "~/utils"

type RankType = "volume" | "pnl" | "points"

export const LeaderboardPage = () => {
  useDocumentTitle(`Silver Koi - Leaderboard`)

  return (
    <div className="flex grow w-full justify-center overflow-hidden">
      <div className="flex flex-col grow max-w-[72rem] px-4 gap-5 justify-center overflow-hidden">
        <Title />

        <UserRanking />

        <div className="flex grow overflow-hidden">
          <GlobalRanking />
        </div>
      </div>
    </div>
  )
}

const Title = () => {
  // TODO: Update once closer to campaign.
  //const { chain } = useChainDetail()
  //const campaignId = getCampaignId(chain)
  //const title = campaignId ? getCampaignTitle(campaignId) : undefined
  const title = "Plume Testnet Campaign 2: TBD"

  return (
    <div className="flex flex-col w-full h-fit px-5 py-4 gap-3 bg-neutral-06/70 rounded-lg">
      <div className={`w-fit ${tw2("font-leaderboard-title")} text-white font-bold rounded`}>
        {title}
      </div>
    </div>
  )
}

const UserRanking = () => {
  const { chain } = useChainDetail()
  const { address } = useAccount()
  const campaignId = getCampaignId(chain)
  const { data: userStats } = useUserRankedStats({
    chain,
    address,
    campaignId,
  })

  const volume = userStats?.volume
  const volumeStr = formatValue(volume, { dollar: true, decimals: 2 })
  const volumeRankStr = userStats?.volumeRankStr ?? "-"

  const pnl = userStats?.realizedPnl
  const [pnlStr, pnlColor] = (() => {
    const text = formatValue(pnl, { dollar: true, decimals: 2, signed: true })
    const color = (() => {
      if (!pnl || pnl.isZero()) {
        return "text-white"
      } else if (pnl.lt(BigDecimal.zero())) {
        return "text-red"
      } else {
        return "text-green"
      }
    })()
    return [text, color]
  })()
  const pnlRankStr = userStats?.pnlRankStr ?? "-"

  const pointsStr = formatValue(userStats?.points)
  const pointsRankStr = userStats?.pointRankStr ?? "-"

  return (
    <div className="flex flex-col w-full h-fit px-5 py-4 gap-3 bg-neutral-06/70 rounded-lg">
      <div className={`w-fit ${tw2("font-leaderboard-title")} text-white font-bold rounded`}>
        My Ranking
      </div>

      <div className="flex grid grid-cols-3">
        <RankCard
          icon="barchart"
          rank={volumeRankStr}
          rankLabel={"Volume Rank"}
          stat={volumeStr}
          statLabel={"Volume"}
        />

        <RankCard
          icon="cash"
          rank={pnlRankStr}
          rankLabel={"PnL Rank"}
          stat={pnlStr}
          statLabel={"PnL"}
          statColorCs={pnlColor}
        />

        <RankCard
          icon="coin"
          rank={pointsRankStr}
          rankLabel={"Points Rank"}
          stat={pointsStr}
          statLabel={"Points"}
        />
      </div>
    </div>
  )
}

type RankCardProps = {
  icon: IconType
  rank: string
  rankLabel: string
  stat: string
  statLabel: string
  statColorCs?: string
}

const RankCard = ({
  icon,
  rank,
  rankLabel,
  stat,
  statLabel,
  statColorCs: _statColorCs,
}: RankCardProps) => {
  const statColorCs = _statColorCs ?? "text-white"
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex items-center justify-center w-[16rem] h-[4rem] px-4 bg-neutral-07 rounded-[0.5rem] border-gradient-bright-[1px-0.5rem]">
        <div className="shrink-0 flex w-7 h-7">
          <img src={getIcon(icon)} />
        </div>

        <div className="shrink-0 grow-0 w-3" />

        <div className="shrink-0 flex flex-col justify-center h-full py-2 gap-1">
          <div
            className={`${tw2("font-leaderboard-card")} font-bold text-blue-03 font-semibold items-center`}
          >
            {rank}
          </div>

          <div
            className={`${tw2("font-leaderboard-card-small")} text-neutral-04 font-semibold items-center`}
          >
            {rankLabel}
          </div>
        </div>

        <div className="grow" />

        <div className="shrink-0 flex flex-col justify-center h-full py-2 gap-1">
          <div
            className={`${tw2("font-leaderboard-card")} font-semibold items-center ${statColorCs}`}
          >
            {stat}
          </div>

          <div
            className={`${tw2("font-leaderboard-card-small")} text-right text-neutral-04 font-semibold items-center`}
          >
            {statLabel}
          </div>
        </div>
      </div>
    </div>
  )
}

const GlobalRanking = () => {
  // TODO: Update once closer to campaing.
  //const { chain } = useChainDetail()
  //const campaignId = getCampaignId(chain)
  //const { data, isLoading: loading } = useCampaignStats({ chain, campaignId })
  //const volumeRanking = data?.leaderboard.volumeRanking ?? []
  //const pnlRanking = data?.leaderboard.pnlRanking ?? []
  //const pointRanking = data?.leaderboard.pointRanking ?? []
  const loading = false
  const volumeRanking: LeaderboardEntry[] = []
  const pnlRanking: LeaderboardEntry[] = []
  const pointRanking: LeaderboardEntry[] = []

  const volumeTableProps = {
    type: "volume" as const,
    loading,
    entries: volumeRanking,
  }
  const pnlTableProps = {
    type: "pnl" as const,
    loading,
    entries: pnlRanking,
  }
  const pointTableProps = {
    type: "points" as const,
    loading,
    entries: pointRanking,
  }

  const [rankType, setRankType] = useState<RankType>("volume")

  const result = (
    <div className="flex flex-col w-full px-5 py-4 bg-neutral-06/70 rounded-lg overflow-hidden">
      <div className="flex items-center justify-between">
        <div className="flex">
          <div
            className={`w-fit py-[0.125rem] ${tw2("font-leaderboard-title")} text-white font-bold rounded`}
          >
            Global Ranking
          </div>

          <div className="shrink-0 w-4" />

          <RankTypeButtons rankType={rankType} setRankType={setRankType} />
        </div>

        <div className="flex">
          <AllTimeStats />
        </div>
      </div>

      <div className="shrink-0 h-3" />

      <div className="flex grow overflow-hidden">
        {rankType === "volume" && <RankTable {...volumeTableProps} />}
        {rankType === "pnl" && <RankTable {...pnlTableProps} />}
        {rankType === "points" && <RankTable {...pointTableProps} />}
      </div>
    </div>
  )
  return result
}

const AllTimeStats = () => {
  const { chain } = useChainDetail()
  const { data } = useAllTimeStats({ chain })

  const totalUsers = formatValue(data?.totalUsers, {})
  const totalTrades = formatValue(data?.totalTrades, {})
  const totalVolume = data?.totalVolume ? format3SigFigDollars(data.totalVolume) : "-"

  const result = (
    <div className="flex grid grid-cols-3 pr-5 gap-5 w-full">
      <StatsCard label="Total Users" value={totalUsers} />
      <StatsCard label="Total Trades" value={totalTrades} />
      <StatsCard label="Total Volume" value={totalVolume} />
    </div>
  )
  return result
}

const StatsCard = ({ label, value }: { label: string; value: string }) => {
  return (
    <div
      className={`flex flex-col ${tw2("font-leaderboard-global-stats")} justify-center items-center gap-1`}
    >
      <div className="text-neutral-04">{label}</div>
      <div className="text-white">{value}</div>
    </div>
  )
}

type RankTypeButtonsProps = {
  rankType: RankType
  setRankType: (_: RankType) => void
}

const RankTypeButtons = ({ rankType, setRankType }: RankTypeButtonsProps) => {
  const makeButton = (rt: RankType) => {
    const selected = rankType === rt
    const name = getRankTypeName(rt)
    const icon = getRankTypeIcon(rt)
    const commonCs = `flex w-[7rem] h-[1.5rem] ${tw2("font-leaderboard-button")} items-center justify-center gap-2 rounded-[1.5rem]`
    const buttonCs = selected
      ? `${commonCs} text-white bg-blue`
      : `${commonCs} text-neutral-04 bg-none`
    const onClick = () => {
      setRankType(rt)
    }
    return (
      <button className={buttonCs} onClick={onClick}>
        <div className="w-4 h-4">
          <img src={getIcon(icon)} />
        </div>
        <div>{name}</div>
      </button>
    )
  }

  return (
    <div
      className={`flex shrink-0 grid grid-cols-3 items-center justify-center h-[1.875rem] px-[0.25rem] gap-4 ${tw2("font-leaderboard-button")} font-bold bg-neutral-08 rounded-[1.875rem]`}
    >
      {makeButton("volume")}
      {makeButton("pnl")}
      {makeButton("points")}
    </div>
  )
}

const getRankTypeName = (rankType: RankType) => {
  switch (rankType) {
    case "volume":
      return "Volume"
    case "pnl":
      return "PnL"
    case "points":
      return "Points"
  }
}

const getRankTypeIcon = (rankType: RankType): IconType => {
  switch (rankType) {
    case "volume":
      return "barchart"
    case "pnl":
      return "cash"
    case "points":
      return "coin"
  }
}

interface RankTableProps {
  type: RankType
  loading: boolean
  entries: LeaderboardEntry[]
}

const RankTable = ({ type, loading, entries }: RankTableProps) => {
  const ref = useRef<HTMLDivElement>(null) // eslint-disable-line no-null/no-null
  const { width: screenWidth } = useWindowSize()

  const data: DataType[] = entries.map((e, i) => ({
    key: `${i}`,
    type,
    rank: e.rank,
    address: e.address,
    value: e.value,
  }))

  const columns = useMemo(() => {
    return makeColumns({ type, screenWidth })
  }, [type, screenWidth])

  const table = (
    <div ref={ref} className={`shrink grow-0 flex flex-col w-full gap-[1.25rem] overflow-hidden`}>
      <InfoTable
        fullVersion={false} // TODO: remove, not used
        loading={loading}
        data={data}
        columns={columns}
      />
    </div>
  )
  return table
}

interface DataType {
  key: string
  type: RankType
  rank: number
  address: string
  value: BigDecimal
}

const columnHelper = createColumnHelper<DataType>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ColumnType = ColumnDef<DataType, any>

const makeColumns = ({
  type,
  screenWidth,
}: {
  type: RankType
  screenWidth: number
}): ColumnType[] => {
  return [
    columnHelper.accessor("rank", {
      id: "rank",
      size: 48,
      header: () => {
        return (
          <div
            className={`flex w-[6rem] h-[2rem] ${tw2("font-leaderboard-table")} justify-center items-center px-5`}
          >
            Rank
          </div>
        )
      },
      cell: (props) => {
        const rank = props.getValue()
        const content = (() => {
          switch (rank) {
            case 1:
              return (
                <div className="w-[1.125rem] h-[1.125rem]">
                  <Icon name="solid-trophy" iconFill="gold" />
                </div>
              )
            case 2:
              return (
                <div className="w-[1.125rem] h-[1.125rem]">
                  <Icon name="solid-trophy" iconFill="silver" />
                </div>
              )
            case 3:
              return (
                <div className="w-[1.125rem] h-[1.125rem]">
                  <Icon name="solid-trophy" iconFill="bronze" />
                </div>
              )
            default:
              return `${rank}`
          }
        })()

        return (
          <div
            className={`flex w-[6rem] h-[3.5rem] px-5 ${tw2("font-leaderboard-table")} justify-center items-center`}
          >
            {content}
          </div>
        )
      },
    }),

    columnHelper.accessor("address", {
      id: "address",
      header: () => {
        return (
          <div className={`flex ${tw2("font-leaderboard-table")} items-center h-[2rem]`}>
            Trader
          </div>
        )
      },
      cell: (props) => {
        const address = props.getValue().toString()
        const count = getAddressHexCount(screenWidth)
        const addressStr = count === undefined ? address : formatAddress(address, count)
        return (
          <div className={`flex h-[3.5rem] ${tw2("font-leaderboard-table")} items-center gap-2`}>
            <ProfileCircle size="1.5rem" address={address} />
            <div className="items-center">{addressStr}</div>
          </div>
        )
      },
    }),

    columnHelper.accessor("value", {
      id: "value",
      header: () => {
        return (
          <div
            className={`flex h-[2rem] px-5 ${tw2("font-leaderboard-table")} justify-end items-center`}
          >
            {getRankTypeName(type)}
          </div>
        )
      },
      cell: (props) => {
        const { text, color } = getValueTextAndColor(type, props.getValue())
        return (
          <div
            className={`flex h-[3.5rem] px-5 ${tw2("font-leaderboard-table")} ${color} justify-end items-center`}
          >
            {text}
          </div>
        )
      },
    }),
  ]
}

const getAddressHexCount = (screenWidth: number) => {
  if (screenWidth >= 550) {
    return undefined
  } else if (screenWidth >= 530) {
    return 20
  } else if (screenWidth >= 505) {
    return 18
  } else if (screenWidth >= 480) {
    return 16
  } else if (screenWidth >= 465) {
    return 14
  } else if (screenWidth >= 450) {
    return 12
  } else if (screenWidth >= 430) {
    return 11
  } else if (screenWidth >= 410) {
    return 10
  } else if (screenWidth >= 370) {
    return 9
  } else if (screenWidth >= 350) {
    return 7
  } else if (screenWidth >= 330) {
    return 6
  } else {
    return 5
  }
}

const getValueTextAndColor = (type: RankType, value?: BigDecimal) => {
  if (!value) return { text: "-", color: "text-white" }

  switch (type) {
    case "volume": {
      const text = formatValue(value, { dollar: true, decimals: 2 })
      return { text, color: "text-blue-03" }
    }
    case "pnl": {
      const text = formatValue(value, { dollar: true, decimals: 2, signed: true })

      const color = (() => {
        if (value.isZero()) {
          return "text-white"
        } else if (value.lt(BigDecimal.zero())) {
          return "text-red"
        } else {
          return "text-green"
        }
      })()
      return { text, color }
    }
    case "points": {
      const text = value.format({ locale: "en-US" })
      return { text, color: "text-blue-03" }
    }
  }
}
