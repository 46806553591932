import React, { PropsWithChildren } from "react"

interface Props {
  className?: string
  disabled?: boolean
  onClick?: (_: React.SyntheticEvent) => void
}

export const Button = ({ className, disabled, onClick, children }: PropsWithChildren<Props>) => {
  const defaultCs = "flex shrink-0 items-center justify-center align-middle"
  const focusCs = "focus:outline-none"
  const disabledCs =
    "disabled:bg-none disabled:bg-neutral-03 disabled:bg-opacity-50 disabled:cursor-not-allowed disabled:text-neutral-03 disabled:text-opacity-50"
  const hoverCs = "hover:cursor-pointer hover:disabled:cursor-not-allowed "

  const cs = `${className ?? ""} ${defaultCs} ${hoverCs} ${focusCs} ${disabledCs}`
  onClick = onClick ?? (() => {})

  return (
    <button className={cs} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}
