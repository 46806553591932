import { useState } from "react"

import { InputState } from "../types"

export function useInputState<T>(defaultInput: InputState<T>): {
  input: InputState<T>
  update: (_: InputState<T>) => void
  reset: () => void
} {
  const [value, setValue] = useState<T>(defaultInput.value)
  const [text, setText] = useState<string>(defaultInput.text)

  const update = (_input: InputState<T>) => {
    setValue(_input.value)
    setText(_input.text)
  }

  const reset = () => {
    setValue(defaultInput.value)
    setText(defaultInput.text)
  }

  return {
    input: { value, text },
    update,
    reset,
  }
}
