import { SyntheticEvent } from "react"

import * as sk from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "../../api/silverkoi"
import { useMobileMainPageContext, useSilverKoiApi, useSubmitTx } from "../../hooks"
import { MobileDetailView } from "./MobileDetailView"
import { TriggerDetailViewData } from "./MobileMainPageContext"
import { MobileSmallButtonWithIcon } from "./MobileSmallButtonWithIcon"

type Props = {
  data: TriggerDetailViewData
}

export const MobileTriggerDetailView = ({ data }: Props) => {
  const { symbol, size, limitPrice, type, side, marketPrice, triggerPrice, fromAbove, trigger } =
    data.trigger
  const symbolMeta = skoi.getSymbolMeta(symbol)
  const { data: api } = useSilverKoiApi()
  const submitTx = useSubmitTx()
  const { setViewData } = useMobileMainPageContext()

  const triggerPricePrefix = fromAbove ? "<" : ">"

  const longOrShort = (() => {
    switch (side) {
      case "bid":
        return "Long"
      case "ask":
        return "Short"
    }
  })()

  const onClickEdit = () => {
    setViewData({ type: "edit-trigger", symbolMeta, trigger: data.trigger })
  }

  const onClickCancel = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      const res = await sk.cancelTrigger({
        api,
        marketId: trigger.marketMeta.marketId,
        traderId: trigger.traderId,
        triggerId: trigger.triggerId,
      })
      setViewData({ type: "overview", symbolMeta, initialTab: "orderbook" })

      return res
    }
    submitTx.mutate({ description: "cancel trigger", txHashFn })
  }

  const buttons = (
    <div className="shrink-0 flex gap-1">
      <MobileSmallButtonWithIcon text="Edit" icon="edit" colorCn="bg-blue" onClick={onClickEdit} />
      <MobileSmallButtonWithIcon
        text="Close"
        icon="cancel"
        colorCn="bg-red"
        onClick={onClickCancel}
      />
    </div>
  )

  const detailData = [
    { label: "Order Size", value: size.toString(4) },
    { label: "Limit Price", value: formatAmount(limitPrice) },
    { label: "Market Price", value: marketPrice ? formatAmount(marketPrice) : "N/A" },
    { label: "Trigger Price", value: triggerPricePrefix + formatAmount(triggerPrice) },
    { label: "Trigger Order Side", value: longOrShort },
    { label: "Type", value: type },
  ]

  return <MobileDetailView symbolMeta={symbolMeta} buttons={buttons} data={detailData} />
}

const formatAmount = (value: BigDecimal) => {
  return "$" + value.toString(4)
}
