import { createContext } from "react"

export type MobileContextData = {
  menuOpen: boolean
  setMenuOpen: (_: boolean) => void
  walletMenuOpen: boolean
  setWalletMenuOpen: (_: boolean) => void
}

export const MobileContext = createContext<MobileContextData>(
  undefined as unknown as MobileContextData,
)
