import { PropsWithChildren, useState } from "react"

import * as sk from "silverkoi"

import { MobileMainPageContext, ViewData } from "./MobileMainPageContext"

interface Props {
  symbolMeta: sk.SymbolMeta
}

export const MobileMainPageContextProvider = ({
  symbolMeta,
  children,
}: PropsWithChildren<Props>) => {
  const [viewData, setViewData] = useState<ViewData>({
    type: "overview",
    symbolMeta,
    initialTab: "orderbook",
  })
  const value = {
    symbolMeta,
    viewData,
    setViewData,
  }
  return <MobileMainPageContext.Provider value={value}>{children}</MobileMainPageContext.Provider>
}
