import { useMemo } from "react"
import { useAccount } from "wagmi"

import {
  MarketSnapshot,
  OrderSide,
  OrderSnapshot,
  PositionSnapshot,
  TimeInForce,
  TriggerSnapshot,
} from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "../api/silverkoi"
import { useMarkets, useUserState } from "../hooks"
import { UserState } from "../types"

export type UseDataReturnType<T> = {
  data?: T[]
  isLoading: boolean
}

export type PositionData = {
  key: string
  symbol: string
  userState: UserState
  position: PositionSnapshot
  leverage?: BigDecimal
  positionId: bigint
  size: BigDecimal
  notional: BigDecimal
  openInterestNotional: BigDecimal
  marketPrice?: BigDecimal
  twapMarketPrice?: BigDecimal
  entryPrice?: BigDecimal
  liquidationPrice?: BigDecimal
  collateral: BigDecimal
  unrealizedPnlChangePct: BigDecimal
  unrealizedPnl: BigDecimal
  unsettledFunding: BigDecimal
  freeCollateral: BigDecimal
  badDebt: BigDecimal
}

export const usePositionData = (): UseDataReturnType<PositionData> => {
  const { isConnected } = useAccount()
  const { data: userState, isLoading: userStateIsLoading } = useUserState()
  const loading = isConnected && (!userState || userStateIsLoading)

  // TODO: Revisit use of useMemo
  const result = useMemo(() => {
    if (loading) {
      return { isLoading: true, data: undefined }
    } else if (!userState) {
      return { isLoading: false, data: [] }
    } else {
      const positions = userState.getNonEmptyPositionsThatAreNotZeroSizeWithNonZeroOpenInterest()
      const data = positions.map((p) => getPositionData(userState, p))
      return { isLoading: false, data }
    }
  }, [loading, userState])
  return result
}

const getPositionData = (userState: UserState, position: PositionSnapshot): PositionData => {
  const { symbol } = position.symbolMeta
  const key = `${symbol}-${position.positionId}`
  const marketPrice = userState.getMarketPrice(symbol)
  const twapMarketPrice = userState.getTwapMarketPrice(symbol)
  const liquidationPrice = skoi.resolveCurrentPrice({
    price: position.liquidationPrice,
    marketPrice,
  })
  const freeCollateral = position.freeCollateral.max(BigDecimal.zero())

  const unrealizedPnlChangePct = position.notional.isZero()
    ? BigDecimal.zero()
    : position.unrealizedPnl.div(position.notional.abs()).mul(BigDecimal.fromReal(100, 1))

  return {
    key,
    symbol,
    position,
    userState,
    positionId: position.positionId,
    leverage: position.leverage,
    size: position.size,
    notional: position.notional,
    openInterestNotional: position.openInterestNotional,
    marketPrice,
    twapMarketPrice,
    entryPrice: position.entryPrice,
    liquidationPrice,
    collateral: position.collateral,
    unrealizedPnl: position.unrealizedPnl,
    unrealizedPnlChangePct,
    unsettledFunding: position.unsettledFunding,
    freeCollateral,
    badDebt: position.badDebt,
  }
}

export type OrderData = {
  key: string
  symbol: string
  userState: UserState
  position: PositionSnapshot
  order: OrderSnapshot
  positionId: bigint
  orderId: bigint
  side: OrderSide
  price: BigDecimal
  size: BigDecimal
  tif: TimeInForce
  deadline: bigint
  remainingSize: BigDecimal
  executedSize: BigDecimal
}

export const useOrderData = (): UseDataReturnType<OrderData> => {
  const { isConnected } = useAccount()
  const { data: userState, isLoading: userStateIsLoading } = useUserState()
  const loading = isConnected && (!userState || userStateIsLoading)
  // TODO: Revisit use of useMemo
  const result = useMemo(() => {
    if (loading) {
      return { isLoading: true, data: undefined }
    } else if (!userState) {
      return { isLoading: false, data: [] }
    } else {
      const orders = userState.getOpenOrders()
      const data = orders.map((o) => getOrderData(userState, o))
      return { isLoading: false, data }
    }
  }, [loading, userState])
  return result
}

const getOrderData = (userState: UserState, order: OrderSnapshot): OrderData => {
  const { symbol } = order.symbolMeta
  const position = userState.getPosition({ symbol, positionId: order.positionId })
  if (!position) {
    throw new Error(`internal error, position not found for order ${order.orderId}`)
  }
  const key = `${symbol}-${position.positionId}-${order.orderId}`

  return {
    key,
    symbol,
    userState,
    position,
    order,
    positionId: position.positionId,
    orderId: order.orderId,
    side: order.side,
    price: order.price,
    size: order.size,
    tif: order.tif,
    deadline: order.deadline,
    remainingSize: order.remainingSize,
    executedSize: order.executedSize,
  }
}

export type TriggerData = {
  key: string
  symbol: string
  userState: UserState
  position: PositionSnapshot
  trigger: TriggerSnapshot
  positionId: bigint
  triggerId: bigint
  size: BigDecimal
  side: OrderSide
  type: string
  marketPrice?: BigDecimal
  triggerPrice: BigDecimal
  limitPrice: BigDecimal
  fromAbove: boolean
}

export const useTriggerData = (): UseDataReturnType<TriggerData> => {
  const { isConnected } = useAccount()
  const { data: markets } = useMarkets()
  const { data: userState, isLoading: userStateIsLoading } = useUserState()
  const loading = isConnected && (!userState || userStateIsLoading)

  // TODO: Revisit use of useMemo
  const result = useMemo(() => {
    if (loading) {
      return { isLoading: true, data: undefined }
    } else if (!userState) {
      return { isLoading: false, data: [] }
    } else {
      const triggers = userState.getTriggers()
      const data = getTriggerData(markets, userState, triggers)
      return { isLoading: false, data }
    }
  }, [loading, userState, markets])
  return result
}

const getTriggerData = (
  markets: Map<string, MarketSnapshot> | undefined,
  userState: UserState,
  triggers: TriggerSnapshot[],
): TriggerData[] => {
  const data: TriggerData[] = []
  triggers.map((trigger) => {
    if (!trigger.fromAboveTriggerPrice.isZero()) {
      data.push(getOneTriggerData(markets, userState, trigger, true))
    }
    if (!trigger.fromBelowTriggerPrice.isZero()) {
      data.push(getOneTriggerData(markets, userState, trigger, false))
    }
  })
  return data
}

const getOneTriggerData = (
  markets: Map<string, MarketSnapshot> | undefined,
  userState: UserState,
  trigger: TriggerSnapshot,
  fromAbove: boolean,
): TriggerData => {
  const { symbol } = trigger.symbolMeta
  const position = userState.getPosition({ symbol, positionId: trigger.positionId })
  if (!position) {
    throw new Error(`internal error, position not found for trigger ${trigger.triggerId}`)
  }
  const key = `${symbol}-${position.positionId}-${trigger.triggerId}`

  const size = trigger.side === "bid" ? trigger.size : trigger.size.neg()

  const triggerPrice = fromAbove ? trigger.fromAboveTriggerPrice : trigger.fromBelowTriggerPrice
  const limitPrice = fromAbove ? trigger.fromAboveLimitPrice : trigger.fromBelowLimitPrice

  const marketPrice = markets?.get(symbol)?.marketPrice
  const type = marketPrice ? getTriggerType({ position, marketPrice, trigger, fromAbove }) : ""

  return {
    key,
    symbol,
    userState,
    position,
    trigger,
    positionId: position.positionId,
    triggerId: trigger.triggerId,
    size,
    side: trigger.side,
    type,
    marketPrice,
    triggerPrice,
    limitPrice,
    fromAbove,
  }
}

const getTriggerType = ({
  position,
  marketPrice,
  trigger,
  fromAbove,
}: {
  position: PositionSnapshot
  marketPrice: BigDecimal
  trigger: TriggerSnapshot
  fromAbove: boolean
}): string => {
  const triggerPrice = fromAbove ? trigger.fromAboveTriggerPrice : trigger.fromBelowTriggerPrice
  const limitPrice = fromAbove ? trigger.fromAboveLimitPrice : trigger.fromBelowLimitPrice

  const direction = trigger.side === "bid" ? "Long" : "Short"
  if (position.size.isZero()) {
    if (limitPrice.isZero()) {
      return `${direction} Stop Market`
    } else {
      return `${direction} Stop Limit`
    }
  } else if (position.size.gt(BigDecimal.zero())) {
    if (triggerPrice.gt(marketPrice)) return "Take Profit"
    else return "Stop Loss"
  } else {
    if (triggerPrice.lt(marketPrice)) return "Take Profit"
    else return "Stop Loss"
  }
}
