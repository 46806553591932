import { useState } from "react"
import { useInterval } from "usehooks-ts"

export const useTime = ({ delayMs }: { delayMs: number | null }) => {
  const [now, setNow] = useState<number>(Date.now())

  useInterval(() => {
    setNow(Date.now())
  }, delayMs)
  return now
}
