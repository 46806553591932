import React, { useEffect } from "react"
import { useAccount } from "wagmi"

import * as skoi from "../../api/silverkoi"
import { useTradeContext, useTradeContextActions } from "../../hooks"
import { tw } from "../../utils"
import { OrderTypeButtons } from "../OrderTypeButtons"
import { ScrollableArea } from "../ScrollableArea"
import { Toggle } from "../Toggle"
import { TransactionSummary } from "../TransactionSummary"
import { MobileTradingPanelInputs } from "./MobileTradingPanelInputs"

export const MobileTradingPanel = () => {
  const { address, isConnected } = useAccount()
  const tradeContext = useTradeContext()
  const { symbol, useTradeContextStore } = tradeContext
  const side = useTradeContextStore((s) => s.input.side)
  const { reset, setInputMode, setOrderType, setTif, setLeverage, setSide, setSymbol } =
    useTradeContextActions()

  const resetInputs = () => {
    reset()
    setSymbol(symbol)
    setInputMode("NewPosition")
    setOrderType("market")
    setTif("gtc")
    setLeverage({ value: skoi.MAX_LEVERAGE, text: skoi.MAX_LEVERAGE.toStringTrimmed() })
  }

  // Clear inputs when wallet changes.
  useEffect(() => {
    resetInputs()
  }, [symbol, isConnected, address]) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSide(e.target.checked ? "ask" : "bid")
    // TODO: reset trigger prices
  }

  return (
    <div className="flex flex-col min-h-0 min-w-0 pb-5">
      <Toggle
        checked={side === "ask"}
        onChange={onToggleChange}
        uncheckedText="LONG"
        checkedText="SHORT"
        classNames={{
          beforeBackground: `before:bg-green`,
          checkedBackground: `before:bg-red`,
          beforeBorder: "before:rounded-[24px]",
          border: "rounded-[24px]",
          toggleText: tw("font-button-2") + " text-neutral-01",
        }}
      />

      <div className="shrink-0 h-2" />

      <OrderTypeButtons />

      <div className="shrink-0 h-2" />

      <ScrollableArea>
        <MobileTradingPanelInputs withLeverage={true} />
        <div className="shrink-0 h-5" />
        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}
