import * as skoi from "../../api/silverkoi"
import { PositionData, useMobileMainPageContext, usePositionData } from "../../hooks"
import { formatValue, getIcon, tw, valueToColor } from "../../utils"
import { PositionCell } from "../PositionCell"

export const MobilePositionList = () => {
  const { data, isLoading } = usePositionData()

  const cards = (data ?? []).map((d) => <Card key={d.key} data={d} />)

  return isLoading ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-full h-fit gap-[0.5rem]">{cards}</div>
  )
}

const Card = ({ data }: { data: PositionData }) => {
  const { setViewData } = useMobileMainPageContext()
  const { symbol, unrealizedPnl, unrealizedPnlChangePct, marketPrice, position } = data
  const pnlColor = valueToColor(unrealizedPnl)
  const { decimals } = skoi.getSymbolConfig(symbol)
  const marketPriceText = formatValue(marketPrice, { decimals, minDecimals: decimals })
  const unrealizedPnlText = formatValue(unrealizedPnl, {
    signed: true,
    decimals: 2,
    minDecimals: 2,
  })
  const unrealizedPnlChangePctText = formatValue(unrealizedPnlChangePct, {
    signed: true,
    decimals: 2,
    minDecimals: 2,
  })

  const symbolMeta = skoi.getSymbolMeta(symbol)
  const onClick = () => {
    setViewData({ type: "position-detail", symbolMeta, position: data })
  }

  return (
    <div
      className={`flex items-center px-3 py-2 bg-neutral-07 text-neutral-01 ${tw("font-caption-1")} justify-between`}
      onClick={onClick}
    >
      <PositionCell position={position} enableClick={false} />

      <div className="grow-0 shrink-0 flex flex-col pl-3 gap-1 items-end">
        <div style={{ color: pnlColor }}>
          {unrealizedPnlText} ({unrealizedPnlChangePctText}%)
        </div>

        <div className="text-neutral-04">${marketPriceText}</div>
      </div>
    </div>
  )
}

const Loading = () => (
  <div className="flex flex-col w-full h-[10rem] items-center justify-center py-3 gap-5">
    <img src={getIcon("loading-spin-blue")} className="w-12 h-12" />
  </div>
)
