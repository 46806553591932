import React, { useState } from "react"
import Toggle from "react-toggle"

import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { InputState } from "~/types"
import { USD_SYMBOL, parseBigDecimal, tw2 } from "~/utils"
import { PriceInputExtraInfo } from "./PriceInputExtraInfo"
import { TradeNumberInput } from "./TradeNumberInput"

interface Props {
  label: string
  placeholder?: string
  input: InputState<BigDecimal | undefined>
  setInput: (_: InputState<BigDecimal | undefined>) => void
  defaultEnabled: boolean
}

export const ToggledPriceInput = ({
  label,
  placeholder,
  input,
  setInput: _setInput,
  defaultEnabled,
}: Props) => {
  const [enabled, setEnabled] = useState(defaultEnabled)

  const setInput = (args: InputState<BigDecimal | undefined>) => {
    if (!enabled) return
    _setInput(args)
  }

  const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.target.checked)
    setInput({ value: undefined, text: "" })
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, skoi.PRICE_INPUT_DECIMALS)
    setInput({ text, value })
  }

  return (
    <div className="w-100">
      <div className="flex items-center w-full justify-between pb-1">
        <div className={`flex ${tw2("font-input-label")} text-white pr-1`}>{label}</div>
        <Toggle defaultChecked={enabled} icons={false} onChange={onToggleChange} />
      </div>

      <TradeNumberInput
        disabled={!enabled}
        placeholder={placeholder ?? "0.00"}
        value={input.text}
        onChange={onValueChange}
        unit={USD_SYMBOL}
      />

      <div className="shrink-0 h-1" />

      <PriceInputExtraInfo setPrice={setInput} />
    </div>
  )
}
