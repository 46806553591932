import { FaFaucetDrip } from "react-icons/fa6"
import { MdClose, MdContentCopy } from "react-icons/md"
import { toast } from "react-toastify"
import { useCopyToClipboard } from "usehooks-ts"
import { useAccount, useDisconnect } from "wagmi"

import * as sk from "silverkoi"

import { Logo } from "~/components/Logo"
import { Icon } from "~/components/icons"
import {
  useAllTimeUserStats,
  useChainDetail,
  useMobileContext,
  useNativeTokenMeta,
  useSilverKoiApi,
  useSubmitTx,
  useTraderId,
  useUserState,
} from "~/hooks"
import {
  USD_SYMBOL,
  formatAddress,
  formatUsdcValue,
  formatValue,
  getCollateralUsdLogo,
  getIcon,
  tw,
} from "~/utils"

const maxWidthCs = "max-w-[18rem]"
const fontCs = "text-[0.8rem]"
const buttonFontCs = tw("font-button-1-mobile")

export const MobileWalletMenu = () => {
  const { walletMenuOpen } = useMobileContext()
  return walletMenuOpen ? (
    <div className="absolute flex w-full h-full justify-end bg-white/10 backdrop-blur-sm z-[21]">
      <MobileMenuBody />
    </div>
  ) : (
    <></>
  )
}

const MobileMenuBody = () => {
  const { chain } = useChainDetail()
  const { address } = useAccount()
  const { data: userState } = useUserState()
  const { data: allTimeUserStats } = useAllTimeUserStats({ chain, address })

  const { data: api } = useSilverKoiApi()
  const { data: traderId } = useTraderId()
  const traderIsRegistered = traderId !== undefined
  const hasFaucet = !!api?.faucet
  const showFaucetButton = hasFaucet && traderIsRegistered
  const buttonsGridCs = showFaucetButton ? "grid-cols-2" : ""

  const nativeTokenMeta = useNativeTokenMeta()

  return (
    <div
      className={`flex flex-col grow ${maxWidthCs} h-full px-3 pt-3 bg-neutral-07 ${fontCs} font-semibold text-white`}
    >
      <div className="flex h-[2rem] items-center justify-between">
        <Logo sizeRem={2} hover={false} />
        <CloseButton />
      </div>

      <div className="flex flex-col shrink-0 w-full pt-3 justify-center items-center gap-3">
        {address && <WalletAddress address={address} />}

        <div className="flex flex-col w-full gap-3 p-2 rounded-lg bg-neutral-06 shadow-inner shadow-black">
          <div className="flex w-full justify-between">
            <div className="flex flex-row gap-2 items-center">Total Account Value:</div>
            <MaybeValue value={formatUsdcValue(userState?.userBalances?.accountValue)} />
          </div>

          <div className="flex w-full justify-between">
            <div className="flex flex-row gap-2 items-center">
              <img src={getCollateralUsdLogo()} className="w-4 h-4" />
              <div>{USD_SYMBOL}</div>
            </div>
            <MaybeValue value={formatUsdcValue(userState?.userBalances?.usdcBalance)} />
          </div>

          <div className="flex w-full justify-between">
            <div className="flex flex-row gap-2 items-center">
              <img src={nativeTokenMeta.image} className="w-4 h-4" />
              <div>{nativeTokenMeta.name}</div>
            </div>
            <MaybeValue value={userState?.userBalances?.nativeBalance?.toString(4)} />
          </div>

          <div className="flex w-full justify-between">
            <div className="flex flex-row gap-2 items-center">
              <img src={getIcon("coin")} className="w-4 h-4" />
              <div>Points</div>
            </div>
            <MaybeValue value={formatValue(allTimeUserStats?.points)} />
          </div>
        </div>
      </div>

      <div className="shrink-0 h-2" />

      <div className={`flex w-full ${buttonsGridCs} gap-2`}>
        {showFaucetButton && <FaucetButton />}
        <DisconnectButton />
      </div>
    </div>
  )
}

const MaybeValue = ({ value }: { value?: string }) => {
  return (
    <div className="flex items-center">
      {value === undefined ? (
        <img src={getIcon("loading-spin")} className="w-4 h-4" />
      ) : (
        <div>{value}</div>
      )}
    </div>
  )
}

const WalletAddress = ({ address }: { address: string }) => {
  const [, copy] = useCopyToClipboard()
  const onClick = () => {
    try {
      copy(address)
      toast.info("Copied address to clipboard!")
    } catch (_: unknown) {
      toast.error("Failed to copy address to clipboard!")
    }
  }

  return (
    <div className="flex w-full items-center justify-center gap-2" onClick={onClick}>
      <div className="w-[1rem] h-[1rem]">
        <Icon name="wallet" iconFill="white" />
      </div>

      <div className={`${fontCs} font-semibold text-white`}>{formatAddress(address, 12)}</div>

      <MdContentCopy size="1rem" color="white" />
    </div>
  )
}

const CloseButton = () => {
  const { setWalletMenuOpen } = useMobileContext()
  return <MdClose size="1.5rem" color="white" onClick={() => setWalletMenuOpen(false)} />
}

const DisconnectButton = () => {
  const { disconnect } = useDisconnect()
  const { setWalletMenuOpen } = useMobileContext()
  const onClick = () => {
    setWalletMenuOpen(false)
    disconnect()
  }

  return (
    <button
      className={
        `flex w-full px-2 py-2 items-center justify-center gap-2 ${buttonFontCs} ` +
        " bg-[#151B2B] rounded-[16px] border border-solid border-white/50"
      }
      onClick={onClick}
    >
      <div className="w-5 h-5">
        <Icon name="logout" iconFill="red" />
      </div>

      <div className="text-red">Disconnect</div>
    </button>
  )
}

const FaucetButton = () => {
  const { setWalletMenuOpen } = useMobileContext()
  const { data: api } = useSilverKoiApi()

  const submitTx = useSubmitTx()

  const onClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!api || !api.faucet) return
    const txHashFn = async () => {
      return await sk.replenishUsdcFromFaucet({ api })
    }
    submitTx.mutate({ description: "get testnet token", txHashFn })
    setWalletMenuOpen(false)
  }

  const disabled = submitTx.isPending

  return (
    <button
      className={
        `flex w-full px-2 py-2 items-center justify-center gap-2 ${buttonFontCs} ` +
        " bg-neutral-05 rounded-[16px] border border-solid border-white/50"
      }
      onClick={onClick}
      disabled={disabled}
    >
      <FaFaucetDrip size="1rem" color="white" />

      <div>Get {USD_SYMBOL}</div>
    </button>
  )
}
