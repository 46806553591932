import { IconProps, IconSvg } from "./IconSvg"

export const PieChart = ({ fill }: IconProps) => {
  return (
    <IconSvg viewBox="0 0 24 24">
      <g
        transform="matrix(1.028576, 0, 0, 1.028514, -2.333377, -2.332031)"
        style={{ transformOrigin: "2.33338px 2.33203px" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 9.43124C9.53077 9.59508 8.60235 9.96204 7.77772 10.513C6.62658 11.2822 5.72937 12.3755 5.19955 13.6545C4.66974 14.9336 4.53112 16.3411 4.80121 17.699C5.07131 19.0568 5.738 20.3041 6.71696 21.2831C7.69593 22.262 8.94321 22.9287 10.3011 23.1988C11.6589 23.4689 13.0664 23.3303 14.3455 22.8005C15.6246 22.2707 16.7178 21.3735 17.487 20.2223C18.038 19.3977 18.405 18.4693 18.5688 17.5H11.6667C11.3573 17.5 11.0605 17.3771 10.8418 17.1583C10.623 16.9395 10.5 16.6428 10.5 16.3333V9.43124ZM6.48139 8.57295C8.01625 7.54739 9.82075 7 11.6667 7C12.311 7 12.8334 7.52233 12.8334 8.16667V15.1667H19.8334C20.4777 15.1667 21 15.689 21 16.3333C21 18.1793 20.4527 19.9838 19.4271 21.5187C18.4015 23.0535 16.9439 24.2498 15.2384 24.9562C13.533 25.6626 11.6564 25.8475 9.84587 25.4873C8.03538 25.1272 6.37234 24.2383 5.06705 22.933C3.76176 21.6277 2.87284 19.9647 2.51272 18.1542C2.15259 16.3437 2.33742 14.4671 3.04384 12.7616C3.75025 11.0562 4.94653 9.59851 6.48139 8.57295Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5083 2.67374C15.7271 2.45495 16.0239 2.33203 16.3333 2.33203C18.8086 2.33203 21.1826 3.31536 22.933 5.0657C24.6833 6.81604 25.6666 9.19001 25.6666 11.6654C25.6666 12.3097 25.1443 12.832 24.5 12.832L16.3333 12.832C16.0239 12.832 15.7271 12.7091 15.5083 12.4903C15.2895 12.2715 15.1666 11.9748 15.1666 11.6654V3.4987C15.1666 3.18928 15.2895 2.89253 15.5083 2.67374ZM17.5 4.76324V10.4987L23.2354 10.4987C22.9948 9.075 22.3175 7.75009 21.283 6.71562C20.2486 5.68114 18.9237 5.00389 17.5 4.76324Z"
          fill={fill}
        />
      </g>
    </IconSvg>
  )
}
