import { WagmiAdapter } from "@reown/appkit-adapter-wagmi"
import { type CaipNetwork } from "@reown/appkit-common"
import { createAppKit } from "@reown/appkit/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactNode } from "react"
import { WagmiProvider } from "wagmi"

import { env } from "~/env"

const queryClient = new QueryClient()

const projectId = env.VITE_APPKIT_PROJECT_ID
const metadata = {
  name: "Silver Koi",
  description: "RWA perps DEX for pro traders",
  url: "https://app-alpha.silverkoi.io",
  icons: [],
}

const plumeDevnet: CaipNetwork = {
  id: "eip155:98864" as const,
  chainId: 98864,
  chainNamespace: "eip155" as const,
  name: "Plume Devnet",
  currency: "ETH",
  explorerUrl: "https://test-explorer.plumenetwork.xyz",
  rpcUrl: "https://test-rpc.plumenetwork.xyz",
}

const networks = [plumeDevnet]

const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
})

createAppKit({
  adapters: [wagmiAdapter],
  networks,
  projectId,
  metadata,
  features: {
    analytics: true,
  },
})

export function AppKitProvider({ children }: { children?: ReactNode }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
