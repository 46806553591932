import React from "react"

import * as skoi from "~/api/silverkoi"
import { useTradeContext } from "~/hooks"
import { useTradeContextActions } from "~/stores"
import { USD_SYMBOL, parseBigDecimal } from "~/utils"
import { PriceInputExtraInfo } from "./PriceInputExtraInfo"
import { TradeNumberInput } from "./TradeNumberInput"

export const LimitPriceInput = () => {
  const { useTradeContextStore } = useTradeContext()
  const limitPrice = useTradeContextStore((s) => s.input.limitPrice)
  const { setLimitPrice } = useTradeContextActions(useTradeContextStore)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { text, value } = parseBigDecimal(e.target.value, skoi.PRICE_INPUT_DECIMALS)
    setLimitPrice({ value, text })
  }

  return (
    <div className="w-full">
      <div className="relative w-full pb-1">
        <TradeNumberInput
          label="Limit Price"
          placeholder={"0.00"}
          value={limitPrice.text}
          onChange={onChange}
          unit={USD_SYMBOL}
        />
      </div>

      <PriceInputExtraInfo setPrice={setLimitPrice} />
    </div>
  )
}
