import { Chain } from "silverkoi"

export type CampaignId = "plume_testnet_campaign" | "plume_devnet_campaign"

export const getCompetitionDates = (chain: Chain): [string, string] | undefined => {
  // TODO: Remove hard-coding once trading comp dates are queryable.
  switch (chain) {
    case "plume-devnet":
      //return ["2024-07-01", "2024-07-15"]
      //return ["2024-08-08", "2024-08-29"]
      return ["2024-08-09", "2024-09-06"]
    case "arbitrum-sepolia":
      return ["2024-04-03", "2024-04-17"]
    default:
      return undefined
  }
}

// TODO: Support concurrent campaigns.
export const getCampaignId = (chain: Chain): CampaignId | undefined => {
  // TODO: Remove hard-coding once trading comp dates are queryable.
  switch (chain) {
    case "plume-devnet":
      return "plume_testnet_campaign"
    default:
      return undefined
  }
}

export const getCampaignTitle = (id: CampaignId): string => {
  switch (id) {
    case "plume_testnet_campaign":
      return "Plume Testnet Campaign 1"
    case "plume_devnet_campaign":
      return "Plume Devnet Campaign"
  }
}
