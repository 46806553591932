import { useEffect } from "react"

import { BigDecimal } from "silverkoi/math"

import { CurrentSizeAndCollateralCards } from "~/components/CurrentSizeAndCollateralCards"
import { OrderTypeButtons } from "~/components/OrderTypeButtons"
import { ScrollableArea } from "~/components/ScrollableArea"
import { TransactionSummary } from "~/components/TransactionSummary"
import { TradeContextProvider } from "~/contexts/TradeContextProvider"
import { useDefaultLeverage, useTradeContextActions } from "~/hooks"
import { MobileMainButton } from "./MobileMainButton"
import { ReducePositionViewData } from "./MobileMainPageContext"
import { MobileTradingPanelInputs } from "./MobileTradingPanelInputs"

type Props = {
  data: ReducePositionViewData
}

export const MobileReducePositionView = ({ data }: Props) => {
  const { position } = data.position
  return (
    <TradeContextProvider symbol={position.symbolMeta.symbol} defaultPosition={position}>
      <Body data={data} />
      <div className="grow" />
      <MobileMainButton />
    </TradeContextProvider>
  )
}

const Body = ({ data }: Props) => {
  const { position } = data.position
  const { reset, setInputMode, setLeverage, setOrderType, setSide } = useTradeContextActions()
  const defaultLeverage = useDefaultLeverage(position)
  const defaultSize = position.size.abs()
  const side = position.size.gt(BigDecimal.zero()) ? "ask" : "bid"

  useEffect(() => {
    reset()
    setInputMode("ReducePosition")
    setLeverage(defaultLeverage)
    setOrderType("market")
    setSide(side)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col w-full h-full px-3 bg-black rounded-lg overflow-hidden">
      <div className="shrink-0 h-2" />

      <CurrentSizeAndCollateralCards position={position} />

      <div className="shrink-0 h-3" />

      <OrderTypeButtons excludeStopLimit={true} />

      <div className="shrink-0 h-3" />

      <ScrollableArea>
        <MobileTradingPanelInputs withLeverage={true} defaultSize={defaultSize} />
        <TransactionSummary />
      </ScrollableArea>
    </div>
  )
}
