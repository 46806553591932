import { createContext } from "react"

import * as sk from "silverkoi"

import { OrderData, PositionData, TriggerData } from "~/hooks"

export type OverviewTab = "positions" | "orders" | "triggers" | "orderbook"

export type OverviewViewData = {
  type: "overview"
  symbolMeta: sk.SymbolMeta
  initialTab: OverviewTab
}

export type TradeViewData = {
  type: "trade"
  symbolMeta: sk.SymbolMeta
}

export type ReducePositionViewData = {
  type: "reduce-position"
  symbolMeta: sk.SymbolMeta
  position: PositionData
}

export type EditPositionViewData = {
  type: "edit-position"
  symbolMeta: sk.SymbolMeta
  position: PositionData
}

export type CreateTriggerViewData = {
  type: "create-trigger"
  symbolMeta: sk.SymbolMeta
  position: PositionData
}

export type EditTriggerViewData = {
  type: "edit-trigger"
  symbolMeta: sk.SymbolMeta
  trigger: TriggerData
}

export type PositionDetailViewData = {
  type: "position-detail"
  symbolMeta: sk.SymbolMeta
  position: PositionData
}

export type OrderDetailViewData = {
  type: "order-detail"
  symbolMeta: sk.SymbolMeta
  order: OrderData
}

export type TriggerDetailViewData = {
  type: "trigger-detail"
  symbolMeta: sk.SymbolMeta
  trigger: TriggerData
}

export type ViewData =
  | CreateTriggerViewData
  | EditPositionViewData
  | EditTriggerViewData
  | OrderDetailViewData
  | OverviewViewData
  | PositionDetailViewData
  | ReducePositionViewData
  | TradeViewData
  | TriggerDetailViewData

export type MobileMainPageContextData = {
  symbolMeta: sk.SymbolMeta
  viewData: ViewData
  setViewData: (_: ViewData) => void
}

export const MobileMainPageContext = createContext<MobileMainPageContextData>(
  undefined as unknown as MobileMainPageContextData,
)
