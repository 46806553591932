import { OrderSide, TimeInForce } from "./types"

export function getOppositeSide(side: OrderSide): OrderSide {
  return side === "bid" ? "ask" : "bid"
}

export function tifToLongString(tif: TimeInForce): string {
  switch (tif) {
    case "ioc":
      return "Immediate or Cancel"
    case "fok":
      return "Fill or Kill"
    case "gtc":
      return "Good til Cancel"
    case "gtd":
      return "Good til Deadline"
  }
}

// This utility type maps all properties of type TFrom to TTo.
export type ShallowMapProp<T extends object, TFrom, TTo> = {
  [K in keyof T]: Exclude<T[K], undefined> extends TFrom ? TTo : T[K]
}
