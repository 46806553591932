import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"

import { Chain } from "silverkoi"

import * as priceApi from "../../api/price"
import { QueryFnArgs, QueryKey } from "./types"

const MarketStatsSchema = z.object({
  daily_stats: z.object({
    high: z.number(),
    low: z.number(),
    change_pct: z.number(),
    volume: z.number(),
  }),
  total_stats: z.object({
    volume: z.number(),
  }),
})

export type MarketStats = z.infer<typeof MarketStatsSchema>

export function useMarketStats(chain: Chain, symbol: string) {
  const url = `${priceApi.pricefeedHttpUrl()}/stats`

  interface Args {
    symbol: string
  }

  const queryKey: [QueryKey<Args>] = [
    { tag: { type: "pricefeed", key: "market-stats" }, args: { symbol } },
  ]

  const queryFn = async ({ queryKey }: QueryFnArgs<Args>) => {
    const { symbol } = queryKey[0].args
    const params = { chain, symbol }
    const response = await axios.get(url, { params })
    const marketStats = MarketStatsSchema.parse(response.data)
    return marketStats
  }

  const query = useQuery({
    queryKey,
    queryFn,
    refetchInterval: 5000,
  })
  return { ...query, data: query.data ?? undefined }
}
